<simple-change-tab [form]="form()">
  <div class="change-section-layout-wrapper">
    <div class="change-section-layout">
      <div class="change-section">
        <div class="change-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-select-button
          [label]="'Prioritized' | translate"
          [name]="propertyStrings.priority"
          [form]="form()"
          [items]="selectablePriorityItems()"
          [disabled]="priorityIsDisabled()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select-button>

        @if (choice() && priorityIsDisabled()) {
          <div class="info-text-wrapper">
            <simple-form-info
              [firstText]="'Choice' | translate"
              [linkText]="choice().value"
              [linkHref]="disabledPriorityLink()"
              [secondText]="'DisabledPriorityInfoText' | translate"
              [name]="choice().value"
            >
            </simple-form-info>
          </div>
        }

        <simple-form-select-button
          [label]="'Status' | translate"
          [name]="propertyStrings.status"
          [form]="form()"
          [items]="selectableStatusItems()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select-button>

        @if (
          modifiedModel().status !== stringUtils.ACTIVE &&
          modifiedModel().status !== stringUtils.NOT_STARTED &&
          modifiedModel().status !== stringUtils.STARTED
        ) {
          <simple-form-input
            [label]="'Notes' | translate"
            [name]="propertyStrings.notes"
            [form]="form()"
            [maxlength]="500"
            [serverErrors]="serverErrors()"
            [useTextarea]="true"
          >
          </simple-form-input>
        }
      </div>
      <div class="change-section">
        <div class="change-section-header">
          {{ "Other" | translate }}
        </div>
        <simple-form-input
          [label]="'AdminComment' | translate"
          [name]="propertyStrings.adminComment"
          [form]="form()"
          [maxlength]="500"
          [serverErrors]="serverErrors()"
          [useTextarea]="true"
        >
        </simple-form-input>
      </div>
    </div>
  </div>
</simple-change-tab>
