<div
  class="form-select-button"
  [formGroup]="form()"
  [ngClass]="{
    disabled: disabled(),
  }"
>
  <div
    class="label-wrapper"
    [ngClass]="{
      focused: focused(),
      'has-errors': hasErrors(),
    }"
  >
    <label [for]="name()">
      {{ label() }}
    </label>
    <label [for]="name()" class="secondary-label">
      {{
        !isRequired() ? "" : " (" + ("Required" | translate | lowercase) + ")"
      }}
    </label>
  </div>
  <p-selectbutton
    [id]="name()"
    [formControlName]="name()"
    [disabled]="disabled()"
    [options]="items()"
    [multiple]="multiple()"
    optionLabel="name"
    optionValue="id"
    [allowEmpty]="multiple()"
    [styleClass]="disabled() ? 'disabled' : ''"
    [ngClass]="{
      'red-background': redBackground(),
      'green-background': greenBackground(),
    }"
  >
    <ng-template #item let-item>
      <div class="item" [title]="item.name | translate">
        {{ item.name | translate }}
      </div>
    </ng-template>
  </p-selectbutton>
  <simple-form-error
    [name]="name()"
    [form]="form()"
    [serverErrors]="serverErrors()"
  >
  </simple-form-error>
</div>
