import { LowerCasePipe, NgClass, NgStyle } from "@angular/common";
import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleTableColumn } from "app/components/simple-components/list/table/columns/simple-table-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { Skeleton } from "primeng/skeleton";

@Component({
  selector: "list-skeleton",
  templateUrl: "./list-skeleton.component.html",
  styleUrls: ["./list-skeleton.component.less"],
  standalone: true,
  imports: [Skeleton, NgStyle, TranslateModule, LowerCasePipe, NgClass],
})
export class ListSkeletonComponent<T> {
  columns = input<SimpleTableColumn<T>[]>();
  emptyState = input<SimpleTableEmptyState>();
  displayMode = input<"pending" | "empty">();
  inModal = input<boolean>();
  inWizard = input<boolean>();
  hasActiveSearchOrFilter = input<boolean>();

  get fakeRowCount() {
    const wrapper = document.querySelector(".list-skeleton-wrapper");
    if (wrapper) {
      const height = wrapper.getBoundingClientRect().height;
      const rowCount = Math.round(height / 50);
      return new Array(rowCount);
    }
  }
}
