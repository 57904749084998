import { LowerCasePipe, NgClass, NgStyle } from "@angular/common";
import { Component, computed, input, output, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Popover } from "primeng/popover";
import { SimpleTableRowAction } from "../body/simple-table-row-action";
import { SimpleTableColumn } from "../columns/simple-table-column";
import { SimpleTableHeaderAction } from "./simple-table-header-action";

@Component({
  selector: "simple-table-head",
  templateUrl: "./simple-table-head.component.html",
  styleUrls: ["./simple-table-head.component.less"],
  standalone: true,
  imports: [NgClass, TranslateModule, Popover, NgStyle, LowerCasePipe],
})
export class SimpleTableHeadComponent<T extends { id?: string }> {
  hovered = signal<boolean>(false);
  headerActionsOpened = signal<boolean>(false);

  columns = input<SimpleTableColumn<T>[]>();
  headerActions = input<SimpleTableHeaderAction[]>();
  rowActions = input<SimpleTableRowAction<T>[]>();
  isSingleSelect = input<boolean>();
  selectedIds = input<string[]>();
  hasSelectableData = input<boolean>();
  someAreSelected = input<boolean>();
  allAreSelected = input<boolean>();
  inModal = input<boolean>();
  inWizard = input<boolean>();

  visibleColumns = computed(() =>
    this.columns().filter((column) => !column.hidden()),
  );

  visibleHeaderActions = computed(() =>
    this.headerActions().filter((action) => !action.hidden()),
  );

  // customVisibleColumns = computed(() =>
  //   this.columns()
  //     .filter((column) => !column.customHidden)
  //     .sort((a, b) => a.customOrder - b.customOrder),
  // );

  onCheckboxClick = output();

  handleCheckboxClick() {
    this.onCheckboxClick.emit();
  }
}
