import { Component, input, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { MediaWidgetComponent } from "app/media/widget/media-widget.component";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-media-tab",
  templateUrl: "./handle-media-tab.component.html",
  standalone: true,
  imports: [HandleHeaderComponent, MediaWidgetComponent, TranslateModule],
})
export class HandleMediaTabComponent extends HandleItemsBaseDirective<MediaWidgetItem> {
  onlyImageUpload = input<boolean>();
  onlyDocumentUpload = input<boolean>();
  canToggleDefault = input<boolean>();

  mediaWidgetComponent = viewChild(MediaWidgetComponent);

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction("AddMedia", "AddMedia", StringUtils.icons.new, () =>
        this.mediaWidgetComponent().fileInput.click(),
      ),
    ]);
  }

  protected configureTableColumns() {}

  protected configureTableActions() {}

  protected configureTableEmptyState() {}
}
