<div class="login-wrapper">
  <div
    class="login-panel"
    [ngClass]="{
      loadmask: pending(),
    }"
  >
    <div class="login-panel-header">
      {{ "LoginWithCode" | translate }}
    </div>
    <div class="login-panel-body">
      <div class="login-form">
        <simple-form-input
          [label]="'EmailAddress' | translate"
          [name]="'email'"
          [form]="form()"
        >
        </simple-form-input>
        <simple-form-input
          [label]="'Code' | translate"
          [name]="'code'"
          [form]="form()"
        >
        </simple-form-input>
        <div class="info-text-wrapper">
          @if (codeWasRequested()) {
            <div class="info-text">
              {{ "ACodeWasSentToYourEmail" | translate }}!
            </div>
            <div
              class="request-code"
              [ngClass]="{ disabled: !emailIsValid() }"
              (click)="requestCode()"
            >
              {{ "RequestCodeAgain" | translate }}
            </div>
          } @else {
            <div
              class="request-code"
              [ngClass]="{ disabled: !emailIsValid() }"
              (click)="requestCode()"
            >
              {{ "RequestCode" | translate }}
            </div>
          }
        </div>
      </div>
    </div>
    <div class="login-panel-footer">
      <button
        type="button"
        class="btn btn-transparent"
        title="{{ 'UsePassword' | translate }}"
        [disabled]="pending()"
        (click)="switchToPassword()"
      >
        {{ "UsePassword" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-success"
        title="{{ 'Submit' | translate }}"
        [disabled]="!form().valid || pending()"
        (click)="loginWithCode()"
      >
        {{ "Login" | translate }}
      </button>
    </div>
    @if (pending()) {
      <dots-loader></dots-loader>
    }
  </div>
</div>
