import { RuleObject } from "app/components/simple-components/various/rules/simple-rules.component";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableRuleColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => RuleContent;

  constructor(
    labels: string[],
    getValue: (row: T) => RuleContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.RULE;
  }
}

export class RuleContent {
  ruleObjects: RuleObject[];

  constructor(ruleObjects: RuleObject[]) {
    this.ruleObjects = ruleObjects;
  }
}
