<div
  class="form-input"
  [formGroup]="form()"
  [ngClass]="{
    disabled: disabled(),
  }"
>
  <div
    class="label-wrapper"
    [ngClass]="{
      focused: focused(),
      'has-errors': hasErrors(),
    }"
  >
    <label [for]="name()">
      {{ label() }}
    </label>
    <label [for]="name()" class="secondary-label">
      {{
        !isRequired() ? "" : " (" + ("Required" | translate | lowercase) + ")"
      }}
    </label>
  </div>
  <!-- TODO skicka in submittabe true/false istället för att inte blir knas nånstans! -->
  <input
    inputTrimmer
    *ngIf="!useTextarea()"
    #input
    [id]="name()"
    [formControlName]="name()"
    [class]="'form-control submittable'"
    [ngClass]="{
      'has-errors': hasErrors(),
    }"
    [disabled]="disabled()"
    [maxlength]="maxlength()"
    [placeholder]="label()"
    (focus)="focused.set(true)"
    (blur)="focused.set(false)"
    type="{{ usePasswordType() ? 'password' : 'search' }}"
    autocomplete="off"
    spellcheck="false"
  />
  <textarea
    inputTrimmer
    *ngIf="useTextarea()"
    #input
    [id]="name()"
    [formControlName]="name()"
    [class]="'form-control apx-scrollbar'"
    [ngClass]="{
      'has-errors': hasErrors(),
    }"
    [disabled]="disabled()"
    [maxlength]="maxlength()"
    [placeholder]="label()"
    (focus)="focused.set(true)"
    (blur)="focused.set(false)"
    rows="3"
    autocomplete="off"
    spellcheck="false"
  >
  </textarea>
  <simple-form-error
    [name]="name()"
    [form]="form()"
    [serverErrors]="serverErrors()"
  >
  </simple-form-error>
</div>

<!-- todo gemensam omslutande html med stylingen på..ng contenta innehållet -->
