import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Facility } from "app/components/facility/facility";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { FacilityService } from "app/services/facility.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { ChangeAccessibilityTabComponent } from "./tabs/change-accessibility-tab.component";

@Component({
  selector: "change-accessibility-facility",
  templateUrl: "./change-accessibility-facility.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ChangeAccessibilityTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class ChangeAccessibilityFacilityComponent extends CreateEditBaseDirective<Facility> {
  private changeAccessibilityTabComponent = viewChild(
    ChangeAccessibilityTabComponent,
  );

  private facilityService = inject(FacilityService);

  async initiate() {
    this.initialModel.set(new Facility({}));
    this.modifiedModel.set(
      new Facility(Utils.getUniqueVariant(this.initialModel())),
    );
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const data = await this.facilityService.updateAccessibility(
        this.itemIds(),
        this.modifiedModel().accessible,
      );
      this.toastrService.success(
        this.translationService.instant("TheFacilitiesWereUpdated"),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeAccessibilityTabComponent().setServerErrors(error.errors);
    }
  }
}
