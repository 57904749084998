export class SortObject<T> {
  sortProperty: string;
  label: string;
  shouldBeDefault: boolean;
  direction: "Ascending" | "Descending";
  hidden: () => boolean;

  constructor(
    sortProperty: keyof T,
    label: string,
    shouldBeDefault?: boolean,
    direction?: "Ascending" | "Descending",
    hidden?: () => boolean,
  ) {
    this.sortProperty = sortProperty ? (sortProperty as string) : "";
    this.label = label;
    this.shouldBeDefault = shouldBeDefault ? shouldBeDefault : false;
    this.direction = direction ? direction : "Ascending";
    this.hidden = hidden ? hidden : () => false;
  }
}
