<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.name"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="
              isEdit
                ? ('AssignmentTemplateChangeDetailsEditHelpText' | translate)
                : ('AssignmentTemplateChangeDetailsHelpText' | translate)
            "
          >
          </simple-tab-heading>
        </p-tab>
        @if (globalState.loggedInUser().isSuperAdmin()) {
          <p-tab [value]="1">
            <simple-tab-heading
              [icon]="'ii-facility'"
              [title]="'Facilities' | translate"
              [helpText]="
                isEdit
                  ? ('AssignmentTemplateHandleFacilitiesEditHelpText'
                    | translate)
                  : ('AssignmentTemplateHandleFacilitiesHelpText' | translate)
              "
              [displayCount]="modifiedModel().facilities.length"
            >
            </simple-tab-heading>
          </p-tab>
          <p-tab [value]="2">
            <simple-tab-heading
              [icon]="'ii-task'"
              [title]="'FacilityTasks' | translate"
              [helpText]="
                isEdit
                  ? ('AssignmentTemplateHandleFacilityTasksEditHelpText'
                    | translate)
                  : ('AssignmentTemplateHandleFacilityTasksHelpText'
                    | translate)
              "
              [displayCount]="modifiedModel().facilityTasks.length"
            >
            </simple-tab-heading>
          </p-tab>
          <p-tab [value]="3">
            <simple-tab-heading
              [icon]="'ii-entities'"
              [title]="'Entities' | translate"
              [helpText]="
                isEdit
                  ? ('AssignmentTemplateHandleEntitiesEditHelpText' | translate)
                  : ('AssignmentTemplateHandleEntitiesHelpText' | translate)
              "
              [displayCount]="modifiedModel().entities.length"
            >
            </simple-tab-heading>
          </p-tab>
          <p-tab [value]="4">
            <simple-tab-heading
              [icon]="'ii-task'"
              [title]="'EntityTasks' | translate"
              [helpText]="
                'AssignmentTemplateHandleEntityTasksHelpText' | translate
              "
              [displayCount]="modifiedModel().entityTasks.length"
            >
            </simple-tab-heading>
          </p-tab>
        }
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <change-details-tab
            [modifiedModel]="modifiedModel()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </change-details-tab>
        </p-tabpanel>
        @if (globalState.loggedInUser().isSuperAdmin()) {
          <p-tabpanel [value]="1">
            <handle-facilities-tab
              [items]="modifiedModel().facilities"
              [headerSubtitleText]="
                'AddedFacilitiesSubtitleAssignmentTemplate' | translate
              "
              [displayOnlyFacilitiesWithPositionDataInList]="
                modifiedModel().geoControlled
              "
              [readonly]="isEdit"
              (onItemsChange)="
                handleItemsChange(propertyStrings.facilities, $event);
                handleItemsChangeExtra()
              "
            >
            </handle-facilities-tab>
          </p-tabpanel>
          <p-tabpanel [value]="2">
            <handle-tasks-tab
              #handleFacilityTasksComponent
              [items]="modifiedModel().facilityTasks"
              [headerSubtitleText]="
                'AddedTasksSubtitleAssignmentTemplate' | translate
              "
              [readonlyChoices]="true"
              [includeChoicesInList]="true"
              [templateTypeIdForList]="modifiedModel().templateTypeId"
              (onItemsChange)="
                handleItemsChange(propertyStrings.facilityTasks, $event)
              "
            >
            </handle-tasks-tab>
          </p-tabpanel>
          <p-tabpanel [value]="3">
            <handle-entities-tab
              [items]="modifiedModel().entities"
              [headerSubtitleText]="
                'AddedEntitiesSubtitleAssignmentTemplate' | translate
              "
              [facilityIds]="facilityIds()"
              [hideFacilityColumn]="isEdit"
              (onItemsChange)="
                handleItemsChange(propertyStrings.entities, $event)
              "
            >
            </handle-entities-tab>
          </p-tabpanel>
          <p-tabpanel [value]="4">
            <handle-tasks-tab
              #handleEntityTasksComponent
              [items]="modifiedModel().entityTasks"
              [headerSubtitleText]="
                'AddedTasksSubtitleAssignmentTemplate' | translate
              "
              [readonlyChoices]="true"
              [includeChoicesInList]="true"
              [templateTypeIdForList]="modifiedModel().templateTypeId"
              (onItemsChange)="
                handleItemsChange(propertyStrings.entityTasks, $event)
              "
            >
            </handle-tasks-tab>
          </p-tabpanel>
        }
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
