import { NgClass } from "@angular/common";
import { Component, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { Category } from "app/components/simple-components/various/categories/simple-categories.component";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormInfoComponent } from "../../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-info/simple-form-info.component";
import { SimpleFormSelectComponent } from "../../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { SimpleChangeTabComponent } from "../../../../../../app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { SimpleFormSelectButtonComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-select-button/simple-form-select-button.component";

@Component({
  selector: "change-details-tab",
  templateUrl: "./change-details-tab.component.html",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormSelectComponent,
    SimpleFormInfoComponent,
    TranslateModule,
    NgClass,
    SimpleFormSelectButtonComponent,
  ],
})
export class ChangeDetailsTabComponent extends ChangeDetailsBaseDirective<AssignmentTemplate> {
  selectableTemplateTypeItems = signal<FormSelectItem[]>([]);
  selectableClientItems = signal<FormSelectItem[]>([]);
  selectableCategoryItems = signal<FormSelectItem[]>([]);
  selectableGeoFencingItems = signal<FormSelectItem[]>([]);

  clientsIsDisabled = signal<boolean>(false);
  geoFencingIsDisabled = signal<boolean>(false);

  disabledClientLink = signal<string>("");
  disabledGeoFencingLink = signal<string>("");

  ngOnInit() {
    this.setSelectableTemplateTypeItems();
    this.setSelectableClientItems();
    this.setSelectableCategoryItems();
    this.setSelectableGeoFencingItems();

    this.clientsIsDisabled.set(!this.selectableClientItems().length);
    this.geoFencingIsDisabled.set(
      this.isEdit &&
        !this.modifiedModel().geoControlled &&
        !this.modifiedModel().facility.hasPosition,
    );

    this.disabledClientLink.set(this.globalState.selectedOrganization().url);
    this.disabledGeoFencingLink.set(this.modifiedModel().facility?.url);

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.templateTypeId]: [
          this.modifiedModel().templateTypeId,
          Validators.required,
        ],
        [this.propertyStrings.clientId]: [this.modifiedModel().clientId],
        [this.propertyStrings.categoryIds]: [this.modifiedModel().categoryIds],
        [this.propertyStrings.geoControlled]: [
          this.modifiedModel().geoControlled,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableTemplateTypeItems() {
    this.selectableTemplateTypeItems.set(
      this.modifiedModel().selectableTemplateTypes.map(
        (templateType) =>
          new FormSelectItem(templateType.id, templateType.name),
      ),
    );
  }

  private setSelectableClientItems() {
    this.selectableClientItems.set(
      this.modifiedModel().selectableClients.map(
        (client) =>
          new FormSelectItem(
            client.id,
            `${client.name} ${client.email ? "(" + client.email + ")" : ""}`,
          ),
      ),
    );
  }

  private setSelectableCategoryItems() {
    this.selectableCategoryItems.set(
      this.modifiedModel().selectableCategories.map(
        (category) => new FormSelectItem(category.id, category.name),
      ),
    );
  }

  private setSelectableGeoFencingItems() {
    this.selectableGeoFencingItems.set([
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
    ]);
  }

  handleAddNewItemInSelect(item: FormSelectItem) {
    const category = new Category({
      id: item.id,
      name: item.name,
    });
    this.modifiedModel().selectableCategories.push(category);
  }
}
