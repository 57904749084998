import { RoutesUtils } from "app/tools/routes-utils";
import { Assignment, AssignmentPayload } from "./assignment";
import { ScheduledAssignment } from "./scheduled-assignment";

export class MeasureAssignment extends Assignment {
  constructor(assignment: Partial<MeasureAssignment>) {
    super(assignment);
    if (this.errorAssignment) {
      this.errorAssignment = new ScheduledAssignment(this.errorAssignment);
    }
    this.setUrl(this.correctRoute);
  }

  get correctRoute() {
    if (this.isArchivedAssignment()) {
      return `${RoutesUtils.assignments}/${RoutesUtils.measureArchived}`;
    } else {
      return `${RoutesUtils.assignments}/${RoutesUtils.measureOngoing}`;
    }
  }

  get isManualMeasureAssignment() {
    return this.errorAssignment?.assignmentTemplate?.templateType
      ?.isManualMeasure;
  }

  toAdminCommentPriorityPayloadObject() {
    return new AssignmentPayload({
      id: this.id,
      priority: this.priority,
      adminComment: this.adminComment,
    });
  }

  toStatusPayloadObject(orgId: number) {
    return new AssignmentPayload({
      id: this.id,
      status: this.status,
      notes: this.notes,
    });
  }
}
