<div class="rules apx-scrollbar">
  @for (rule of rules(); track $index) {
    <div
      class="rule {{ rule.icon }}"
      [ngClass]="{
        editable: editable(),
      }"
      [title]="rule.title | translate"
      (click)="editable() ? handleRuleClick(rule) : null"
    ></div>
  }
</div>
