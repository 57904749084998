import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableUserColumn,
  UserContent,
} from "app/components/simple-components/list/table/columns/simple-table-user-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { ListUserComponent } from "app/components/user/list-user/list-user.component";
import { User } from "app/components/user/user";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-users-tab",
  templateUrl: "./handle-users-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListUserComponent,
    TranslateModule,
  ],
})
export class HandleUsersTabComponent extends HandleItemsBaseDirective<User> {
  visibleListUser = signal<boolean>(false);

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "AddAssignees",
        "AddAssignees",
        StringUtils.icons.new,
        () => this.visibleListUser.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableUserColumn(
        ["Name", "EmailAddress"],
        (row) => new UserContent(row),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.getCurrentStatus(this.globalState.selectedOrganization().id),
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Users", StringUtils.icons.user),
    );
  }

  handleCloseListUser() {
    this.visibleListUser.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListUser(items: User[]) {
    this.handleCloseListUser();
    this.items.set(items.map((item) => new User(item)));
    this.onItemsChange.emit(this.items());
  }
}
