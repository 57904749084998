import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { HandleMediaTabComponent } from "app/media/handle-media/handle-media-tab.component";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { EntityService } from "../../../services/entity.service";
import { Entity } from "../entity";
import { ChangeDetailsTabComponent } from "./tabs/change-details/change-details-tab.component";
import { ViewDetailsTabComponent } from "./tabs/view-details/view-details-tab.component";

@Component({
  selector: "create-edit-entity",
  templateUrl: "./create-edit-entity.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ViewDetailsTabComponent,
    ChangeDetailsTabComponent,
    HandleMediaTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditEntityComponent extends CreateEditBaseDirective<Entity> {
  private viewDetailsTabComponent = viewChild(ViewDetailsTabComponent);
  private changeDetailsTabComponent = viewChild(ChangeDetailsTabComponent);

  private entityService = inject(EntityService);

  async initiate() {
    this.pending.set(true);
    try {
      if (this.isEdit) {
        const facilities = await this.entityService.getFacilities();

        const data = await this.entityService.get(this.itemIds()[0]);
        this.initialModel.set(data);
        this.pending.set(false);

        this.initialModel().selectableFacilities = facilities;

        this.modifiedModel.set(
          new Entity(Utils.getUniqueVariant(this.initialModel())),
        );
      } else {
        const facilities = await this.entityService.getFacilities();
        this.pending.set(false);

        this.initialModel.set(
          new Entity({
            selectableFacilities: facilities,
          }),
        );

        this.modifiedModel.set(
          new Entity(Utils.getUniqueVariant(this.initialModel())),
        );
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.entityService.update(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          )
        : await this.entityService.create(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          );
      const uploadMediaPromises: Promise<any>[] = [];
      const existingMedia: MediaWidgetItem[] = [];
      this.modifiedModel().media.forEach((item) => {
        if (item.blob) {
          const uploadMediaPromise = this.entityService.uploadMedia(
            data.id,
            item,
          );
          uploadMediaPromises.push(uploadMediaPromise);
        } else {
          existingMedia.push(item);
        }
      });
      if (this.isEdit) {
        const uploadMediaResponses = await Promise.all(uploadMediaPromises);

        const uploadedMedia = uploadMediaResponses.map((obj) => {
          const data = JSON.parse(obj["response"]).data;
          return new MediaWidgetItem(data);
        });
        const defaultIsAmongUploaded = uploadedMedia.some(
          (item) => item.default,
        );
        if (defaultIsAmongUploaded) {
          existingMedia.forEach((item) => (item.default = false));
        }
        const allMedia = existingMedia.concat(uploadedMedia);
        await this.entityService.assignMedia(data.id, allMedia);
        this.toastrService.success(
          this.translationService.instant("TheEntityWasUpdated"),
        );
        this.onRequestDone.emit([data]);
      } else {
        await Promise.all(uploadMediaPromises);
        this.toastrService.success(
          this.translationService.instant("TheEntityWasCreated"),
        );
        this.onRequestDone.emit([data]);
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeDetailsTabComponent().setServerErrors(error.errors);
    }
  }

  handleDetailsChangeExtra() {
    if (this.changeDetailsTabComponent()) {
      const latCtrl = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.latitude);
      const longCtrl = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.longitude);

      if (latCtrl.value && latCtrl.valid && longCtrl.value && longCtrl.valid) {
        this.refreshMap(
          latCtrl.value.replace(",", "."),
          longCtrl.value.replace(",", "."),
        );
      }
    }
  }

  private refreshMap(lat: string, long: string) {
    const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(long));

    this.changeDetailsTabComponent()
      .googleMapsComponent()
      .updateLocation(latLng);
    this.changeDetailsTabComponent().googleMapsComponent().displayMarker();
    this.changeDetailsTabComponent().googleMapsComponent().determineZoom();

    if (this.isEdit && this.viewDetailsTabComponent().googleMapsComponent()) {
      this.viewDetailsTabComponent()
        .googleMapsComponent()
        .updateLocation(latLng);
      this.viewDetailsTabComponent().googleMapsComponent().displayMarker();
      this.viewDetailsTabComponent().googleMapsComponent().determineZoom();
    }
  }
}
