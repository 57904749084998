import { Component, inject, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Choice } from "app/components/choice/choice";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { Task } from "app/components/task/task";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { StringUtils } from "../../../../app/tools/string-utils";
import { TemplateTypeService } from "../../../services/template-type.service";
import { TemplateType } from "../template-type";
import { ChangeDetailsTabComponent } from "./tabs/change-details/change-details-tab.component";
import { HandleTasksTabComponent } from "./tabs/handle-tasks/handle-tasks-tab.component";

@Component({
  selector: "create-edit-template-type",
  templateUrl: "./create-edit-template-type.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ChangeDetailsTabComponent,
    HandleTasksTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditTemplateTypeComponent extends CreateEditBaseDirective<TemplateType> {
  tasksAreValid = signal<boolean>(false);
  tasksHasErrors = signal<boolean>(false);
  notificationChoiceIsValid = signal<boolean>(false);
  notificationChoiceHasErrors = signal<boolean>(false);

  private changeDetailsTabComponent = viewChild(ChangeDetailsTabComponent);

  private templateTypeService = inject(TemplateTypeService);

  async initiate() {
    this.pending.set(true);
    try {
      if (this.isEdit) {
        const data = await this.templateTypeService.get(this.itemIds()[0]);
        this.initialModel.set(data);
        this.pending.set(false);

        this.initialModel().selectableTemplateBaseTypes.push(
          this.initialModel().templateBaseType,
        );

        this.modifiedModel.set(
          new TemplateType(Utils.getUniqueVariant(this.initialModel())),
        );
      } else {
        const templateBaseTypes =
          await this.templateTypeService.getTemplateBaseTypes(
            true,
            this.globalState.selectedOrganization().id,
          );

        // Abort if no template base types.
        if (!templateBaseTypes.length) {
          this.toastrService.error(
            `${this.translationService.instant(
              StringUtils.NO_TEMPLATE_BASE_TYPE_ERROR,
            )}!`,
          );
          this.handleClose();
          return;
        }
        this.pending.set(false);

        const standardTemplateBaseType = templateBaseTypes.find(
          (templateBaseType) => Utils.isStandard(templateBaseType),
        );
        this.initialModel.set(
          new TemplateType({
            templateBaseTypeId: standardTemplateBaseType.id,
            selectableTemplateBaseTypes: templateBaseTypes,
          }),
        );

        this.modifiedModel.set(
          new TemplateType(Utils.getUniqueVariant(this.initialModel())),
        );
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.templateTypeService.updateWithAffectedEntities(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          )
        : await this.templateTypeService.create(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          );

      this.toastrService.success(
        this.translationService.instant("TheTemplateTypeWasUpdated"),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeDetailsTabComponent().setServerErrors(error.errors);
    }
  }

  handleItemsChangeExtra(tasks: Task[]) {
    this.validateTasks(tasks);

    if (this.modifiedModel().notificationChoice) {
      this.validateNotificationChoice();
    }
  }

  private validateTasks(tasks: Task[]) {
    tasks.forEach((task) => {
      const hasChoices = task.choices.length;
      const requiredOrDefaultTaskHasNotONEDefaultChoice =
        (task.isRequired || task.isDefault) &&
        task.choices.filter((choice) => choice.isDefault).length !== 1;
      const taskHasUnspecifiedChoicesOnly =
        hasChoices && task.choices.every((choice) => choice.isUnspecified);

      task.errorMessages = [];
      if (!hasChoices) {
        task.errorMessages.push("TheTaskNeedsAtLeastOneChoice");
      }
      if (requiredOrDefaultTaskHasNotONEDefaultChoice) {
        task.errorMessages.push("TheTaskNeedsONEDefaultChoice");
      }
      if (taskHasUnspecifiedChoicesOnly) {
        task.errorMessages.push("TheTaskMayNotHaveUnspecifiedChoicesOnly");
      }
    });

    const alltasksHaveChoices =
      this.modifiedModel().tasks.length &&
      this.modifiedModel().tasks.every((task) => !!task.choices.length);

    const allDefaultOrRequiredTasksHaveOneDefaultChoiceOnly =
      this.modifiedModel()
        .tasks.filter(
          (task) => task.choices.length && (task.isDefault || task.isRequired),
        )
        .every(
          (task) =>
            task.choices.filter((choice) => choice.isDefault).length === 1,
        );

    const someTasksHasUnspecifiedChoicesOnly = this.modifiedModel()
      .tasks.filter((task) => task.choices.length)
      .some((task) => task.choices.every((choice) => choice.isUnspecified));

    this.tasksAreValid.set(
      alltasksHaveChoices &&
        allDefaultOrRequiredTasksHaveOneDefaultChoiceOnly &&
        !someTasksHasUnspecifiedChoicesOnly,
    );

    this.tasksHasErrors.set(
      !!this.modifiedModel().tasks.find((task) => !!task.errorMessages.length),
    );
  }

  private validateNotificationChoice() {
    const isAnErrorChoice = this.modifiedModel().notificationChoice.isError;
    const existsInSelectedChoices = this.modifiedModel()
      .tasks.flatMap((tasks) => tasks.choices)
      .some(
        (choice) => choice.id === this.modifiedModel().notificationChoice.id,
      );
    this.changeDetailsTabComponent().notificationChoiceErrorMessages.set([]);
    if (!isAnErrorChoice) {
      this.changeDetailsTabComponent()
        .notificationChoiceErrorMessages()
        .push("TheChoiceMustBeAnErrorChoice");
    }
    if (!existsInSelectedChoices) {
      this.changeDetailsTabComponent()
        .notificationChoiceErrorMessages()
        .push("TheChoiceMustExistInTheSelectedChoices");
    }

    this.notificationChoiceIsValid.set(
      !this.modifiedModel().notificationChoice ||
        (this.modifiedModel().notificationChoice.isError &&
          this.modifiedModel()
            .tasks.flatMap((task) => task.choices)
            .some(
              (choice) =>
                choice.id === this.modifiedModel().notificationChoice.id,
            )),
    );

    this.notificationChoiceHasErrors.set(
      !!this.changeDetailsTabComponent().notificationChoiceErrorMessages.length,
    );
  }

  handleUpdatedChoices(updatedChoices: Choice[]) {
    if (this.modifiedModel().notificationChoice) {
      const choiceToUpdateFrom = updatedChoices.find(
        (choice) => choice.id === this.modifiedModel().notificationChoice.id,
      );
      if (choiceToUpdateFrom) {
        Object.assign(
          this.modifiedModel().notificationChoice,
          choiceToUpdateFrom,
        );
        this.validateTasks(this.modifiedModel().tasks);
      }
    }
  }

  handleDeletedChoices(deletedChoiceIds: string[]) {
    if (
      this.modifiedModel().notificationChoice &&
      deletedChoiceIds.includes(this.modifiedModel().notificationChoice.id)
    ) {
      this.modifiedModel().notificationChoice = undefined;
      this.changeDetailsTabComponent().notificationChoiceErrorMessages.set([]);
    }
  }
}
