<div
  class="st-head"
  [ngClass]="{
    'in-modal': inModal(),
    'in-wizard': inWizard(),
  }"
>
  <div class="st-header">
    @for (column of visibleColumns(); track $index) {
      <div
        class="st-section"
        [ngStyle]="{
          'max-width': column.maxwidth ? column.maxwidth + 'px' : '',
        }"
      >
        <div class="st-value column-header">
          <div class="main" [title]="column.labels[0] | translate">
            {{ column.labels[0] | translate }}
          </div>
          @if (column.labels[1]) {
            <div class="secondary" [title]="column.labels[1] | translate">
              {{ column.labels[1] | translate }}
            </div>
          }
        </div>
      </div>
    }

    <div
      class="st-section actions"
      [ngStyle]="{
        width: inWizard() ? 50 * (rowActions().length + 1) + 'px' : 100 + 'px',
      }"
    >
      @if (!inWizard() && visibleHeaderActions().length) {
        <div
          class="st-actions-menu bi-three-dots-vertical"
          [ngClass]="{
            opened: headerActionsOpened(),
            disabled: !someAreSelected() || !hasSelectableData(),
          }"
          [title]="'OpenMenu' | translate"
          (click)="headerActionTemplate.toggle($event)"
        ></div>
        <p-popover
          #headerActionTemplate
          (onShow)="headerActionsOpened.set(true)"
          (onHide)="headerActionsOpened.set(false)"
        >
          <div class="popover-panel-header">
            {{ selectedIds()?.length }}
            {{ "SelectedObjects" | translate | lowercase }}
          </div>
          @for (action of headerActions(); track $index) {
            <div
              class="standard-menu-item"
              [title]="action.name | translate"
              [ngClass]="{
                'red-highlight': action.isDeleteAction,
                disabled: action.hidden(),
              }"
              (click)="action.event(); headerActionTemplate.toggle($event)"
            >
              <i [class]="action.iconName"></i>{{ action.name | translate }}
            </div>
          }
        </p-popover>
      }
      @if (inWizard()) {
        @for (action of headerActions(); track $index) {
          <div
            class="st-header-action"
            [ngClass]="{
              disabled:
                !someAreSelected() || !hasSelectableData() || action.hidden(),
            }"
            [title]="action.name | translate"
            (click)="action.event()"
          >
            <i [class]="action.iconName"></i>
          </div>
        }
      }
      <div
        class="st-checkbox"
        (mouseover)="hovered.set(true)"
        (mouseleave)="hovered.set(false)"
        [ngClass]="{
          disabled: !hasSelectableData() || isSingleSelect(),
          'bi-square':
            !hovered() &&
            !allAreSelected() &&
            hasSelectableData() &&
            !someAreSelected(),
          'bi-check-square': hovered() && !allAreSelected(),
          'bi-check-square-fill': allAreSelected() && hasSelectableData(),
          'bi-dash-square':
            someAreSelected() && !allAreSelected() && !hovered(),
        }"
        [title]="
          allAreSelected()
            ? ('UnselectAll' | translate)
            : ('SelectAll' | translate)
        "
        (click)="handleCheckboxClick()"
      ></div>
    </div>
  </div>
</div>
