import { Component, input } from "@angular/core";
import { Entity } from "app/components/entity/entity";
import { SimpleImageComponent } from "../image/simple-image.component";

@Component({
  selector: "simple-entity",
  templateUrl: "./simple-entity.component.html",
  styleUrls: ["./simple-entity.component.less"],
  standalone: true,
  imports: [SimpleImageComponent],
})
export class SimpleEntityComponent {
  entity = input<Entity>();
}
