import { Component, input } from "@angular/core";

@Component({
  selector: "simple-categories",
  templateUrl: "./simple-categories.component.html",
  styleUrls: ["./simple-categories.component.less"],
  standalone: true,
})
export class SimpleCategoriesComponent {
  categories = input<Category[]>();
}

export class Category {
  id: string = "";
  name: string = "";

  constructor(category: Partial<Category>) {
    Object.assign(this, category);
  }
}
