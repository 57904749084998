import { Component, input, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Schedule } from "app/components/schedule/schedule";
import { SimpleFormInfoComponent } from "app/components/simple-components/crud/modal/tabs/change/form/form-info/simple-form-info.component";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { SimpleChangeTabComponent } from "app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormSelectButtonComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-select-button/simple-form-select-button.component";

@Component({
  selector: "change-status-tab",
  templateUrl: "./change-status-tab.component.html",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    TranslateModule,
    SimpleFormInfoComponent,
    SimpleFormSelectButtonComponent,
  ],
})
export class ChangeStatusTabComponent extends ChangeDetailsBaseDirective<Schedule> {
  selectableStatusItems = signal<FormSelectItem[]>([]);

  affectableIds = input<string[]>();

  ngOnInit() {
    this.setSelectableStatusItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.active]: [
          this.modifiedModel().active,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableStatusItems() {
    this.selectableStatusItems.set([
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.ACTIVE),
      ),
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.INACTIVE),
      ),
    ]);
  }
}
