import {
  computed,
  Directive,
  inject,
  input,
  model,
  output,
  signal,
  ViewContainerRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalStateService } from "app/global-state/global-state.service";
import { TranslationService } from "app/services/translation.service";
import { PropertyNameGetter } from "app/tools/property-name-getter";
import { SimpleListAction } from "../list/actions/simple-list-action";
import { SimpleTableRowAction } from "../list/table/body/simple-table-row-action";
import { SimpleTableColumn } from "../list/table/columns/simple-table-column";
import { SimpleTableEmptyState } from "../list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "../list/table/head/simple-table-header-action";

@Directive()
export abstract class HandleItemsBaseDirective<T extends { id?: string }> {
  listActions = signal<SimpleListAction[]>([]);
  columns = signal<SimpleTableColumn<T>[]>([]);
  headerActions = signal<SimpleTableHeaderAction[]>([]);
  rowActions = signal<SimpleTableRowAction<T>[]>([]);
  emptyState = signal<SimpleTableEmptyState>(null);
  affectedIds = signal<string[]>([]);
  selectedIds = signal<string[]>([]);
  unselectableIds = signal<string[]>([]);
  addedIds = signal<string[]>([]);
  modifiedIds = signal<string[]>([]);
  itemIds = signal<string[]>([]);

  items = model<T[]>();
  itemAsIds = computed(() => this.items().map((item) => item.id));

  headerSubtitleText = input<string>();

  onItemsChange = output<T[]>();

  protected translationService = inject(TranslationService);
  protected sanitizer = inject(DomSanitizer);
  protected viewContainerRef = inject(ViewContainerRef);
  protected globalState = inject(GlobalStateService);

  get totalCount() {
    return this.items().length;
  }

  get selectedCount() {
    return this.selectedIds().length;
  }

  get hasSelectableData() {
    return !this.unselectableIds().length;
  }

  get someAreSelected() {
    return !!this.selectedIds().length;
  }

  get allAreSelected() {
    return (
      this.selectedIds().length && this.selectedIds().length === this.totalCount
    );
  }

  ngOnInit() {
    this.configureListActions();
    this.configureTableColumns();
    this.configureTableActions();
    this.configureTableEmptyState();
  }

  handleCheckboxClick(value: boolean) {
    if (value) {
      this.selectedIds.set(this.items().map((item) => item.id));
    } else {
      this.selectedIds.set([]);
    }
  }

  handleRowSingleClick(item: T) {
    this.handleRowCheckboxClick(item);
  }

  handleRowDoubleClick(item: T) {
    // this.itemIds.set([item.id]);
    //this.visibleCreateEdit.set(true); TODO I SENARE TASK!
  }

  handleRowCheckboxClick(item: T) {
    if (!this.selectedIds().includes(item.id)) {
      this.selectedIds.update((ids) => [...ids, item.id]);
    } else {
      this.selectedIds.update((ids) => ids.filter((id) => id !== item.id));
    }
  }

  remove() {
    this.affectedIds.update((ids) =>
      ids.filter((id) => !this.itemIds().includes(id)),
    );
    this.selectedIds.update((ids) =>
      ids.filter((id) => !this.itemIds().includes(id)),
    );
    this.unselectableIds.update((ids) =>
      ids.filter((id) => !this.itemIds().includes(id)),
    );
    this.addedIds.update((ids) =>
      ids.filter((id) => !this.itemIds().includes(id)),
    );
    this.modifiedIds.update((ids) =>
      ids.filter((id) => !this.itemIds().includes(id)),
    );
    this.items.update((items) =>
      items.filter((item) => !this.itemIds().includes(item.id)),
    );
    this.itemIds.set([]);
    this.onItemsChange.emit(this.items());
  }

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf({} as new (args?: any) => T);
  }

  protected abstract configureListActions(): void;
  protected abstract configureTableColumns(): void;
  protected abstract configureTableActions(): void;
  protected abstract configureTableEmptyState(): void;
}
