import { Component, inject, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { User } from "app/components/user/user";
import { GlobalStateService } from "app/global-state/global-state.service";
import { DatexPipe } from "app/pipes/datex.pipe";
import { SimpleImageComponent } from "../image/simple-image.component";

@Component({
  selector: "simple-user",
  templateUrl: "./simple-user.component.html",
  styleUrls: ["./simple-user.component.less"],
  standalone: true,
  imports: [TranslateModule, DatexPipe, SimpleImageComponent],
})
export class SimpleUserComponent {
  user = input<User>();

  globalState = inject(GlobalStateService);
}
