import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { ChoiceService } from "app/services/choice.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleTabHeadingComponent } from "../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { Choice } from "../choice";
import { ChangeDetailsTabComponent } from "./tabs/change-details-tab.component";

@Component({
  selector: "create-edit-choice",
  templateUrl: "./create-edit-choice.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ChangeDetailsTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditChoiceComponent extends CreateEditBaseDirective<Choice> {
  private changeDetailsTabComponent = viewChild(ChangeDetailsTabComponent);

  private choiceService = inject(ChoiceService);

  async initiate() {
    if (this.isEdit) {
      this.pending.set(true);
      try {
        const data = await this.choiceService.get(this.itemIds()[0]);
        this.initialModel.set(data);
        this.pending.set(false);

        this.modifiedModel.set(
          new Choice(Utils.getUniqueVariant(this.initialModel())),
        );
      } catch (error) {
        this.pending.set(false);
        this.toastrService.error(error.message);
        this.handleClose();
      }
    } else {
      this.initialModel.set(new Choice({}));
      this.modifiedModel.set(
        new Choice(Utils.getUniqueVariant(this.initialModel())),
      );
    }
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.choiceService.update(
            this.modifiedModel().toPayloadObject(),
            this.globalState.selectedOrganization().id,
          )
        : await this.choiceService.create(
            this.modifiedModel().toPayloadObject(),
            this.globalState.selectedOrganization().id,
          );

      this.toastrService.success(
        this.translationService.instant("TheChoiceWasUpdated"),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeDetailsTabComponent().setServerErrors(error.errors);
    }
  }
}
