import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { RuleObject } from "app/components/simple-components/various/rules/simple-rules.component";
import { RoutesUtils } from "app/tools/routes-utils";
import { Choice } from "../choice/choice";

export class Task extends CrudItem<Task> {
  text: string = "";
  code: string = "";
  description: string = "";
  isRequired: boolean = false;
  isDefault: boolean = false;
  allowComment: boolean = false;
  allowMedia: boolean = false;
  choices: Choice[] = [];

  choice: Choice = null;

  get displayName() {
    return this.text;
  }

  get rulesReadonly() {
    const rules: RuleObject[] = [];
    if (this.isRequired) {
      rules.push(
        new RuleObject({
          icon: "bi bi-exclamation-square green",
          title: "IsRequired",
        }),
      );
    }
    if (this.isDefault) {
      rules.push(
        new RuleObject({
          icon: "bi bi-check-square green",
          title: "IsDefault",
        }),
      );
    }
    if (this.allowComment) {
      rules.push(
        new RuleObject({
          icon: "bi bi-pencil-square green",
          title: "AllowsComment",
        }),
      );
    }
    if (this.allowMedia) {
      rules.push(
        new RuleObject({
          icon: "bi bi-image green",
          title: "AllowsMedia",
        }),
      );
    }
    return rules;
  }

  constructor(task: Partial<Task>) {
    super();
    Object.assign(this, task);
    this.mapChoices();
    this.fixOptionalTextFieldInconsistencies();
    this.setUrl(RoutesUtils.tasks);
  }

  private mapChoices() {
    this.choices = this.choices.map((choice) => new Choice(choice));
    this.choices.sortByProperty(new Choice({}).propertyStrings.value);
    if (this.choice) {
      this.choice = new Choice(this.choice);
    }
  }

  private fixOptionalTextFieldInconsistencies() {
    if (this.code === null) {
      this.code = "";
    }
    if (this.description === null) {
      this.description = "";
    }
  }

  toPayloadObject() {
    return new TaskPayload({
      id: this.id,
      text: this.text,
      code: this.code,
      description: this.description,
      isRequired: this.isRequired,
      isDefault: this.isDefault,
      allowComment: this.allowComment,
      allowMedia: this.allowMedia,
    });
  }
}

export class TaskPayload extends Task {
  constructor(taskPayload: Partial<TaskPayload>) {
    super(taskPayload);
    Object.assign(this, taskPayload);
  }
}
