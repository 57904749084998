import { LowerCasePipe, NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DatePicker } from "primeng/datepicker";
import { SimpleFormErrorComponent } from "../form-error/simple-form-error.component";
import { SimpleFormControlDirective } from "../simple-form-control.directive";

@Component({
  selector: "simple-form-time",
  templateUrl: "./simple-form-time.component.html",
  styleUrls: ["./simple-form-time.component.less"],
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    SimpleFormErrorComponent,
    LowerCasePipe,
    DatePicker,
    ReactiveFormsModule,
  ],
})
export class SimpleFormTimeComponent extends SimpleFormControlDirective {}
