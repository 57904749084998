import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableTextColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => TextContent;

  constructor(
    labels: string[],
    getValue: (row: T) => TextContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.TEXT;
  }
}

export class TextContent {
  firstText: string;
  secondText: string;

  constructor(firstText: string, secondText?: string) {
    this.firstText = firstText;
    this.secondText = secondText;
  }
}
