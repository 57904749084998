import { NgClass } from "@angular/common";
import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ColorUtils } from "app/tools/color-utils";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "simple-status-label",
  templateUrl: "./simple-status-label.component.html",
  styleUrls: ["./simple-status-label.component.less"],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class SimpleStatusLabelComponent {
  stringUtils = StringUtils;

  text = input<string>();

  getStatusColor() {
    return ColorUtils.getStatusColor(this.text());
  }
}
