import { Injectable } from "@angular/core";
import { User } from "app/components/user/user";
import { Filter, KeyValuePair, SortDirection } from "app/filter";
import { BaseService } from "app/services/base.service";
import { NumberUtils } from "app/tools/number-utils";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import {
  UserGroup,
  UserGroupPayload,
} from "../components/user-group/user-group";

@Injectable({
  providedIn: "root",
})
export class UserGroupService extends BaseService<UserGroup> {
  prefix = RoutesUtils.userGroup;
  useCoreApi = true;

  constructor() {
    super(UserGroup);
  }

  async get(id: string): Promise<UserGroup> {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/${id}`,
        true,
      );
      return new UserGroup(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: UserGroupPayload): Promise<UserGroup> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}`,
        item,
        true,
      );
      return new UserGroup(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: UserGroupPayload): Promise<UserGroup> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
        true,
      );
      return new UserGroup(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.delete(
        `${this.prefix}/${id}`,
        true,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(itemIds: string[]): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        { itemIds: itemIds },
        true,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getUsers(organizationId: number): Promise<User[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<User>("firstname")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `user?organization=${organizationId}`,
        filter.toPayloadObject(),
        true,
      );
      return this.extractData(response).map((item: User) => new User(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async deleteUserGroupsWithAffectedData(
    userGroupIds: string[],
    organizationId: number,
  ) {
    try {
      const model = {
        itemIds: userGroupIds,
        organizationId: organizationId,
      };
      const response = await this.hostedHttpClient.deleteWithBody(
        `userGroup/affectedData`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class UserGroupFilter extends Filter {
  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.ASSIGNEES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.USER_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.USER_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.HAS_USERS_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_USERS_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_USERS_KEY] = "false";
        }
      }
    });

    Object.assign(this.facets, tempFacets);

    return {
      filter: JSON.stringify(this),
    };
  }
}
