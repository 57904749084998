import { NgTemplateOutlet } from "@angular/common";
import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Assignment } from "app/components/assignment/assignment";
import { DetailedAssignmentScheduledArchivedComponent } from "app/components/assignment/detailed-assignment/scheduled/archived/detailed-assignment-scheduled-archived.component";
import { GenerateReportComponent } from "app/components/assignment/report/generated/generate-report.component";
import { GeneratedReport } from "app/components/assignment/report/generated/generated-report";
import { StaticReport } from "app/components/assignment/report/static/static-report";
import { StaticReportComponent } from "app/components/assignment/report/static/static-report.component";
import { Client } from "app/components/organization/organization";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import {
  SimpleTableRowAction,
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
  SimpleTableRowActionView,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  CategoryContent,
  SimpleTableCategoryColumn,
} from "app/components/simple-components/list/table/columns/simple-table-category-column";
import {
  ChoiceContent,
  SimpleTableChoiceColumn,
} from "app/components/simple-components/list/table/columns/simple-table-choice-column";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  IconContent,
  IconObject,
  SimpleTableIconColumn,
} from "app/components/simple-components/list/table/columns/simple-table-icon-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import {
  SimpleTableUserColumn,
  UserContent,
} from "app/components/simple-components/list/table/columns/simple-table-user-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import {
  SimpleTableHeaderAction,
  SimpleTableHeaderActionDelete,
} from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { KeyValuePair } from "app/filter";
import {
  AssignmentFilter,
  AssignmentService,
} from "app/services/assignment.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import { ListHeaderComponent } from "../../../../header/list-header/list-header.component";
import { SimpleViewClientComponent } from "../../../../simple-components/various/view-client/simple-view-client.component";

@Component({
  selector: "list-assignment-scheduled-archived",
  templateUrl: "./list-assignment-scheduled-archived.component.html",
  standalone: true,
  imports: [
    DetailedAssignmentScheduledArchivedComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    GenerateReportComponent,
    StaticReportComponent,
    NgTemplateOutlet,
    ListHeaderComponent,
    SimpleViewClientComponent,
  ],
})
export class ListAssignmentScheduledArchivedComponent extends ListBaseDirective<Assignment> {
  readonly filterObject = new AssignmentFilter();

  visibleGenerateReport = signal<boolean>(false);
  visibleGetReport = signal<boolean>(false);
  client = signal<Client>(new Client({}));
  visibleViewClient = signal<boolean>(false);

  constructor(private assignmentService: AssignmentService) {
    super(assignmentService);
  }

  protected configureListActions() {}

  protected async configureTableFilter() {
    this.filterObject.context = RoutesUtils.scheduledArchived;

    this.initialFacets.set([
      new KeyValuePair(StringUtils.IS_COMPLETED_KEY, "true"),
      new KeyValuePair(StringUtils.IS_EXPIRED_KEY, "true"),
      new KeyValuePair(StringUtils.IS_MEASURE_KEY, "false"),
      new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "false"),
      new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
    ]);

    const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
    const facilitiesKey = StringUtils.FACILITIES_KEY;
    const categoriesKey = StringUtils.CATEGORIES_KEY;
    const schedulesKey = StringUtils.SCHEDULES_KEY;
    const choicesKey = StringUtils.CHOICES_KEY;
    const completedByKey = StringUtils.COMPLETED_BY_KEY;
    const archivedBetweenKey = StringUtils.ARCHIVED_BETWEEN_KEY;
    const statusKey = StringUtils.STATUS_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [templateTypesKey]: null,
        [facilitiesKey]: null,
        [categoriesKey]: null,
        [schedulesKey]: null,
        [choicesKey]: null,
        [completedByKey]: null,
        [archivedBetweenKey]: null,
        [statusKey]: null,
      });
    }

    try {
      const [templateTypes, facilities, categories, schedules, choices, users] =
        await Promise.all([
          this.assignmentService.getTemplateTypes(
            this.globalState.selectedOrganization().id,
          ),
          this.assignmentService.getFacilities(),
          this.assignmentService.getCategories(
            this.globalState.selectedOrganization().id,
          ),
          this.assignmentService.getSchedules(
            this.globalState.selectedOrganization().id,
          ),
          this.assignmentService.getChoices(
            this.globalState.selectedOrganization().id,
          ),
          this.assignmentService.getUsers(
            this.globalState.selectedOrganization().id,
          ),
        ]);

      const convertedTemplateTypes = templateTypes
        .filter((templateType) => !templateType.isManualMeasure)
        .map(
          (templateType) =>
            new SimpleFilterInputItem(templateType.id, templateType.name),
        );
      const convertedFacilities = facilities.map(
        (facility) => new SimpleFilterInputItem(facility.id, facility.name),
      );
      const convertedCategories = categories.map(
        (category) => new SimpleFilterInputItem(category.id, category.name),
      );
      const convertedSchedules = schedules.map(
        (schedule) => new SimpleFilterInputItem(schedule.id, schedule.name),
      );
      const convertedChoices = choices.map(
        (choice) => new SimpleFilterInputItem(choice.id, choice.value),
      );
      const convertedUsers = users.map(
        (completedByUser) =>
          new SimpleFilterInputItem(completedByUser.id, completedByUser.email),
      );

      this.filterInputs.set([
        new SimpleFilterInput(
          templateTypesKey,
          templateTypesKey,
          StringUtils.icons.templateType,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, ""));
            }
            return keyValuePairs;
          },
          convertedTemplateTypes,
        ),
        new SimpleFilterInput(
          facilitiesKey,
          facilitiesKey,
          StringUtils.icons.facility,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(facilitiesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(facilitiesKey, ""));
            }
            return keyValuePairs;
          },
          convertedFacilities,
        ),
        new SimpleFilterInput(
          categoriesKey,
          categoriesKey,
          StringUtils.icons.category,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(categoriesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(categoriesKey, ""));
            }
            return keyValuePairs;
          },
          convertedCategories,
        ),
        new SimpleFilterInput(
          schedulesKey,
          schedulesKey,
          StringUtils.icons.schedule,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(schedulesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(schedulesKey, ""));
            }
            return keyValuePairs;
          },
          convertedSchedules,
        ),
        new SimpleFilterInput(
          choicesKey,
          choicesKey,
          StringUtils.icons.choice,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(choicesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(choicesKey, ""));
            }
            return keyValuePairs;
          },
          convertedChoices,
        ),
        new SimpleFilterInput(
          completedByKey,
          completedByKey,
          StringUtils.icons.user,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(completedByKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(completedByKey, ""));
            }
            return keyValuePairs;
          },
          convertedUsers,
        ),
        new SimpleFilterInput(
          statusKey,
          statusKey,
          StringUtils.icons.setStatus,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(statusKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(statusKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(
              StringUtils.COMPLETED,
              StringUtils.COMPLETED,
            ),
            new SimpleFilterInputItem(StringUtils.EXPIRED, StringUtils.EXPIRED),
          ],
        ),
        new SimpleFilterInput(
          archivedBetweenKey,
          archivedBetweenKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateRangeSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(archivedBetweenKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(archivedBetweenKey, ""));
            }
            return keyValuePairs;
          },
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(
        "AssignmentTemplate.TemplateType.Name" as keyof Assignment,
        "TemplateType",
      ),
      new SortObject(
        "AssignmentTemplate.Facility.Name" as keyof Assignment,
        "Facility",
      ),
      new SortObject("Schedule.Name" as keyof Assignment, "Schedule"),
      new SortObject(
        "Completed, DueDate" as keyof Assignment,
        "Archived",
        true,
        "Descending",
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTextColumn(
        ["TemplateType"],
        (row) => new TextContent(row.templateType.name),
      ),
      new SimpleTableCategoryColumn(
        ["Categories"],
        (item) => new CategoryContent(item.categories),
      ),
      new SimpleTableFacilityColumn(
        ["Facility"],
        (row) => new FacilityContent(row.facility),
      ),
      new SimpleTableTextColumn(
        ["Schedule"],
        (row) => new TextContent(row.schedule?.name),
      ),
      new SimpleTableChoiceColumn(
        ["Choices"],
        (row) => new ChoiceContent(row.choices),
      ),
      new SimpleTableUserColumn(
        ["CompletedBy"],
        (row) => new UserContent(row.completedByUser),
      ),
      new SimpleTableIconColumn(
        [],
        (row) =>
          new IconContent([
            new IconObject(
              row.client ? StringUtils.icons.client : "",
              "Client",
              row.client
                ? `${row.client.name} ${row.client.emailAddress ? "(" + row.client.emailAddress + ")" : ""}`
                : "",
            ),
            new IconObject(
              row.geoControlled ? StringUtils.icons.geo : "",
              "Geofencing",
              "GeofencingText",
              true,
            ),
          ]),
      ),
      new SimpleTableDateColumn(
        ["Archived"],
        (row) => new DateContent(row.archived),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) => new StatusContent(row.status),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "GenerateSummaryReport",
        StringUtils.icons.generateErrorReport,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleGenerateReport.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderAction(
        "GetAssignmentReport",
        StringUtils.icons.download,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleGetReport.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () => this.isInModal(),
      ),
    ]);

    // ROW

    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.handle,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowAction(
        "GetAssignmentReport",
        StringUtils.icons.download,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleGetReport.set(true);
        },
        (row) => !row.reportUrl || this.isInModal(),
      ),
      new SimpleTableRowAction(
        "RecreateScheduledAssignment",
        StringUtils.icons.recreateAssignment,
        (row) => {
          // this.setAsAffected([row.id]);
          // const newDueDate = new Date();
          // newDueDate.setDate(
          //   new Date().getDate() +
          //     (row.data.schedule.daysDelay ? row.data.schedule.daysDelay : 7)
          // );
          // this.administrationService.openRecreateAssignmentSwal(
          //   row.id,
          //   newDueDate.toISOString(),
          //   () => this.getTableDataAndClearAfterUpdate(row.id)
          // ); TODO
        },
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
      new SimpleTableRowAction(
        "RecreateAssignmentReport",
        StringUtils.icons.recreateReport,
        (row) => {
          // this.setAsAffected([row.id]);
          // this.administrationService.openRecreateReportSwal(
          //   row.id,
          //   this.selectedOrganization().id,
          //   () => this.getTableData()
          // ); TODO
        },
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
      new SimpleTableRowActionView(
        "ViewClient",
        StringUtils.icons.client,
        (row) => {
          this.client.set(row.client);
          this.visibleViewClient.set(true);
        },
        (row) => !row.client,
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        (row) =>
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl) ||
          this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState(
        "Assignments",
        StringUtils.icons.scheduledAssignment,
      ),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(new SwalConfig(this.translationService).getDelete()).then(
      async (result) => {
        if (result.value) {
          this.handleCloseCreateEdit();
          this.itemIds.set(ids);
          this.pending.set(true);
          try {
            const data = await this.assignmentService.deleteRange(
              this.itemIds(),
              this.globalState.selectedOrganization().id,
            );
            this.pending.set(false);
            this.toastrService.success(
              this.onlyOneSelected
                ? this.translationService.instant(
                    "TheScheduledAssignmentWasDeleted",
                  )
                : this.translationService.instant(
                    "TheScheduledAssignmentsWereDeleted",
                  ),
            );
            this.selectedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.unselectableIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.addedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.modifiedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.itemIds.set([]);
            this.getTableData();
          } catch (error) {
            this.pending.set(false);
            this.toastrService.error(error.message);
          }
        }
      },
    );
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleCloseGenerateReport() {
    this.visibleGenerateReport.set(false);
    this.itemIds.set([]);
  }

  handleCloseGetReport() {
    this.visibleGetReport.set(false);
    this.itemIds.set([]);
  }

  async handleRequestDoneGenerateReport(data: GeneratedReport[]) {
    this.handleCloseGenerateReport();
    this.clearSelectedRows();
  }

  async handleRequestDoneGetReport(data: StaticReport[]) {
    this.handleCloseGetReport();
    this.clearSelectedRows();
  }
}
