import { NgClass } from "@angular/common";
import { Component, input, output, viewChild } from "@angular/core";
import { DotsLoaderComponent } from "app/components/dots-loader/dots-loader.component";
import { KeyValuePair } from "app/filter";
import { ScrollerScrollIndexChangeEvent } from "primeng/scroller";
import { SimpleTableBodyComponent } from "./body/simple-table-body.component";
import { SimpleTableRowAction } from "./body/simple-table-row-action";
import { SimpleTableColumn } from "./columns/simple-table-column";
import { SimpleTableEmptyState } from "./empty-state/simple-table-empty-state";
import { SimpleFilterInput } from "./filter/simple-filter-input";
import { SimpleFilterComponent } from "./filter/simple-filter.component";
import { SortObject } from "./filter/sort-object";
import { SimpleTableHeadComponent } from "./head/simple-table-head.component";
import { SimpleTableHeaderAction } from "./head/simple-table-header-action";

@Component({
  selector: "simple-table",
  templateUrl: "./simple-table.component.html",
  styleUrls: ["./simple-table.component.less"],
  standalone: true,
  imports: [
    SimpleFilterComponent,
    SimpleTableHeadComponent,
    SimpleTableBodyComponent,
    DotsLoaderComponent,
    NgClass,
  ],
})
export class SimpleTableComponent<T extends { id?: string }> {
  initialFacets = input<KeyValuePair[]>();
  searchValue = input<string>();
  filterInputs = input<SimpleFilterInput[]>();
  sortObjects = input<SortObject<T>[]>();
  currentSortObject = input<SortObject<T>>();
  columns = input<SimpleTableColumn<T>[]>();
  headerActions = input<SimpleTableHeaderAction[]>();
  rowActions = input<SimpleTableRowAction<T>[]>();
  emptyState = input<SimpleTableEmptyState>();
  isSingleSelect = input<boolean>();
  filteredIds = input<string[]>();
  rows = input<T[]>();
  selectedIds = input<string[]>();
  addedIds = input<string[]>();
  modifiedIds = input<string[]>();
  unselectableIds = input<string[]>();
  pending = input<boolean>();
  inModal = input<boolean>();
  inWizard = input<boolean>();
  totalCount = input<number>();
  selectedCount = input<number>();
  hasSelectableData = input<boolean>();
  someAreSelected = input<boolean>();
  allAreSelected = input<boolean>();
  hasActiveSearchOrFilter = input<boolean>();

  onSearch = output<string>();
  onSearchClear = output();
  onSortDirectionChange = output<"Ascending" | "Descending">();
  onSortLabelChange = output<SortObject<T>>();
  onFilter = output<SimpleFilterInput>();
  onFilterClear = output();

  onCheckboxClick = output<boolean>();
  onSelectedClear = output();

  onRowCheckboxClick = output<T>();
  onRowSingleClick = output<T>();
  onRowDoubleClick = output<T>();
  onLazyLoad = output<ScrollerScrollIndexChangeEvent>();

  simpleFilter = viewChild<SimpleFilterComponent<T>>(SimpleFilterComponent);
  simpleTableHead = viewChild<SimpleTableHeadComponent<T>>(
    SimpleTableHeadComponent,
  );
  simpleTableBody = viewChild<SimpleTableBodyComponent<T>>(
    SimpleTableBodyComponent,
  );

  search(value: string) {
    this.onSearch.emit(value);
  }

  clearSearch() {
    this.onSearchClear.emit();
  }

  sortDirectionChange(direction: "Ascending" | "Descending") {
    this.onSortDirectionChange.emit(direction);
  }

  sortLabelChange(sortObject: SortObject<T>) {
    this.onSortLabelChange.emit(sortObject);
  }

  filter(filterInput: SimpleFilterInput) {
    this.onFilter.emit(filterInput);
  }

  clearFilter() {
    this.onFilterClear.emit();
  }

  clearSelected() {
    this.onSelectedClear.emit();
  }

  handleCheckboxClick() {
    if (this.allAreSelected()) {
      this.onCheckboxClick.emit(false);
    } else {
      this.onCheckboxClick.emit(true);
    }
  }

  handleRowSingleClick(row: T) {
    this.onRowSingleClick.emit(row);
  }

  handleRowDoubleClick(row: T) {
    this.onRowDoubleClick.emit(row);
  }

  handleRowCheckboxClick(row: T) {
    this.onRowCheckboxClick.emit(row);
  }

  handleLazyLoad(event: ScrollerScrollIndexChangeEvent) {
    this.onLazyLoad.emit(event);
  }

  scrollToIndex(index: number) {
    this.simpleTableBody()?.scrollerBody()?.scrollToIndex(index, "smooth");
  }
}
