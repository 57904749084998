import { NgClass, NgTemplateOutlet } from "@angular/common";
import { Component, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Choice } from "app/components/choice/choice";
import { Task } from "app/components/task/task";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { Drawer } from "primeng/drawer";
import { SimpleChoicesComponent } from "../choices/simple-choices.component";
import { SimpleImageComponent } from "../image/simple-image.component";
import { SimpleLinkButtonComponent } from "../link-button/simple-link-button.component";
import { SimpleTaskComponent } from "../task/simple-task.component";

@Component({
  selector: "simple-assessment",
  templateUrl: "./simple-assessment.component.html",
  styleUrls: ["./simple-assessment.component.less"],
  standalone: true,
  imports: [
    SimpleChoicesComponent,
    SimpleTaskComponent,
    TranslateModule,
    SimpleImageComponent,
    SimpleAssessmentComponent,
    NgClass,
    NgTemplateOutlet,
    SimpleLinkButtonComponent,
    Drawer,
  ],
})
export class SimpleAssessmentComponent {
  visible = signal<boolean>(false);

  choice = input<Choice>();
  task = input<Task>();
  comment = input<string>();
  media = input<MediaWidgetItem[]>();
  displayInfoOnClick = input<boolean>();
  displayInfoDirectly = input<boolean>();
  infoText = input<string>();
  url = input<string>();
  linkText = input<string>();
}
