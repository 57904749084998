import { Choice } from "app/components/choice/choice";
import { Task } from "app/components/task/task";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableAssessmentColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => AssessmentContent;

  constructor(
    labels: string[],
    getValue: (row: T) => AssessmentContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.ASSESSMENT;
  }
}

export class AssessmentContent {
  choice: Choice;
  task: Task;

  constructor(choice: Choice, task: Task) {
    this.choice = choice;
    this.task = task;
  }
}
