<simple-change-tab [form]="form()">
  <div class="change-section-layout-wrapper">
    <div class="change-section-layout">
      <div class="change-section">
        <div class="change-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Name' | translate"
          [name]="propertyStrings.name"
          [form]="form()"
          [maxlength]="150"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!isEdit"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'Description' | translate"
          [name]="propertyStrings.description"
          [form]="form()"
          [maxlength]="500"
          [serverErrors]="serverErrors()"
          [useTextarea]="true"
        >
        </simple-form-input>

        <div class="form-control-wrapper">
          <simple-form-input
            class="stretch"
            [label]="'StreetName' | translate"
            [name]="propertyStrings.streetName"
            [form]="form()"
            [maxlength]="50"
            [serverErrors]="serverErrors()"
          >
          </simple-form-input>

          <simple-form-input
            class="fixed-width"
            [label]="'StreetNumber' | translate"
            [name]="propertyStrings.streetNumber"
            [form]="form()"
            [maxlength]="15"
            [serverErrors]="serverErrors()"
          >
          </simple-form-input>
        </div>

        <div class="form-control-wrapper">
          <simple-form-input
            class="fixed-width"
            [label]="'ZipCode' | translate"
            [name]="propertyStrings.zipCode"
            [form]="form()"
            [maxlength]="15"
            [serverErrors]="serverErrors()"
          >
          </simple-form-input>

          <simple-form-input
            class="stretch"
            [label]="'City' | translate"
            [name]="propertyStrings.city"
            [form]="form()"
            [maxlength]="25"
            [serverErrors]="serverErrors()"
          >
          </simple-form-input>
          <div
            class="text-button"
            [ngClass]="{
              disabled: !validLocationData(),
              shine: validLocationData(),
            }"
            (click)="getAddressFromLocation()"
          >
            <i class="bi-magic"></i>
            {{ "GetAddressFromLocation" | translate }}
          </div>
        </div>

        <simple-form-input
          [label]="'InfoLink' | translate"
          [name]="propertyStrings.infoLink"
          [form]="form()"
          [maxlength]="250"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-select-button
          [label]="'Accessibility' | translate"
          [name]="propertyStrings.accessible"
          [form]="form()"
          [items]="selectableAccessibilityItems()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select-button>

        <simple-form-select-button
          [label]="'Status' | translate"
          [name]="propertyStrings.status"
          [form]="form()"
          [items]="selectableStatusItems()"
          [redBackground]="!modifiedModel().status"
          [greenBackground]="modifiedModel().status"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select-button>
      </div>
    </div>
    <div class="change-section-layout">
      <div class="change-section">
        <div class="change-section-header">
          {{ "LocationData" | translate }}
        </div>
        <simple-form-input
          [label]="'Latitude' | translate"
          [name]="propertyStrings.latitude"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'Longitude' | translate"
          [name]="propertyStrings.longitude"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'Radius' | translate"
          [name]="propertyStrings.radius"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <google-maps-component
          [height]="200"
          [latitude]="modifiedModel().latitude"
          [longitude]="modifiedModel().longitude"
          [radius]="modifiedModel().radius"
          [address]="address()"
          [country]="globalState.loggedInUser().country"
          [name]="modifiedModel().name"
          (onMarkerPositionChanged)="handleMarkerMove($event)"
        >
        </google-maps-component>
        <div class="button-wrapper">
          <button
            class="btn btn-transparent"
            [ngClass]="{
              disabled: !validAddressData(),
            }"
            (click)="getLocationFromAddress()"
          >
            {{ "GetLocationFromAddress" | translate }}
          </button>
          <button class="btn btn-transparent" (click)="getCurrentLocation()">
            {{ "GetCurrentLocation" | translate }}
          </button>
        </div>
        @if (displayGeoInfoText()) {
          <simple-form-info
            [firstText]="
              '*' + ('RequiredHasGeoControlledTemplates' | translate)
            "
          >
          </simple-form-info>
        }
      </div>
      <div class="change-section">
        <div class="change-section-header">
          {{ "Other" | translate }}
        </div>

        <simple-form-editor
          [label]="'ExtraInfo' | translate"
          [name]="propertyStrings.extraInfo"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-editor>

        @if (
          globalState.selectedOrganization().hasDfIntegration ||
          globalState.selectedOrganization().hasInfraControlIntegration
        ) {
          <simple-form-input
            [label]="'ExternalId' | translate"
            [name]="propertyStrings.externalId"
            [form]="form()"
            [maxlength]="50"
            [serverErrors]="serverErrors()"
          >
          </simple-form-input>
        }
      </div>
    </div>
  </div>
</simple-change-tab>
