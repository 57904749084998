import { NgClass } from "@angular/common";
import { Component, computed, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleListAction } from "../simple-components/list/actions/simple-list-action";

@Component({
  selector: "list-actions",
  templateUrl: "./list-actions.component.html",
  styleUrls: ["./list-actions.component.less"],
  standalone: true,
  imports: [TranslateModule, NgClass],
})
export class ListActionsComponent {
  actions = input<SimpleListAction[]>();
  inModal = input<boolean>();
  inWizard = input<boolean>();

  visibleActions = computed(() =>
    this.actions().filter((action) => !action.hidden()),
  );
}
