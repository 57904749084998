import { NgClass } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Popover } from "primeng/popover";
import { ExtensiveImageComponent } from "../../extensive-image/extensive-image.component";
import { ExtensivePdfComponent } from "../../extensive-pdf/extensive-pdf.component";
import { MediaWidgetItem } from "./media-widget-item";

@Component({
  selector: "media-widget-item",
  templateUrl: "media-widget-item.component.html",
  styleUrls: ["media-widget-item.component.less"],
  standalone: true,
  imports: [
    ExtensiveImageComponent,
    ExtensivePdfComponent,
    NgClass,
    TranslateModule,
    Popover,
  ],
})
export class MediaWidgetItemComponent {
  item = input<MediaWidgetItem>();
  canToggleDefault = input<boolean>();
  viewOnly = input<boolean>();
  itemSize = input<number>();

  onOpen = output<MediaWidgetItem>();
  onToggleDefault = output<MediaWidgetItem>();
  onDownload = output<MediaWidgetItem>();
  onRemove = output<MediaWidgetItem>();

  open() {
    this.onOpen.emit(this.item());
  }

  setAsDefault() {
    this.onToggleDefault.emit(this.item());
  }

  download() {
    this.onDownload.emit(this.item());
  }

  remove() {
    this.onRemove.emit(this.item());
  }
}
