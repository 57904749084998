import { Component, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Facility } from "app/components/facility/facility";
import { GoogleMapsComponent } from "app/components/google-maps/google-maps.component";
import { SimpleViewTabComponent } from "app/components/simple-components/crud/modal/tabs/view/simple-view-tab.component";
import { ViewDetailsBaseDirective } from "app/components/simple-components/directives/view-details-base.directive";
import { SimpleStatusLabelComponent } from "../../../../../../app/components/simple-components/various/status-label/simple-status-label.component";
import { SimpleViewConnectionsComponent } from "../../../../../../app/components/simple-components/various/view-connections/simple-view-connections.component";
import { ExtensiveImageComponent } from "../../../../../media/extensive-image/extensive-image.component";
import { ParseMDPipe } from "../../../../../pipes/parse-md-pipe";
import { SimpleImageComponent } from "../../../../simple-components/various/image/simple-image.component";

@Component({
  selector: "view-details-tab",
  templateUrl: "./view-details-tab.component.html",
  standalone: true,
  imports: [
    SimpleViewTabComponent,
    SimpleStatusLabelComponent,
    GoogleMapsComponent,
    SimpleViewConnectionsComponent,
    TranslateModule,
    ParseMDPipe,
    SimpleImageComponent,
    ExtensiveImageComponent,
  ],
})
export class ViewDetailsTabComponent extends ViewDetailsBaseDirective<Facility> {
  googleMapsComponent = viewChild(GoogleMapsComponent);
}
