import { NgClass } from "@angular/common";
import {
  Component,
  ElementRef,
  inject,
  input,
  model,
  OnInit,
  output,
  signal,
  ViewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TranslationService } from "app/services/translation.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { Utils } from "app/tools/utils";
import Swal from "sweetalert2";
import { ACCEPTED_MEDIA_TYPES, FileUtils } from "../../tools/file-utils";
import { MediaUtils } from "../../tools/media-utils";
import { MediaResolver } from "../resolver/media-resolver";
import { MediaType, MediaWidgetItem } from "./item/media-widget-item";
import { MediaWidgetItemComponent } from "./item/media-widget-item.component";

@Component({
  selector: "media-widget",
  templateUrl: "media-widget.component.html",
  styleUrls: ["media-widget.component.less"],
  standalone: true,
  imports: [NgClass, MediaWidgetItemComponent, TranslateModule],
})
export class MediaWidgetComponent implements OnInit {
  media = MediaUtils;
  mediaWidgetBodyId = Utils.getRandomNumberString();

  acceptedMediaTypes = signal<string>("");

  collection = model<MediaWidgetItem[]>();
  allowMultipleUpload = input<boolean>();
  allowFileDrop = input<boolean>();
  fileSizeLimit = input<number>(FileUtils.mbToBytes(10));
  onlyImageUpload = input<boolean>();
  onlyPdfUpload = input<boolean>();
  canToggleDefault = input<boolean>(true);
  viewOnly = input<boolean>();
  itemSize = input<number>(120);
  infoText = input<string>();
  disabled = input<boolean>();
  hideAddButton = input<boolean>();
  modifiedDummyText = input<string>(null);
  useHeaderInBottom = input<boolean>();

  onCollectionChange = output<MediaWidgetItem[]>();
  onCollectionAdd = output<MediaWidgetItem[]>();
  onCollectionRemove = output<MediaWidgetItem[]>();

  @ViewChild("dropHelper", { read: ElementRef }) dropHelperEl: ElementRef;
  @ViewChild("fileInput", { read: ElementRef }) fileInputEl: ElementRef;

  private translationService = inject(TranslationService);
  private mediaResolver = inject(MediaResolver);

  get dropHelper(): HTMLDivElement {
    return this.dropHelperEl.nativeElement;
  }

  get fileInput(): HTMLDivElement {
    return this.fileInputEl.nativeElement;
  }

  ngOnInit() {
    if (this.onlyImageUpload()) {
      this.acceptedMediaTypes.set(ACCEPTED_MEDIA_TYPES.IMAGE);
    } else if (this.onlyPdfUpload()) {
      this.acceptedMediaTypes.set(ACCEPTED_MEDIA_TYPES.PDF);
    } else {
      this.acceptedMediaTypes.set(
        [ACCEPTED_MEDIA_TYPES.IMAGE, ACCEPTED_MEDIA_TYPES.PDF].join(","),
      );
    }
  }

  openItem(item: MediaWidgetItem) {
    // this.bsModalRef = this.modalService.show(MediaWidgetItemViewerComponent, {
    //   initialState: {
    //     item: item,
    //     collection: this.collection,
    //   },
    //   ...ConfigUtils.MODAL_CONFIG_X_LARGE,
    // });
    // const component = this.bsModalRef.content;
    // this.subscriptions.add(
    //   component.closed$
    //     .pipe(takeUntil(this.destroyed$))
    //     .subscribe((wasClosed) => {
    //       if (wasClosed) {
    //         this.closeModal();
    //       }
    //     })
    // ); TODO
  }

  toggleDefault(item: MediaWidgetItem) {
    this.collection().forEach((mediaListItem) => {
      mediaListItem.default = false;
    });
    item.default = true;
    this.onCollectionChange.emit(this.collection());
  }

  downloadItem(mediaItem: MediaWidgetItem) {
    const url = this.mediaResolver.resolve(mediaItem.url);
    window.open(url, "_blank");
  }

  removeItem(mediaItem: MediaWidgetItem) {
    this.removeFromCollection(mediaItem);
  }

  handleFileChange(event: any) {
    const inputElement = <HTMLInputElement>event.target;
    const files = inputElement.files;
    this.uploadFiles(files);
    inputElement.value = "";
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    if (!this.allowFileDrop()) {
      return;
    }
    this.dropHelper.classList.add("is-visible");
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    if (!this.allowFileDrop()) {
      return;
    }

    // We need some calculation in order to only do stuff when we leave the actual dropzone.
    const rect = document
      .getElementById(this.mediaWidgetBodyId)
      .getBoundingClientRect();
    if (
      event.clientY < rect.top ||
      event.clientY >= rect.bottom ||
      event.clientX < rect.left ||
      event.clientX >= rect.right
    ) {
      this.dropHelper.classList.remove("is-visible");
    }
  }

  handleFileDrop(event: DragEvent) {
    event.preventDefault();
    if (!this.allowFileDrop()) {
      return;
    }
    this.dropHelper.classList.remove("is-visible");
    this.uploadFiles(event.dataTransfer.files);
  }

  uploadFiles(files: FileList) {
    const validFiles: File[] = [];
    const errorMessages: string[] = [];

    Array.prototype.forEach.call(files, (file: File) => {
      const error = this.validateFile(file);
      if (error) {
        errorMessages.push(error);
      } else {
        validFiles.push(file);
      }
    });

    const processValidFiles = () => {
      this.addToCollection(
        validFiles.map(
          (file) =>
            new MediaWidgetItem({
              id: Utils.getRandomNumberString(),
              title: file.name,
              mediaType: FileUtils.isPDF(file)
                ? MediaType.PDF
                : MediaType.Image,
              blob: file,
            }),
        ),
      );
    };

    new Promise((resolve) => {
      // Show validation message
      if (errorMessages.length) {
        const uniqueErrors = Utils.getUniqueEntriesOnly(...errorMessages);
        Swal.fire(
          new SwalConfig(this.translationService).getBlank({
            html: uniqueErrors.join("<br/><br/>"),
            showCancelButton: false,
            confirmButtonText: this.translationService.instant("Ok"),
          }),
        ).then((result) => {
          if (result.value) {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    }).then(() => {
      if (validFiles.length) {
        processValidFiles();
      }
    });
  }

  private validateFile(file: File) {
    let error = "";

    if (this.onlyImageUpload()) {
      if (!FileUtils.isImage(file)) {
        error = this.translationService.instant("FileValidationNotValidImage");
      }
    } else if (this.onlyPdfUpload()) {
      if (!FileUtils.isPDF(file)) {
        error = this.translationService.instant("FileValidationNotValidPdf");
      }
    } else {
      if (!FileUtils.isImage(file) && !FileUtils.isPDF(file)) {
        error = this.translationService.instant("FileValidationNotValid");
      }
    }

    if (!FileUtils.isAllowedFileSize(file, this.fileSizeLimit())) {
      if (!error) {
        const fileSizeMb = Math.round(
          FileUtils.bytesToMb(this.fileSizeLimit()),
        );
        error = this.translationService.instant(
          "FileValidationExceedsTheLimit",
          { 0: file.name, 1: fileSizeMb, 2: "MB" },
        );
      }
    }

    return error;
  }

  private addToCollection(itemsToAdd: MediaWidgetItem[]) {
    this.collection().push(...itemsToAdd);

    if (this.canToggleDefault()) {
      // If no default image, find and choose the first one.
      const existingDefaultMedia = this.collection()
        .filter((item) => item.isImage)
        .some((item) => item.default);
      if (!existingDefaultMedia) {
        const firstImage = this.collection().find((item) => item.isImage);
        if (firstImage) {
          this.toggleDefault(firstImage);
        }
      }
    }

    this.onCollectionChange.emit(this.collection());
    this.onCollectionAdd.emit(itemsToAdd);
  }

  private removeFromCollection(itemToRemove: MediaWidgetItem) {
    this.collection.set(
      this.collection().filter((item) => item.id !== itemToRemove.id),
    );

    if (this.canToggleDefault()) {
      // If it was the current default image, find and choose the next one.
      if (itemToRemove.default && this.collection().length) {
        const nextImage = this.collection().find((item) => {
          return item.isImage;
        });
        if (nextImage) {
          this.toggleDefault(nextImage);
        }
      }
    }

    this.onCollectionChange.emit(this.collection());
    this.onCollectionRemove.emit([itemToRemove]);
  }
}
