import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableTranslatedTextColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => TranslatedTextContent;

  constructor(
    labels: string[],
    getValue: (row: T) => TranslatedTextContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.TRANSLATED_TEXT;
  }
}

export class TranslatedTextContent {
  firstText: string;
  secondText: string;

  constructor(firstText: string, secondText?: string) {
    this.firstText = firstText;
    this.secondText = secondText;
  }
}
