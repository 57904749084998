<div
  class="help-actions-menu"
  [ngClass]="{
    opened: popoverOpened(),
    'in-modal': isInModal(),
  }"
  [title]="'UserSupport' | translate"
  (click)="!fetching() ? helpTemplate.toggle($event) : null"
>
  @if (!fetching()) {
    <i class="bi bi-question-circle-fill"></i>
  }
  @if (fetching()) {
    <i class="bi bi-arrow-repeat"></i>
  }
</div>
<p-popover
  #helpTemplate
  (onShow)="popoverOpened.set(true)"
  (onHide)="popoverOpened.set(false)"
>
  @for (helpArticle of helpArticles(); track $index) {
    <div
      class="help-menu-item"
      [title]="helpArticle.title"
      (click)="navigateToWiki(helpArticle); helpTemplate.toggle($event)"
    >
      <div class="help-article-wrapper">
        <div class="icon-title-wrapper">
          <div class="help-article-icon {{ helpArticle.icon }}"></div>
          <div class="help-article-title">
            {{ helpArticle.title }}
          </div>
        </div>
      </div>
    </div>
  }
  @if (!helpArticles().length) {
    <div class="help-menu-item dummy">
      <div class="help-article-wrapper">
        <div class="icon-title-wrapper">
          <div class="help-article-icon bi-info-circle"></div>
          <div class="help-article-title">
            {{ "NoArticles" | translate }}
          </div>
        </div>
      </div>
    </div>
  }

  <div
    class="help-menu-item"
    [title]="'VisitWiki' | translate"
    (click)="navigateToWiki(); helpTemplate.toggle($event)"
  >
    <div class="help-article-wrapper">
      <div class="icon-title-wrapper">
        <div class="help-article-icon bi-server"></div>
        <div class="help-article-title">
          {{ "VisitWiki" | translate }}
        </div>
      </div>
    </div>
  </div>
</p-popover>
