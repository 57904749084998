import { RoutesUtils } from "app/tools/routes-utils";
import packageInfo from "../../package.json";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  logger: false,
  baseUrl: RoutesUtils.base,
  clientId: "inspection-api",
  idServer: RoutesUtils.devEnv.idServer,
  coreApi: RoutesUtils.devEnv.coreApi,
  inspectionApi: RoutesUtils.devEnv.inspectionApi,
  coreUrl: RoutesUtils.devEnv.coreUrl,
  storageUrl: RoutesUtils.devEnv.storageUrl,
  appInsights: {
    instrumentationKey: "72161c3f-337c-47e1-89aa-d77876b28bf1",
  },
  cacheBusterHash: packageInfo.version,
  secret: "secret",
};
