<simple-change-tab [form]="form()">
  <div class="change-section-layout-wrapper">
    <div class="change-section-layout">
      <div class="change-section">
        <div class="change-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Name' | translate"
          [name]="propertyStrings.text"
          [form]="form()"
          [maxlength]="150"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!modifiedModel().id"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'Code' | translate"
          [name]="propertyStrings.code"
          [form]="form()"
          [maxlength]="150"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'Description' | translate"
          [name]="propertyStrings.description"
          [form]="form()"
          [maxlength]="2000"
          [serverErrors]="serverErrors()"
          [useTextarea]="true"
        >
        </simple-form-input>
      </div>

      <div class="change-section">
        <div class="change-section-header">
          {{ "Rules" | translate }}
        </div>
        <simple-form-checkbox
          [label]="'IsRequired' | translate"
          [name]="propertyStrings.isRequired"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-checkbox>

        <simple-form-checkbox
          [label]="'IsDefault' | translate"
          [name]="propertyStrings.isDefault"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-checkbox>

        <simple-form-checkbox
          [label]="'AllowsComment' | translate"
          [name]="propertyStrings.allowComment"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-checkbox>

        <simple-form-checkbox
          [label]="'AllowsMedia' | translate"
          [name]="propertyStrings.allowMedia"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-checkbox>
      </div>
    </div>
  </div>
</simple-change-tab>
