<div
  class="side-menu"
  [ngClass]="{
    collapsed: collapsed(),
    opened: menuOpenedMobile(),
    closed: !menuOpenedMobile(),
  }"
>
  <div
    class="header-item"
    [ngClass]="{
      clickable: canSwitchOrganization(),
    }"
    title="{{
      canSwitchOrganization ? ('SwitchOrganization' | translate) : ''
    }}"
    (click)="toggleGlobalOrgModal()"
  >
    @if (!collapsed()) {
      <div class="org-logo">
        <extensive-image
          [imageUrl]="globalState.selectedOrganization().logotypeUrl"
          [imageStorageSize]="42"
          [placeholderImageType]="'organization'"
        >
        </extensive-image>
      </div>
      <div class="org-info">
        <div class="org-name">
          {{ globalState.selectedOrganization().orgName }}
        </div>
        <div class="org-roles">
          {{ getCurrentRole() }}
        </div>
      </div>
    }

    <div class="org-icon bi-layers" *ngIf="canSwitchOrganization()"></div>
    <div
      class="test-marker"
      *ngIf="
        globalState.loggedInUser().isSuperAdmin() &&
        globalState.selectedOrganization().isTest
      "
    >
      {{ stringUtils.TEST }}
    </div>
  </div>

  <div class="items-wrapper">
    <div class="items apx-scrollbar scroll-shadow">
      <a
        class="item"
        *ngIf="
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        "
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.dashboard
        }}"
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.dashboard),
          'is-disabled': routeIsDisabled(routesUtils.dashboard),
        }"
        [title]="'Dashboard' | translate"
      >
        <i class="link-icon bi-graph-up-arrow"></i>
        {{ "Dashboard" | translate }}
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.assignments
        }}/{{ routesUtils.scheduledOngoing }}"
        [ngClass]="{
          'is-active':
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.scheduledOngoing
            ) ||
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.scheduledArchived
            ),
          'is-disabled':
            routeIsDisabled(
              routesUtils.assignments + '/' + routesUtils.scheduledOngoing
            ) ||
            routeIsDisabled(
              routesUtils.assignments + '/' + routesUtils.scheduledArchived
            ),
        }"
        [title]="'Assignments' | translate"
      >
        <i class="link-icon ii-assignment"></i>
        {{ "Assignments" | translate }}
      </a>

      <a
        class="item sub-item with-border-start"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.assignments
        }}/{{ routesUtils.scheduledOngoing }}"
        [ngClass]="{
          'is-active': routeIsActive(
            routesUtils.assignments + '/' + routesUtils.scheduledOngoing
          ),
          'is-disabled': routeIsDisabled(
            routesUtils.assignments + '/' + routesUtils.scheduledOngoing
          ),
          'is-visible':
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.scheduledOngoing
            ) ||
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.scheduledArchived
            ),
        }"
        [title]="'Ongoing' | translate"
      >
        <i class="link-icon bi-phone"></i>
        {{ "Ongoing" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item sub-item with-border-end"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.assignments
        }}/{{ routesUtils.scheduledArchived }}"
        [ngClass]="{
          'is-active': routeIsActive(
            routesUtils.assignments + '/' + routesUtils.scheduledArchived
          ),
          'is-disabled': routeIsDisabled(
            routesUtils.assignments + '/' + routesUtils.scheduledArchived
          ),
          'is-visible':
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.scheduledOngoing
            ) ||
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.scheduledArchived
            ),
        }"
        [title]="'ArchivedPlural' | translate"
      >
        <i class="link-icon bi-archive"></i>
        {{ "ArchivedPlural" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.assignments
        }}/{{ routesUtils.measureOngoing }}"
        [ngClass]="{
          'is-active':
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.measureOngoing
            ) ||
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.measureArchived
            ),
          'is-disabled':
            routeIsDisabled(
              routesUtils.assignments + '/' + routesUtils.measureOngoing
            ) ||
            routeIsDisabled(
              routesUtils.assignments + '/' + routesUtils.measureArchived
            ),
        }"
        [title]="'MeasureAssignments' | translate"
      >
        <i class="link-icon ii-measure"></i>
        {{ "MeasureAssignments" | translate }}
      </a>

      <a
        class="item sub-item with-border-start"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.assignments
        }}/{{ routesUtils.measureOngoing }}"
        [ngClass]="{
          'is-active': routeIsActive(
            routesUtils.assignments + '/' + routesUtils.measureOngoing
          ),
          'is-disabled': routeIsDisabled(
            routesUtils.assignments + '/' + routesUtils.measureOngoing
          ),
          'is-visible':
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.measureOngoing
            ) ||
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.measureArchived
            ),
        }"
        [title]="'Ongoing' | translate"
      >
        <i class="link-icon bi-phone"></i>
        {{ "Ongoing" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item sub-item with-border-end"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.assignments
        }}/{{ routesUtils.measureArchived }}"
        [ngClass]="{
          'is-active': routeIsActive(
            routesUtils.assignments + '/' + routesUtils.measureArchived
          ),
          'is-disabled': routeIsDisabled(
            routesUtils.assignments + '/' + routesUtils.measureArchived
          ),
          'is-visible':
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.measureOngoing
            ) ||
            routeIsActive(
              routesUtils.assignments + '/' + routesUtils.measureArchived
            ),
        }"
        [title]="'ArchivedPlural' | translate"
      >
        <i class="link-icon bi-archive"></i>
        {{ "ArchivedPlural" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.schedules
        }}"
        *ngIf="
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        "
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.schedules),
          'is-disabled': routeIsDisabled(routesUtils.schedules),
        }"
        [title]="'Schedules' | translate"
      >
        <i class="link-icon bi-calendar-week"></i>
        {{ "Schedules" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.assignmentTemplates
        }}"
        *ngIf="
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        "
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.assignmentTemplates),
          'is-disabled': routeIsDisabled(routesUtils.assignmentTemplates),
        }"
        [title]="'AssignmentTemplates' | translate"
      >
        <i class="link-icon bi-map"></i>
        {{ "AssignmentTemplates" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.facilities
        }}"
        *ngIf="
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        "
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.facilities),
          'is-disabled': routeIsDisabled(routesUtils.facilities),
        }"
        [title]="'Facilities' | translate"
      >
        <i class="link-icon ii-facility"></i>
        {{ "Facilities" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.entities
        }}"
        *ngIf="
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        "
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.entities),
          'is-disabled': routeIsDisabled(routesUtils.entities),
        }"
        [title]="'Entities' | translate"
      >
        <i class="link-icon ii-entities"></i>
        {{ "Entities" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.groups
        }}"
        *ngIf="
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        "
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.groups),
          'is-disabled': routeIsDisabled(routesUtils.groups),
        }"
        [title]="'Groups' | translate"
      >
        <i class="link-icon bi-people"></i>
        {{ "Groups" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.users
        }}"
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.users),
          'is-disabled': routeIsDisabled(routesUtils.users),
        }"
        [title]="'Users' | translate"
      >
        <i class="link-icon bi-person"></i>
        {{ "Users" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.templateTypes
        }}"
        *ngIf="globalState.loggedInUser().isSuperAdmin()"
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.templateTypes),
          'is-disabled': routeIsDisabled(routesUtils.templateTypes),
        }"
        [title]="'TemplateTypes' | translate"
      >
        <i class="link-icon ii-template-type"></i>
        {{ "TemplateTypes" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.tasks
        }}"
        *ngIf="globalState.loggedInUser().isSuperAdmin()"
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.tasks),
          'is-disabled': routeIsDisabled(routesUtils.tasks),
        }"
        [title]="'Tasks' | translate"
      >
        <i class="link-icon ii-task"></i>
        {{ "Tasks" | translate }}
        <div class="ribbon"></div>
      </a>

      <a
        class="item"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.choices
        }}"
        *ngIf="globalState.loggedInUser().isSuperAdmin()"
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.choices),
          'is-disabled': routeIsDisabled(routesUtils.choices),
        }"
        [title]="'Choices' | translate"
      >
        <i class="link-icon bi-check2-circle"></i>
        {{ "Choices" | translate }}
        <div class="ribbon"></div>
      </a>
    </div>

    <div class="footer-items">
      <div
        class="collapse-button"
        [ngClass]="{
          'bi-chevron-double-right': collapsed(),
          'bi-chevron-bar-left': !collapsed(),
        }"
        [title]="'ToggleMenu' | translate"
        (click)="toggleMenu()"
      ></div>
      <a
        class="item organization"
        routerLink="/{{ globalState.selectedOrganization().friendlyUrl }}/{{
          routesUtils.myOrganization
        }}"
        [ngClass]="{
          'is-active': routeIsActive(routesUtils.myOrganization),
          'is-disabled': routeIsDisabled(routesUtils.myOrganization),
        }"
        [title]="'MyOrganization' | translate"
      >
        <div class="org-icon bi-globe"></div>
        {{ "MyOrganization" | translate }}
        <div class="ribbon"></div>
      </a>
    </div>
  </div>
</div>

<global-organization-modal
  [visible]="visibleSelectOrganization()"
  (onClose)="handleCloseSelectOrganization()"
></global-organization-modal>
