import { Component, inject, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Assignment } from "app/components/assignment/assignment";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentService } from "app/services/assignment.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { HandleAssigneeGroupsTabComponent } from "../../detailed-assignment/scheduled/ongoing/tabs/handle-assignee-groups/handle-assignee-groups-tab.component";
import { HandleAssigneesTabComponent } from "../../detailed-assignment/scheduled/ongoing/tabs/handle-assignees/handle-assignees-tab.component";

@Component({
  selector: "handle-assignee-groups-and-assignees",
  templateUrl: "./handle-assignee-groups-and-assignees.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    HandleAssigneeGroupsTabComponent,
    HandleAssigneesTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class HandleAssigneeGroupsAndAssigneesComponent extends CreateEditBaseDirective<Assignment> {
  isMeasure = input<boolean>();

  private assignmentService = inject(AssignmentService);

  async initiate() {
    this.pending.set(true);
    try {
      const assignments =
        await this.assignmentService.getAssigneeGroupsAndAssignees(
          this.itemIds(),
          this.globalState.selectedOrganization().id,
        );
      this.pending.set(false);

      const allAssigneeGroups = assignments.flatMap(
        (item) => item.assigneeGroups,
      );
      const allAssignees = assignments.flatMap((item) => item.assignees);
      const allUniqueAssigneeGroups = [];
      const allUniqueAssignees = [];

      const map = new Map();
      for (const item of allAssigneeGroups) {
        if (!map.has(item.userGroup.id)) {
          map.set(item.userGroup.id, true);
          allUniqueAssigneeGroups.push(item);
        }
      }
      for (const item of allAssignees) {
        if (!map.has(item.user.id)) {
          map.set(item.user.id, true);
          allUniqueAssignees.push(item);
        }
      }

      this.initialModel.set(
        new Assignment({
          assigneeGroups: allUniqueAssigneeGroups,
          assignees: allUniqueAssignees,
          errorAssignmentId: this.isMeasure
            ? "someIdToTriggerIsMeasureCheck"
            : "",
        }),
      );
      this.modifiedModel.set(
        new Assignment(Utils.getUniqueVariant(this.initialModel())),
      );
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data =
        await this.assignmentService.updateAssigneeGroupsAndAssignees(
          this.itemIds(),
          this.modifiedModel().assigneeGroups,
          this.modifiedModel().assignees,
          this.isMeasure(),
        );
      this.toastrService.success(
        this.translationService.instant("TheAssignmentsWereUpdated"),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }
}
