import { SimpleAction } from "../simple-action";

export class SimpleListAction extends SimpleAction {
  readonly titleText: string;
  readonly event: () => void;
  readonly hidden: () => boolean;

  constructor(
    name: string,
    titleText: string,
    iconName: string,
    event: () => void,
    hidden?: () => boolean,
  ) {
    super(name, iconName);
    this.titleText = titleText;
    this.event = event;
    this.hidden = hidden ? hidden : () => false;
  }
}
