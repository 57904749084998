import { Component, inject, input, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { Subscriber } from "app/components/user/user";
import { FileData, FileHelper } from "app/tools/file-helper";
import { NumberUtils } from "app/tools/number-utils";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { DateTime } from "luxon";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { ReportService } from "../../../../services/report.service";
import { GeneratedReport, GeneratedReportTypes } from "./generated-report";
import { GenerateReportTabComponent } from "./tabs/generate-report-tab.component";

@Component({
  selector: "generate-report",
  templateUrl: "./generate-report.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    GenerateReportTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class GenerateReportComponent extends CreateEditBaseDirective<GeneratedReport> {
  tooLargeForDownload = signal<boolean>(false);
  sendReportByEmail = signal<boolean>(false);
  subscribers = signal<Subscriber[]>([]);

  isArchived = input<boolean>();
  isMeasure = input<boolean>();
  sort = input<object>();

  generateReportTabComponent = viewChild(GenerateReportTabComponent);

  private reportService = inject(ReportService);
  private fileHelper = inject(FileHelper);

  async initiate() {
    this.pending.set(true);
    try {
      this.subscribers.set(
        await this.reportService.getSubscribers(
          this.itemIds(),
          this.globalState.selectedOrganization().id,
        ),
      );
      this.pending.set(false);

      this.tooLargeForDownload.set(
        this.itemIds().length >= NumberUtils.MAX_ITEM_COUNT_FOR_REPORT_DOWNLOAD,
      );
      this.sendReportByEmail.set(this.tooLargeForDownload());

      const defaultHeader = `${
        this.isMeasure()
          ? this.translationService.instant("ErrorReport")
          : this.translationService.instant("SummaryReport")
      } ${DateTime.now().toISODate()}`;

      this.initialModel.set(
        new GeneratedReport({
          header: defaultHeader,
        }),
      );

      this.modifiedModel.set(
        new GeneratedReport(Utils.getUniqueVariant(this.initialModel())),
      );

      this.setLoggedInUserAsRecipient();
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    const subscriberEmails = this.subscribers()
      .filter((subscriber) => subscriber.selected)
      .map((subscriber) => subscriber.email);
    const userEmails = this.modifiedModel().users.map((user) => user.email);
    const externalEmails = this.modifiedModel().emails;

    const allEmails = Utils.getUniqueEntriesOnly(
      ...subscriberEmails,
      ...userEmails,
      ...externalEmails,
    );

    const model = new GeneratedReport({
      itemIds: this.itemIds(),
      organizationId: this.globalState.selectedOrganization().id,
      header: this.modifiedModel().header,
      reportFileFormat: this.modifiedModel().reportFileFormat,
      emails: this.sendReportByEmail() ? allEmails : [],
      reportType: this.isMeasure()
        ? GeneratedReportTypes.Error
        : GeneratedReportTypes.Summary,
      status: this.isArchived ? StringUtils.COMPLETED : StringUtils.INCOMPLETED,
      sort: this.sort(),
    });

    this.pending.set(true);
    try {
      const data = await this.reportService.getGeneratedReport(model);
      this.fileHelper.handleFile(new FileData(data));
      this.toastrService.success(
        this.translationService.instant("TheReportWasGenerated"),
      );
      this.onRequestDone.emit([new GeneratedReport({})]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.generateReportTabComponent().setServerErrors(error.errors);
    }
  }

  handleDetailsChangeExtra() {
    if (this.generateReportTabComponent()) {
      const sendReportByEmailCtrl = this.generateReportTabComponent()
        .form()
        .get("sendReportByEmail");
      this.sendReportByEmail.set(sendReportByEmailCtrl.value);

      if (this.sendReportByEmail()) {
        this.generateReportTabComponent().setSelectableEmailItems();
      }
    }
  }

  // TODO behöver jag ha dessa??
  private hasSubscribers() {
    return this.subscribers().some((subscriber) => subscriber.selected);
  }

  private hasUsers() {
    return !!this.modifiedModel().users.length;
  }

  private hasExternalEmails() {
    return !!this.modifiedModel().emails.length;
  }

  private setLoggedInUserAsRecipient() {
    const loggedInUserInSubscribers = this.subscribers().find(
      (subscriber) =>
        subscriber.email === this.globalState.loggedInUser().email,
    );
    const loggedInUserIsInOrganization = this.globalState
      .loggedInUser()
      .isOrgMember(this.globalState.selectedOrganization().friendlyUrl);

    if (loggedInUserInSubscribers) {
      loggedInUserInSubscribers.selected = true;
    } else if (loggedInUserIsInOrganization) {
      this.modifiedModel().users.push(this.globalState.loggedInUser());
    } else {
      this.modifiedModel().selectableEmails.push(
        this.globalState.loggedInUser().email,
      );
      this.modifiedModel().emails.push(this.globalState.loggedInUser().email);
    }
  }
}
