import { StringUtils } from "./string-utils";
import { InspectionColor } from "./utils";

export class ColorUtils {
  public static GREEN_COLOR = "#7b981f";
  public static ORANGE_COLOR = "#ffae00";
  public static RED_COLOR = "#d54334";
  public static BLUE_COLOR = "#2196f3";
  public static MAIN_FONT_COLOR = "#666666";
  public static WHITE_COLOR = "#fff";

  public static getInspectionColors = () => {
    return Object.keys(InspectionColor)
      .map((key) => InspectionColor[key as any])
      .map((value) => value as InspectionColor);
  };

  static getStatusColor(status: string) {
    if (
      status === StringUtils.ACTIVE ||
      status === StringUtils.COMPLETED ||
      status === StringUtils.RESOLVED ||
      status === StringUtils.CHOSEN
    ) {
      return ColorUtils.GREEN_COLOR;
    } else if (
      status === StringUtils.INACTIVE ||
      status === StringUtils.EXPIRED
    ) {
      return ColorUtils.RED_COLOR;
    } else if (
      status === StringUtils.PENDING ||
      status === StringUtils.STARTED
    ) {
      return ColorUtils.ORANGE_COLOR;
    } else if (status === StringUtils.CLOSED) {
      return ColorUtils.MAIN_FONT_COLOR;
    } else if (status === StringUtils.TEST) {
      return ColorUtils.BLUE_COLOR;
    } else if (status === StringUtils.NOT_STARTED) {
      return ColorUtils.WHITE_COLOR;
    } else {
      return ColorUtils.BLUE_COLOR;
    }
  }
}
