@if (type() === columnType.TEXT) {
  <column-type-text [column]="column()" [item]="item()"></column-type-text>
} @else if (type() === columnType.DATE) {
  <column-type-date [column]="column()" [item]="item()"></column-type-date>
} @else if (type() === columnType.PUBLISH_DATE) {
  <column-type-publish-date
    [column]="column()"
    [item]="item()"
  ></column-type-publish-date>
} @else if (type() === columnType.DUE_DATE) {
  <column-type-due-date
    [column]="column()"
    [item]="item()"
  ></column-type-due-date>
} @else if (type() === columnType.ICON) {
  <column-type-icon [column]="column()" [item]="item()"></column-type-icon>
} @else if (type() === columnType.RULE) {
  <column-type-rule [column]="column()" [item]="item()"></column-type-rule>
} @else if (type() === columnType.COUNT) {
  <column-type-count [column]="column()" [item]="item()"></column-type-count>
} @else if (type() === columnType.STATUS) {
  <column-type-status [column]="column()" [item]="item()"></column-type-status>
} @else if (type() === columnType.CHOICE) {
  <column-type-choice [column]="column()" [item]="item()"> </column-type-choice>
} @else if (type() === columnType.CATEGORY) {
  <column-type-category
    [column]="column()"
    [item]="item()"
  ></column-type-category>
} @else if (type() === columnType.ASSESSMENT) {
  <column-type-assessment
    [column]="column()"
    [item]="item()"
  ></column-type-assessment>
} @else if (type() === columnType.ISSUE_NUMBER) {
  <column-type-issue-number
    [column]="column()"
    [item]="item()"
  ></column-type-issue-number>
} @else if (type() === columnType.TRANSLATED_TEXT) {
  <column-type-translated-text
    [column]="column()"
    [item]="item()"
  ></column-type-translated-text>
} @else if (type() === columnType.USER) {
  <column-type-user [column]="column()" [item]="item()"></column-type-user>
} @else if (type() === columnType.TASK) {
  <column-type-task [column]="column()" [item]="item()"></column-type-task>
} @else if (type() === columnType.ENTITY) {
  <column-type-entity [column]="column()" [item]="item()"></column-type-entity>
} @else if (type() === columnType.FACILITY) {
  <column-type-facility
    [column]="column()"
    [item]="item()"
  ></column-type-facility>
}
