import { CrudItem } from "app/components/simple-components/crud/crud-item";

export class MediaWidgetItem extends CrudItem<MediaWidgetItem> {
  url: string = "";
  blob: Blob = null;
  title: string = "";
  default: boolean = false;
  mediaType: MediaType = MediaType.Image;
  isImage: boolean = false;
  isPDF: boolean = false;
  actionsOpened: boolean = false;
  base64: any = "";

  constructor(item: Partial<MediaWidgetItem>) {
    super();
    Object.assign(this, item);

    this.isImage = this.mediaType === MediaType.Image;
    this.isPDF = this.mediaType === MediaType.PDF;
  }
}

export enum MediaType {
  Image = 0,
  PDF = 1,
}
