import { Injectable } from "@angular/core";
import { Filter } from "app/filter";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Choice, ChoicePayload } from "../components/choice/choice";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ChoiceService extends BaseService<Choice> {
  prefix = RoutesUtils.choice;

  constructor() {
    super(Choice);
  }

  async get(id: string): Promise<Choice> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}/${id}`);
      return new Choice(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: ChoicePayload, organizationId: number): Promise<Choice> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}?organization=${organizationId}`,
        [item],
      );
      return this.extractData(response).map(
        (data: Choice) => new Choice(data),
      )[0];
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: ChoicePayload, organizationId: number): Promise<Choice> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}?organization=${organizationId}`,
        [item],
      );
      return this.extractData(response).map(
        (data: Choice) => new Choice(data),
      )[0];
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.delete(
        `${this.prefix}/${id}`,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(itemIds: string[]): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        { itemIds: itemIds },
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class ChoiceFilter extends Filter {
  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.RULES_KEY) {
        const rules = facet.value.split(",");
        rules.forEach((rule) => {
          if (rule === StringUtils.IS_ERROR) {
            tempFacets[StringUtils.IS_ERROR_KEY] = "true";
          } else if (rule === StringUtils.IS_DEFAULT) {
            tempFacets[StringUtils.IS_DEFAULT_KEY] = "true";
          } else if (rule === StringUtils.IS_UNSPECIFIED) {
            tempFacets[StringUtils.IS_UNSPECIFIED_KEY] = "true";
          } else if (rule === StringUtils.ALLOWS_PRIORITY) {
            tempFacets[StringUtils.ALLOW_PRIORITY_KEY] = "true";
          }
        });
      } else if (
        facet.key === StringUtils.IS_ERROR_KEY ||
        facet.key === StringUtils.IS_DEFAULT_KEY ||
        facet.key === StringUtils.IS_UNSPECIFIED_KEY ||
        facet.key === StringUtils.ALLOW_PRIORITY_KEY
      ) {
        delete this.facets[facet.key];
      }
    });

    Object.assign(this.facets, tempFacets);

    return {
      filter: JSON.stringify(this),
    };
  }
}
