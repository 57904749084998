import { Directive, inject, input } from "@angular/core";
import { GlobalStateService } from "app/global-state/global-state.service";
import { TranslationService } from "app/services/translation.service";
import { StringUtils } from "app/tools/string-utils";

@Directive()
export abstract class ViewDetailsBaseDirective<T extends { id?: string }> {
  stringUtils = StringUtils;

  model = input<T>();

  get isEdit() {
    return !!this.model().id;
  }

  protected translationService = inject(TranslationService);
  protected globalState = inject(GlobalStateService);
}
