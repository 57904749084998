import { Injectable } from "@angular/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { TemplateType } from "app/components/template-type/template-type";
import { UserGroup } from "app/components/user-group/user-group";
import { User } from "app/components/user/user";
import { Filter, KeyValuePair, SortDirection } from "app/filter";
import { BaseService } from "app/services/base.service";
import { NumberUtils } from "app/tools/number-utils";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import {
  Schedule,
  ScheduleGroup,
  SchedulePayload,
  ScheduleUser,
} from "../components/schedule/schedule";

@Injectable({
  providedIn: "root",
})
export class ScheduleService extends BaseService<Schedule> {
  prefix = RoutesUtils.schedule;

  constructor() {
    super(Schedule);
  }

  async get(id: string): Promise<Schedule> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}/${id}`);
      return new Schedule(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: SchedulePayload): Promise<Schedule> {
    try {
      const response = await this.hostedHttpClient.post(`${this.prefix}`, item);
      return new Schedule(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: SchedulePayload): Promise<Schedule> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
      );
      return new Schedule(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.delete(
        `${this.prefix}/${id}`,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(itemIds: string[]): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        { itemIds: itemIds },
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getStatusData(itemIds: string[], active: boolean): Promise<string[]> {
    try {
      const model = {
        itemIds: itemIds,
        active: active,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/updatestatusdata`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateStatus(itemIds: string[], active: boolean): Promise<Schedule[]> {
    try {
      const model = {
        itemIds: itemIds,
        active: active,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/statuses`,
        model,
      );
      return this.extractData(response).map(
        (item: Schedule) => new Schedule(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getScheduleGroupsAndScheduleUsers(
    itemIds: string[],
    organizationId: number,
  ): Promise<Schedule[]> {
    try {
      const model = {
        itemIds: itemIds,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/scheduleusers?organization=${organizationId}`,
        model,
      );
      return this.extractData(response).map(
        (item: Schedule) => new Schedule(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateScheduleGroupsAndScheduleUsers(
    itemIds: string[],
    scheduleGroups: ScheduleGroup[],
    scheduleUsers: ScheduleUser[],
  ): Promise<Schedule[]> {
    try {
      const model = {
        schedules: [],
      };
      itemIds.forEach((id) => {
        model.schedules.push({
          id: id,
          scheduleUsers: scheduleUsers.map((user) => {
            return {
              userId: user.userId,
              canPerformMeasure: user.canPerformMeasure,
              canPerformScheduled: user.canPerformScheduled,
              canAssign: user.canAssign,
            };
          }),
          scheduleGroups: scheduleGroups.map((group) => {
            return {
              userGroupId: group.userGroupId,
              canPerformMeasure: group.canPerformMeasure,
              canPerformScheduled: group.canPerformScheduled,
              canAssign: group.canAssign,
            };
          }),
        });
      });
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/scheduleusers`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getPublicationDates(item: Schedule): Promise<string[]> {
    try {
      const model = {
        startDate: item.startDate,
        endDate: item.endDate,
        publishingTime: item.publishingTime,
        weeklyInterval: item.weeklyInterval,
        monthlyInterval: item.monthlyInterval,
        annualInterval: item.annualInterval,
        weekdays: item.weekdays,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/publicationdates`,
        model,
      );
      return this.extractData(response).publishDates;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getAssignmentTemplates(
    organizationId: number,
  ): Promise<AssignmentTemplate[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(new TemplateType({}).propertyStrings.name),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `assignmenttemplate?organization=${organizationId}`,
        filter.toPayloadObject(),
      );
      return this.extractData(response).map(
        (item: AssignmentTemplate) => new AssignmentTemplate(item),
      );
    } catch (errorResponse) {
      this.extractError(errorResponse);
    }
  }

  async getUserGroups(organizationId: number): Promise<UserGroup[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<UserGroup>("name")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `usergroup?organization=${organizationId}`,
        filter.toPayloadObject(),
        true,
      );
      return this.extractData(response).map(
        (item: UserGroup) => new UserGroup(item),
      );
    } catch (errorResponse) {
      this.extractError(errorResponse);
    }
  }

  async getUsers(organizationId: number): Promise<User[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<User>("firstname")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `user?organization=${organizationId}`,
        filter.toPayloadObject(),
        true,
      );
      return this.extractData(response).map((item: User) => new User(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class ScheduleFilter extends Filter {
  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY] = "false";
        }
      } else if (facet.key === StringUtils.ASSIGNMENT_TEMPLATES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.ASSIGNMENT_TEMPLATE_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.ASSIGNMENT_TEMPLATE_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.HAS_GROUPS_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_SCHEDULE_GROUPS_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_SCHEDULE_GROUPS_KEY] = "false";
        }
      } else if (facet.key === StringUtils.HAS_SCHEDULE_GROUPS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.GROUPS_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.USER_GROUP_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.USER_GROUP_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.HAS_ASSIGNEES_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_SCHEDULE_USERS_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_SCHEDULE_USERS_KEY] = "false";
        }
      } else if (facet.key === StringUtils.HAS_SCHEDULE_USERS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.ASSIGNEES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.USER_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.USER_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.STATUS_KEY) {
        const status = facet.value;
        if (status === StringUtils.ACTIVE) {
          tempFacets[StringUtils.ACTIVE_KEY] = "true";
        } else if (status === StringUtils.INACTIVE) {
          tempFacets[StringUtils.ACTIVE_KEY] = "false";
        }
      } else if (facet.key === StringUtils.ACTIVE_KEY) {
        delete this.facets[facet.key];
      }
    });

    Object.assign(this.facets, tempFacets);

    return {
      filter: JSON.stringify(this),
    };
  }
}
