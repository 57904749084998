import { Component, computed, Signal } from "@angular/core";
import { SimpleStatusLabelComponent } from "app/components/simple-components/various/status-label/simple-status-label.component";
import { StatusContent } from "../../../columns/simple-table-status-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-status",
  templateUrl: "./column-type-status.component.html",
  standalone: true,
  imports: [SimpleStatusLabelComponent],
})
export class ColumnTypeStatusComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<StatusContent>;
}
