import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { Choice } from "app/components/choice/choice";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentService } from "app/services/assignment.service";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { HandleAssigneeGroupsTabComponent } from "../../scheduled/ongoing/tabs/handle-assignee-groups/handle-assignee-groups-tab.component";
import { HandleAssigneesTabComponent } from "../../scheduled/ongoing/tabs/handle-assignees/handle-assignees-tab.component";
import { ChangeDetailsTabComponent } from "./tabs/change-details/change-details-tab.component";
import { ViewDetailsTabComponent } from "./tabs/view-details/view-details-tab.component";

@Component({
  selector: "detailed-assignment-measure-ongoing",
  templateUrl: "./detailed-assignment-measure-ongoing.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ViewDetailsTabComponent,
    ChangeDetailsTabComponent,
    HandleAssigneeGroupsTabComponent,
    HandleAssigneesTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class DetailedAssignmentMeasureOngoingComponent extends CreateEditBaseDirective<MeasureAssignment> {
  private changeDetailsTabComponent = viewChild(ChangeDetailsTabComponent);

  private assignmentService = inject(AssignmentService);

  async initiate() {
    this.pending.set(true);
    try {
      const data = (await this.assignmentService.get(
        this.itemIds()[0],
      )) as MeasureAssignment;
      this.initialModel.set(data);
      this.pending.set(false);

      this.initialModel().choice = new Choice(
        this.initialModel().errorResult.task.choice,
      );

      this.initialModel().status =
        this.initialModel().status === StringUtils.ACTIVE
          ? StringUtils.NOT_STARTED
          : this.initialModel().status;

      this.modifiedModel.set(
        new MeasureAssignment(Utils.getUniqueVariant(this.initialModel())),
      );

      if (this.modifiedModel().isArchivedAssignment()) {
        this.toastrService.error(
          `${this.translationService.instant(
            StringUtils.WRONG_ASSIGNMENT_LIST_ERROR,
          )}!`,
        );
        this.handleClose();
        this.router.navigate(
          [
            `${
              this.globalState.selectedOrganization().friendlyUrl
            }/${this.modifiedModel().correctRoute}`,
          ],
          { queryParamsHandling: "merge", replaceUrl: true },
        );
        return;
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      await this.assignmentService.updateAssigneeGroupsAndAssignees(
        [this.modifiedModel().id],
        this.modifiedModel().assigneeGroups,
        this.modifiedModel().assignees,
        true,
      );
      await this.assignmentService.update(
        this.modifiedModel().toAdminCommentPriorityPayloadObject(),
      );
      await this.assignmentService.updateMeasureStatus(
        [this.modifiedModel().id],
        this.modifiedModel().status,
        this.modifiedModel().notes,
        this.globalState.selectedOrganization().id,
      );
      this.toastrService.success(
        this.translationService.instant("TheAssignmentWasUpdated"),
      );
      this.onRequestDone.emit([this.modifiedModel()]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeDetailsTabComponent().setServerErrors(error.errors);
    }
  }
}
