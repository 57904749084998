import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ScheduledAssignment } from "app/components/assignment/scheduled-assignment";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentService } from "app/services/assignment.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { ChangeDueDateTabComponent } from "./tabs/change-due-date-tab.component";

@Component({
  selector: "change-due-date",
  templateUrl: "./change-due-date.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ChangeDueDateTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class ChangeDueDateComponent extends CreateEditBaseDirective<ScheduledAssignment> {
  changeAssignmentDueDateTabComponent = viewChild(ChangeDueDateTabComponent);

  private assignmentService = inject(AssignmentService);

  async initiate() {
    this.initialModel.set(new ScheduledAssignment({}));
    this.modifiedModel.set(
      new ScheduledAssignment(Utils.getUniqueVariant(this.initialModel())),
    );
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const [date, _] = this.modifiedModel().dueDate.split(" ");
      const newDueDate = `${date} ${this.modifiedModel().dueTime}`;

      const data = await this.assignmentService.updateDueDate(
        this.itemIds(),
        newDueDate,
      );
      this.toastrService.success(
        this.translationService.instant("TheAssignmentsWereUpdated"),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeAssignmentDueDateTabComponent().setServerErrors(error.errors);
    }
  }
}
