import { Component, input, output, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Choice } from "app/components/choice/choice";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  ChoiceContent,
  SimpleTableChoiceColumn,
} from "app/components/simple-components/list/table/columns/simple-table-choice-column";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import {
  SimpleTableTaskColumn,
  TaskContent,
} from "app/components/simple-components/list/table/columns/simple-table-task-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { SimpleChoicesComponent } from "app/components/simple-components/various/choices/simple-choices.component";
import { Task } from "app/components/task/task";
import { StringUtils } from "app/tools/string-utils";
import { ListTaskComponent } from "../../../../task/list-task/list-task.component";

@Component({
  selector: "handle-tasks-tab",
  templateUrl: "./handle-tasks-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListTaskComponent,
    TranslateModule,
  ],
})
export class HandleTasksTabComponent extends HandleItemsBaseDirective<Task> {
  visibleListTask = signal<boolean>(false);
  visibleListChoice = signal<boolean>(false);

  readonlyChoices = input<boolean>();
  includeChoicesInList = input<boolean>();
  templateTypeIdForList = input<string>();

  onUpdatedChoices = output<Choice[]>();
  onDeletedChoices = output<string[]>();

  choicesComponent = viewChild(SimpleChoicesComponent);

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction("AddTasks", "AddTasks", StringUtils.icons.new, () =>
        this.visibleListTask.set(true),
      ),
      new SimpleListAction(
        "AddChoices",
        "AddChoices",
        StringUtils.icons.new,
        () => this.visibleListChoice.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTaskColumn(
        ["Name", "Code"],
        (row) => new TaskContent(row),
      ),
      new SimpleTableRuleColumn(
        ["Rules"],
        (row) => new RuleContent(row.rulesReadonly),
      ),
      new SimpleTableChoiceColumn(
        ["Choices"],
        (row) => new ChoiceContent(row.choices),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Tasks", StringUtils.icons.task),
    );
  }

  handleCloseListTask() {
    this.visibleListTask.set(false);
    this.itemIds.set([]);
  }

  handleCloseListChoice() {
    this.visibleListChoice.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListTask(items: Task[]) {
    this.handleCloseListTask();
    this.items.set(items.map((item) => new Task(item)));
    this.onItemsChange.emit(this.items());
  }

  handleConfirmListChoice(items: Choice[]) {
    this.handleCloseListChoice();
  }

  // getChoicesGlobal() {
  //   let choicesGlobal: Choice[] = [];
  //   const selectedTasks = this.items().filter((task) => task.selected);
  //   if (selectedTasks.length) {
  //     if (this.areTaskChoicesTheSame(selectedTasks)) {
  //       selectedTasks.forEach((selectedTask) => {
  //         choicesGlobal = [...choicesGlobal, ...selectedTask.choices];
  //       });
  //       const uniqueChoices = [];
  //       const map = new Map();
  //       for (const item of choicesGlobal) {
  //         if (!map.has(item.id)) {
  //           map.set(item.id, true);
  //           uniqueChoices.push(item);
  //         }
  //       }
  //       choicesGlobal = uniqueChoices;
  //     }
  //   }
  //   return choicesGlobal;
  // }

  // private areTaskChoicesTheSame(selectedTasks: Task[]) {
  //   if (selectedTasks.every((o) => !o.choices.length)) {
  //     return false;
  //   }
  //   const sortingFunction = (a: any, b: any) =>
  //     a.id === b.id ? 0 : a.id < b.id ? -1 : 1;
  //   const sortedChoices = selectedTasks[0].choices
  //     .slice()
  //     .sort(sortingFunction);
  //   return selectedTasks.every(
  //     (o) =>
  //       o.choices.length === sortedChoices.length &&
  //       o.choices
  //         .slice()
  //         .sort(sortingFunction)
  //         .every((c, i) => c.id === sortedChoices[i].id)
  //   );
  // }

  // handleChoicesChangeGlobal(choices: Choice[]) {
  //   const selectedTasks = this.items().filter((task) => task.selected);
  //   selectedTasks.forEach((task) => (task.choices = choices));
  //   this.validate(selectedTasks);
  // }

  // handleUpdatedChoices(updatedChoices: Choice[]) {
  //   const allChoices = this.items().reduce(
  //     (list, item) => [...list, ...item.choices],
  //     [] as Choice[]
  //   );
  //   allChoices.forEach((item) => {
  //     const choiceToUpdateFrom = updatedChoices.find(
  //       (choice) => choice.id === item.id
  //     );
  //     if (choiceToUpdateFrom) {
  //       Object.assign(item, choiceToUpdateFrom);
  //     }
  //   });
  //   this.onUpdatedChoices.emit(updatedChoices);
  // }

  // handleDeletedChoices(deletedChoiceIds: string[]) {
  //   this.items().forEach((item) => {
  //     item.choices = item.choices.filter(
  //       (choice) => !deletedChoiceIds.includes(choice.id)
  //     );
  //   });
  //   this.onDeletedChoices.emit(deletedChoiceIds);
  // }
}
