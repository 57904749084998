import { User } from "app/components/user/user";

export class Report {
  id: string = "";
  organizationId: number;
  itemIds: string[] = [];
  emails: string[] = [];
  users: User[] = [];

  selectableEmails: string[] = [];

  constructor(report: Partial<Report>) {
    Object.assign(this, report);
  }
}
