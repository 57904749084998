import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

// Workaround for the default DatePipe.
// Taken from https://stackoverflow.com/a/40160501/186787
@Pipe({
  name: "datex",
  standalone: true,
})
export class DatexPipe implements PipeTransform {
  transform(value: any, format?: "with-time" | "only-time"): string {
    // Try and parse the passed value.
    const date = DateTime.fromSQL(value);

    // If Luxon didn't understand the value, return it unformatted.
    if (!date.isValid) {
      return value;
    }

    // Otherwise, return the date formatted as requested.
    return date
      .toLocaleString(
        format === "with-time"
          ? DateTime.DATETIME_MED
          : format === "only-time"
            ? DateTime.TIME_24_SIMPLE
            : DateTime.DATE_MED,
      )
      .replace(".", "");
  }
}
