import { Component, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Entity } from "app/components/entity/entity";
import { ListEntityComponent } from "app/components/entity/list-entity/list-entity.component";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  EntityContent,
  SimpleTableEntityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-entity-column";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-entities-tab",
  templateUrl: "./handle-entities-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListEntityComponent,
    TranslateModule,
  ],
})
export class HandleEntitiesTabComponent extends HandleItemsBaseDirective<Entity> {
  visibleListEntity = signal<boolean>(false);

  hideFacilityColumn = input<boolean>();
  facilityIds = input<string[]>([]);

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "AddEntities",
        "AddEntities",
        StringUtils.icons.new,
        () => this.visibleListEntity.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableEntityColumn(["Name"], (row) => new EntityContent(row)),
      new SimpleTableTextColumn(
        ["Description"],
        (row) => new TextContent(row.description),
      ),
      new SimpleTableFacilityColumn(
        ["Facility"],
        (row) => new FacilityContent(row.facility),
        () => this.hideFacilityColumn(),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.status ? StringUtils.ACTIVE : StringUtils.INACTIVE,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Entities", StringUtils.icons.entity),
    );
  }

  handleCloseListEntity() {
    this.visibleListEntity.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListEntity(items: Entity[]) {
    this.handleCloseListEntity();
    this.items.set(items.map((item) => new Entity(item)));
    this.onItemsChange.emit(this.items());
  }
}
