import { DEFAULT_LANGUAGE } from "app/components/user/user";
import { LogLevel, Utils } from "./utils";

export enum SortDirection {
  Ascending = -1,
  Descending = 1,
}

declare global {
  interface Array<T> {
    toCommaSeparatedList(onlyCommas?: boolean): Array<T>;
    flatten<T>(): T[];
    sortByProperty(property: string, sortDirection?: SortDirection): void;
  }
}

if (!Array.prototype.toCommaSeparatedList) {
  Array.prototype.toCommaSeparatedList = function (onlyCommas?: boolean) {
    if (onlyCommas) {
      return this.join(", ");
    } else {
      return this.join(", ").replace(/,(?!.*,)/gim, " &");
    }
  };
}

if (!Array.prototype.flatten) {
  Array.prototype.flatten = function <T>(): T[] {
    const array = this;

    if ((array as unknown as T[]).every((val) => !Array.isArray(val))) {
      return (array as unknown as T[]).slice();
    }

    return array.reduce((flat, toFlatten) => {
      return flat.concat(
        Array.isArray(toFlatten) ? toFlatten.flatten() : toFlatten
      );
    }, []);
  };
}

if (!Array.prototype.sortByProperty) {
  Array.prototype.sortByProperty = function (
    property: string,
    sortDirection: SortDirection = SortDirection.Descending
  ): void {
    if (this == null) {
      Utils.logMessage(
        "Array.prototype.sortByProperty called on null or undefined",
        this.loggedInUser,
        LogLevel.Error
      );
      return;
    }
    if (property == null) {
      Utils.logMessage(
        "Array.prototype.sortByProperty called with null or undefined property",
        this.loggedInUser,
        LogLevel.Error
      );
      return;
    }
    if (
      (typeof property !== "string" && !Array.isArray(property)) ||
      (Array.isArray(property) && property.some((o) => typeof o !== "string"))
    ) {
      Utils.logMessage(
        `Array.prototype.sortByProperty called with a non-string, a non-array property/properties or wrong delimiter (${property})`,
        this.loggedInUser,
        LogLevel.Error
      );
      return;
    }

    const splittedProp = property.split(/[\s.,|]+/);
    const prop = splittedProp.length > 1 ? splittedProp : property;

    // Do not proceed if the collection is undefined or empty
    if (!this.length) {
      return;
    }

    if (Array.isArray(prop) && splittedProp.length > 2) {
      Utils.logMessage(
        `Array.prototype.sortByProperty called with more than two level properties (${property})`,
        this.loggedInUser,
        LogLevel.Error
      );
      return;
    }
    if (Array.isArray(prop) && splittedProp.length === 2) {
      if (
        !this[0].hasOwnProperty(splittedProp[0]) ||
        this[0][splittedProp[0]] === null
      ) {
        Utils.logMessage(
          `Array.prototype.sortByProperty called with a non-existing first level property/properties (${property})`,
          this.loggedInUser,
          LogLevel.Error
        );
        return;
      }
      if (
        !this[0][splittedProp[0]].hasOwnProperty(splittedProp[1]) ||
        this[0][splittedProp[0]][splittedProp[1]] === null
      ) {
        Utils.logMessage(
          `Array.prototype.sortByProperty called with a non-existing second level property/properties (${property})`,
          this.loggedInUser,
          LogLevel.Error
        );
        return;
      }
    }
    if (!Array.isArray(prop) && !this[0].hasOwnProperty(prop)) {
      Utils.logMessage(
        `Array.prototype.sortByProperty called with a non-existing property/properties (${property})`,
        this.loggedInUser,
        LogLevel.Error
      );
      return;
    }

    this.sort(function (a, b) {
      let i = 0;
      while (i < splittedProp.length) {
        a = a[splittedProp[i]];
        b = b[splittedProp[i]];
        i++;
      }

      // Return 0 for null values to avoid exception for localeComapre
      if (a === null || b === null) {
        return 0;
      }

      if (typeof a === "boolean" && typeof b === "boolean") {
        if (a < b) {
          return -1 * sortDirection;
        } else if (a > b) {
          return 1 * sortDirection;
        } else {
          return 0;
        }
      } else {
        if (
          a.localeCompare(b, DEFAULT_LANGUAGE, { numeric: true }) <
          b.localeCompare(a, DEFAULT_LANGUAGE, { numeric: true })
        ) {
          return -1 * sortDirection;
        } else if (
          a.localeCompare(b, DEFAULT_LANGUAGE, { numeric: true }) >
          b.localeCompare(a, DEFAULT_LANGUAGE, { numeric: true })
        ) {
          return 1 * sortDirection;
        } else {
          return 0;
        }
      }
    });
  };
}
