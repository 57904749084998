import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { GlobalStateService } from "app/global-state/global-state.service";
import { StringUtils } from "../../tools/string-utils";
import { SimpleStatusLabelComponent } from "../simple-components/various/status-label/simple-status-label.component";

/**
 * Recursive nodes component.
 */
@Component({
  selector: "[inspire-tree-nodes]",
  template: `
    <li
      *ngFor="let node of nodes"
      [class]="getNodeClasses(node)"
      [ngClass]="{
        collapsed: node.collapsed(),
        expanded: !node.collapsed(),
        loading: node.loading(),
        focused: node.focused(),
        folder: node.children,
        hidden: node.hidden() || node.removed(),
        leaf: !node.children,
        selected: node.selected()
      }"
    >
      <span *ngIf="node.loading()" class="loader"></span>

      <a
        *ngIf="isNodeExpandable(node)"
        class="toggle"
        (click)="node.toggleCollapse()"
      ></a>

      <a
        class="title icon"
        [ngClass]="{
          icon: isNodeExpandable(node)
        }"
        (click)="click(node, $event)"
      >
        <div class="title__text">
          {{ node.text }}
          <div class="test-mark" *ngIf="isSuperAdmin && node.isTest">
            <simple-status-label [text]="stringUtils.TEST">
            </simple-status-label>
          </div>
        </div>
      </a>

      <ng-container *ngIf="node.children && node.children.length">
        <ol inspire-tree-nodes [nodes]="node.children" [tree]="tree"></ol>
      </ng-container>
    </li>
  `,
  standalone: true,
  imports: [NgFor, NgClass, NgIf, SimpleStatusLabelComponent],
})
export class InspireTreeNodesComponent {
  // An array of nodes to render
  @Input() nodes;

  // A pointer to the tree instance for API calls
  @Input() tree;

  isSuperAdmin: boolean = false;

  stringUtils = StringUtils;

  constructor(private globalState: GlobalStateService) {
    this.isSuperAdmin = this.globalState.loggedInUser().isSuperAdmin();
  }

  /**
   * Node click event handler.
   *
   * @param {TreeNode} node Clicked node.
   * @param {MouseEvent} event Mouse click event object.
   */
  click(node, event) {
    this.tree.emit("node.click", event, node);

    if (this.tree.config.multiselect) {
      this.tree.preventDeselection = event.metaKey || event.ctrlKey;
    }

    node.toggleSelect();
  }

  // Check if can toggle open and close a node.
  // Uses InspireTree's internals.
  isNodeExpandable(node) {
    return node.hasChildren() || node.children;
  }

  // Custom node classes.
  getNodeClasses(node) {
    return node.itree.li.attributes.class;
  }
}
