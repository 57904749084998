<div class="choices-wrapper">
  <div class="choices apx-scrollbar">
    @for (choice of choices(); track $index) {
      <div
        class="choice"
        [ngClass]="{
          editable: editable(),
        }"
        [title]="choice.value"
        (click)="editable() ? handleChoiceClick(choice) : null"
      >
        @if (!hideRules()) {
          <div class="choice-rules-wrapper">
            @for (rule of choice.rulesReadonly; track $index) {
              <div
                class="choice-rule {{ rule.icon }}"
                [title]="rule.title | translate"
              ></div>
            }
          </div>
        }
        <div class="choice-meta-wrapper">
          <span class="choice-value">
            {{ choice.value }}
          </span>
          <span class="choice-color" [style.backgroundColor]="choice.color">
          </span>
        </div>
      </div>
    }
  </div>

  @if (editable()) {
    <div
      class="action bi-plus-circle-dotted"
      [ngClass]="{
        disabled: disableAction(),
      }"
      title="{{ 'Add' | translate }}"
      (click)="handleAddClick()"
    ></div>
  }
</div>
