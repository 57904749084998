import { RoutesUtils } from "app/tools/routes-utils";
import { Assignment, AssignmentPayload } from "./assignment";

export class ScheduledAssignment extends Assignment {
  constructor(assignment: Partial<ScheduledAssignment>) {
    super(assignment);
    this.setUrl(this.correctRoute);
  }

  get correctRoute() {
    if (this.isArchivedAssignment()) {
      return `${RoutesUtils.assignments}/${RoutesUtils.scheduledArchived}`;
    } else {
      return `${RoutesUtils.assignments}/${RoutesUtils.scheduledOngoing}`;
    }
  }

  toDueDatePayloadObject() {
    const [date, _] = this.dueDate.split(" ");
    const newDueDate = `${date} ${this.dueTime}`;
    return new AssignmentPayload({
      id: this.id,
      dueDate: newDueDate,
    });
  }
}
