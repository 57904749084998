<div
  class="timeline-wrapper"
  [ngClass]="{ 'use-small-styles': useSmallStyles() || items().length > 10 }"
>
  @if (items().length) {
    <div class="data-wrapper">
      @for (item of items(); track $index) {
        @if (shouldDisplayYear(item)) {
          <div
            class="timeline-year"
            [ngClass]="{
              visible: item.display(),
            }"
            title="{{ item.yearString }}"
            style="--delay: {{ $index * 0.1 }}s"
          >
            <div class="text">
              {{ item.yearString }}
            </div>
            <div class="line"></div>
          </div>
        }
        @if ($index < previewCount()) {
          <div
            class="timeline-item"
            [ngClass]="{
              visible: item.display(),
            }"
            title="{{ item.infoText }}"
            style="--delay: {{ $index * 0.1 }}s"
          >
            <div class="text-wrapper">
              <div class="title">
                {{ item.dayAndMonthString }}
              </div>
              <div class="subtitle">
                {{ item.dayNameString }}
              </div>
            </div>
            <div class="line"></div>
            <div class="dot"></div>
          </div>
        }
      }
      <div
        class="timeline"
        [ngClass]="{
          visible: items().length,
        }"
      ></div>
    </div>
  }
  @if (!items().length) {
    <div class="empty-state">
      {{ "NoPublicationsText" | translate }}
    </div>
  }
</div>
