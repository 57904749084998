import { LowerCasePipe, NgClass, NgIf } from "@angular/common";
import { Component, input } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { InputTrimmerDirective } from "../../../../../../../../directives/input-trimmer.directive";
import { SimpleFormErrorComponent } from "../form-error/simple-form-error.component";
import { SimpleFormControlDirective } from "../simple-form-control.directive";

@Component({
  selector: "simple-form-input",
  templateUrl: "./simple-form-input.component.html",
  styleUrls: ["./simple-form-input.component.less"],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    InputTrimmerDirective,
    SimpleFormErrorComponent,
    LowerCasePipe,
    TranslateModule,
  ],
})
export class SimpleFormInputComponent extends SimpleFormControlDirective {
  useTextarea = input<boolean>();
  usePasswordType = input<boolean>();

  ngAfterViewInit() {
    if (this.focusOnInit()) {
      this.focusElement();
    }
  }
}
