<simple-crud-modal
  [visible]="visible()"
  [headerText]="'ChangeGeoFencing' | translate"
  [closeText]="'Close' | translate"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending() || !affectableIds().length"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="'SetGeoFencingHelpText' | translate"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <change-geo-fencing-tab
            [modifiedModel]="modifiedModel()"
            [affectableIds]="affectableIds()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </change-geo-fencing-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
