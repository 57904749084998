import { Task } from "app/components/task/task";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableTaskColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (item: T) => TaskContent;

  constructor(
    labels: string[],
    getValue: (row: T) => TaskContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.TASK;
  }
}

export class TaskContent {
  task: Task;

  constructor(task: Task) {
    this.task = task;
  }
}
