<div class="login-wrapper">
  <div
    class="login-panel"
    [ngClass]="{
      loadmask: pending(),
    }"
  >
    <div class="login-panel-header">
      {{ "LoginWithPassword" | translate }}
    </div>
    <div class="login-panel-body">
      <div class="login-form">
        <simple-form-input
          [label]="'EmailAddress' | translate"
          [name]="'email'"
          [form]="form()"
        >
        </simple-form-input>
        <simple-form-input
          [label]="'Password' | translate"
          [name]="'password'"
          [form]="form()"
          [usePasswordType]="true"
        >
        </simple-form-input>
      </div>
    </div>
    <div class="login-panel-footer">
      <button
        type="button"
        class="btn btn-transparent"
        title="{{ 'UseCode' | translate }}"
        [disabled]="pending()"
        (click)="switchToCode()"
      >
        {{ "UseCode" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-success"
        title="{{ 'Submit' | translate }}"
        [disabled]="!form().valid || pending()"
        (click)="loginWithPassword()"
      >
        {{ "Login" | translate }}
      </button>
    </div>
    @if (pending()) {
      <dots-loader></dots-loader>
    }
  </div>
</div>
