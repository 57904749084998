import { LowerCasePipe, NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ColorUtils } from "app/tools/color-utils";
import { ColorPicker } from "primeng/colorpicker";
import { SimpleFormErrorComponent } from "../form-error/simple-form-error.component";
import { SimpleFormControlDirective } from "../simple-form-control.directive";

@Component({
  selector: "simple-form-color",
  templateUrl: "./simple-form-color.component.html",
  styleUrls: ["./simple-form-color.component.less"],
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    ColorPicker,
    SimpleFormErrorComponent,
    LowerCasePipe,
    ReactiveFormsModule,
  ],
})
export class SimpleFormColorComponent extends SimpleFormControlDirective {
  getPresetColors() {
    return ColorUtils.getInspectionColors();
  }
}
