import { Component, input } from "@angular/core";
import { Facility } from "app/components/facility/facility";
import { SimpleImageComponent } from "../image/simple-image.component";

@Component({
  selector: "simple-facility",
  templateUrl: "./simple-facility.component.html",
  styleUrls: ["./simple-facility.component.less"],
  standalone: true,
  imports: [SimpleImageComponent],
})
export class SimpleFacilityComponent {
  facility = input<Facility>();
}
