import { NgTemplateOutlet } from "@angular/common";
import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import {
  Assignee,
  AssigneeGroup,
  Assignment,
} from "app/components/assignment/assignment";
import { DetailedAssignmentScheduledOngoingComponent } from "app/components/assignment/detailed-assignment/scheduled/ongoing/detailed-assignment-scheduled-ongoing.component";
import { Client } from "app/components/organization/organization";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import {
  SimpleTableRowAction,
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
  SimpleTableRowActionView,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  CategoryContent,
  SimpleTableCategoryColumn,
} from "app/components/simple-components/list/table/columns/simple-table-category-column";
import {
  CountContent,
  CountObject,
  SimpleTableCountColumn,
} from "app/components/simple-components/list/table/columns/simple-table-count-column";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  DueDateContent,
  SimpleTableDueDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-due-date-column";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  IconContent,
  IconObject,
  SimpleTableIconColumn,
} from "app/components/simple-components/list/table/columns/simple-table-icon-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import {
  SimpleTableHeaderAction,
  SimpleTableHeaderActionDelete,
} from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { KeyValuePair } from "app/filter";
import { SwalConfig } from "app/swal/swal-config.component";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import {
  AssignmentFilter,
  AssignmentService,
} from "../../../../../services/assignment.service";
import { ListHeaderComponent } from "../../../../header/list-header/list-header.component";
import { SimpleViewClientComponent } from "../../../../simple-components/various/view-client/simple-view-client.component";
import { SimpleViewConnectionsComponent } from "../../../../simple-components/various/view-connections/simple-view-connections.component";
import { SimpleViewItemsComponent } from "../../../../simple-components/various/view-items/simple-view-items.component";
import { HandleAssigneeGroupsAndAssigneesComponent } from "../../handle-assignee-groups-and-assignees/handle-assignee-groups-and-assignees.component";
import { ChangeDueDateComponent } from "./change-due-date/change-due-date.component";

@Component({
  selector: "list-assignment-scheduled-ongoing",
  templateUrl: "./list-assignment-scheduled-ongoing.component.html",
  standalone: true,
  imports: [
    DetailedAssignmentScheduledOngoingComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    HandleAssigneeGroupsAndAssigneesComponent,
    ChangeDueDateComponent,
    NgTemplateOutlet,
    ListHeaderComponent,
    SimpleViewItemsComponent,
    SimpleViewConnectionsComponent,
    SimpleViewClientComponent,
  ],
})
export class ListAssignmentScheduledOngoingComponent extends ListBaseDirective<Assignment> {
  readonly filterObject = new AssignmentFilter();

  visibleHandleAssigneeGroupsAndAssignees = signal<boolean>(false);
  visibleChangeDueDate = signal<boolean>(false);
  client = signal<Client>(new Client({}));
  assigneeGroups = signal<AssigneeGroup[]>([]);
  assignees = signal<Assignee[]>([]);
  visibleViewClient = signal<boolean>(false);
  visibleViewItems = signal<boolean>(false);

  constructor(private assignmentService: AssignmentService) {
    super(assignmentService);
  }

  protected configureListActions() {}

  protected async configureTableFilter() {
    this.filterObject.context = RoutesUtils.scheduledOngoing;

    this.initialFacets.set([
      new KeyValuePair(StringUtils.IS_ACTIVE_KEY, "true"),
      new KeyValuePair(StringUtils.IS_STARTED_KEY, "true"),
      new KeyValuePair(StringUtils.IS_MEASURE_KEY, "false"),
      new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "false"),
      new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
    ]);

    const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
    const facilitiesKey = StringUtils.FACILITIES_KEY;
    const categoriesKey = StringUtils.CATEGORIES_KEY;
    const schedulesKey = StringUtils.SCHEDULES_KEY;
    const groupsKey = StringUtils.GROUPS_KEY;
    const assigneesKey = StringUtils.ASSIGNEES_KEY;
    const createdBetweenKey = StringUtils.CREATED_BETWEEN_KEY;
    const expiresBetweenKey = StringUtils.EXPIRES_BETWEEN_KEY;
    const statusKey = StringUtils.STATUS_KEY;
    const hasGroupsKey = StringUtils.HAS_GROUPS_KEY;
    const hasAssigneesKey = StringUtils.HAS_ASSIGNEES_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [templateTypesKey]: null,
        [facilitiesKey]: null,
        [categoriesKey]: null,
        [schedulesKey]: null,
        [groupsKey]: null,
        [assigneesKey]: null,
        [createdBetweenKey]: null,
        [expiresBetweenKey]: null,
        [statusKey]: null,
        [hasGroupsKey]: null,
        [hasAssigneesKey]: null,
      });
    }

    try {
      const [
        templateTypes,
        facilities,
        categories,
        schedules,
        userGroups,
        users,
      ] = await Promise.all([
        this.assignmentService.getTemplateTypes(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getFacilities(),
        this.assignmentService.getCategories(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getSchedules(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getUserGroups(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getUsers(
          this.globalState.selectedOrganization().id,
        ),
      ]);

      const convertedTemplateTypes = templateTypes
        .filter((templateType) => !templateType.isManualMeasure)
        .map(
          (templateType) =>
            new SimpleFilterInputItem(templateType.id, templateType.name),
        );
      const convertedFacilities = facilities.map(
        (facility) => new SimpleFilterInputItem(facility.id, facility.name),
      );
      const convertedCategories = categories.map(
        (category) => new SimpleFilterInputItem(category.id, category.name),
      );
      const convertedSchedules = schedules.map(
        (schedule) => new SimpleFilterInputItem(schedule.id, schedule.name),
      );
      const convertedUserGroups = userGroups.map(
        (userGroup) => new SimpleFilterInputItem(userGroup.id, userGroup.name),
      );
      const convertedUsers = users.map(
        (user) =>
          new SimpleFilterInputItem(
            user.id,
            user.fullName ? user.fullName : user.email,
          ),
      );

      this.filterInputs.set([
        new SimpleFilterInput(
          templateTypesKey,
          templateTypesKey,
          StringUtils.icons.templateType,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, ""));
            }
            return keyValuePairs;
          },
          convertedTemplateTypes,
        ),
        new SimpleFilterInput(
          facilitiesKey,
          facilitiesKey,
          StringUtils.icons.facility,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(facilitiesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(facilitiesKey, ""));
            }
            return keyValuePairs;
          },
          convertedFacilities,
        ),
        new SimpleFilterInput(
          categoriesKey,
          categoriesKey,
          StringUtils.icons.category,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(categoriesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(categoriesKey, ""));
            }
            return keyValuePairs;
          },
          convertedCategories,
        ),
        new SimpleFilterInput(
          schedulesKey,
          schedulesKey,
          StringUtils.icons.schedule,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(schedulesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(schedulesKey, ""));
            }
            return keyValuePairs;
          },
          convertedSchedules,
        ),
        new SimpleFilterInput(
          groupsKey,
          groupsKey,
          StringUtils.icons.group,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(groupsKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(groupsKey, ""));
            }
            return keyValuePairs;
          },
          convertedUserGroups,
        ),
        new SimpleFilterInput(
          hasGroupsKey,
          hasGroupsKey,
          StringUtils.icons.group,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(hasGroupsKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(hasGroupsKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          assigneesKey,
          assigneesKey,
          StringUtils.icons.user,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(assigneesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(assigneesKey, ""));
            }
            return keyValuePairs;
          },
          convertedUsers,
        ),
        new SimpleFilterInput(
          hasAssigneesKey,
          hasAssigneesKey,
          StringUtils.icons.user,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(hasAssigneesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(hasAssigneesKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          statusKey,
          statusKey,
          StringUtils.icons.setStatus,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(statusKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(statusKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(
              StringUtils.NOT_STARTED,
              StringUtils.NOT_STARTED,
            ),
            new SimpleFilterInputItem(StringUtils.STARTED, StringUtils.STARTED),
          ],
        ),
        new SimpleFilterInput(
          expiresBetweenKey,
          expiresBetweenKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateRangeSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(expiresBetweenKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(expiresBetweenKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          createdBetweenKey,
          createdBetweenKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateRangeSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(createdBetweenKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(createdBetweenKey, ""));
            }
            return keyValuePairs;
          },
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(
        "AssignmentTemplate.TemplateType.Name" as keyof Assignment,
        "TemplateType",
      ),
      new SortObject(
        "AssignmentTemplate.Facility.Name" as keyof Assignment,
        "Facility",
      ),
      new SortObject("Schedule.Name" as keyof Assignment, "Schedule"),
      new SortObject(this.propertyStrings.dueDate, "Expires", true),
      new SortObject(this.propertyStrings.created, "Created"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTextColumn(
        ["TemplateType"],
        (row) => new TextContent(row.templateType.name),
      ),
      new SimpleTableCategoryColumn(
        ["Categories"],
        (item) => new CategoryContent(item.categories),
      ),
      new SimpleTableFacilityColumn(
        ["Facility"],
        (row) => new FacilityContent(row.facility),
      ),
      new SimpleTableTextColumn(
        ["Schedule"],
        (row) => new TextContent(row.schedule?.name),
      ),
      new SimpleTableDueDateColumn(
        ["Expires"],
        (row) => new DueDateContent(row.dueDate),
      ),
      new SimpleTableIconColumn(
        [],
        (row) =>
          new IconContent([
            new IconObject(
              row.client ? StringUtils.icons.client : "",
              "Client",
              row.client
                ? `${row.client.name} ${row.client.emailAddress ? "(" + row.client.emailAddress + ")" : ""}`
                : "",
            ),
            new IconObject(
              row.geoControlled ? StringUtils.icons.geo : "",
              "Geofencing",
              "GeofencingText",
              true,
            ),
          ]),
      ),
      new SimpleTableCountColumn([], (row) => {
        const groups = row.assigneeGroups.filter(
          (group) => group.canPerformScheduled,
        );
        const assignees = row.assignees.filter(
          (assignee) => assignee.canPerformScheduled,
        );
        return new CountContent([
          new CountObject(
            groups ? StringUtils.icons.group : "",
            groups.length,
            "Groups",
          ),
          new CountObject(
            assignees.length ? StringUtils.icons.user : "",
            assignees.length,
            "Assignees",
          ),
        ]);
      }),
      new SimpleTableDateColumn(
        ["Created", "LastSaved"],
        (row) => new DateContent(row.created, row.lastUpdated),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.status === StringUtils.ACTIVE
              ? StringUtils.NOT_STARTED
              : StringUtils.STARTED,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "HandleGroupsAndAssignees",
        StringUtils.icons.group,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleHandleAssigneeGroupsAndAssignees.set(true);
        },
        () =>
          this.isInModal() ||
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl),
      ),
      new SimpleTableHeaderAction(
        "ChangeDueDate",
        StringUtils.icons.setDueDate,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeDueDate.set(true);
        },
        () =>
          this.isInModal() ||
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl),
      ),
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () =>
          this.isInModal() ||
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl),
      ),
    ]);

    // ROW

    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.handle,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowAction(
        "HandleGroupsAndAssignees",
        StringUtils.icons.group,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleHandleAssigneeGroupsAndAssignees.set(true);
        },
        () =>
          this.isInModal() ||
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl),
      ),
      new SimpleTableRowActionView(
        "ViewClient",
        StringUtils.icons.client,
        (row) => {
          this.client.set(row.client);
          this.visibleViewClient.set(true);
        },
        (row) => !row.client,
      ),
      new SimpleTableRowActionView(
        "ViewConnections",
        StringUtils.icons.connections,
        (row) => {
          this.assigneeGroups.set(row.assigneeGroups);
          this.assignees.set(row.assignees);
          this.visibleViewItems.set(true);
        },
        (row) => !row.assigneeGroups.length && !row.assignees.length,
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        (row) =>
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl) ||
          this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState(
        "Assignments",
        StringUtils.icons.scheduledAssignment,
      ),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(new SwalConfig(this.translationService).getDelete()).then(
      async (result) => {
        if (result.value) {
          this.handleCloseCreateEdit();
          this.itemIds.set(ids);
          this.pending.set(true);
          try {
            const data = await this.assignmentService.deleteRange(
              this.itemIds(),
              this.globalState.selectedOrganization().id,
            );
            this.pending.set(false);
            this.toastrService.success(
              this.onlyOneSelected
                ? this.translationService.instant(
                    "TheScheduledAssignmentWasDeleted",
                  )
                : this.translationService.instant(
                    "TheScheduledAssignmentsWereDeleted",
                  ),
            );
            this.selectedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.unselectableIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.addedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.modifiedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.itemIds.set([]);
            this.getTableData();
          } catch (error) {
            this.pending.set(false);
            this.toastrService.error(error.message);
          }
        }
      },
    );
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleCloseHandleAssigneeGroupsAndAssignees() {
    this.visibleHandleAssigneeGroupsAndAssignees.set(false);
    this.itemIds.set([]);
  }

  handleCloseSetDueDate() {
    this.visibleChangeDueDate.set(false);
    this.itemIds.set([]);
  }

  async handleRequestDoneHandleAssigneeGroupsAndAssignees(data: Assignment[]) {
    this.handleCloseHandleAssigneeGroupsAndAssignees();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }

  async handleRequestDoneSetDueDate(data: Assignment[]) {
    this.handleCloseSetDueDate();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }
}
