<simple-view-tab>
  <div class="view-section-layout-wrapper">
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "General" | translate }}
        </div>
        <div class="content">
          <div class="content-value">
            <div class="banner-image">
              <extensive-image
                [imageUrl]="model().thumbnailUrl"
                [displayLoader]="true"
              >
              </extensive-image>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Name" | translate }}
          </div>
          <div class="content-value">
            {{ model().name }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Description" | translate }}
          </div>

          @if (model().description) {
            <div class="content-value">
              {{ model().description }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "EntityID" | translate }}
          </div>
          @if (model().articleId) {
            <div class="content-value">
              {{ model().articleId }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "TypeDesignation" | translate }}
          </div>

          @if (model().typeDesignation) {
            <div class="content-value">
              {{ model().typeDesignation }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Manufacturer" | translate }}
          </div>

          @if (model().manufacturer) {
            <div class="content-value">
              {{ model().manufacturer }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Assembler" | translate }}
          </div>

          @if (model().assembler) {
            <div class="content-value">
              {{ model().assembler }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "AssemblyYear" | translate }}
          </div>

          @if (model().assemblyYear) {
            <div class="content-value">
              {{ model().assemblyYear }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "InfoLink" | translate }}
          </div>
          @if (model().infoLink) {
            <div class="content-value">
              <a
                class="link"
                [title]="model().infoLink"
                [href]="model().infoLink"
                target="_blank"
              >
                {{ model().infoLink }}
              </a>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Accessibility" | translate }}
          </div>
          <div class="content-value">
            {{ (model().accessible ? "Yes" : "No") | translate }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Status" | translate }}
          </div>
          <div class="content-value">
            <simple-status-label
              [text]="model().status ? 'Active' : 'Inactive'"
              [title]="model().status ? 'Active' : 'Inactive'"
            >
            </simple-status-label>
          </div>
        </div>
      </div>
    </div>
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "LocationData" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Latitude" | translate }}
          </div>
          @if (model().latitude) {
            <div class="content-value">
              {{ model().latitude }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Longitude" | translate }}
          </div>
          @if (model().longitude) {
            <div class="content-value">
              {{ model().longitude }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <google-maps-component
          [latitude]="model().latitude"
          [longitude]="model().longitude"
          [name]="model().name"
          [isStatic]="true"
          [height]="100"
        >
        </google-maps-component>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "Other" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "ExtraInfo" | translate }}
          </div>
          @if (model().extraInfo) {
            <div
              class="content-value with-frame"
              [innerHTML]="model().extraInfo | parseMD"
            ></div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        @if (
          globalState.selectedOrganization().hasDfIntegration ||
          globalState.selectedOrganization().hasInfraControlIntegration
        ) {
          <div class="content">
            <div class="content-header">
              {{ "ExternalId" | translate }}
            </div>

            @if (model().externalId) {
              <div class="content-value">
                {{ model().externalId }}
              </div>
            } @else {
              <div class="dummy-text">
                {{ "NotSpecified" | translate }}
              </div>
            }
          </div>
        }
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "AddedMedia" | translate }}
        </div>
        <div class="content">
          @if (model().media.length) {
            <div class="content-value flex">
              @for (item of model().media; track $index) {
                <simple-image [url]="item.url" [size]="52"></simple-image>
              }
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "AppearsIn" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Facility" | translate }}
          </div>
          @if (model().facility) {
            <div class="content-value">
              <simple-view-connections [facility]="model().facility">
              </simple-view-connections>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</simple-view-tab>
