import { Component, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Choice } from "app/components/choice/choice";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { TemplateType } from "app/components/template-type/template-type";
import { SimpleFormInputComponent } from "../../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";
import { SimpleChangeTabComponent } from "../../../../../../app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { SimpleFormSelectButtonComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-select-button/simple-form-select-button.component";

@Component({
  selector: "change-details-tab",
  templateUrl: "./change-details-tab.component.html",
  styleUrls: ["./change-details-tab.component.less"],
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormInputComponent,
    TranslateModule,
    SimpleFormSelectButtonComponent,
  ],
})
export class ChangeDetailsTabComponent extends ChangeDetailsBaseDirective<TemplateType> {
  selectableTemplateBaseTypeItems = signal<FormSelectItem[]>([]);
  notificationChoiceErrorMessages = signal<string[]>([]);

  ngOnInit() {
    this.setSelectableTemplateBaseTypeItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.name]: [
          this.modifiedModel().name,
          [Validators.required, Validators.maxLength(100)],
        ],
        [this.propertyStrings.abbreviation]: [
          this.modifiedModel().abbreviation,
          [Validators.required, Validators.maxLength(10)],
        ],
        [this.propertyStrings.templateBaseTypeId]: [
          this.modifiedModel().templateBaseTypeId,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  handleChoicesChange(choices: Choice[]) {
    this.modifiedModel().notificationChoice = choices[0];
    if (this.modifiedModel().notificationChoice) {
      this.onDetailsChange.emit(this.form());
    } else {
      this.notificationChoiceErrorMessages.set([]);
      this.modifiedModel().notificationChoiceId = null; // TODO funkar detta verkligen?
    }
  }

  private setSelectableTemplateBaseTypeItems() {
    this.selectableTemplateBaseTypeItems.set(
      this.modifiedModel().selectableTemplateBaseTypes.map(
        (baseType) => new FormSelectItem(baseType.id, baseType.name),
      ),
    );
  }
}
