import { Assignment } from "app/components/assignment/assignment";
import { ScheduleGroup, ScheduleUser } from "app/components/schedule/schedule";
import { Subscriber } from "app/components/user/user";

export class ManualMeasureAssignment extends Assignment {
  subscribers: Subscriber[] = [];
  geoControlled: boolean = false;
  priority: boolean = false;
  allowPriority: boolean = false;

  constructor(manualMeasure: Partial<ManualMeasureAssignment>) {
    super(manualMeasure);
    Object.assign(this, manualMeasure);
  }

  toPayloadObject(orgId: number) {
    return new ManualMeasurePayload({
      organizationId: orgId,
      facilityId: this.facility.id,
      scheduleGroups: this.assigneeGroups.map(
        (group) => new ScheduleGroup(group),
      ),
      scheduleUsers: this.assignees.map(
        (assignee) => new ScheduleUser(assignee),
      ),
      subscribers: this.getSubscribers(),
      errors: this.getMappedErrors(),
      geoControlled: this.facility.geoControlled,
    });
  }

  private getSubscribers() {
    return this.subscribers.map((subscriber) => {
      subscriber.scheduledCreated = false;
      subscriber.scheduledExpired = false;
      subscriber.scheduledCompleted = false;
      return subscriber;
    });
  }

  private getMappedErrors() {
    const errors = [];
    if (this.entities.length) {
      this.entities.forEach((entity) => {
        errors.push(
          new ManualMeasureError({
            entityId: entity.id,
            comment: entity.issueDetails,
            newMedia: entity.issueMedia.map((newItem) => {
              return {
                data: newItem.base64,
              };
            }),
            priority: entity.priority,
          }),
        );
      });
    }
    if (this.facility.issueDetails) {
      errors.push(
        new ManualMeasureError({
          facilityId: this.facility.id,
          comment: this.facility.issueDetails,
          newMedia: this.facility.issueMedia.map((newItem) => {
            return {
              data: newItem.base64,
            };
          }),
          priority: this.facility.priority,
        }),
      );
    }
    return errors;
  }
}

export class ManualMeasurePayload extends ManualMeasureAssignment {
  organizationId: number;
  facilityId: string;
  scheduleUsers: ScheduleUser[];
  scheduleGroups: ScheduleGroup[];
  subscribers: Subscriber[];
  errors: ManualMeasureError[];
  geoControlled: boolean;

  constructor(manualMeasurePayload: Partial<ManualMeasurePayload>) {
    super(manualMeasurePayload);
    Object.assign(this, manualMeasurePayload);
  }
}

export class ManualMeasureError {
  entityId: string;
  facilityId: string;
  comment: string;
  newMedia: { data }[];
  priority: boolean;

  constructor(manualMeasureError: Partial<ManualMeasureError>) {
    Object.assign(this, manualMeasureError);
  }
}
