import { NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { GlobalStateService } from "app/global-state/global-state.service";
import { UserSupportLocalStorageService } from "app/services/user-support-local-storage.service";
import { UserSupportService } from "app/services/user-support.service";
import { Popover } from "primeng/popover";
import { NotificationMessageModalComponent } from "../modal/notification-message-modal.component";
import { NotificationMessage } from "../notification-message";
import { NotificationMessageDirective } from "../notification-message.directive";

@Component({
  selector: "notification-messages",
  templateUrl: "./notification-messages.component.html",
  styleUrls: ["./notification-messages.component.less"],
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    NotificationMessageModalComponent,
    Popover,
  ],
})
export class NotificationMessagesComponent<
  T,
> extends NotificationMessageDirective<T> {
  constructor(
    protected userSupportService: UserSupportService,
    private userSupportLocalStorageService: UserSupportLocalStorageService,
    private globalState: GlobalStateService,
  ) {
    super(userSupportService);
  }

  protected mapData() {
    this.notificationMessages.set(
      this.userSupportLocalStorageService.setHasBeenOpenedState(
        this.file.notificationMessages.filter(
          (message) => !message.important && this.shouldDisplay(message),
        ),
        this.globalState.loggedInUser().userConfig,
      ),
    );
  }

  hasUnreadNotificationMessages() {
    return this.notificationMessages().some(
      (notification) => !notification.hasBeenOpened,
    );
  }

  getUnreadCount() {
    return this.notificationMessages().filter(
      (notification) => !notification.hasBeenOpened,
    ).length;
  }

  handleMessageClick(notificationMessage: NotificationMessage) {
    notificationMessage.hasBeenOpened = true;
    this.saveUserConfig(notificationMessage);
    this.currentMessage.set(notificationMessage);
    this.visibleNotificationMessageModal.set(true);
  }

  private saveUserConfig(notificationMessage: NotificationMessage) {
    if (notificationMessage.hasBeenOpened) {
      if (
        !this.globalState
          .loggedInUser()
          .userConfig.openedNotificationIds.includes(notificationMessage.id)
      ) {
        this.globalState
          .loggedInUser()
          .userConfig.openedNotificationIds.push(notificationMessage.id);
      }
    } else {
      if (
        this.globalState
          .loggedInUser()
          .userConfig.openedNotificationIds.includes(notificationMessage.id)
      ) {
        this.globalState.loggedInUser().userConfig.openedNotificationIds =
          this.globalState
            .loggedInUser()
            .userConfig.openedNotificationIds.filter(
              (id) => id !== notificationMessage.id,
            );
      }
    }
    this.userSupportLocalStorageService.updateUserConfig(
      this.globalState.loggedInUser().userConfig,
    );
  }
}
