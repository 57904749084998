<div class="date-wrapper">
  <div class="first-date" [title]="value().firstDate | datex: 'with-time'">
    {{ value().firstDate | datex }}
  </div>
  @if (value().secondDate && value().secondDate !== value().firstDate) {
    <div class="second-date" [title]="value().secondDate | datex: 'with-time'">
      {{ value().secondDate | datex }}
    </div>
  }
</div>
