<simple-crud-modal
  [visible]="visible()"
  [headerText]="'HandleGroupsAndAssignees' | translate"
  [closeText]="'Close' | translate"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs
      [value]="
        modifiedModel().scheduleGroups.length
          ? 0
          : modifiedModel().scheduleUsers.length
            ? 1
            : 0
      "
      scrollable
    >
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-people'"
            [title]="'Groups' | translate"
            [helpText]="'ScheduleHandleGroupsBulkHelpText' | translate"
            [displayCount]="modifiedModel().scheduleGroups.length"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'bi bi-person'"
            [title]="'Assignees' | translate"
            [helpText]="'ScheduleHandleUsersBulkHelpText' | translate"
            [displayCount]="modifiedModel().scheduleUsers.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <handle-schedule-groups-tab
            [items]="modifiedModel().scheduleGroups"
            [headerSubtitleText]="
              'AddedGroupsSubtitleScheduleMultiple' | translate
            "
            (onItemsChange)="
              handleItemsChange(propertyStrings.scheduleGroups, $event)
            "
          >
          </handle-schedule-groups-tab>
        </p-tabpanel>
        <p-tabpanel [value]="1">
          <handle-schedule-users-tab
            [items]="modifiedModel().scheduleUsers"
            [headerSubtitleText]="
              'AddedUsersSubtitleScheduleMultiple' | translate
            "
            [selectedScheduleGroups]="modifiedModel().scheduleGroups"
            (onItemsChange)="
              handleItemsChange(propertyStrings.scheduleUsers, $event)
            "
          >
          </handle-schedule-users-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
