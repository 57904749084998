<simple-view-tab>
  <div class="view-section-layout-wrapper">
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "General" | translate }}
        </div>
        @if (!hideBannerImage()) {
          <div class="content">
            <div class="content-value">
              <div class="banner-image keep-ratio">
                <extensive-image
                  [imageUrl]="model().profileImageUrl"
                  [imageStorageSize]="150"
                  [placeholderImageType]="'user'"
                  [rounded]="true"
                  [displayLoader]="true"
                >
                </extensive-image>
              </div>
            </div>
          </div>
        }
        <div class="content">
          <div class="content-header">
            {{ "Name" | translate }}
          </div>
          <div class="content-value">
            {{ model().fullName }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "EmailAddress" | translate }}
          </div>
          <div class="content-value">
            {{ model().email }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "PhoneNumber" | translate }}
          </div>
          @if (model().contactCountryCode && model().contactPhoneNumber) {
            <div class="content-value">
              (+{{ model().contactCountryCode }})
              {{ model().contactPhoneNumber }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Country" | translate }}
          </div>
          <div class="content-value">
            {{ appInitializer.getCountryName(model().country) }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Language" | translate }}
          </div>
          <div class="content-value">
            {{ appInitializer.getCultureName(model().culture) }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Timezone" | translate }}
          </div>
          <div class="content-value">
            {{ appInitializer.getTimeZoneName(model().timeZone) }}
          </div>
        </div>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "Certificate" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "CertificateNumber" | translate }}
          </div>
          @if (model().certificates) {
            <div class="content-value">
              {{ model().certificates }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Documents" | translate }}
          </div>
          @if (documents().length) {
            <div class="content-value">
              <div class="documents apx-scrollbar">
                @for (document of documents(); track $index) {
                  <div class="document" [title]="document.title">
                    <extensive-pdf
                      [pdfUrl]="document.url"
                      [blob]="document.blob ? document.blob : null"
                      [thumbnailVersion]="true"
                    >
                    </extensive-pdf>
                  </div>
                }
              </div>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
    </div>
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "Authorization" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "MainOrganization" | translate }}
          </div>
          <div class="content-value">
            <div
              class="organization"
              [ngClass]="{
                highlighted:
                  model().organization.id ===
                  globalState.selectedOrganization().id,
              }"
            >
              <simple-image
                [url]="model().organization.logotypeUrl"
                [placeholderImageType]="'organization'"
                [size]="42"
              ></simple-image>
              <div class="info">
                <div class="name">
                  {{ model().organization.orgName }}
                </div>
                <div class="roles">
                  {{ getFormattedRoles(model().roles) }}
                </div>
                <div class="invite-date">
                  {{ "InvitedOn" | translate }}
                  {{ model().inviteDate | datex }}
                </div>
              </div>
              <div class="status">
                <simple-status-label [text]="model().status">
                </simple-status-label>
              </div>
              @if (model().organization.isTest) {
                <div class="test-marker">
                  {{ stringUtils.TEST }}
                </div>
              }
            </div>
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Memberships" | translate }}
          </div>
          @if (model().organizationMemberships.length) {
            <div class="content-value">
              @for (
                membership of model().organizationMemberships;
                track $index
              ) {
                <div
                  class="organization"
                  [ngClass]="{
                    highlighted:
                      membership.organization.id ===
                      globalState.selectedOrganization().id,
                  }"
                >
                  <div class="image">
                    <simple-image
                      [url]="membership.organization.logotypeUrl"
                      [placeholderImageType]="'organization'"
                      [size]="42"
                    ></simple-image>
                  </div>
                  <div class="info">
                    <div class="name">
                      {{ membership.organization.orgName }}
                    </div>
                    <div class="roles">
                      {{ getFormattedRoles(membership.roles) }}
                    </div>
                    <div class="invite-date">
                      {{ "InvitedOn" | translate }}
                      {{ membership.inviteDate | datex }}
                    </div>
                  </div>
                  <div class="status">
                    <simple-status-label [text]="membership.status">
                    </simple-status-label>
                  </div>
                  @if (membership.organization.isTest) {
                    <div class="test-marker">
                      {{ stringUtils.TEST }}
                    </div>
                  }
                </div>
              }
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "AppearsIn" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Groups" | translate }}
          </div>
          @if (model().userGroups.length) {
            <div class="content-value">
              <simple-view-connections
                [userGroupsAsLinks]="model().userGroups"
                [disabled]="
                  !globalState
                    .loggedInUser()
                    .canEdit(globalState.selectedOrganization().friendlyUrl)
                "
              >
              </simple-view-connections>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Schedules" | translate }}
          </div>
          @if (schedules().length) {
            <div class="content-value">
              <simple-view-connections
                [schedulesForUsers]="schedules()"
                [disabled]="
                  !globalState
                    .loggedInUser()
                    .canEdit(globalState.selectedOrganization().friendlyUrl)
                "
              >
              </simple-view-connections>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</simple-view-tab>
