import { NgClass } from "@angular/common";
import { Component, inject, signal } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AuthenticationService } from "app/authentication/authentication.service";
import { TokenStorageService } from "app/authentication/token-storage.service";
import { TranslationService } from "app/services/translation.service";
import { RegexUtils } from "app/tools/regex-utils";
import { ToastrService } from "ngx-toastr";
import { DotsLoaderComponent } from "../../components/dots-loader/dots-loader.component";
import { SimpleFormInputComponent } from "../../components/simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";

@Component({
  selector: "login-with-password",
  templateUrl: "./login-with-password.component.html",
  styleUrl: "../login-shared-style.less",
  standalone: true,
  imports: [
    TranslateModule,
    SimpleFormInputComponent,
    DotsLoaderComponent,
    NgClass,
  ],
})
export class LoginWithPasswordComponent {
  protected form = signal<FormGroup>(new FormGroup({}));
  protected email = signal<string>("");
  protected password = signal<string>("");
  protected pending = signal<boolean>(false);

  private formBuilder = inject(FormBuilder);
  private authService = inject(AuthenticationService);
  private translationService = inject(TranslationService);
  private tokenStorage = inject(TokenStorageService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private toastrService = inject(ToastrService);

  ngOnInit() {
    this.email.set(this.route.snapshot.queryParams["email"]);
    this.form.set(
      this.formBuilder.group({
        ["email"]: [this.email(), [Validators.required, this.emailValidator]],
        ["password"]: [this.password(), [Validators.required]],
      }),
    );
    this.form().valueChanges.subscribe(() => {
      Object.entries(this.form().controls).forEach(([name, control]) => {
        if (!control.disabled) {
          if (name === "email") {
            this.email.set(control.value);
          }
          if (name === "password") {
            this.password.set(control.value);
          }
        }
      });
    });
    this.translationService.use("sv"); // TODO sätta från url
  }

  private emailValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
      return { email: true };
    } else {
      return null;
    }
  }

  protected async loginWithPassword() {
    this.pending.set(true);
    try {
      const accessData = await this.authService.loginWithPassword(
        encodeURIComponent(this.email()),
        this.password(),
      );
      this.tokenStorage.setAccessData(accessData);

      const returnUrl = this.route.snapshot.queryParams["returnUrl"];
      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      } else {
        this.router.navigate([`/`]);
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      // TODO server errors här?
    }
  }

  switchToCode() {
    this.router.navigate(["/login-with-code"], {
      queryParams: { email: this.email() },
    });
  }
}
