import { Directive, signal } from "@angular/core";
import { ReplaySubject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserSupportService } from "../../services/user-support.service";
import { UserSupportFile } from "./user-support";

@Directive()
export abstract class UserSupportDirective<T> {
  file: UserSupportFile;
  fetching = signal<boolean>(false);

  popoverOpened = signal<boolean>(false);

  protected subscriptions = new Subscription();
  protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(protected userSupportService: UserSupportService) {}

  async initiateFileSubscription() {
    await this.userSupportService.initiateFetch();
    this.subscriptions.add(
      this.userSupportService.file
        .pipe(takeUntil(this.destroyed$))
        .subscribe((file) => {
          if (file) {
            this.fileWasFetched(file);
          }
        }),
    );

    this.subscriptions.add(
      this.userSupportService.fetching
        .pipe(takeUntil(this.destroyed$))
        .subscribe((fetching) => {
          this.fetching.set(fetching);
        }),
    );
  }

  private fileWasFetched(file: UserSupportFile) {
    this.file = file;
    this.mapData();
  }

  protected abstract mapData(): void;

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroyed$.complete();
  }
}
