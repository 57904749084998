import { Component, inject, input, output, signal } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { User } from "app/components/user/user";
import { PhoneValidationHelper } from "app/helpers/phone-validation/phone-validation-helper";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { RegexUtils } from "app/tools/regex-utils";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormInfoComponent } from "../../../../../components/simple-components/crud/modal/tabs/change/form/form-info/simple-form-info.component";
import { SimpleFormInputComponent } from "../../../../../components/simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";
import { SimpleFormSelectComponent } from "../../../../../components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { SimpleChangeTabComponent } from "../../../../../components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { MediaWidgetComponent } from "../../../../../media/widget/media-widget.component";
import { SimpleFormSelectButtonComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-select-button/simple-form-select-button.component";

@Component({
  selector: "change-details-tab",
  templateUrl: "./change-details-tab.component.html",
  styleUrls: ["./change-details-tab.component.less"],
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormInputComponent,
    SimpleFormSelectComponent,
    SimpleFormInfoComponent,
    MediaWidgetComponent,
    TranslateModule,
    SimpleFormSelectButtonComponent,
  ],
})
export class ChangeDetailsTabComponent extends ChangeDetailsBaseDirective<User> {
  selectableCountryCodeItems = signal<FormSelectItem[]>([]);
  selectableCountryItems = signal<FormSelectItem[]>([]);
  selectableTimeZoneItems = signal<FormSelectItem[]>([]);
  selectableCultureItems = signal<FormSelectItem[]>([]);
  selectableRoleItems = signal<FormSelectItem[]>([]);
  selectableStatusItems = signal<FormSelectItem[]>([]);

  canChangeDetails = input<boolean>();
  canChangeAuthorization = input<boolean>();
  documents = input<MediaWidgetItem[]>([]);
  isInvite = input<boolean>();

  onMediaChange = output<MediaWidgetItem[]>();

  private phoneValidationHelper = inject(PhoneValidationHelper);

  ngOnInit() {
    this.setSelectableCountryCodeItems();
    this.setSelectableCountryItems();
    this.setSelectableTimezoneItems();
    this.setSelectableCultureItems();
    this.setSelectableRoleItems();
    this.setSelectableStatusItems();

    if (this.isEdit) {
      this.form.set(
        this.formBuilder.group(
          {
            [this.propertyStrings.firstname]: [
              this.modifiedModel().firstname,
              [Validators.required, Validators.maxLength(25)],
            ],
            [this.propertyStrings.lastname]: [
              this.modifiedModel().lastname,
              [Validators.required, Validators.maxLength(25)],
            ],
            [this.propertyStrings.email]: [
              this.modifiedModel().email,
              [Validators.required, this.emailValidator],
            ],
            [this.propertyStrings.contactCountryCode]: [
              this.modifiedModel().contactCountryCode,
              this.countryCodeTriggerPhoneValidator,
            ],
            [this.propertyStrings.contactPhoneNumber]: [
              this.modifiedModel().contactPhoneNumber,
              this.phoneValidationHelper.phoneNumberValidator(
                this.propertyStrings.contactCountryCode,
              ),
            ],
            [this.propertyStrings.country]: [
              this.modifiedModel().country,
              Validators.required,
            ],
            [this.propertyStrings.timeZone]: [
              this.modifiedModel().timeZone,
              Validators.required,
            ],
            [this.propertyStrings.culture]: [
              this.modifiedModel().culture,
              Validators.required,
            ],
            [this.propertyStrings.rolesTemp]: [
              this.modifiedModel().rolesTemp,
              Validators.required,
            ],
            [this.propertyStrings.statusTemp]: [
              this.modifiedModel().statusTemp,
              Validators.required,
            ],
            [this.propertyStrings.certificates]: [
              this.modifiedModel().certificates,
              Validators.maxLength(50),
            ],
          },
          { validators: this.countryCodePhoneValidator },
        ),
      );
    } else {
      if (!this.isInvite) {
        this.form.set(
          this.formBuilder.group(
            {
              [this.propertyStrings.firstname]: [
                this.modifiedModel().firstname,
                [Validators.required, Validators.maxLength(25)],
              ],
              [this.propertyStrings.lastname]: [
                this.modifiedModel().lastname,
                [Validators.required, Validators.maxLength(25)],
              ],
              [this.propertyStrings.email]: [
                this.modifiedModel().email,
                [Validators.required, this.emailValidator],
              ],
              [this.propertyStrings.rolesTemp]: [
                this.modifiedModel().rolesTemp,
                Validators.required,
              ],
              [this.propertyStrings.password]: [
                this.modifiedModel().password,
                [
                  Validators.required,
                  this.passwordDigitValidator,
                  this.passwordLowerValidator,
                  this.passwordUpperValidator,
                  this.passwordSpecialValidator,
                  this.passwordLengthValidator,
                ],
              ],
              [this.propertyStrings.confirmPassword]: [
                this.modifiedModel().confirmPassword,
                Validators.required,
              ],
            },
            { validators: this.passwordMatchValidator },
          ),
        );
      } else {
        this.form.set(
          this.formBuilder.group({
            [this.propertyStrings.email]: [
              this.modifiedModel().email,
              [Validators.required, this.emailValidator],
            ],
            [this.propertyStrings.rolesTemp]: [
              this.modifiedModel().rolesTemp,
              Validators.required,
            ],
          }),
        );
      }
    }
    super.ngOnInit();
  }

  private emailValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
      return { email: true };
    } else {
      return null;
    }
  }

  private countryCodePhoneValidator(form: FormGroup): ValidationErrors {
    const countryCode = form.get("contactCountryCode");
    const phoneNumber = form.get("contactPhoneNumber");

    if (countryCode.value && !phoneNumber.value) {
      phoneNumber.setErrors({ required: true });
      phoneNumber.markAsTouched();
    } else if (phoneNumber.value && !countryCode.value) {
      countryCode.setErrors({ required: true });
      countryCode.markAsTouched();
    } else if (!countryCode.value && !phoneNumber.value) {
      countryCode.setErrors(null);
      phoneNumber.setErrors(null);
    }
    return null;
  }

  private countryCodeTriggerPhoneValidator(control: AbstractControl) {
    const form = control.parent;
    if (form && control.value) {
      const phone = form.get("contactPhoneNumber");
      phone.updateValueAndValidity();
    }
  }

  private passwordMatchValidator(form: FormGroup): ValidationErrors {
    const newPassword = form.get("password");
    const confirmPassword = form.get("confirmPassword");

    if (
      newPassword.value &&
      confirmPassword.value &&
      newPassword.value !== confirmPassword.value
    ) {
      confirmPassword.setErrors({ passwordsDoNotMatch: true });
    }

    if (
      newPassword.value &&
      confirmPassword.value &&
      newPassword.value === confirmPassword.value
    ) {
      confirmPassword.setErrors(null);
    }
    return null;
  }

  private passwordDigitValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_DIGIT).test(control.value)
    ) {
      return { passwordRequiresDigit: true };
    } else {
      return null;
    }
  }

  private passwordLowerValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_LOWER).test(control.value)
    ) {
      return { passwordRequiresLower: true };
    } else {
      return null;
    }
  }

  private passwordUpperValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_UPPER).test(control.value)
    ) {
      return { passwordRequiresUpper: true };
    } else {
      return null;
    }
  }

  private passwordSpecialValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_SPECIAL).test(control.value)
    ) {
      return { passwordRequiresNonAlphanumeric: true };
    } else {
      return null;
    }
  }

  private passwordLengthValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_LENGTH).test(control.value)
    ) {
      return { passwordRequiresLengthSixOrMore: true };
    } else {
      return null;
    }
  }

  private setSelectableCountryCodeItems() {
    this.selectableCountryCodeItems.set(
      this.modifiedModel().selectableCountryCodes.map(
        (countryCode) =>
          new FormSelectItem(countryCode.id, `+${countryCode.id}`),
      ),
    );
  }

  private setSelectableCountryItems() {
    this.selectableCountryItems.set(
      this.modifiedModel().selectableCountries.map(
        (country) => new FormSelectItem(country.id, country.text),
      ),
    );
  }

  private setSelectableTimezoneItems() {
    this.selectableTimeZoneItems.set(
      this.modifiedModel().selectableTimeZones.map(
        (timeZone) => new FormSelectItem(timeZone.id, timeZone.text),
      ),
    );
  }

  private setSelectableCultureItems() {
    this.selectableCultureItems.set(
      this.modifiedModel().selectableCultures.map(
        (culture) => new FormSelectItem(culture.id, culture.text),
      ),
    );
  }

  private setSelectableRoleItems() {
    this.selectableRoleItems.set(
      this.modifiedModel().selectableRoles.map(
        (role) =>
          new FormSelectItem(role.id, this.translationService.instant(role.id)),
      ),
    );
  }

  private setSelectableStatusItems() {
    this.selectableStatusItems.set([
      new FormSelectItem(
        StringUtils.ACTIVE,
        this.translationService.instant(StringUtils.ACTIVE),
      ),
      new FormSelectItem(
        StringUtils.INACTIVE,
        this.translationService.instant(StringUtils.INACTIVE),
      ),
    ]);
  }

  handleMediaCollectionChange(collection: MediaWidgetItem[]) {
    this.onMediaChange.emit(collection);
  }
}
