import { Component, computed, Signal } from "@angular/core";
import { TextContent } from "../../../columns/simple-table-text-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-text",
  templateUrl: "./column-type-text.component.html",
  styleUrl: "./column-type-text.component.less",
  standalone: true,
})
export class ColumnTypeTextComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<TextContent>;
}
