import { LowerCasePipe, NgClass } from "@angular/common";
import { Component, computed, input, output, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Utils } from "app/tools/utils";
import { Drawer } from "primeng/drawer";
import { Popover } from "primeng/popover";
import { NumberUtils } from "../../../../../tools/number-utils";
import { SimpleFilterInput } from "./simple-filter-input";
import { SortObject } from "./sort-object";
import { SimpleFilterSubComponent } from "./sub/simple-filter-sub.component";

@Component({
  selector: "simple-filter",
  templateUrl: "./simple-filter.component.html",
  styleUrls: ["./simple-filter.component.less"],
  standalone: true,
  imports: [
    NgClass,
    SimpleFilterSubComponent,
    TranslateModule,
    Popover,
    LowerCasePipe,
    Drawer,
  ],
})
export class SimpleFilterComponent<T extends { id?: string }> {
  searchIsFocused = signal<boolean>(false);
  sortDirectionIsOpened = signal<boolean>(false);
  sortLabelIsOpened = signal<boolean>(false);
  filterIsOpened = signal<boolean>(false);

  searchValue = input<string>("");
  sortObjects = input<SortObject<T>[]>();
  currentSortObject = input<SortObject<T>>();
  filterInputs = input<SimpleFilterInput[]>();
  inModal = input<boolean>();
  totalCount = input<number>();
  selectedCount = input<number>();

  onSearch = output<string>();
  onSearchClear = output();
  onSortDirectionChange = output<"Ascending" | "Descending">();
  onSortLabelChange = output<SortObject<T>>();
  onFilter = output<SimpleFilterInput>();
  onFilterClear = output();
  onSelectedClear = output();

  visibleSortObjects = computed(() =>
    this.sortObjects().filter((object) => !object.hidden()),
  );

  visibleFilterInputs = computed(() =>
    this.filterInputs().filter((input) => !input.hidden()),
  );

  get activeFilterInputs() {
    // TODO computed?
    return this.visibleFilterInputs().filter(
      (filterInput) => filterInput.selectedItems.length,
    );
  }

  handleSearch(event: Event) {
    this.emitOnSearchWithDebounce(event.target["value"]);
  }

  private emitOnSearchWithDebounce = Utils.debounce(
    (searchValue: string) => this.onSearch.emit(searchValue),
    NumberUtils.DEFAULT_DEBOUNCE_TIME,
  );

  handleClearSearch() {
    this.onSearchClear.emit();
  }

  handleSortDirection(direction: "Ascending" | "Descending") {
    if (direction === this.currentSortObject().direction) {
      return;
    }
    this.onSortDirectionChange.emit(direction);
  }

  handleSortLabel(sortObject: SortObject<T>) {
    if (sortObject.sortProperty === this.currentSortObject().sortProperty) {
      return;
    }
    this.onSortLabelChange.emit(sortObject);
  }

  handleFilter(filterInput: SimpleFilterInput) {
    this.emitOnFilterWithDebounce(filterInput);
  }

  private emitOnFilterWithDebounce = Utils.debounce(
    (filterInput: SimpleFilterInput) => this.onFilter.emit(filterInput),
    NumberUtils.DEFAULT_DEBOUNCE_TIME,
  );

  handleClearFilter() {
    this.onFilterClear.emit();
  }

  handleClearSelected() {
    this.onSelectedClear.emit();
  }
}
