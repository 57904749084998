<div class="handle-header">
  <div class="title-wrapper">
    <div class="title" title="{{ headerTitleText() }}">
      {{ headerTitleText() }}
    </div>
    @if (headerSubtitleText()) {
      <div class="subtitle" title="{{ headerSubtitleText() }}">
        {{ headerSubtitleText() }}
      </div>
    }
  </div>
  <list-actions [actions]="listActions()" [inWizard]="true"></list-actions>
</div>
