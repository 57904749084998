import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "app/app-initializer";
import { Organization } from "app/components/organization/organization";
import { ViewDetailsBaseDirective } from "app/components/simple-components/directives/view-details-base.directive";
import { SimpleViewTabComponent } from "../../../../../components/simple-components/crud/modal/tabs/view/simple-view-tab.component";

@Component({
  selector: "view-details-tab",
  templateUrl: "./view-details-tab.component.html",
  standalone: true,
  imports: [SimpleViewTabComponent, TranslateModule],
})
export class ViewDetailsTabComponent extends ViewDetailsBaseDirective<Organization> {
  appInitializer = inject(AppInitializer);
}
