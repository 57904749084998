import { NgTemplateOutlet } from "@angular/common";
import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Client } from "app/components/organization/organization";
import { Schedule } from "app/components/schedule/schedule";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowAction,
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
  SimpleTableRowActionView,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  CategoryContent,
  SimpleTableCategoryColumn,
} from "app/components/simple-components/list/table/columns/simple-table-category-column";
import {
  CountContent,
  CountObject,
  SimpleTableCountColumn,
} from "app/components/simple-components/list/table/columns/simple-table-count-column";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  IconContent,
  IconObject,
  SimpleTableIconColumn,
} from "app/components/simple-components/list/table/columns/simple-table-icon-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import {
  SimpleTableHeaderAction,
  SimpleTableHeaderActionDelete,
} from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { KeyValuePair } from "app/filter";
import { SwalConfig } from "app/swal/swal-config.component";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import {
  AssignmentTemplateFilter,
  AssignmentTemplateService,
} from "../../../services/assignment-template.service";
import { ListHeaderComponent } from "../../header/list-header/list-header.component";
import { SimpleViewClientComponent } from "../../simple-components/various/view-client/simple-view-client.component";
import { SimpleViewConnectionsComponent } from "../../simple-components/various/view-connections/simple-view-connections.component";
import { SimpleViewItemsComponent } from "../../simple-components/various/view-items/simple-view-items.component";
import { AssignmentTemplate } from "../assignment-template";
import { CreateEditAssignmentTemplateComponent } from "../create-edit-assignment-template/create-edit-assignment-template.component";
import { ChangeCategoriesComponent } from "./change-categories/change-categories.component";
import { ChangeClientComponent } from "./change-geo-fencing/change-client/change-client.component";
import { ChangeGeoFencingComponent } from "./change-geo-fencing/change-geo-fencing.component";

@Component({
  selector: "list-assignment-template",
  templateUrl: "./list-assignment-template.component.html",
  standalone: true,
  imports: [
    CreateEditAssignmentTemplateComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    ChangeCategoriesComponent,
    ChangeClientComponent,
    ChangeGeoFencingComponent,
    NgTemplateOutlet,
    ListHeaderComponent,
    SimpleViewItemsComponent,
    SimpleViewConnectionsComponent,
    SimpleViewClientComponent,
  ],
})
export class ListAssignmentTemplateComponent extends ListBaseDirective<AssignmentTemplate> {
  readonly filterObject = new AssignmentTemplateFilter();

  displayNewButton = signal<boolean>(false);

  visibleChangeCategories = signal<boolean>(false);
  visibleChangeClient = signal<boolean>(false);
  visibleChangeGeoFencing = signal<boolean>(false);
  client = signal<Client>(new Client({}));
  schedules = signal<Schedule[]>([]);
  visibleViewClient = signal<boolean>(false);
  visibleViewItems = signal<boolean>(false);

  constructor(private assignmentTemplateService: AssignmentTemplateService) {
    super(assignmentTemplateService);
  }

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "Create",
        "NewAssignmentTemplate",
        StringUtils.icons.new,
        () => this.visibleCreateEdit.set(true),
        () => !this.displayNewButton() || this.isInModal(),
      ),
    ]);
  }

  protected async configureTableFilter() {
    const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
    const categoriesKey = StringUtils.CATEGORIES_KEY;
    const clientsKey = StringUtils.CLIENTS_KEY;
    const geoFencingKey = StringUtils.GEO_FENCING_KEY;
    const facilitiesKey = StringUtils.FACILITIES_KEY;
    const schedulesKey = StringUtils.SCHEDULES_KEY;
    const createdKey = StringUtils.CREATED_KEY;
    const updatedKey = StringUtils.UPDATED_KEY;
    const statusKey = StringUtils.STATUS_KEY;
    const hasSchedulesKey = StringUtils.HAS_SCHEDULES_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [templateTypesKey]: null,
        [categoriesKey]: null,
        [clientsKey]: null,
        [geoFencingKey]: null,
        [facilitiesKey]: null,
        [schedulesKey]: null,
        [createdKey]: null,
        [updatedKey]: null,
        [statusKey]: null,
        [hasSchedulesKey]: null,
      });
    }

    try {
      const [templateTypes, categories, clients, facilities, schedules] =
        await Promise.all([
          this.assignmentTemplateService.getTemplateTypes(
            this.globalState.selectedOrganization().id,
          ),
          this.assignmentTemplateService.getCategories(
            this.globalState.selectedOrganization().id,
          ),
          this.assignmentTemplateService.getClients(
            this.globalState.selectedOrganization().id,
          ),
          this.assignmentTemplateService.getFacilities(),
          this.assignmentTemplateService.getSchedules(
            this.globalState.selectedOrganization().id,
          ),
        ]);

      const convertedTemplateTypes = templateTypes.map(
        (templateType) =>
          new SimpleFilterInputItem(templateType.id, templateType.name),
      );
      const convertedCategories = categories.map(
        (category) => new SimpleFilterInputItem(category.id, category.name),
      );
      const convertedClients = clients.map(
        (client) =>
          new SimpleFilterInputItem(
            client.id,
            `${client.name} ${client.email ? "(" + client.email + ")" : ""}`,
          ),
      );
      const convertedFacilities = facilities.map(
        (facility) => new SimpleFilterInputItem(facility.id, facility.name),
      );
      const convertedSchedules = schedules.map(
        (schedule) => new SimpleFilterInputItem(schedule.id, schedule.name),
      );

      this.displayNewButton.set(
        templateTypes.some((tt) => !tt.isManualMeasure) &&
          !!facilities.length &&
          this.globalState.loggedInUser().isSuperAdmin(),
      );

      this.filterInputs.set([
        new SimpleFilterInput(
          templateTypesKey,
          templateTypesKey,
          StringUtils.icons.templateType,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, ""));
            }
            return keyValuePairs;
          },
          convertedTemplateTypes,
        ),
        new SimpleFilterInput(
          facilitiesKey,
          facilitiesKey,
          StringUtils.icons.facility,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(facilitiesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(facilitiesKey, ""));
            }
            return keyValuePairs;
          },
          convertedFacilities,
        ),
        new SimpleFilterInput(
          categoriesKey,
          categoriesKey,
          StringUtils.icons.category,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(categoriesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(categoriesKey, ""));
            }
            return keyValuePairs;
          },
          convertedCategories,
        ),
        new SimpleFilterInput(
          clientsKey,
          clientsKey,
          StringUtils.icons.client,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(clientsKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(clientsKey, ""));
            }
            return keyValuePairs;
          },
          convertedClients,
        ),
        new SimpleFilterInput(
          schedulesKey,
          schedulesKey,
          StringUtils.icons.schedule,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(schedulesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(schedulesKey, ""));
            }
            return keyValuePairs;
          },
          convertedSchedules,
        ),
        new SimpleFilterInput(
          hasSchedulesKey,
          hasSchedulesKey,
          StringUtils.icons.schedule,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(hasSchedulesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(hasSchedulesKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          geoFencingKey,
          geoFencingKey,
          StringUtils.icons.geo,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(geoFencingKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(geoFencingKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          statusKey,
          statusKey,
          StringUtils.icons.setStatus,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(statusKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(statusKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.ACTIVE, StringUtils.ACTIVE),
            new SimpleFilterInputItem(
              StringUtils.INACTIVE,
              StringUtils.INACTIVE,
            ),
          ],
        ),
        new SimpleFilterInput(
          createdKey,
          createdKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(createdKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(createdKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          updatedKey,
          updatedKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(updatedKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(updatedKey, ""));
            }
            return keyValuePairs;
          },
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(
        "TemplateType.Name" as keyof AssignmentTemplate,
        "TemplateType",
      ),
      new SortObject(
        "Facility.Name" as keyof AssignmentTemplate,
        "Facility",
        true,
      ),
      new SortObject(this.propertyStrings.created, "Created"),
      new SortObject(this.propertyStrings.updated, "Updated"),
      new SortObject(this.propertyStrings.geoControlled, "GeoFencing"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTextColumn(
        ["TemplateType"],
        (row) => new TextContent(row.templateType.name),
      ),
      new SimpleTableCategoryColumn(
        ["Categories"],
        (row) => new CategoryContent(row.categories),
      ),
      new SimpleTableFacilityColumn(
        ["Facility"],
        (row) => new FacilityContent(row.facility),
      ),
      new SimpleTableIconColumn(
        [],
        (row) =>
          new IconContent([
            new IconObject(
              row.client ? StringUtils.icons.client : "",
              "Client",
              row.client
                ? `${row.client.name} ${row.client.emailAddress ? "(" + row.client.emailAddress + ")" : ""}`
                : "",
            ),
            new IconObject(
              row.geoControlled ? StringUtils.icons.geo : "",
              "Geofencing",
              "GeofencingText",
              true,
            ),
          ]),
      ),
      new SimpleTableCountColumn(
        [],
        (row) =>
          new CountContent([
            new CountObject(
              row.schedules.length ? StringUtils.icons.schedule : "",
              row.schedules.length,
              "Schedules",
            ),
          ]),
      ),
      new SimpleTableDateColumn(
        ["Created", "Updated"],
        (row) => new DateContent(row.created, row.updated),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.hasActiveTemplateObjects
              ? StringUtils.ACTIVE
              : StringUtils.INACTIVE,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "ChangeCategories",
        StringUtils.icons.category,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeCategories.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderAction(
        "ChangeClient",
        StringUtils.icons.client,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeClient.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderAction(
        "ChangeGeoFencing",
        StringUtils.icons.geo,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeGeoFencing.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () => this.isInModal(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.handle,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowAction(
        "ChangeCategories",
        StringUtils.icons.category,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleChangeCategories.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowActionView(
        "ViewClient",
        StringUtils.icons.client,
        (row) => {
          this.client.set(row.client);
          this.visibleViewClient.set(true);
        },
        (row) => !row.client,
      ),
      new SimpleTableRowActionView(
        "ViewConnections",
        StringUtils.icons.connections,
        (row) => {
          this.schedules.set(row.schedules);
          this.visibleViewItems.set(true);
        },
        (row) => !row.schedules.length,
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        () => this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState(
        "AssignmentTemplates",
        StringUtils.icons.assignmentTemplate,
      ),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(new SwalConfig(this.translationService).getDelete()).then(
      async (result) => {
        if (result.value) {
          this.handleCloseCreateEdit();
          this.itemIds.set(ids);
          this.pending.set(true);
          try {
            const data = await this.assignmentTemplateService.deleteRange(
              this.itemIds(),
            );
            this.pending.set(false);
            this.toastrService.success(
              this.onlyOneSelected
                ? this.translationService.instant(
                    "TheAssignmentTemplateWasDeleted",
                  )
                : this.translationService.instant(
                    "TheAssignmentTemplatesWereDeleted",
                  ),
            );
            this.selectedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.unselectableIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.addedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.modifiedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.itemIds.set([]);
            this.getTableData();
          } catch (error) {
            this.pending.set(false);
            this.toastrService.error(error.message);
          }
        }
      },
    );
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleCloseHandleCategories() {
    this.visibleChangeCategories.set(false);
    this.itemIds.set([]);
  }

  handleCloseHandleClient() {
    this.visibleChangeClient.set(false);
    this.itemIds.set([]);
  }

  handleCloseSetGeoFencing() {
    this.visibleChangeGeoFencing.set(false);
    this.itemIds.set([]);
  }

  async handleRequestDoneHandleCategories(data: AssignmentTemplate[]) {
    this.handleCloseHandleCategories();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }

  async handleRequestDoneHandleClient(data: AssignmentTemplate[]) {
    this.handleCloseHandleClient();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }

  async handleRequestDoneSetGeoFencing(data: AssignmentTemplate[]) {
    this.handleCloseSetGeoFencing();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }
}
