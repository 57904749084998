<div class="sf-container" [ngClass]="{ 'in-modal': inModal() }">
  <div class="sf-actions">
    <div class="sf-section">
      <div class="sss-wrapper">
        <div
          class="icon bi-search"
          [ngClass]="{
            active: searchValue() || searchIsFocused(),
          }"
        ></div>
        <input
          #searchInput
          type="search"
          class="form-control search-input"
          [title]="'Search' | translate"
          [value]="searchValue()"
          placeholder="{{ 'Search' | translate }}..."
          autocomplete="off"
          spellcheck="false"
          (input)="handleSearch($event)"
          (focus)="searchIsFocused.set(true)"
          (blur)="searchIsFocused.set(false)"
        />
        @if (searchValue()) {
          <div
            class="clear-button bi-x"
            [title]="'ClearSearch' | translate"
            (click)="handleClearSearch()"
          ></div>
        }
      </div>
    </div>
    <div class="sf-section sort">
      <div
        class="sf-action sort highlighted"
        [ngClass]="{
          opened: sortLabelIsOpened(),
        }"
        title="{{ 'Sorted' | translate }} {{ 'On' | translate | lowercase }} {{
          currentSortObject().label | translate | lowercase
        }}"
        (click)="sortLabelTemplate.toggle($event)"
      >
        <div class="text">
          {{ currentSortObject().label | translate }}
        </div>
      </div>
      <div
        class="sf-action sort highlighted"
        [ngClass]="{
          opened: sortDirectionIsOpened(),
        }"
        title="{{ 'Sorted' | translate }} {{ 'In' | translate | lowercase }} {{
          currentSortObject().direction | translate | lowercase
        }} {{ 'AlphabeticalOrder' | translate | lowercase }}"
        (click)="sortDirectionTemplate.toggle($event)"
      >
        <div
          class="icon"
          [ngClass]="{
            'bi-sort-alpha-down': currentSortObject().direction === 'Ascending',
            'bi-sort-alpha-down-alt':
              currentSortObject().direction === 'Descending',
          }"
        ></div>
      </div>
    </div>
    <p-popover
      #sortDirectionTemplate
      (onShow)="sortDirectionIsOpened.set(true)"
      (onHide)="sortDirectionIsOpened.set(false)"
    >
      <div
        class="standard-menu-item sort"
        [ngClass]="{
          highlighted: currentSortObject().direction === 'Ascending',
        }"
        [title]="'Ascending' | translate"
        (click)="
          sortDirectionTemplate.toggle($event); handleSortDirection('Ascending')
        "
      >
        <div class="icon bi-sort-alpha-down"></div>
      </div>
      <div
        class="standard-menu-item sort"
        [ngClass]="{
          highlighted: currentSortObject().direction === 'Descending',
        }"
        [title]="'Descending' | translate"
        (click)="
          sortDirectionTemplate.toggle($event);
          handleSortDirection('Descending')
        "
      >
        <div class="icon bi-sort-alpha-down-alt"></div>
      </div>
    </p-popover>
    <p-popover
      #sortLabelTemplate
      (onShow)="sortLabelIsOpened.set(true)"
      (onHide)="sortLabelIsOpened.set(false)"
    >
      @for (sortObject of visibleSortObjects(); track $index) {
        @if (sortObject.sortProperty) {
          <div
            class="standard-menu-item sort"
            [ngClass]="{
              highlighted:
                sortObject.sortProperty === currentSortObject().sortProperty,
            }"
            (click)="
              sortLabelTemplate.toggle($event); handleSortLabel(sortObject)
            "
          >
            {{ sortObject.label | translate }}
          </div>
        }
      }
    </p-popover>
    <div class="sf-section filter">
      <div
        class="sf-action filter"
        [title]="'Filter' | translate"
        [ngClass]="{
          opened: filterIsOpened(),
          highlighted: activeFilterInputs.length,
          disabled: !visibleFilterInputs().length,
        }"
        (click)="filterIsOpened.set(!filterIsOpened())"
      >
        <div class="icon bi-funnel"></div>
        <div class="text">
          {{ "Filter" | translate }}
          @if (activeFilterInputs.length) {
            ({{ activeFilterInputs.length }})
          }
        </div>
        @if (activeFilterInputs.length) {
          <div
            class="clear-button bi-x"
            [title]="'ClearFilter' | translate"
            (click)="$event.stopImmediatePropagation(); handleClearFilter()"
          ></div>
        }
      </div>
    </div>
    <div class="sf-section">
      <div
        class="sf-action count"
        [ngClass]="{
          highlighted: selectedCount() > 0,
        }"
      >
        <div class="count-wrapper">
          <div class="total-count">
            <div class="text">{{ "Total" | translate }}:</div>
            <div class="count">
              {{ totalCount() }}
            </div>
          </div>
          @if (selectedCount() > 0) {
            <div class="selected-count">
              <div class="text">{{ "Selected" | translate }}:</div>
              <div class="count">
                {{ selectedCount() }}
              </div>
            </div>
            <div
              class="clear-button bi-x"
              [title]="'ClearSelected' | translate"
              (click)="handleClearSelected()"
            ></div>
          }
        </div>
      </div>
    </div>
  </div>
  <!-- TODO flytta in i sub -->
  <p-drawer
    [(visible)]="filterIsOpened"
    [showCloseIcon]="false"
    [position]="'left'"
    [appendTo]="'body'"
  >
    <ng-template #header>
      <div class="label">
        {{ "Filter" | translate }}
      </div>
      <div class="actions">
        <div
          class="action"
          [title]="'ClearFilter' | translate"
          (click)="handleClearFilter()"
          [ngClass]="{ disabled: activeFilterInputs.length === 0 }"
        >
          <i class="icon bi-eraser"></i>
        </div>
        <div
          class="action"
          [title]="'Close' | translate"
          (click)="filterIsOpened.set(false)"
        >
          <i class="icon bi-x-lg"></i>
        </div>
      </div>
    </ng-template>
    <simple-filter-sub
      [filterInputs]="visibleFilterInputs()"
      (onFilter)="handleFilter($event)"
      (onFilterClear)="handleClearFilter()"
    >
    </simple-filter-sub>
  </p-drawer>
</div>
