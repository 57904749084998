import { LowerCasePipe, NgClass } from "@angular/common";
import {
  Component,
  ElementRef,
  inject,
  input,
  model,
  output,
  signal,
  ViewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TranslationService } from "app/services/translation.service";
import { ACCEPTED_MEDIA_TYPES, FileUtils } from "app/tools/file-utils";
import { MediaUtils } from "app/tools/media-utils";
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageCropperModule,
  ImageTransform,
  OutputFormat,
} from "ngx-image-cropper";
import { ExtensiveImageComponent } from "../../../../media/extensive-image/extensive-image.component";
import { DotsLoaderComponent } from "../../../dots-loader/dots-loader.component";

@Component({
  selector: "simple-change-image",
  templateUrl: "./simple-change-image.component.html",
  styleUrls: ["./simple-change-image.component.less"],
  standalone: true,
  imports: [
    ExtensiveImageComponent,
    ImageCropperModule,
    DotsLoaderComponent,
    TranslateModule,
    NgClass,
    LowerCasePipe,
  ],
})
export class SimpleChangeImageComponent {
  media = MediaUtils;
  acceptedMediaTypes = ACCEPTED_MEDIA_TYPES.IMAGE;
  scale = 1;
  isSlidingColorPicker = false;

  // We can allow larger files than backend here due to that we always crop before sending.
  // The user will be warned and permitted when the resulting cropped image will be over the maxlimit.
  maxImageSize: number = 60;
  maxImageSizeBytes = FileUtils.mbToBytes(this.maxImageSize);
  maxImageSizeOnServer = signal<number>(10);

  uploadedFile = signal<File>(null);
  imageIsLoaded = signal<boolean>(false);
  croppedImage = signal<Blob>(null);
  format = signal<OutputFormat>(null);
  transform = signal<ImageTransform>({});
  croppedImageSize = signal<number>(0);
  croppedImageAsUrl = signal<string>("");
  shouldUseBackgroundColor = signal<boolean>(false);
  backgroundColor = signal<string>("rgba(255, 255, 255, 1)");
  exceededMaxLimit = signal<boolean>(false);
  validationErrors = signal<string[]>([]);
  imageUrl = model<string>();
  rounded = input<boolean>();

  onClear = output();

  private translationService = inject(TranslationService);

  @ViewChild("dropHelper", { read: ElementRef }) dropHelperEl: ElementRef;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ViewChild("fileInput") fileInput: HTMLInputElement;

  get dropHelper(): HTMLDivElement {
    return this.dropHelperEl.nativeElement;
  }

  ngAfterViewInit() {
    if (!this.imageUrl()) {
      this.fileInput["nativeElement"].click();
    }
  }

  handleFileChange(event: any) {
    const inputElement = <HTMLInputElement>event.target;
    const files = inputElement.files;
    this.uploadFile(files[0]);
    inputElement.value = "";
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    this.dropHelper.classList.add("is-visible");
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();

    // We need some calculation in order to only do stuff when we leave the actual dropzone.
    const rect = document
      .getElementById("existingImage")
      .getBoundingClientRect();
    if (
      event.clientY < rect.top ||
      event.clientY >= rect.bottom ||
      event.clientX < rect.left ||
      event.clientX >= rect.right
    ) {
      this.dropHelper.classList.remove("is-visible");
    }
  }

  handleFileDrop(event: DragEvent) {
    event.preventDefault();
    this.dropHelper.classList.remove("is-visible");
    this.uploadFile(event.dataTransfer.files[0]);
  }

  uploadFile(file: File) {
    this.validationErrors.set([]);

    if (!FileUtils.isImage(file)) {
      this.validationErrors().push(
        this.translationService.instant("FileValidationNotValidImageSingle"),
      );
    }

    if (!FileUtils.isAllowedFileSize(file, this.maxImageSizeBytes)) {
      if (!this.validationErrors().length) {
        const fileSizeMb = Math.round(
          FileUtils.bytesToMb(this.maxImageSizeBytes),
        );
        this.validationErrors().push(
          this.translationService.instant(
            "FileValidationExceedsTheLimitSingle",
            { 0: file.name, 1: fileSizeMb, 2: "MB" },
          ),
        );
      }
    }

    if (!this.validationErrors().length) {
      this.uploadedFile.set(file);
      this.setFormat();
    }
  }

  reset() {
    this.uploadedFile.set(null);
    this.croppedImage.set(null);
    this.imageIsLoaded.set(false);
    this.scale = 1;
    this.transform.set({});
    this.croppedImageSize.set(0);
  }

  removeExistingImage() {
    this.validationErrors.set([]);
    this.imageUrl.set(null);
    this.onClear.emit();
  }

  // Cropper

  setFormat() {
    this.format.set("png");
  }

  setCroppedImageUrl() {
    if (this.croppedImage()) {
      this.croppedImageAsUrl.set(URL.createObjectURL(this.croppedImage()));
    }
  }

  handleImageCropped(event: ImageCroppedEvent) {
    this.croppedImage.set(event.blob);
    this.setCroppedImageUrl();
    this.croppedImageSize.set(this.getCroppedImageSizeAsMb());
    this.exceededMaxLimit.set(
      this.croppedImageSize() > this.maxImageSizeOnServer(),
    );
  }

  getCroppedImageSizeAsMb() {
    return (
      Math.ceil(
        FileUtils.bytesToMb(this.croppedImage().size + Number.EPSILON) * 100,
      ) / 100
    );
  }

  toggleShouldUseBackgroundColor() {
    this.shouldUseBackgroundColor.set(!this.shouldUseBackgroundColor());
    if (this.shouldUseBackgroundColor()) {
      this.handleColorChange("rgba(255, 255, 255, 1)");
    } else {
      this.handleColorChange("rgba(255, 255, 255, 0)");
    }
  }

  handleColorChange(colorValue: string) {
    if (!this.isSlidingColorPicker) {
      this.backgroundColor.set(colorValue);

      setTimeout(() => {
        this.imageCropper.crop();
      });
    }
  }

  handleSliderDragStart() {
    this.isSlidingColorPicker = true;
  }

  handleSliderDragEnd(event: { slider: string; color: string }) {
    this.isSlidingColorPicker = false;
    this.handleColorChange(event.color);
  }

  handleImageLoaded() {
    this.imageIsLoaded.set(true);
    this.exceededMaxLimit.set(true);
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform.set({
      ...this.transform(),
      scale: this.scale,
    });
  }

  zoomOut() {
    if (this.scale > 0.2) {
      this.scale -= 0.1;
      this.transform.set({
        ...this.transform(),
        scale: this.scale,
      });
    }
  }
}
