import { Component, input } from "@angular/core";
import { ListActionsComponent } from "app/components/list-actions/list-actions.component";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";

@Component({
  selector: "handle-header",
  templateUrl: "./handle-header.component.html",
  styleUrls: ["./handle-header.component.less"],
  standalone: true,
  imports: [ListActionsComponent],
})
export class HandleHeaderComponent {
  headerTitleText = input<string>();
  headerSubtitleText = input<string>();
  listActions = input<SimpleListAction[]>();
}
