import { Component, inject, input, output, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { GlobalStateService } from "app/global-state/global-state.service";
import { Utils } from "app/tools/utils";
import { ButtonModule } from "primeng/button";
import { Dialog } from "primeng/dialog";
import { FeedbackComponent } from "../../../../components/feedback/feedback.component";
import { HelpComponent } from "../../../../components/user-support/help/help.component";
import { DotsLoaderComponent } from "../../../dots-loader/dots-loader.component";

@Component({
  selector: "simple-crud-modal",
  templateUrl: "./simple-crud-modal.component.html",
  styleUrls: ["./simple-crud-modal.component.less"],
  standalone: true,
  imports: [
    HelpComponent,
    FeedbackComponent,
    DotsLoaderComponent,
    Dialog,
    ButtonModule,
    TranslateModule,
  ],
})
export class SimpleCrudModalComponent {
  context = signal<string>("");

  visible = input<boolean>();
  headerText = input<string>();
  hideCloseButton = input<boolean>();
  hideSubmitButton = input<boolean>();
  closeText = input<string>();
  submitText = input<string>();
  disableSubmit = input<boolean>();
  isLoading = input<boolean>();
  displayDeleteButton = input<boolean>();
  large = input<boolean>();

  onClose = output();
  onDelete = output();
  onSubmit = output();

  private route = inject(ActivatedRoute);
  protected globalState = inject(GlobalStateService);

  ngOnInit() {
    const segments = this.route.snapshot.url;
    if (segments.length > 1) {
      let word = segments[1].path;
      if (word.includes("-")) {
        const [firstHalf, secondHalf] = word.split("-");
        word = `${Utils.capitalizeFirstLetter(firstHalf)}${Utils.capitalizeFirstLetter(
          secondHalf,
        )}`;
      } else {
        word = Utils.capitalizeFirstLetter(word);
      }
      if (segments[2] && segments[2].path.includes("measure")) {
        this.context.set("MeasureAssignments");
      } else {
        this.context.set(Utils.capitalizeFirstLetter(word));
      }
    }
  }

  handleClose() {
    this.onClose.emit();
  }

  handleDelete() {
    this.onDelete.emit();
  }

  handleSubmit() {
    this.onSubmit.emit();
  }
}
