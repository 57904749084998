import { LowerCasePipe, NgClass } from "@angular/common";
import { Component, input } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SelectButton } from "primeng/selectbutton";
import { SimpleFormErrorComponent } from "../form-error/simple-form-error.component";
import { FormSelectItem } from "../form-select/simple-form-select.component";
import { SimpleFormControlDirective } from "../simple-form-control.directive";

@Component({
  selector: "simple-form-select-button",
  templateUrl: "./simple-form-select-button.component.html",
  styleUrls: ["./simple-form-select-button.component.less"],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    SimpleFormErrorComponent,
    LowerCasePipe,
    TranslateModule,
    SelectButton,
  ],
})
export class SimpleFormSelectButtonComponent extends SimpleFormControlDirective {
  items = input<FormSelectItem[]>();
  multiple = input<boolean>();
  redBackground = input<boolean>();
  greenBackground = input<boolean>();
}
