import { NgClass } from "@angular/common";
import { Component, computed, inject, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TokenStorageService } from "app/authentication/token-storage.service";
import { ScheduleForViewing } from "app/components/schedule/schedule";
import { LandingPageBaseDirective } from "app/components/simple-components/directives/landing-page-base.directive";
import { SimpleLandingPageComponent } from "app/components/simple-components/landing-page/simple-landing-page.component";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { UserService } from "app/services/user.service";
import { Popover } from "primeng/popover";
import { ExtensiveImageComponent } from "../../../media/extensive-image/extensive-image.component";
import { ViewDetailsTabComponent } from "../create-edit-user/tabs/view-details/view-details-tab.component";
import { EditLoggedInUserComponent } from "../edit-logged-in-user/edit-logged-in-user.component";
import { User } from "../user";
import { ProfileImageComponent } from "./profile-image/profile-image.component";
import { ChangePasswordComponent } from "./security/password/change-password.component";

@Component({
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.less"],
  standalone: true,
  imports: [
    ExtensiveImageComponent,
    TranslateModule,
    ViewDetailsTabComponent,
    SimpleLandingPageComponent,
    ChangePasswordComponent,
    ProfileImageComponent,
    EditLoggedInUserComponent,
    Popover,
    NgClass,
  ],
})
export class ProfileComponent extends LandingPageBaseDirective<User> {
  loggedInUser = computed(() => this.globalState.loggedInUser());
  documents = signal<MediaWidgetItem[]>([]);
  schedules = signal<ScheduleForViewing[]>([]);
  actionsOpened = signal(false);

  visibleEdit = signal(false);
  visibleChangePassword = signal(false);
  visibleChangeProfileImage = signal(false);

  private userService = inject(UserService);
  private tokenStorage = inject(TokenStorageService);

  async ngOnInit() {
    try {
      this.assignCultureSettings();
      this.pending.set(true);
      this.documents.set(
        await this.userService.getPdfs(this.loggedInUser().id),
      );
      if (
        this.globalState
          .loggedInUser()
          .canEdit(this.globalState.selectedOrganization().friendlyUrl)
      ) {
        this.schedules.set(
          await this.userService.getSchedules(this.loggedInUser().id),
        );
      }
      this.pending.set(false);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }

  private assignCultureSettings() {
    this.loggedInUser().selectableCountryCodes =
      this.appInitializer.cultureSettings.countryCodes;
    this.loggedInUser().selectableCountries =
      this.appInitializer.cultureSettings.countries;
    this.loggedInUser().selectableTimeZones =
      this.appInitializer.cultureSettings.timeZones;
    this.loggedInUser().selectableCultures =
      this.appInitializer.cultureSettings.cultures;
    this.loggedInUser().selectableRoles = this.appInitializer.roles;
  }

  handleCloseEdit() {
    this.visibleEdit.set(false);
  }

  handleCloseChangePassword() {
    this.visibleChangePassword.set(false);
  }

  handleCloseChangeProfileImage() {
    this.visibleChangeProfileImage.set(false);
  }

  handleRequestDoneEdit(data: User[]) {
    this.handleCloseEdit();
    Object.assign(this.globalState.loggedInUser(), data[0]);
  }

  handleRequestDoneChangePassword(data: User[]) {
    this.handleCloseChangePassword();
    // TODO vad göra efter att lösenord ändrats?
  }

  handleRequestDoneChangeProfileImage(data: User[]) {
    this.handleCloseChangeProfileImage();
    this.globalState.loggedInUser().profileImageUrl = data[0].profileImageUrl;
  }

  async getUserData() {
    this.pending.set(true);
    try {
      const data = await this.userService.getPersonalData();
      this.pending.set(false);
      // TODO: Vad göra med data?
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }

  signout() {
    this.tokenStorage.clearAccessData();
    window.location.reload();
  }

  //   leaveMembership(membership: OrganizationMembership) {
  //     if (membership.status !== StringUtils.PENDING) {
  //       Swal.fire(
  //         new SwalConfig(this.translationService).getDelete({
  //           cancelButtonText: this.translationService.instant("Cancel"),
  //           confirmButtonText: this.translationService.instant("LeaveMembership"),
  //         }),
  //       ).then(async (result) => {
  //         if (result.value) {
  //           try {
  //             const response = await this.userService.delete(
  //               this.globalState.loggedInUser().id,
  //               membership.organization.id,
  //             );
  //             //this.displaySuccessMessage(response.successMessage);

  //             // If was the selected org, navigate to user org if not super admin.
  //             if (
  //               !this.globalState.loggedInUser().isSuperAdmin() &&
  //               membership.organization.id ===
  //                 this.globalState.selectedOrganization().id
  //             ) {
  //               await this.router.navigate(
  //                 [
  //                   `/${this.globalState.loggedInUser().organization.friendlyUrl}/${
  //                     RoutesUtils.profile
  //                   }`,
  //                 ],
  //                 { replaceUrl: true },
  //               );
  //               this.refresh();
  //             } else {
  //               this.refresh();
  //             }
  //           } catch (errorResponse) {
  //             //this.handleErrorResponse(errorResponse);
  //           }
  //         }
  //       });
  //     }
  //   }
}
