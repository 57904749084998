<simple-crud-modal
  [visible]="visible()"
  [headerText]="'HandleGroupsAndAssignees' | translate"
  [closeText]="'Close' | translate"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs
      [value]="
        modifiedModel().assigneeGroups.length
          ? 0
          : modifiedModel().assignees.length
            ? 1
            : 0
      "
      scrollable
    >
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-people'"
            [title]="'Groups' | translate"
            [helpText]="'AssignmentHandleGroupsHelpText' | translate"
            [displayCount]="modifiedModel().assigneeGroups.length"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'bi bi-person'"
            [title]="'Assignees' | translate"
            [helpText]="'AssignmentHandleUsersHelpText' | translate"
            [displayCount]="modifiedModel().assignees.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <handle-assignee-groups-tab
            [items]="modifiedModel().assigneeGroups"
            [headerSubtitleText]="
              'AddedGroupsSubtitleAssignmentMultiple' | translate
            "
            [isMeasure]="modifiedModel().isMeasureAssignment()"
            (onItemsChange)="
              handleItemsChange(propertyStrings.assigneeGroups, $event)
            "
          >
          </handle-assignee-groups-tab>
        </p-tabpanel>
        <p-tabpanel [value]="1">
          <handle-assignees-tab
            [items]="modifiedModel().assignees"
            [headerSubtitleText]="
              'AddedUsersSubtitleAssignmentMultiple' | translate
            "
            [selectedAssigneeGroups]="modifiedModel().assigneeGroups"
            [isMeasure]="modifiedModel().isMeasureAssignment()"
            (onItemsChange)="
              handleItemsChange(propertyStrings.assignees, $event)
            "
          >
          </handle-assignees-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
