import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { ListAssignmentTemplateComponent } from "app/components/assignment-template/list-assignment-template/list-assignment-template.component";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  CategoryContent,
  SimpleTableCategoryColumn,
} from "app/components/simple-components/list/table/columns/simple-table-category-column";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-assignment-templates-tab",
  templateUrl: "./handle-assignment-templates-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListAssignmentTemplateComponent,
    TranslateModule,
  ],
})
export class HandleAssignmentTemplatesTabComponent extends HandleItemsBaseDirective<AssignmentTemplate> {
  visibleListAssignmentTemplate = signal<boolean>(false);

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "AddAssignmentTemplates",
        "AddAssignmentTemplates",
        StringUtils.icons.new,
        () => this.visibleListAssignmentTemplate.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTextColumn(
        ["TemplateType"],
        (row) => new TextContent(row.templateType.name),
      ),
      new SimpleTableCategoryColumn(
        ["Categories"],
        (row) => new CategoryContent(row.categories),
      ),
      new SimpleTableFacilityColumn(
        ["Facility"],
        (row) => new FacilityContent(row.facility),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.hasActiveTemplateObjects
              ? StringUtils.ACTIVE
              : StringUtils.INACTIVE,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState(
        "AssignmentTemplates",
        StringUtils.icons.assignmentTemplate,
      ),
    );
  }

  handleCloseListAssignmentTemplate() {
    this.visibleListAssignmentTemplate.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListAssignmentTemplate(items: AssignmentTemplate[]) {
    this.handleCloseListAssignmentTemplate();
    this.items.set(items.map((item) => new AssignmentTemplate(item)));
    this.onItemsChange.emit(this.items());
  }

  decreaseQuantity(assignmentTemplate: AssignmentTemplate) {
    if (assignmentTemplate.quantity > 1) {
      assignmentTemplate.quantity -= 1;
    }
  }

  increaseQuantity(assignmentTemplate: AssignmentTemplate) {
    if (assignmentTemplate.quantity < 10) {
      assignmentTemplate.quantity += 1;
    }
  }

  handleModelChange(assignmentTemplate: AssignmentTemplate, value: number) {
    assignmentTemplate.quantity =
      value && value > 0 && value < 11 ? value : null;
  }

  handleFocus(assignmentTemplate: AssignmentTemplate) {
    assignmentTemplate.quantityCached = assignmentTemplate.quantity;
    assignmentTemplate.quantity = null;
  }

  handleBlur(assignmentTemplate: AssignmentTemplate) {
    if (assignmentTemplate.quantity) {
      assignmentTemplate.quantityCached = assignmentTemplate.quantity;
    } else {
      assignmentTemplate.quantity = assignmentTemplate.quantityCached;
    }
  }

  handleWheel(assignmentTemplate: AssignmentTemplate, event: WheelEvent) {
    event.preventDefault();
    const scrollUp = event.deltaY < 0;
    if (scrollUp) {
      this.increaseQuantity(assignmentTemplate);
    } else {
      this.decreaseQuantity(assignmentTemplate);
    }
  }

  togglePublishDirectly(assignmentTemplate: AssignmentTemplate) {
    assignmentTemplate.publishDirectly = !assignmentTemplate.publishDirectly;
  }
}
