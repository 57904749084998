import { NgClass } from "@angular/common";
import { Component, input, output, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.less"],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class TimelineComponent {
  previousItemYear: string;

  items = input<DateItem[]>();
  previewCount = input<number>();
  useSmallStyles = input<boolean>();

  onClick = output();

  shouldDisplayYear(item: DateItem) {
    if (
      (this.items().length && this.items()[0] === item) ||
      item.yearString !== this.previousItemYear
    ) {
      this.previousItemYear = item.yearString;
      return true;
    }
    return false;
  }
}

export class DateItem {
  display = signal<boolean>(false);
  yearString: string;
  infoText: string;
  dayAndMonthString: string;
  dayNameString: string;

  constructor(dateItem: Partial<DateItem>) {
    Object.assign(this, dateItem);
  }
}
