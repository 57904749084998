import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { SimpleChangeTabComponent } from "app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { Category } from "app/components/simple-components/various/categories/simple-categories.component";
import { SimpleFormSelectComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";

@Component({
  selector: "change-categories-tab",
  templateUrl: "./change-categories-tab.component.html",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormSelectComponent,
    TranslateModule,
  ],
})
export class ChangeCategoriesTabComponent extends ChangeDetailsBaseDirective<AssignmentTemplate> {
  selectableCategoryItems = signal<FormSelectItem[]>([]);

  ngOnInit() {
    this.setSelectableCategoryItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.categoryIds]: [this.modifiedModel().categoryIds],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableCategoryItems() {
    this.selectableCategoryItems.set(
      this.modifiedModel().selectableCategories.map(
        (category) => new FormSelectItem(category.id, category.name),
      ),
    );
  }

  handleAddNewItemInSelect(item: FormSelectItem) {
    const category = new Category({
      id: item.id,
      name: item.name,
    });
    this.modifiedModel().selectableCategories.push(category);
  }
}
