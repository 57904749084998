import { Component, inject, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HandleEntitiesTabComponent } from "app/components/facility/create-edit-facility/tabs/handle-entities/handle-entities-tab.component";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { HandleTasksTabComponent } from "app/components/template-type/create-edit-template-type/tabs/handle-tasks/handle-tasks-tab.component";
import { ClientService } from "app/services/client.service";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { AssignmentTemplateService } from "../../../services/assignment-template.service";
import { AssignmentTemplate } from "../assignment-template";
import { ChangeDetailsTabComponent } from "./tabs/change-details/change-details-tab.component";
import { HandleFacilitiesTabComponent } from "./tabs/handle-facilities/handle-facilities-tab.component";

@Component({
  selector: "create-edit-assignment-template",
  templateUrl: "./create-edit-assignment-template.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ChangeDetailsTabComponent,
    HandleFacilitiesTabComponent,
    HandleTasksTabComponent,
    HandleEntitiesTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditAssignmentTemplateComponent extends CreateEditBaseDirective<AssignmentTemplate> {
  currentTemplateTypeId: string;

  facilityIds = signal<string[]>([]);
  facilitiesAreValid = signal<boolean>(false);
  facilitiesHasErrors = signal<boolean>(false);
  facilityTasksAreValid = signal<boolean>(false);
  facilityTasksHasErrors = signal<boolean>(false);
  entitiesAreValid = signal<boolean>(false);
  entitiesHasErrors = signal<boolean>(false);
  entityTasksAreValid = signal<boolean>(false);
  entityTasksHasErrors = signal<boolean>(false);

  private changeDetailsTabComponent = viewChild(ChangeDetailsTabComponent);

  private assignmentTemplateService = inject(AssignmentTemplateService);
  private clientService = inject(ClientService);

  async initiate() {
    this.pending.set(true);
    try {
      if (this.isEdit) {
        const [clients, categories] = await Promise.all([
          this.clientService.getAll(),
          this.assignmentTemplateService.getCategories(
            this.globalState.selectedOrganization().id,
          ),
        ]);
        const data = await this.assignmentTemplateService.get(
          this.itemIds()[0],
        );
        this.initialModel.set(data);
        this.pending.set(false);

        this.initialModel().selectableTemplateTypes.push(
          this.initialModel().templateType,
        );
        this.initialModel().selectableClients = clients;
        this.initialModel().selectableCategories = categories;

        this.initialModel().facilities.push(this.initialModel().facility);
        this.facilityIds.set([this.initialModel().facility.id]);

        this.modifiedModel.set(
          new AssignmentTemplate(Utils.getUniqueVariant(this.initialModel())),
        );
      } else {
        // Abort if not superadmin.
        if (!this.globalState.loggedInUser().isSuperAdmin()) {
          this.toastrService.error(
            `${this.translationService.instant(StringUtils.NO_PERMISSION)}!`,
          );
          this.handleClose();
          return;
        }

        let templateTypes =
          await this.assignmentTemplateService.getTemplateTypes(
            this.globalState.selectedOrganization().id,
          );
        templateTypes = templateTypes.filter(
          (templateType) => !templateType.isManualMeasure,
        );
        const facilities = await this.assignmentTemplateService.getFacilities();

        // Abort if no template types or facilities.
        if (!templateTypes.length || !facilities.length) {
          this.toastrService.error(
            `${this.translationService.instant(
              StringUtils.NO_TEMPLATE_TYPE_ERROR,
            )}!`,
          );
          this.handleClose();
          return;
        }

        const [clients, categories] = await Promise.all([
          this.clientService.getAll(),
          this.assignmentTemplateService.getCategories(
            this.globalState.selectedOrganization().id,
          ),
        ]);
        this.pending.set(false);

        this.initialModel.set(
          new AssignmentTemplate({
            templateTypeId: templateTypes[0].id,
            selectableTemplateTypes: templateTypes,
            selectableClients: clients,
            selectableCategories: categories,
            name: templateTypes[0].name,
          }),
        );

        this.modifiedModel.set(
          new AssignmentTemplate(Utils.getUniqueVariant(this.initialModel())),
        );

        this.currentTemplateTypeId = this.modifiedModel().templateTypeId;
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.assignmentTemplateService.update(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          )
        : await this.assignmentTemplateService.create(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          );
      this.toastrService.success(
        this.translationService.instant("TheAssignmentTemplateWasUpdated"),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeDetailsTabComponent().setServerErrors(error.errors);
    }
  }

  handleDetailsChangeExtra() {
    if (this.changeDetailsTabComponent()) {
      const templateTypeIdCtrl = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.templateTypeId);
      const geoControlledCtrl = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.geoControlled);
      if (
        !this.isEdit &&
        templateTypeIdCtrl.value !== this.currentTemplateTypeId
      ) {
        this.modifiedModel().facilityTasks = [];
        this.modifiedModel().entityTasks = [];
        this.currentTemplateTypeId = templateTypeIdCtrl.value;
        this.modifiedModel().name =
          this.modifiedModel().selectableTemplateTypes.find(
            (templateType) => templateType.id === templateTypeIdCtrl.value,
          ).name;
      }
      if (!this.isEdit && geoControlledCtrl.value) {
        this.modifiedModel().facilities =
          this.modifiedModel().facilities.filter(
            (facility) => facility.hasPosition,
          );
        this.handleItemsChangeExtra();
      }
    }
  }

  handleItemsChangeExtra() {
    this.facilityIds.set(
      this.modifiedModel().facilities.map((facility) => facility.id),
    );
    this.modifiedModel().entities = this.modifiedModel().entities.filter(
      (entity) => this.facilityIds().includes(entity.facility.id),
    );

    this.facilitiesAreValid.set(!!this.modifiedModel().facilities.length);

    this.facilitiesHasErrors.set(
      (!this.modifiedModel().facilities.length &&
        !!this.modifiedModel().facilityTasks.length) ||
        (!this.modifiedModel().facilities.length &&
          !this.modifiedModel().entities.length &&
          !!this.modifiedModel().entityTasks.length),
    );

    this.facilityTasksAreValid.set(
      !!this.modifiedModel().facilityTasks.length ||
        (!this.modifiedModel().facilityTasks.length &&
          (!!this.modifiedModel().entities.length ||
            !!this.modifiedModel().entityTasks.length)),
    );

    this.facilityTasksHasErrors.set(
      (!this.modifiedModel().facilityTasks.length &&
        !!this.modifiedModel().entities.length) ||
        (!this.modifiedModel().facilityTasks.length &&
          !this.modifiedModel().entities.length &&
          !!this.modifiedModel().entityTasks.length),
    );

    this.entitiesAreValid.set(
      !this.modifiedModel().entities.length &&
        !this.modifiedModel().entityTasks.length &&
        !!this.modifiedModel().facilities.length &&
        !!this.modifiedModel().facilityTasks.length,
    );

    this.entitiesHasErrors.set(
      !this.modifiedModel().entities.length &&
        !!this.modifiedModel().entityTasks.length,
    );

    this.entityTasksAreValid.set(
      !!this.modifiedModel().entityTasks.length ||
        (!this.modifiedModel().entityTasks.length &&
          !this.modifiedModel().entities.length &&
          !!this.modifiedModel().facilities.length &&
          !!this.modifiedModel().facilityTasks.length),
    );

    this.entityTasksHasErrors.set(
      (!!this.modifiedModel().entities.length &&
        !this.modifiedModel().entityTasks.length) ||
        !!this.modifiedModel().entityTasks.length,
    );
  }
}
