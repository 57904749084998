<div class="notification-item-wrapper apx-scrollbar scroll-shadow">
  <div class="icons-wrapper" #iconWrapperRef>
    <div
      class="notification-item-icon
			{{ notificationMessage().icon }}
			{{ notificationMessage().type }}"
    ></div>
    @if (isReleaseType()) {
      <div class="passing-stuff">
        <i class="secondary-icon first-batch first"></i>
        <i class="secondary-icon first-batch second"></i>
        <i class="secondary-icon first-batch third"></i>
        <i class="secondary-icon first-batch fourth"></i>

        <i class="secondary-icon second-batch first"></i>
        <i class="secondary-icon second-batch second"></i>
        <i class="secondary-icon second-batch third"></i>
        <i class="secondary-icon second-batch fourth"></i>
      </div>
    }
  </div>
  <div class="text-wrapper">
    <div class="notification-item-title">
      {{ notificationMessage().title }}
    </div>
    <div class="date">
      {{ notificationMessage().startDate }}
    </div>
    <div
      class="notification-item-summary"
      [innerHTML]="notificationMessage().summary | sanitizeHtml"
    ></div>
    @if (notificationMessage().linkable) {
      <div class="notification-item-link">
        <button
          class="btn-transparent"
          (click)="navigateToPath(notificationMessage().path)"
        >
          {{ "GoToArticle" | translate }}
          <i class="bi bi-box-arrow-up-right"></i>
        </button>
      </div>
    }
  </div>
</div>
