<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.fullName"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="isEdit ? 0 : 1" scrollable>
      <p-tablist>
        @if (isEdit) {
          <p-tab [value]="0">
            <simple-tab-heading
              [icon]="'bi-search'"
              [title]="'Overview' | translate"
              [helpText]="'UserViewDetailsHelpText' | translate"
            >
            </simple-tab-heading>
          </p-tab>
        }
        @if (
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl) &&
          (canChangeDetails() || canChangeAuthorization())
        ) {
          <p-tab [value]="1">
            <simple-tab-heading
              [icon]="'bi-card-text'"
              [title]="'Details' | translate"
              [helpText]="
                isEdit
                  ? ('UserChangeDetailsEditHelpText' | translate)
                  : isInvite
                    ? ('UserChangeDetailsHelpText' | translate)
                    : ('CreateNewUserHelpText' | translate)
              "
            >
            </simple-tab-heading>
          </p-tab>
        }
      </p-tablist>

      <p-tabpanels>
        @if (isEdit) {
          <p-tabpanel [value]="0">
            <view-details-tab
              [model]="modifiedModel()"
              [documents]="currentDocuments()"
              [schedules]="schedules()"
            >
            </view-details-tab>
          </p-tabpanel>
        }
        @if (
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl) &&
          (canChangeDetails() || canChangeAuthorization())
        ) {
          <p-tabpanel [value]="1">
            <change-details-tab
              [modifiedModel]="modifiedModel()"
              [canChangeDetails]="canChangeDetails()"
              [canChangeAuthorization]="canChangeAuthorization()"
              [documents]="currentDocuments()"
              [isInvite]="isInvite()"
              (onDetailsChange)="
                handleDetailsChange($event); handleDetailsChangeExtra()
              "
            >
            </change-details-tab>
          </p-tabpanel>
        }
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
