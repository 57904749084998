<handle-header
  [headerTitleText]="'AddedTasks' | translate"
  [headerSubtitleText]="headerSubtitleText()"
  [listActions]="listActions()"
></handle-header>

<simple-table
  [columns]="columns()"
  [headerActions]="headerActions()"
  [rowActions]="rowActions()"
  [emptyState]="emptyState()"
  [rows]="items()"
  [selectedIds]="selectedIds()"
  [addedIds]="addedIds()"
  [modifiedIds]="modifiedIds()"
  [unselectableIds]="unselectableIds()"
  [inWizard]="true"
  [totalCount]="totalCount"
  [selectedCount]="selectedCount"
  [hasSelectableData]="hasSelectableData"
  [someAreSelected]="someAreSelected"
  [allAreSelected]="allAreSelected"
  (onCheckboxClick)="handleCheckboxClick($event)"
  (onRowSingleClick)="handleRowSingleClick($event)"
  (onRowDoubleClick)="handleRowDoubleClick($event)"
  (onRowCheckboxClick)="handleRowCheckboxClick($event)"
>
</simple-table>

<list-task
  [visible]="visibleListTask()"
  [isInModal]="true"
  [dataToSetAsSelected]="itemAsIds()"
  [includeChoices]="includeChoicesInList()"
  [templateTypeId]="templateTypeIdForList()"
  (onClose)="handleCloseListTask()"
  (onConfirm)="handleConfirmListTask($event)"
></list-task>

<!-- <ng-container slot="headerActions"> TODO
    @if (!readonlyChoices) {
    <choices
      [items]="getChoicesGlobal()"
      [actionTitle]="'AddChoicesToSelected' | translate"
      [disableAction]="!someAreSelected"
      [hideChoices]="true"
      [useButtonAction]="true"
      (onModelsChange)="handleChoicesChangeGlobal($event)"
      (onUpdated)="handleUpdatedChoices($event)"
      (onDeleted)="handleDeletedChoices($event)"
    >
    </choices>
    }
  </ng-container> -->

<!-- <ng-container slot="content">
          <div class="details">
            <div class="name" title="{{ item.text }}" *ngIf="item.text">
              {{ item.text }}
            </div>
            <div class="description" title="{{ item.code }}" *ngIf="item.code">
              {{ item.code }}
            </div>
          </div>
          <div class="rules">
            <simple-rules [rules]="item.rulesReadonly"> </simple-rules>
          </div>
          <div class="choices">
            <choices
              #component
              [items]="item.choices"
              [actionTitle]="'AddChoices' | translate"
              [readonlyItems]="readonlyChoices()"
              (onModelsChange)="handleChoicesChange(item, $event)"
              (onUpdated)="handleUpdatedChoices($event)"
              (onDeleted)="handleDeletedChoices($event)"
            >
            </choices>
          </div>
        </ng-container> -->
