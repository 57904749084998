import { signal } from "@angular/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { RuleObject } from "app/components/simple-components/various/rules/simple-rules.component";
import { UserGroup } from "app/components/user-group/user-group";
import { Subscriber, User } from "app/components/user/user";
import { RoutesUtils } from "app/tools/routes-utils";
import { DateTime } from "luxon";
import { DateItem } from "./timeline/timeline.component";

export class Schedule extends CrudItem<Schedule> {
  name: string = "";
  assignmentTemplates: AssignmentTemplate[] = [];
  scheduleGroups: ScheduleGroup[] = [];
  scheduleUsers: ScheduleUser[] = [];
  subscribers: Subscriber[] = [];
  startDate: string = DateTime.now().toSQLDate();
  endDate: string = "";
  daysDelay: number = 5;
  weeklyInterval: number = 0;
  monthlyInterval: number = 0;
  annualInterval: number = 0;
  weekdays: number[] = [];
  publishingTime: string = DateTime.now()
    .plus({ minutes: 5 })
    .toLocaleString(DateTime.TIME_24_SIMPLE);
  active: boolean = true;
  nextPublishDate: string = "";

  publishingType: PublishingType = PublishingType.Once;
  interval: number = 0;
  weekdaysInternal: number[] = [];
  publicationDates = signal<DateItem[]>([]);
  isSingleOccurrence: boolean = false;

  previewCount: number = 20;

  get displayName() {
    return this.name;
  }

  constructor(schedule?: Partial<Schedule>) {
    super();
    Object.assign(this, schedule);
    this.mapData();
    this.setUrl(RoutesUtils.schedules);
  }

  private mapData() {
    this.mapAssignmentTemplates();
    this.mapScheduleGroups();
    this.mapScheduleUsers();
    this.mapSubscribers();
    this.mapVariables();
  }

  private mapAssignmentTemplates() {
    this.assignmentTemplates = this.assignmentTemplates.map(
      (assignmentTemplate) => new AssignmentTemplate(assignmentTemplate),
    );
    this.assignmentTemplates.sortByProperty(`${this.propertyStrings.name}`);
  }

  private mapScheduleGroups() {
    this.scheduleGroups = this.scheduleGroups.map(
      (scheduleGroup) => new ScheduleGroup(scheduleGroup),
    );
    this.scheduleGroups.sortByProperty(this.propertyStrings.name);
  }

  private mapScheduleUsers() {
    this.scheduleUsers = this.scheduleUsers.map(
      (scheduleUser) => new ScheduleUser(scheduleUser),
    );
    this.scheduleUsers.sortByProperty("user fullName");
  }

  private mapSubscribers() {
    this.subscribers = this.subscribers.map(
      (subscriber) => new Subscriber(subscriber),
    );
    this.subscribers.sortByProperty(new User({}).propertyStrings.email);
  }

  private mapVariables() {
    if (this.annualInterval > 0) {
      this.publishingType = PublishingType.Annually;
      this.interval = this.annualInterval - 1;
    } else if (this.monthlyInterval > 0) {
      this.publishingType = PublishingType.Monthly;
      this.interval = this.monthlyInterval - 1;
    } else if (this.weeklyInterval > 0) {
      this.publishingType = PublishingType.Weekly;
      this.interval = this.weeklyInterval - 1;
      this.weekdaysInternal = this.weekdays
        .map((day) => (day === 0 ? 6 : day - 1))
        .sort();
    }
  }

  toPayloadObject(orgId: number) {
    const weekdays = this.weekdaysInternal.map((day) =>
      day === 6 ? 0 : day + 1,
    );
    return new SchedulePayload({
      id: this.id ? this.id : null,
      name: this.name,
      isSingleOccurrence: this.publishingType === PublishingType.Once,
      scheduleTemplates: this.getScheduleTemplates(),
      active: this.active,
      startDate: DateTime.fromSQL(this.startDate).toISODate(),
      endDate:
        this.publishingType !== PublishingType.Once && this.endDate
          ? DateTime.fromSQL(this.endDate).toISODate()
          : null,
      scheduleUsers: this.scheduleUsers,
      scheduleGroups: this.scheduleGroups,
      publishingTime: this.publishingTime,
      daysDelay: this.daysDelay,
      organizationId: orgId,
      subscribers: this.subscribers,
      annualInterval:
        this.publishingType === PublishingType.Annually ? this.interval + 1 : 0,
      monthlyInterval:
        this.publishingType === PublishingType.Monthly ? this.interval + 1 : 0,
      weeklyInterval:
        this.publishingType === PublishingType.Weekly ? this.interval + 1 : 0,
      weekdays: this.publishingType === PublishingType.Weekly ? weekdays : [],
    });
  }

  private getScheduleTemplates() {
    return this.assignmentTemplates.map((assignmentTemplate) => {
      return new ScheduleTemplate({
        assignmentTemplateId: assignmentTemplate.id,
        quantity: assignmentTemplate.quantity,
        publishNow: assignmentTemplate.publishDirectly,
      });
    });
  }
}

export class SchedulePayload extends Schedule {
  organizationId: number;
  scheduleTemplates: ScheduleTemplate[] = [];
  isSingleOccurrence: boolean = false;

  constructor(schedulePayload: Partial<SchedulePayload>) {
    super(schedulePayload);
    Object.assign(this, schedulePayload);
  }
}

export class ScheduleForViewing extends Schedule {
  canPerformScheduled: boolean = false;
  canPerformMeasure: boolean = false;
  canAssign: boolean = false;

  constructor(scheduleForViewing: Partial<ScheduleForViewing>) {
    super(scheduleForViewing);
    Object.assign(this, scheduleForViewing);
  }
}

export class ScheduleGroup extends UserGroup {
  canPerformScheduled: boolean = true;
  canPerformMeasure: boolean = true;
  canAssign: boolean = false;
  canBeAssigned: boolean = false;
  userGroup: UserGroup = null;
  userGroupId: string = "";
  scheduleId: string = "";

  get rulesReadonlyInSchedule() {
    const rules: RuleObject[] = [];
    if (this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment green",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill blue",
          title: "CanAssign",
        }),
      );
    }
    return rules;
  }

  get rulesEditableInSchedule() {
    const rules: RuleObject[] = [];
    if (this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment green",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (!this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (!this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill blue",
          title: "CanAssign",
        }),
      );
    }
    if (!this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill",
          title: "CanAssign",
        }),
      );
    }
    return rules;
  }

  constructor(scheduleGroup: Partial<ScheduleGroup>) {
    super(scheduleGroup);
    Object.assign(this, scheduleGroup);
    this.mapUserGroup();
  }

  private mapUserGroup() {
    if (this.userGroup) {
      this.userGroup = new UserGroup(this.userGroup);
      this.userGroupId = this.userGroup.id;
      this.name = this.userGroup.name;
    }
  }
}

export class ScheduleUser extends User {
  canPerformScheduled: boolean = true;
  canPerformMeasure: boolean = true;
  canAssign: boolean = false;
  user: User = null;
  userId: string = "";
  scheduleId: string = "";

  get rulesReadonlyInSchedule() {
    const rules: RuleObject[] = [];
    if (this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment green",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill blue",
          title: "CanAssign",
        }),
      );
    }
    return rules;
  }

  get rulesEditableInSchedule() {
    const rules: RuleObject[] = [];
    if (this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment green",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (!this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (!this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill blue",
          title: "CanAssign",
        }),
      );
    }
    if (!this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill",
          title: "CanAssign",
        }),
      );
    }
    return rules;
  }

  constructor(scheduleUser: Partial<ScheduleUser>) {
    super(scheduleUser);
    Object.assign(this, scheduleUser);
    this.mapUser();
  }

  private mapUser() {
    if (this.user) {
      this.user = new User(this.user);
      this.userId = this.user.id;
      this.email = this.user.email;
    }
  }
}

export class ScheduleTemplate {
  assignmentTemplateId: string = "";
  quantity: number = 1;
  publishNow: boolean = false;

  constructor(scheduleTemplate: Partial<ScheduleTemplate>) {
    Object.assign(this, scheduleTemplate);
  }
}

export enum PublishingType {
  Once = "Once",
  Annually = "Annually",
  Monthly = "Monthly",
  Weekly = "Weekly",
}
