import { Component, input, model } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Drawer } from "primeng/drawer";
import { GoogleMapsComponent } from "../../../google-maps/google-maps.component";

@Component({
  selector: "simple-view-location-data",
  templateUrl: "./simple-view-location-data.component.html",
  styleUrls: ["./simple-view-location-data.component.less"],
  standalone: true,
  imports: [TranslateModule, Drawer, GoogleMapsComponent],
})
export class SimpleViewLocationDataComponent {
  visible = model<boolean>(false);

  headerText = input<string>();
  locationData = input<string[]>();
}
