import { Injectable } from "@angular/core";
import { Schedule, ScheduleForViewing } from "app/components/schedule/schedule";
import { Filter } from "app/filter";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { RoutesUtils } from "app/tools/routes-utils";
import {
  InvitedUser,
  LoggedInUser,
  User,
  UserPayload,
} from "../components/user/user";
import { PostImage } from "../post-image";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseService<User> {
  prefix = RoutesUtils.user;
  useCoreApi = true;

  constructor() {
    super(User);
  }

  async get(id: string): Promise<User> {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/${id}`,
        true,
      );
      return new User(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: UserPayload): Promise<User> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}`,
        item,
        true,
      );
      return new User(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: UserPayload): Promise<User> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
        true,
      );
      return new User(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string, organizationId: number): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.delete(
        `organization/${organizationId}/${this.prefix}/${id}`,
        true,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(
    itemIds: string[],
    organizationId: number,
  ): Promise<User[]> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `organization/${organizationId}/${this.prefix}`,
        { itemIds: itemIds },
        true,
      );
      return this.extractData(response).map((item: User) => new User(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async getDeleteData(itemIds: string[], orgId: number) {
    try {
      const model = {
        itemIds: itemIds,
      };
      const response = await this.hostedHttpClient.post(
        `organization/${orgId}/${this.prefix}/deleteCount`,
        model,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getStatusData(
    itemIds: string[],
    status: boolean,
    orgId: number,
  ): Promise<string[]> {
    try {
      const model = {
        itemIds: itemIds,
        active: status,
        organizationId: orgId,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/updatestatusdata`,
        model,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updateStatusWithOrgId(
    itemIds: string[],
    status: boolean,
    orgId: number,
  ): Promise<User[]> {
    try {
      const model = {
        itemIds: itemIds,
        active: status,
        organizationId: orgId,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/statuses`,
        model,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateProfileImage(id: string, img: PostImage): Promise<string> {
    try {
      const formData = new FormData();
      formData.append("file", img.blob, img.name);
      const response = await this.hostedHttpClient.putWithoutContentType(
        `${this.prefix}/${id}/profilepicture`,
        formData,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async invite(item: User): Promise<User> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/invite`,
        item,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async register(userId: string, userCulture: string, user: UserPayload) {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${userId}/activate?culture=${userCulture}`,
        user,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async handleInvitation(
    email: string,
    code: string,
    culture: string,
    acceptInvitation: boolean,
  ) {
    try {
      const response = await this.hostedHttpClient.put(
        `organizationMember/invitation`,
        {
          email: email,
          code: code,
          culture: culture,
          acceptInvitation: acceptInvitation,
        },
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async resendInvite(user: UserPayload, orgId: number): Promise<User> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/${user.id}/resendinvite/${orgId}`,
        { email: user.email },
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updatePassword(
    oldP: string,
    newP: string,
    confirmP: string,
  ): Promise<InvitedUser> {
    try {
      const model = {
        oldPassword: oldP,
        newPassword: newP,
        confirmPassword: confirmP,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/password`,
        model,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updatePasswordWithOtherUserId(
    newP: string,
    confirmP: string,
    otherUserId: string,
  ): Promise<InvitedUser> {
    try {
      const model = {
        newPassword: newP,
        confirmPassword: confirmP,
        id: otherUserId,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/setpassword`,
        model,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async getPersonalData() {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/personal-info`,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getPdfs(id: string): Promise<MediaWidgetItem[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/${id}/media`,
      );
      return this.extractData(response).map(
        (item: MediaWidgetItem) => new MediaWidgetItem(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async uploadPdfs(id: string, items: MediaWidgetItem[]) {
    try {
      const formData = new FormData();
      items.forEach((item) => formData.append("file", item.blob, item.title));
      const response = await this.hostedHttpClient.postWithHeaders(
        `${this.prefix}/${id}/media/upload`,
        formData,
        { "Content-Type": "upload-file" },
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async deletePdfs(id: string, orgId: number, itemIds: string[]) {
    try {
      const model = {
        userId: id,
        organizationId: orgId,
        itemIds: itemIds,
      };
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}/media`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getSchedules(id: string): Promise<ScheduleForViewing[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/${id}/schedule`,
      );
      return this.extractData(response).map(
        (item: Schedule) => new Schedule(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getLoggedInUser() {
    try {
      const response = await this.hostedHttpClient.get(
        `${RoutesUtils.user}/${RoutesUtils.current}`,
        true,
      );
      return new LoggedInUser(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class UserFilter extends Filter {
  toPayloadObject() {
    return {
      filter: JSON.stringify(this),
    };
  }
}
