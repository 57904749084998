import { Component, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Assignment } from "app/components/assignment/assignment";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { SimpleCrudModalComponent } from "../../../components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleLinkButtonComponent } from "../../../components/simple-components/various/link-button/simple-link-button.component";
import { DatexPipe } from "../../../pipes/datex.pipe";

@Component({
  selector: "view-assignments",
  templateUrl: "./view-assignments.component.html",
  styleUrls: [".//view-assignments.component.less"],
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleLinkButtonComponent,
    TranslateModule,
    DatexPipe,
  ],
})
export class ViewAssignmentsComponent extends CreateEditBaseDirective<Assignment> {
  titleText = signal<string>("");

  assignments = input<Assignment[]>();
  property = input<keyof Assignment>(null);
  firstLabel = input<string>();
  secondLabel = input<string>();
  thirdLabel = input<string>();

  protected initiate() {
    this.titleText.set(
      this.assignments()[0].isMeasureAssignment()
        ? "MeasureAssignments"
        : "ScheduledAssignments",
    );
  }
}
