<div class="user">
  <simple-image
    [url]="user().profileImageUrl"
    [rounded]="true"
    [placeholderImageType]="'user'"
  ></simple-image>
  <div class="text">
    <div class="first">
      @if (user().fullName) {
        <span [title]="user().fullName">{{ user().fullName }}</span>
      } @else {
        <span [title]="user().email">{{ user().email }}</span>
      }
    </div>
    <div class="secondary">
      @if (user().fullName) {
        <span [title]="user().email">{{ user().email }}</span>
      } @else {
        @if (
          !!user().getCurrentDeleted(globalState.selectedOrganization().id)
        ) {
          <span
            title="{{ 'UserDeleted' | translate }} {{
              user().getCurrentDeleted(globalState.selectedOrganization().id)
                | datex
            }}"
            >{{ "UserDeleted" | translate }}
            {{
              user().getCurrentDeleted(globalState.selectedOrganization().id)
                | datex
            }}</span
          >
        } @else if (
          user().getCurrentStatus(globalState.selectedOrganization().id) ===
          "pending"
        ) {
          <span
            title="{{ 'UserInvited' | translate }} {{
              user().getCurrentInviteDate(globalState.selectedOrganization().id)
                | datex
            }}"
            >{{ "UserInvited" | translate }}
            {{
              user().getCurrentInviteDate(globalState.selectedOrganization().id)
                | datex
            }}</span
          >
        }
      }
    </div>
  </div>
</div>
