import { Component, computed, Signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TranslateCommaSeparatedPipe } from "../../../../../../../pipes/translate-comma-separated-string.pipe";
import { TranslatedTextContent } from "../../../columns/simple-table-translated-text-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-translated-text",
  templateUrl: "./column-type-translated-text.component.html",
  styleUrl: "./column-type-translated-text.component.less",
  standalone: true,
  imports: [TranslateModule, TranslateCommaSeparatedPipe],
})
export class ColumnTypeTranslatedTextComponent<
  T,
> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<TranslatedTextContent>;
}
