import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableDateColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => DateContent;

  constructor(
    labels: string[],
    getValue: (row: T) => DateContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.DATE;
    this.maxwidth = 100;
  }
}

export class DateContent {
  firstDate: string;
  secondDate: string;

  constructor(firstDate: string, secondDate?: string) {
    this.firstDate = firstDate;
    this.secondDate = secondDate;
  }
}
