import {
  Directive,
  effect,
  inject,
  input,
  output,
  signal,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalStateService } from "app/global-state/global-state.service";
import { TranslationService } from "app/services/translation.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { PropertyNameGetter } from "app/tools/property-name-getter";
import { Utils } from "app/tools/utils";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Directive()
export abstract class CreateEditBaseDirective<T> {
  initialModel = signal<T | null>(null);
  modifiedModel = signal<T | null>(null);
  pending = signal<boolean>(false);
  changeDetailsIsValid = signal<boolean>(false);
  changeDetailsHasErrors = signal<boolean>(false);

  visible = input<boolean>();
  itemIds = input<string[]>([]);
  hideCloseButton = input<boolean>();
  hideSubmitButton = input<boolean>();
  useUnsavedChangesLogic = input<boolean>();
  displayDeleteButton = input<boolean>();

  onClose = output();
  onDelete = output();
  onRequestDone = output<T[]>();

  get isEdit() {
    return !!this.itemIds().length;
  }

  protected globalState = inject(GlobalStateService);
  protected translationService = inject(TranslationService);
  protected router = inject(Router);
  protected toastrService = inject(ToastrService);

  constructor() {
    effect(() => {
      if (this.visible()) {
        this.initiate();
      } else {
        this.initialModel.set(null);
        this.modifiedModel.set(null);
      }
    });
  }

  handleClose() {
    if (this.useUnsavedChangesLogic()) {
      this.closeWithUnsavedChangesLogic();
    } else {
      this.onClose.emit();
    }
  }

  handleDelete() {
    this.onDelete.emit();
  }

  handleDetailsChange(form: FormGroup) {
    Object.entries(form.controls).forEach(([name, control]) => {
      if (!control.disabled) {
        this.modifiedModel()[name] = control.value;
        this.refreshModel();
      }
    });
    this.changeDetailsIsValid.set(form.valid);
    const hasErrors = Object.keys(form.controls).some((key) => {
      const formControl = form.get(key);
      return formControl.invalid && (formControl.dirty || formControl.touched);
    });
    this.changeDetailsHasErrors.set(hasErrors);
  }

  handleItemsChange(property: string, items: unknown[]) {
    this.modifiedModel()[property] = items;
    this.refreshModel();
  }

  private refreshModel() {
    this.modifiedModel.set(this.modifiedModel());
  }

  private closeWithUnsavedChangesLogic() {
    const unsavedChanges = !Utils.isEqual(
      this.modifiedModel(),
      this.initialModel(),
    );
    if (unsavedChanges) {
      Swal.fire(
        new SwalConfig(this.translationService).getUnsavedChanges({}),
      ).then(async (result) => {
        if (result.value) {
          this.onClose.emit();
        }
      });
    } else {
      this.onClose.emit();
    }
  }

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf({} as new (args?: unknown) => T);
  }

  protected abstract initiate(): void;
}
