import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { InspireTreeComponent } from "../../../components/inspire-tree/inspire-tree.component";
import { SimpleCrudModalComponent } from "../../../components/simple-components/crud/modal/simple-crud-modal.component";
import { GlobalOrganizationService } from "../../../services/global-organization.service";
import { Organization, OrganizationTreeItem } from "../organization";

@Component({
  selector: "global-organization-modal",
  templateUrl: "./global-organization-modal.component.html",
  styleUrls: ["./global-organization-modal.component.less"],
  standalone: true,
  imports: [SimpleCrudModalComponent, InspireTreeComponent, TranslateModule],
})
export class GlobalOrganizationModalComponent extends CreateEditBaseDirective<Organization> {
  inspireTreeComponent = viewChild<InspireTreeComponent>(InspireTreeComponent);

  private globalOrganizationService = inject(GlobalOrganizationService);

  initiate() {
    this.pending.set(true);
    this.inspireTreeComponent().componentReady();
  }

  public onGetTreeData($event: any) {
    const [node, resolve, _] = $event;
    if (!node) {
      // A falsey node indicates this is an initial data request:
      // fetch the entire tree.
      this.globalOrganizationService
        .getTreeData()
        .then((organizations: OrganizationTreeItem[]) => {
          this.pending.set(false);
          return resolve(organizations);
        })
        .catch((__) => {
          this.pending.set(false);
          return resolve(null);
        });
    } else {
      // An actual node is passed: fetch a tree branch.
      this.globalOrganizationService
        .getTreeBranch(node.realId)
        .then((organizations: OrganizationTreeItem[]) => {
          this.addTreeBranch(node.realId, organizations);
          return resolve(organizations);
        })
        .catch((__) => {
          return resolve(null);
        });
    }
  }

  private addTreeBranch(
    nodeRealId: number,
    branchChildren: OrganizationTreeItem[],
  ) {
    this.traverseNodes((node: OrganizationTreeItem) => {
      if (node.realId === nodeRealId) {
        node.children = branchChildren;
        return true;
      }
    });
  }

  private traverseNodes(predicate: Function) {
    let stopTraversal = false;

    const traverseNodes = (nodes: OrganizationTreeItem[]) => {
      if (stopTraversal) {
        return;
      }

      nodes.forEach((node) => {
        if (predicate.call(null, node) === true) {
          stopTraversal = true;
          return;
        }
        if (node.children && typeof node.children === "object") {
          traverseNodes(node.children);
        }
      });
    };

    this.globalOrganizationService.getTreeData().then((nodes) => {
      traverseNodes(nodes);
    });
  }

  public onNodeSelected($event: any) {
    const [node] = $event;
    if (node.id !== this.globalState.selectedOrganization().id) {
      this.handleClose();
      const [_, __, path, secondPath] = this.router.url.split("/");
      const context = secondPath ? `${path}/${secondPath}` : path;
      if (context) {
        this.router.navigate([`/${node.url}/${context}`]);
      } else {
        this.router.navigate(["/", node.url]);
      }
    }
  }
}
