import { Component } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { SimpleFormCheckboxComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-checkbox/simple-form-checkbox.component";
import { SimpleFormInputComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";
import { SimpleChangeTabComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { Task } from "../../task";

@Component({
  selector: "change-details-tab",
  templateUrl: "./change-details-tab.component.html",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormInputComponent,
    SimpleFormCheckboxComponent,
    TranslateModule,
  ],
})
export class ChangeDetailsTabComponent extends ChangeDetailsBaseDirective<Task> {
  ngOnInit() {
    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.text]: [
          this.modifiedModel().text,
          [Validators.required, Validators.maxLength(150)],
        ],
        [this.propertyStrings.code]: [
          this.modifiedModel().code,
          Validators.maxLength(150),
        ],
        [this.propertyStrings.description]: [
          this.modifiedModel().description,
          Validators.maxLength(2000),
        ],
        [this.propertyStrings.isRequired]: [
          this.modifiedModel().isRequired,
          this.rulesHelper,
        ],
        [this.propertyStrings.isDefault]: [
          this.modifiedModel().isDefault,
          this.rulesHelper,
        ],
        [this.propertyStrings.allowComment]: [
          this.modifiedModel().allowComment,
        ],
        [this.propertyStrings.allowMedia]: [this.modifiedModel().allowMedia],
      }),
    );
    super.ngOnInit();
  }

  private rulesHelper(control: AbstractControl) {
    if (control.parent) {
      const form = control.parent;
      const controlName = Object.keys(form.controls).find(
        (key) => form.controls[key] === control,
      );
      const isRequired = form.get("isRequired");
      const isDefault = form.get("isDefault");

      if (controlName === "isRequired" && control.value) {
        isDefault.setValue(!control.value);
      } else if (controlName === "isDefault" && control.value) {
        isRequired.setValue(!control.value);
      }
    }
    return null;
  }
}
