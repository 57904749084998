<simple-change-tab [form]="form()">
  <div class="change-section-layout-wrapper">
    <div class="change-section-layout">
      <div class="change-section">
        <div class="change-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-select-button
          [label]="'Prioritized' | translate"
          [name]="propertyStrings.priority"
          [form]="form()"
          [items]="selectablePriorityItems()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select-button>
        <simple-form-info [firstText]="affectableIds().length.toString()">
        </simple-form-info>
      </div>
    </div>
  </div>
</simple-change-tab>
