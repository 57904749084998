<div
  class="mw-item"
  [title]="'Open' | translate"
  [style.width]="itemSize() + 'px'"
  [style.height]="itemSize() + 'px'"
  (click)="open()"
>
  @if (item().isImage && item().default) {
    <div class="mw-item-selected-image">
      {{ "SelectedSingularis" | translate }}
    </div>
  }
  @if (item().blob) {
    <div class="mw-item-new-item">
      {{ "New" | translate }}
    </div>
  }
  @if (item().isImage) {
    <extensive-image
      [imageUrl]="item().url"
      [imageStorageSize]="itemSize()"
      [blob]="item().blob ?? null"
      [displayLoader]="true"
    >
    </extensive-image>
  }
  @if (item().isPDF) {
    <extensive-pdf
      [pdfUrl]="item().url"
      [blob]="item().blob ?? null"
      [thumbnailVersion]="true"
      [displayLoader]="true"
    >
    </extensive-pdf>
  }
</div>

<div
  class="mw-item-footer"
  [title]="item().title"
  [ngClass]="{
    pinned: item().actionsOpened,
    'has-menu-button': !viewOnly(),
  }"
>
  <div class="title">
    {{ item().title }}
  </div>
  @if (!viewOnly()) {
    <div
      class="options-menu bi-three-dots-vertical"
      title="{{ 'OpenMenu' | translate }}"
      [ngClass]="{
        opened: item().actionsOpened,
      }"
      (click)="optionsTemplate.toggle($event)"
    ></div>
    <p-popover
      #optionsTemplate
      (onShow)="item().actionsOpened = true"
      (onHide)="item().actionsOpened = false"
    >
      @if (item().isImage && canToggleDefault()) {
        <div
          class="standard-menu-item"
          [ngClass]="{
            disabled: item().default,
          }"
          (click)="setAsDefault(); optionsTemplate.toggle($event)"
        >
          {{ "SelectAsCoverImage" | translate }}
        </div>
      }
      @if (!item().blob) {
        <div
          class="standard-menu-item"
          (click)="download(); optionsTemplate.toggle($event)"
        >
          {{ "Download" | translate }}
        </div>
      }
      <div
        class="standard-menu-item"
        (click)="remove(); optionsTemplate.toggle($event)"
      >
        {{ "Remove" | translate }}
      </div>
    </p-popover>
  }
</div>
