import { Component, computed, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { ScheduleGroup } from "app/components/schedule/schedule";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { ListUserGroupComponent } from "app/components/user-group/list-user-group/list-user-group.component";
import { UserGroup } from "app/components/user-group/user-group";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-schedule-groups-tab",
  templateUrl: "./handle-schedule-groups-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListUserGroupComponent,
    TranslateModule,
  ],
})
export class HandleScheduleGroupsTabComponent extends HandleItemsBaseDirective<ScheduleGroup> {
  globalDummyGroup = new ScheduleGroup({});

  visibleListUserGroup = signal<boolean>(false);

  itemAsIds = computed(() => this.items().map((item) => item.userGroup.id));

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "AddGroups",
        "AddGroups",
        StringUtils.icons.new,
        () => this.visibleListUserGroup.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTextColumn(
        ["Name"],
        (row) => new TextContent(row.userGroup.name),
      ),
      new SimpleTableRuleColumn(
        ["Rules"],
        (row) => new RuleContent(row.rulesEditableInSchedule),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Groups", StringUtils.icons.group),
    );
  }

  handleCloseListUserGroup() {
    this.visibleListUserGroup.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListUserGroup(items: UserGroup[]) {
    this.handleCloseListUserGroup();
    const scheduleGroups = items.map((group) => {
      const scheduleGroup = new ScheduleGroup(group);
      scheduleGroup.userGroup = new UserGroup(group);
      return scheduleGroup;
    });
    this.items.set(scheduleGroups);
    this.onItemsChange.emit(this.items());
  }

  // initiateGlobalRules() { TODO
  //   const selectedItems = this.handleItems.filter(
  //     (model) => model.selected === true
  //   );
  //   this.globalDummyGroup.canPerformScheduled = selectedItems.every(
  //     (item) => item.canPerformScheduled
  //   );
  //   this.globalDummyGroup.canPerformMeasure = selectedItems.every(
  //     (item) => item.canPerformMeasure
  //   );
  //   this.globalDummyGroup.canAssign = selectedItems.every(
  //     (item) => item.canAssign
  //   );
  // }

  // handleRuleClick(item: ScheduleGroup, rule: RuleObject) {
  //   const propertyName = Utils.uncapitalizeFirstLetter(rule.title);
  //   item[propertyName] = !item[propertyName];

  //   if (propertyName === this.propertyStrings.canAssign && item.canAssign) {
  //     item.canPerformScheduled = true;
  //   } else if (
  //     propertyName === this.propertyStrings.canPerformScheduled &&
  //     !item.canPerformScheduled
  //   ) {
  //     item.canAssign = false;
  //   }
  // }

  // handleRuleClickGlobal(rule: RuleObject) {
  //   const propertyName = Utils.uncapitalizeFirstLetter(rule.title);
  //   this.globalDummyGroup[propertyName] = !this.globalDummyGroup[propertyName];
  //   const selectedItems = this.handleItems.filter(
  //     (model) => model.selected === true
  //   );
  //   selectedItems.forEach(
  //     (item) => (item[propertyName] = this.globalDummyGroup[propertyName])
  //   );

  //   if (
  //     propertyName === this.propertyStrings.canAssign &&
  //     this.globalDummyGroup.canAssign
  //   ) {
  //     this.globalDummyGroup.canPerformScheduled = true;
  //     selectedItems.forEach((item) => (item.canPerformScheduled = true));
  //   } else if (
  //     propertyName === this.propertyStrings.canPerformScheduled &&
  //     !this.globalDummyGroup.canPerformScheduled
  //   ) {
  //     this.globalDummyGroup.canAssign = false;
  //     selectedItems.forEach((item) => (item.canAssign = false));
  //   }
  // }

  // override handleConfirmed(confirmedItems: UserGroup[]) {
  //   const mappedConfirmedItems = confirmedItems.map((confirmedItem) => {
  //     const existingItem = this.handleItems.find(
  //       (item) => item.userGroup.id === confirmedItem.id
  //     );
  //     if (existingItem) {
  //       return existingItem;
  //     } else {
  //       return new ScheduleGroup({
  //         userGroup: confirmedItem,
  //         userGroupId: confirmedItem.id,
  //       });
  //     }
  //   });
  //   this.handleItems = this.constructItemList(
  //     this.handleItems,
  //     mappedConfirmedItems
  //   );
  //   this.setAllAreSelected();
  //   this.setSomeAreSelected();
  //   this.emitModelsChange();
  //   this.closeModal();
  // }

  // override constructItemList(
  //   currentItemList: ScheduleGroup[],
  //   confirmedItemList: ScheduleGroup[]
  // ) {
  //   // Reduce to only confirmed items.
  //   currentItemList = currentItemList.filter((currentItem) =>
  //     confirmedItemList.find(
  //       (confirmedItem) =>
  //         confirmedItem.userGroup.id === currentItem.userGroup.id
  //     )
  //   );

  //   // Apply only new items.
  //   currentItemList = currentItemList.concat(
  //     confirmedItemList.filter(
  //       (confirmedItem) =>
  //         !currentItemList.find(
  //           (currentItem) =>
  //             currentItem.userGroup.id === confirmedItem.userGroup.id
  //         )
  //     )
  //   );

  //   return currentItemList;
  // }

  // override remove(itemToRemove: ScheduleGroup) {
  //   this.handleItems = this.handleItems.filter(
  //     (model) => model.userGroup.id !== itemToRemove.userGroup.id
  //   );
  //   this.setAllAreSelected();
  //   this.setSomeAreSelected();
  //   this.emitModelsChange();
  // }

  // toggleCanBeAssigned(scheduleGroup: ScheduleGroup) {
  //   scheduleGroup.canBeAssigned = !scheduleGroup.canBeAssigned;
  // }
}
