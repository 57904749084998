import { Params } from "@angular/router";
import { environment } from "environments/environment";
import { RoutesUtils } from "./routes-utils";

/**
 * Utility methods to help resolve URLs.
 */
export class UrlUtils {
  public static BLOBSTORAGE = environment.storageUrl;

  public static API_CORE_URL = environment.coreApi;
  public static API_INSPECTION_URL = environment.inspectionApi;
  public static DEFAULT_REDIRECT = `${RoutesUtils.dashboard}`;
  public static WIKI_URL = "https://inquinova.com";
  public static CLICKUP_INTERACTION = "https://n8n.inquinova.com/webhook";

  // Generate an absolute URL to the server, ignoring the context path.
  // Otherwise the "baseHref" would be included. Used for API calls.
  public static BASE_URL =
    document.location.protocol + "//" + document.location.host + "/";
  // public static HOSTED_URL = 'https://apirix-core-dev.apirix.com/';

  // Injects the environment-dependent deploy URL to a URL provided.
  public static toDeployUrl(url: string) {
    return `${environment.baseUrl}${url}`;
  }

  public static toMediaUrl(url: string, size?: number) {
    const baseUrl = `${environment.storageUrl}/${
      url.includes(".pdf") ? "file" : "image"
    }/${url}`;
    return size ? `${baseUrl}/${size}` : baseUrl;
  }

  public static isInvitationRoute() {
    const INVITATION_ROUTES = [`${RoutesUtils.invitation}`];
    return INVITATION_ROUTES.some((x) => window.location.pathname.includes(x));
  }

  /**
   * Returns all query parameters in the URL besides the ones to skip, if any params given
   * @param paramsToSkip String params to skip when fetching query parameters from URL
   */
  public static getOtherQueryParams(
    routeParams: Params,
    ...paramsToSkip: string[]
  ): any {
    const queryParams = {};
    Object.keys(routeParams)
      .filter((o) => paramsToSkip.indexOf(o) === -1)
      .forEach((param) => {
        queryParams[param] = routeParams[param];
      });
    return queryParams;
  }
}
