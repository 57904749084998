<simple-landing-page [isLoading]="pending()">
  <div class="header-wrapper" ngProjectAs="landing-page-header-slot">
    <div class="image">
      <extensive-image
        [imageUrl]="globalState.selectedOrganization().logotypeUrl"
        [imageStorageSize]="65"
        [placeholderImageType]="'organization'"
        [displayLoader]="true"
      >
      </extensive-image>
    </div>
    <div class="details">
      <div class="greeting">
        {{ getGreeting() }}
        {{ globalState.loggedInUser().fullName }}!
      </div>
    </div>
  </div>

  <div class="content" ngProjectAs="landing-page-content-slot">
    <div class="filter-wrapper">
      <div class="selected-filters-wrapper">
        <div class="selected-filters">
          @for (selectedFilter of selectedFilters; track $index) {
            <div
              class="selected-filter"
              title="{{ selectedFilter.title }}: {{
                selectedFilter.selectedItemNames
              }}"
              (click)="removeFilter(selectedFilter)"
            >
              <div class="title">{{ selectedFilter.title }}:</div>
              <div class="values">
                {{ selectedFilter.selectedItemNames }}
              </div>
              <div class="icon bi-x"></div>
            </div>
          }
          @if (!hasSelectedFilters) {
            <div class="no-filter-text">
              {{ "NoFiltersAdded" | translate }}...
            </div>
          }
        </div>
        <button
          class="btn btn-transparent"
          [ngClass]="{
            selected: hasSelectedFilters,
            disabled: !filterObjects().length,
          }"
          title="{{ 'Filter' | translate }}"
          (click)="filterOpened.set(!filterOpened())"
        >
          {{ "Filter" }}
          <div
            class="icon smaller"
            [ngClass]="{
              'bi-chevron-down': !filterOpened(),
              'bi-chevron-up': filterOpened(),
            }"
          ></div>
        </button>
      </div>

      @if (filterOpened()) {
        <div class="filter-object-wrapper">
          @for (filterObject of filterObjects(); track $index) {
            <div class="filter-object">
              <button
                class="btn btn-transparent"
                [ngClass]="{
                  opened: filterObject.opened,
                  selected: filterObject.hasSelectedItems,
                }"
                title="{{ 'OpenMenu' | translate }}"
                (click)="filterTemplate.toggle($event)"
              >
                {{ filterObject.title }}
              </button>
              <p-popover
                #filterTemplate
                (onShow)="filterObject.opened = true; focusFilterSearchInput()"
                (onHide)="filterObject.opened = false"
              >
                <input
                  #filterInput
                  class="filter-search"
                  [placeholder]="'Search' | translate"
                  [(ngModel)]="filterObject.searchValue"
                />
                @if (filterObject.searchValue) {
                  <div
                    class="clear-search bi-x"
                    [title]="'Clear' | translate"
                    (click)="
                      $event.stopImmediatePropagation();
                      filterObject.searchValue = ''
                    "
                  ></div>
                }

                @for (item of filterObject.filteredItems; track $index) {
                  <div
                    class="standard-menu-item"
                    [ngClass]="{
                      selected: item.selected,
                    }"
                    [title]="item.name"
                    (click)="handleFilterClick(item, filterObject)"
                  >
                    <div class="text">
                      {{ item.name }}
                    </div>
                  </div>
                }
              </p-popover>
            </div>
          }
        </div>
      }
    </div>
    <div class="chart apx-scrollbar">
      <div class="canvas-wrapper">
        <canvas
          baseChart
          [type]="chartType"
          [options]="chartOptions"
          [data]="chartData"
          [plugins]="chartPlugins"
          (chartClick)="handleChartClick($event)"
        >
        </canvas>
        @if (
          !chartData.datasets.length && !pendingItems() && !pendingCounts()
        ) {
          <div class="state-text">{{ "NoDataWasFound" | translate }}...</div>
        }
        @if (pendingItems() || pendingCounts()) {
          <div class="state-text icon bi-arrow-repeat"></div>
        }
      </div>
      <div class="timespan">
        <button
          type="button"
          class="btn btn-transparent"
          [ngClass]="{
            selected: selectedTimeSpan() === timeSpanEnum.ThisDay,
          }"
          [title]="'ThisDay' | translate"
          (click)="changeTimeSpan(timeSpanEnum.ThisDay)"
        >
          {{ "ThisDay" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-transparent"
          [ngClass]="{
            selected: selectedTimeSpan() === timeSpanEnum.ThisWeek,
          }"
          [title]="'ThisWeek' | translate"
          (click)="changeTimeSpan(timeSpanEnum.ThisWeek)"
        >
          {{ "ThisWeek" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-transparent"
          [ngClass]="{
            selected: selectedTimeSpan() === timeSpanEnum.ThisMonth,
          }"
          [title]="'ThisMonth' | translate"
          (click)="changeTimeSpan(timeSpanEnum.ThisMonth)"
        >
          {{ "ThisMonth" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-transparent"
          [ngClass]="{
            selected: selectedTimeSpan() === timeSpanEnum.ThisYear,
          }"
          [title]="'ThisYear' | translate"
          (click)="changeTimeSpan(timeSpanEnum.ThisYear)"
        >
          {{ "ThisYear" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-transparent disabled"
          [title]="'CustomInterval' | translate"
        >
          {{ "CustomInterval" | translate }}
          <i class="bi-chevron-down"></i>
        </button>
      </div>
      <div class="state-buttons">
        <div class="button-wrapper">
          <button
            type="button"
            class="btn btn-transparent"
            [ngClass]="{
              selected:
                selectedAssignmentState() ===
                assignmentStateEnum.ExpiringAssignments,
            }"
            [title]="'ExpiringAssignments' | translate"
            (click)="
              changeAssignmentState(assignmentStateEnum.ExpiringAssignments)
            "
          >
            <div class="text">
              {{ "ExpiringAssignments" | translate }}
            </div>
            <div
              class="count"
              [ngClass]="{
                'bi-arrow-repeat': pendingCounts(),
              }"
            >
              @if (!pendingCounts()) {
                ({{ scheduledAssignmentsExpiringCount() }})
              }
            </div>
          </button>
          <button
            type="button"
            class="btn btn-transparent"
            [ngClass]="{
              selected:
                selectedAssignmentState() ===
                assignmentStateEnum.CompletedAssignments,
            }"
            [title]="'CompletedAssignments' | translate"
            (click)="
              changeAssignmentState(assignmentStateEnum.CompletedAssignments)
            "
          >
            <div class="text">
              {{ "CompletedAssignments" | translate }}
            </div>
            <div
              class="count"
              [ngClass]="{
                'bi-arrow-repeat': pendingCounts(),
              }"
            >
              @if (!pendingCounts()) {
                ({{ scheduledAssignmentsCompletedCount() }})
              }
            </div>
          </button>
          <button
            type="button"
            class="btn btn-transparent"
            [ngClass]="{
              selected:
                selectedAssignmentState() ===
                assignmentStateEnum.ExpiredAssignments,
            }"
            [title]="'ExpiredAssignments' | translate"
            (click)="
              changeAssignmentState(assignmentStateEnum.ExpiredAssignments)
            "
          >
            <div class="text">
              {{ "ExpiredAssignments" | translate }}
            </div>
            <div
              class="count"
              [ngClass]="{
                'bi-arrow-repeat': pendingCounts(),
              }"
            >
              @if (!pendingCounts()) {
                ({{ scheduledAssignmentsExpiredCount() }})
              }
            </div>
          </button>
        </div>
        <div class="button-wrapper">
          <button
            type="button"
            class="btn btn-transparent"
            [ngClass]="{
              selected:
                selectedAssignmentState() ===
                assignmentStateEnum.CreatedMeasures,
            }"
            [title]="'CreatedMeasures' | translate"
            (click)="changeAssignmentState(assignmentStateEnum.CreatedMeasures)"
          >
            <div class="text">
              {{ "CreatedMeasures" | translate }}
            </div>
            <div
              class="count"
              [ngClass]="{
                'bi-arrow-repeat': pendingCounts(),
              }"
            >
              @if (!pendingCounts()) {
                ({{ measureAssignmentsCreatedCount() }})
              }
            </div>
          </button>
          <button
            type="button"
            class="btn btn-transparent"
            [ngClass]="{
              selected:
                selectedAssignmentState() ===
                assignmentStateEnum.ResolvedMeasures,
            }"
            [title]="'ResolvedMeasures' | translate"
            (click)="
              changeAssignmentState(assignmentStateEnum.ResolvedMeasures)
            "
          >
            <div class="text">
              {{ "ResolvedMeasures" | translate }}
            </div>
            <div
              class="count"
              [ngClass]="{
                'bi-arrow-repeat': pendingCounts(),
              }"
            >
              @if (!pendingCounts()) {
                ({{ measureAssignmentsResolvedCount() }})
              }
            </div>
          </button>
          <button
            type="button"
            class="btn btn-transparent"
            [ngClass]="{
              selected:
                selectedAssignmentState() ===
                assignmentStateEnum.ClosedMeasures,
            }"
            [title]="'ClosedMeasures' | translate"
            (click)="changeAssignmentState(assignmentStateEnum.ClosedMeasures)"
          >
            <div class="text">
              {{ "ClosedMeasures" | translate }}
            </div>
            <div
              class="count"
              [ngClass]="{
                'bi-arrow-repeat': pendingCounts(),
              }"
            >
              @if (!pendingCounts()) {
                ({{ measureAssignmentsClosedCount() }})
              }
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</simple-landing-page>

<view-assignments
  [visible]="visibleViewAssignments()"
  [assignments]="assignmentsToView()"
  [property]="currentItemsProperty()"
  [firstLabel]="currentfirstLabel()"
  [secondLabel]="currentSecondLabel()"
  [thirdLabel]="currentThirdLabel()"
  (onClose)="handleCloseViewAssignments()"
>
</view-assignments>
