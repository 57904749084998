import { NgClass, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { GlobalStateService } from "app/global-state/global-state.service";
import { TranslationService } from "app/services/translation.service";
import { environment } from "environments/environment";

@Component({
  selector: "simple-link-button",
  templateUrl: "./simple-link-button.component.html",
  styleUrls: ["./simple-link-button.component.less"],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class SimpleLinkButtonComponent implements OnInit {
  @Input() url: string;
  @Input() linkText: string;
  @Input() shouldStopPropagation: boolean;
  @Input() shouldDisable: boolean;
  @Input() name: string;

  titleText: string;

  constructor(
    private globalState: GlobalStateService,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    const name = this.name
      ? `"${this.name}"`
      : this.translationService.instant("Object").toLowerCase();
    this.titleText = `${this.translationService.instant(
      "Open"
    )} ${name} ${this.translationService.instant("InANewTab").toLowerCase()}`;
  }

  navigateTo($event: MouseEvent) {
    if (!this.shouldDisable) {
      if (this.shouldStopPropagation) {
        $event.stopImmediatePropagation();
      }
      const url = `${environment.coreUrl}/${
        this.globalState.selectedOrganization().friendlyUrl
      }/${this.url}`;
      window.open(url, "_blank");
    }
  }
}
