<simple-crud-modal
  [visible]="visible()"
  [headerText]="'ManualMeasure' | translate"
  [closeText]="'Close' | translate"
  [submitText]="'Create' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <!-- <tab
      [customClass]="
        handleFacilityIsValid()
          ? 'valid'
          : handleFacilityHasErrors()
          ? 'invalid'
          : ''
      "
      (selectTab)="setTabIndexInUrl(1)"
    >
      <ng-template tabHeading>
        <simple-tab-heading
          [icon]="'ii-facility'"
          [title]="'Facility' | translate"
          [helpText]="'FacilityChangeDetailsMeasureHelpText' | translate"
        >
        </simple-tab-heading>
      </ng-template>
      <handle-facility-tab
        [useSingleSelectionInList]="true"
        [onlyActiveFacilitiesInList]="true"
        [items]="modifiedModel().facility ? [modifiedModel().facility] : []"
        [choice]="modifiedModel().choice"
        [headerSubtitleText]="'AddedFacilitySubtitleMeasure' | translate"
        (onModelsChange)="setFacility($event)"
        (onUpdated)="assignFacility($event)"
      >
      </handle-facility-tab>
    </tab>
    <tab
      [customClass]="
        handleEntitiesIsValid()
          ? 'valid'
          : handleEntitiesHasErrors()
          ? 'invalid'
          : ''
      "
      (selectTab)="setTabIndexInUrl(2)"
    >
      <ng-template tabHeading>
        <simple-tab-heading
          [icon]="'ii-entities'"
          [title]="'Entities' | translate"
          [helpText]="'EntitiesChangeDetailsMeasureHelpText' | translate"
          [displayCount]="modifiedModel().entities.length"
        >
        </simple-tab-heading>
      </ng-template>
      <handle-entities-tab-measure
        [items]="modifiedModel().entities"
        [choice]="modifiedModel().choice"
        [headerSubtitleText]="'AddedEntitiesSubtitleMeasure' | translate"
        [facilityId]="
          modifiedModel().facility ? modifiedModel().facility.id : null
        "
        [onlyActiveEntitiesInList]="true"
        (onModelsChange)="handleEntityChange($event)"
        (onUpdated)="handleOnUpdatedEntities()"
      >
      </handle-entities-tab-measure>
    </tab>
    <tab [customClass]="'valid'" (selectTab)="setTabIndexInUrl(3)">
      <ng-template tabHeading>
        <simple-tab-heading
          [icon]="'bi-people'"
          [title]="'Groups' | translate"
          [helpText]="'GroupsMeasureHelpText' | translate"
          [displayCount]="modifiedModel().assigneeGroups.length"
        >
        </simple-tab-heading>
      </ng-template>
      <handle-assignee-groups-tab
        [items]="modifiedModel().assigneeGroups"
        [isMeasure]="true"
        [headerSubtitleText]="'AddedGroupsSubtitleMeasure' | translate"
        [hideNotificationButton]="true"
      >
      </handle-assignee-groups-tab>
    </tab>
    <tab [customClass]="'valid'" (selectTab)="setTabIndexInUrl(4)">
      <ng-template tabHeading>
        <simple-tab-heading
          [icon]="'bi-person'"
          [title]="'Assignees' | translate"
          [helpText]="'UsersMeasureHelpText' | translate"
          [displayCount]="modifiedModel().assignees.length"
        >
        </simple-tab-heading>
      </ng-template>
      <handle-assignees-tab
        [items]="modifiedModel().assignees"
        [selectedAssigneeGroups]="modifiedModel().assigneeGroups"
        [isMeasure]="true"
        [headerSubtitleText]="'AddedUsersSubtitleMeasure' | translate"
        [hideNotificationButton]="true"
      >
      </handle-assignees-tab>
    </tab>
    <tab
      [customClass]="
        handleSubscribersIsValid()
          ? 'valid'
          : handleSubscribersHasErrors()
          ? 'invalid'
          : ''
      "
      (selectTab)="setTabIndexInUrl(5)"
    >
      <ng-template tabHeading>
        <simple-tab-heading
          [icon]="'bi bi-bell'"
          [title]="'Subscribers' | translate"
          [helpText]="'MeasureHandleSubscribersHelpText' | translate"
          [displayCount]="modifiedModel().subscribers.length"
        >
        </simple-tab-heading>
      </ng-template>
      <handle-subscribers-tab
        [items]="modifiedModel().subscribers"
        [headerSubtitleText]="'AddedSubscribersSubtitleMeasure' | translate"
        [isManualMeasureContext]="true"
        [bypassRequiredError]="true"
      >
      </handle-subscribers-tab>
    </tab> -->
  }
</simple-crud-modal>
