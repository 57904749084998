<simple-crud-modal
  [visible]="visible()"
  [headerText]="'Message' | translate"
  [closeText]="'Close' | translate"
  [hideSubmitButton]="true"
  (onClose)="handleClose()"
>
  @if (notificationMessage()) {
    <notification-message-details [notificationMessage]="notificationMessage()">
    </notification-message-details>
  }
</simple-crud-modal>
