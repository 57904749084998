import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableIssueNumberColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => IssueNumberContent;

  constructor(
    labels: string[],
    getValue: (row: T) => IssueNumberContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.ISSUE_NUMBER;
    this.maxwidth = 50;
  }
}

export class IssueNumberContent {
  text: string;

  constructor(text: string) {
    this.text = text;
  }
}
