import { Component, input } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "simple-change-tab",
  templateUrl: "./simple-change-tab.component.html",
  styleUrls: ["./simple-change-tab.component.less"],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class SimpleChangeTabComponent {
  form = input<FormGroup>();
}
