import { inject, Injectable } from "@angular/core";
import {
  LocalStorageKey,
  LocalStorageService,
} from "app/services/local-storage.service";
import { AccessData } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class TokenStorageService {
  private localStorageService = inject(LocalStorageService);

  getAccessData(): AccessData {
    return this.localStorageService.getItem(LocalStorageKey.AccessData);
  }

  setAccessData(accessData: AccessData) {
    const expiringDate = accessData.expires_in * 1000 + Date.now();
    accessData.expiring_date = expiringDate;
    this.localStorageService.setItem(LocalStorageKey.AccessData, accessData);
  }

  clearAccessData() {
    this.localStorageService.removeItem(LocalStorageKey.AccessData);
  }
}
