import { Component, computed, Signal } from "@angular/core";
import { SimpleAssessmentComponent } from "../../../../../various/assessments/simple-assessment.component";
import { AssessmentContent } from "../../../columns/simple-table-assessment-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-assessment",
  templateUrl: "./column-type-assessment.component.html",
  standalone: true,
  imports: [SimpleAssessmentComponent],
})
export class ColumnTypeAssessmentComponent<
  T,
> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<AssessmentContent>;
}
