import { User } from "app/components/user/user";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableUserColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => UserContent;

  constructor(
    labels: string[],
    getValue: (row: T) => UserContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.USER;
  }
}

export class UserContent {
  user: User;

  constructor(user: User) {
    this.user = user;
  }
}
