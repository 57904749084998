<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.name"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="isEdit ? 0 : 1" scrollable>
      <p-tablist>
        @if (isEdit) {
          <p-tab [value]="0">
            <simple-tab-heading
              [icon]="'bi bi-search'"
              [title]="'Overview' | translate"
              [helpText]="'EntityViewDetailsHelpText' | translate"
            >
            </simple-tab-heading>
          </p-tab>
        }
        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="
              isEdit
                ? ('EntityChangeDetailsEditHelpText' | translate)
                : ('EntityChangeDetailsHelpText' | translate)
            "
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="2">
          <simple-tab-heading
            [icon]="'bi bi-files'"
            [title]="'Media' | translate"
            [helpText]="
              isEdit
                ? ('EntityHandleMediaEditHelpText' | translate)
                : ('EntityHandleMediaHelpText' | translate)
            "
            [displayCount]="modifiedModel().media.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        @if (isEdit) {
          <p-tabpanel [value]="0">
            <view-details-tab [model]="modifiedModel()"></view-details-tab>
          </p-tabpanel>
        }
        <p-tabpanel [value]="1">
          <change-details-tab
            [modifiedModel]="modifiedModel()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </change-details-tab>
        </p-tabpanel>
        <p-tabpanel [value]="2">
          <handle-media-tab
            [items]="modifiedModel().media"
            [headerSubtitleText]="'AddedMediaSubtitleEntity' | translate"
            [canToggleDefault]="true"
            (onItemsChange)="handleItemsChange(propertyStrings.media, $event)"
          >
          </handle-media-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
