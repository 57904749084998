<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.facility?.name"
  [closeText]="'Close' | translate"
  [hideSubmitButton]="true"
  [isLoading]="pending()"
  (onClose)="handleClose()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-search'"
            [title]="'Overview' | translate"
            [helpText]="
              'MeasureAssignmentArchivedViewDetailsHelpText' | translate
            "
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <view-details-tab [model]="modifiedModel()"> </view-details-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
