import { Directive, signal } from "@angular/core";
import { DateTime } from "luxon";
import { UserSupportDirective } from "../user-support.directive";
import { NotificationMessage } from "./notification-message";

@Directive()
export abstract class NotificationMessageDirective<
  T,
> extends UserSupportDirective<T> {
  notificationMessages = signal<NotificationMessage[]>([]);
  currentMessage = signal<NotificationMessage>(null);

  visibleNotificationMessageModal = signal<boolean>(false);

  async ngOnInit() {
    super.initiateFileSubscription();
  }

  protected shouldDisplay(message: NotificationMessage) {
    const today = DateTime.now().toISODate();
    return (
      message.startDate <= today &&
      (!message.endDate || message.endDate >= today)
    );
  }

  handleCloseNotificationMessageModal() {
    this.visibleNotificationMessageModal.set(false);
  }

  protected abstract mapData(): void;
}
