<div class="text-wrapper">
  <div class="first-text" [title]="value().firstText | translateEach">
    {{ value().firstText | translateEach }}
  </div>
  @if (value().secondText) {
    <div class="second-text" [title]="value().secondText | translateEach">
      {{ value().secondText | translateEach }}
    </div>
  }
</div>
