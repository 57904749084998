<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.facility?.name"
  [closeText]="'Close' | translate"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-search'"
            [title]="'Overview' | translate"
            [helpText]="'MeasureAssignmentViewDetailsHelpText' | translate"
          >
          </simple-tab-heading>
        </p-tab>
        @if (
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        ) {
          <p-tab [value]="1">
            <simple-tab-heading
              [icon]="'bi bi-card-text'"
              [title]="'Details' | translate"
              [helpText]="'MeasureAssignmentChangeDetailsHelpText' | translate"
            >
            </simple-tab-heading>
          </p-tab>
          <p-tab [value]="2">
            <simple-tab-heading
              [icon]="'bi bi-people'"
              [title]="'Groups' | translate"
              [helpText]="'AssignmentHandleGroupsHelpText' | translate"
              [displayCount]="modifiedModel().assigneeGroups.length"
            >
            </simple-tab-heading>
          </p-tab>
          <p-tab [value]="3">
            <simple-tab-heading
              [icon]="'bi bi-person'"
              [title]="'Assignees' | translate"
              [helpText]="'AssignmentHandleUsersHelpText' | translate"
              [displayCount]="modifiedModel().assignees.length"
            >
            </simple-tab-heading>
          </p-tab>
        }
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <view-details-tab [model]="modifiedModel()"> </view-details-tab>
        </p-tabpanel>
        @if (
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        ) {
          <p-tabpanel [value]="1">
            <change-details-tab
              [modifiedModel]="modifiedModel()"
              [choice]="modifiedModel().choice"
              (onDetailsChange)="handleDetailsChange($event)"
            >
            </change-details-tab>
          </p-tabpanel>
          <p-tabpanel [value]="2">
            <handle-assignee-groups-tab
              [items]="modifiedModel().assigneeGroups"
              [headerSubtitleText]="'AddedGroupsSubtitleAssignment' | translate"
              [isMeasure]="true"
              [hideNotificationButton]="
                !globalState
                  .loggedInUser()
                  .canEdit(globalState.selectedOrganization().friendlyUrl)
              "
              (onItemsChange)="
                handleItemsChange(propertyStrings.assigneeGroups, $event)
              "
            >
            </handle-assignee-groups-tab>
          </p-tabpanel>
          <p-tabpanel [value]="3">
            <handle-assignees-tab
              [items]="modifiedModel().assignees"
              [headerSubtitleText]="'AddedUsersSubtitleAssignment' | translate"
              [selectedAssigneeGroups]="modifiedModel().assigneeGroups"
              [isMeasure]="true"
              [hideNotificationButton]="
                !globalState
                  .loggedInUser()
                  .canEdit(globalState.selectedOrganization().friendlyUrl)
              "
              (onItemsChange)="
                handleItemsChange(propertyStrings.assignees, $event)
              "
            >
            </handle-assignees-tab>
          </p-tabpanel>
        }
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
