<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.name"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="
              isEdit
                ? ('UserGroupChangeDetailsEditHelpText' | translate)
                : ('UserGroupChangeDetailsHelpText' | translate)
            "
          >
          </simple-tab-heading>
        </p-tab>

        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'bi bi-person'"
            [title]="'Assignees' | translate"
            [helpText]="
              isEdit
                ? ('UserGroupHandleUsersEditHelpText' | translate)
                : ('UserGroupHandleUsersHelpText' | translate)
            "
            [displayCount]="modifiedModel().users.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <change-details-tab
            [modifiedModel]="modifiedModel()"
            (onDetailsChange)="handleDetailsChange($event)"
          >
          </change-details-tab>
        </p-tabpanel>

        <p-tabpanel [value]="1">
          <handle-users-tab
            [items]="modifiedModel().users"
            [headerSubtitleText]="'AddedUsersSubtitleUserGroup' | translate"
            (onItemsChange)="handleItemsChange(propertyStrings.users, $event)"
          >
          </handle-users-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
