export class NumberUtils {
  // Screen sizes.
  public static BOOTSTRAP_LG_MIN = 1200;
  public static BOOTSTRAP_XL_MIN = 1600;

  // Tables.
  public static TABLE_DATA_PAGE_SIZE = 50;
  public static TABLE_DATA_PAGE_SIZE_MAX_VALUE = 50000;

  // Various.
  public static WINDOW_RESIZE_WAIT_TIME = 100;

  // User support
  public static USER_SUPPORT_FILE_POLLING_TIME = 300000; // 5 minutes
  public static IMPORTANT_MESSAGE_DISPLAY_TIME = 20000; // 20 seconds
  public static IMPORTANT_MESSAGE_ANIMATION_TIME = 500; // half a second

  // Based on an .5s animation
  public static DEFAULT_DEBOUNCE_TIME = 500;
  public static MAX_ITEM_COUNT_FOR_REPORT_DOWNLOAD = 500;
}
