import { Facility } from "app/components/facility/facility";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableFacilityColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => FacilityContent;

  constructor(
    labels: string[],
    getValue: (row: T) => FacilityContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.FACILITY;
  }
}

export class FacilityContent {
  facility: Facility;

  constructor(facility: Facility) {
    this.facility = facility;
  }
}
