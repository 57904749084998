import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { User } from "app/components/user/user";
import { RoutesUtils } from "app/tools/routes-utils";

export class UserGroup extends CrudItem<UserGroup> {
  name: string = "";
  users: User[] = [];
  userCount: number;

  get displayName() {
    return this.name;
  }

  constructor(userGroup: Partial<UserGroup>) {
    super();
    Object.assign(this, userGroup);
    this.mapUsers();
    this.setUrl(RoutesUtils.groups);
  }

  private mapUsers() {
    this.users = this.users.map((user) => new User(user));
    this.users.sortByProperty(new User({}).propertyStrings.fullName);
  }

  toPayloadObject(orgId: number) {
    return new UserGroupPayload({
      id: this.id,
      name: this.name,
      userIds: this.users.map((user) => user.id),
      organizationId: orgId,
    });
  }
}

export class UserGroupPayload extends UserGroup {
  organizationId: number;
  userIds: string[] = [];

  constructor(userGroupPayload: Partial<UserGroupPayload>) {
    super(userGroupPayload);
    Object.assign(this, userGroupPayload);
  }
}

export class UserGroupOnUser extends UserGroup {
  organizationId: number;

  constructor(userGroupOnUser: Partial<UserGroupOnUser>) {
    super(userGroupOnUser);
    Object.assign(this, userGroupOnUser);
  }
}
