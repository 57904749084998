import { Injectable } from "@angular/core";
import { Filter } from "app/filter";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import {
  TemplateBaseType,
  TemplateType,
  TemplateTypePayload,
} from "../components/template-type/template-type";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class TemplateTypeService extends BaseService<TemplateType> {
  prefix = RoutesUtils.templateType;

  constructor() {
    super(TemplateType);
  }

  async get(id: string): Promise<TemplateType> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}/${id}`);
      return new TemplateType(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: TemplateTypePayload): Promise<TemplateType> {
    try {
      const response = await this.hostedHttpClient.post(`${this.prefix}`, item);
      return new TemplateType(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: TemplateTypePayload): Promise<TemplateType> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
      );
      return new TemplateType(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.delete(
        `${this.prefix}/${id}`,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(itemIds: string[]): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        { itemIds: itemIds },
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async importFromParent(
    templateTypes: TemplateType[],
    organizationId: number,
  ) {
    try {
      const model = {
        itemIds: templateTypes.map((templateType) => templateType.id),
        organizationId: organizationId,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/copy`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getTemplateBaseTypes(
    shouldIncludeFlag?: boolean,
    organizationId?: number,
  ): Promise<TemplateBaseType[]> {
    try {
      if (shouldIncludeFlag && organizationId) {
        const response = await this.hostedHttpClient.get(
          `templateBaseType?forCreatingTemplateType=true&organizationId=${organizationId}`,
        );
        return this.extractData(response).map(
          (item: TemplateBaseType) => new TemplateBaseType(item),
        );
      } else {
        const response = await this.hostedHttpClient.get(`templateBaseType`);
        return this.extractData(response).map(
          (item: TemplateBaseType) => new TemplateBaseType(item),
        );
      }
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateWithAffectedEntities(
    templateType: TemplateTypePayload,
  ): Promise<TemplateType> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${templateType.id}/affectedentities`,
        templateType,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateDescription(
    id: string,
    value: string,
    organizationId: number,
  ): Promise<TemplateType> {
    try {
      const model = {
        templateTypes: [
          {
            id: id,
            assignmentDescription: value,
          },
        ],
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/assignmentdescription/${organizationId}`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class TemplateTypeFilter extends Filter {
  parentOrganizationId: number;

  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.BASE_TYPES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.TEMPLATE_BASE_TYPE_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.TEMPLATE_BASE_TYPE_IDS_KEY) {
        delete this.facets[facet.key];
      }
    });

    Object.assign(this.facets, tempFacets);

    // Exclude parentOrganizationId property from the filterObject.
    const { parentOrganizationId, ...theRest } = this;

    if (this.parentOrganizationId) {
      return {
        filter: JSON.stringify(theRest),
        organization: this.parentOrganizationId,
      };
    } else {
      return {
        filter: JSON.stringify(theRest),
      };
    }
  }
}
