<simple-crud-modal
  [visible]="visible()"
  [headerText]="'ChangeOrganization' | translate"
  [closeText]="'Close' | translate"
  [hideSubmitButton]="true"
  [isLoading]="pending()"
  (onClose)="handleClose()"
>
  <inspire-tree
    [customClass]="'custom-inspire-tree'"
    (nodeSelected)="onNodeSelected($event)"
    (dataLoad)="onGetTreeData($event)"
  >
  </inspire-tree>
</simple-crud-modal>
