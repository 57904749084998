import { Component, inject, input, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { InvitedUser } from "app/components/user/user";
import { UserService } from "app/services/user.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { ChangePasswordTabComponent } from "./tabs/change-password-tab.component";

@Component({
  selector: "change-password",
  templateUrl: "./change-password.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ChangePasswordTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class ChangePasswordComponent extends CreateEditBaseDirective<InvitedUser> {
  otherUserId = input<string>("");

  private changePasswordTabComponent = viewChild(ChangePasswordTabComponent);

  private userService = inject(UserService);

  initiate() {
    this.initialModel.set(new InvitedUser({}));
    this.modifiedModel.set(
      new InvitedUser(Utils.getUniqueVariant(this.initialModel())),
    );
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const data = this.otherUserId
        ? await this.userService.updatePasswordWithOtherUserId(
            this.modifiedModel().newPassword,
            this.modifiedModel().confirmPassword,
            this.otherUserId(),
          )
        : await this.userService.updatePassword(
            this.modifiedModel().oldPassword,
            this.modifiedModel().newPassword,
            this.modifiedModel().confirmPassword,
          );

      this.toastrService.success(
        this.translationService.instant("TheUserWasUpdated"),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changePasswordTabComponent().setServerErrors(error.errors);
    }
  }
}
