import { Choice } from "app/components/choice/choice";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableChoiceColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (item: T) => ChoiceContent;

  constructor(
    labels: string[],
    getValue: (row: T) => ChoiceContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.CHOICE;
  }
}

export class ChoiceContent {
  choices: Choice[] = [];
  hideRules: boolean = false;

  constructor(choices: Choice[], hideRules?: boolean) {
    this.choices = choices;
    this.hideRules = hideRules || false;
  }
}
