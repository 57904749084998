<div
  class="form-calendar"
  [formGroup]="form()"
  [ngClass]="{
    disabled: disabled(),
  }"
>
  <div
    class="label-wrapper"
    [ngClass]="{
      focused: focused(),
      'has-errors': hasErrors(),
    }"
  >
    <label [for]="name()">
      {{ label() }}
    </label>
    <label [for]="name()" class="secondary-label">
      {{
        !isRequired() ? "" : " (" + ("Required" | translate | lowercase) + ")"
      }}
    </label>
  </div>
  <p-datepicker
    #picker
    [formControlName]="name()"
    [dateFormat]="'yy-mm-dd'"
    [dataType]="'string'"
    [placeholder]="label()"
    [showClear]="!isRequired() && !!getFormControl().value"
    [showTransitionOptions]="''"
    [hideTransitionOptions]="''"
    [keepInvalid]="true"
    [inputStyleClass]="hasErrors() ? 'has-errors' : ''"
    [minDate]="minDateAsDate()"
    [maxDate]="maxDateAsDate()"
    (onShow)="focused.set(true)"
    (onClose)="focused.set(false)"
  />
  <simple-form-error
    [name]="name()"
    [form]="form()"
    [serverErrors]="serverErrors()"
  >
  </simple-form-error>
</div>
