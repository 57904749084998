import { NgClass } from "@angular/common";
import { Component, input } from "@angular/core";
import { DotsLoaderComponent } from "app/components/dots-loader/dots-loader.component";

@Component({
  selector: "simple-landing-page",
  templateUrl: "./simple-landing-page.component.html",
  styleUrls: ["./simple-landing-page.component.less"],
  standalone: true,
  imports: [DotsLoaderComponent, NgClass],
})
export class SimpleLandingPageComponent {
  isLoading = input<boolean>();
}
