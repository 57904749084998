import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Assignment } from "app/components/assignment/assignment";
import { SimpleViewTabComponent } from "app/components/simple-components/crud/modal/tabs/view/simple-view-tab.component";
import { ViewDetailsBaseDirective } from "app/components/simple-components/directives/view-details-base.directive";
import { SimpleStatusLabelComponent } from "app/components/simple-components/various/status-label/simple-status-label.component";
import { SimpleAssessmentComponent } from "../../../../../../simple-components/various/assessments/simple-assessment.component";
import { SimpleViewConnectionsComponent } from "../../../../../../simple-components/various/view-connections/simple-view-connections.component";

@Component({
  selector: "view-details-tab",
  templateUrl: "./view-details-tab.component.html",
  standalone: true,
  imports: [
    SimpleViewTabComponent,
    SimpleStatusLabelComponent,
    TranslateModule,
    SimpleAssessmentComponent,
    SimpleViewConnectionsComponent,
  ],
})
export class ViewDetailsTabComponent extends ViewDetailsBaseDirective<Assignment> {
  getReport() {
    //TODO
    // this.bsModalRef = this.modalService.show(StaticReportComponent, {
    //   initialState: {
    //     editModelIds: [this.viewModel.id],
    //     disableTabIndexUrl: true,
    //     useAffectableIds: true,
    //     hideText: true,
    //     isMeasure: this.viewModel.isMeasureAssignment(),
    //   },
    //   ...ConfigUtils.MODAL_CONFIG_LARGE,
    // });
    // this.subscribeToCrudModalContent();
  }
}
