<simple-view-tab>
  <div class="view-section-layout-wrapper">
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "General" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Facility" | translate }}
          </div>
          <div class="content-value">
            <simple-view-connections [facility]="model().facility">
            </simple-view-connections>
          </div>
        </div>
        @if (model().entities.length) {
          <div class="content">
            <div class="content-header">
              {{ "Entity" | translate }}
            </div>
            <div class="content-value">
              <simple-view-connections [entities]="model().entities">
              </simple-view-connections>
            </div>
          </div>
        }
        <div class="content">
          <div class="content-header">
            {{ "IssueNumber" | translate }}
          </div>
          <div class="content-value">
            {{ model().issueNumber }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Client" | translate }}
          </div>
          @if (model().assignmentTemplate.client) {
            <div class="content-value">
              {{ model().assignmentTemplate.client.name }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Geofencing" | translate }}
          </div>
          {{
            model().assignmentTemplate.geoControlled
              ? ("Yes" | translate)
              : ("No" | translate)
          }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Prioritized" | translate }}
          </div>
          <div class="content-value">
            {{
              model().errorResult.priority
                ? ("Yes" | translate)
                : ("No" | translate)
            }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Status" | translate }}
          </div>
          <div class="content-value">
            <simple-status-label
              [text]="
                model().status === stringUtils.COMPLETED
                  ? stringUtils.RESOLVED
                  : model().status
              "
              [title]="
                model().status === stringUtils.COMPLETED
                  ? stringUtils.RESOLVED
                  : model().status
              "
            >
            </simple-status-label>
          </div>
        </div>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "Other" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "AdminComment" | translate }}
          </div>
          @if (model().adminComment) {
            <div class="content-value">
              {{ model().adminComment }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
      @if (model().errorAssignment.reportUrl) {
        <div class="view-section">
          <button
            class="btn-success"
            [title]="'GetAssignmentReport' | translate"
            (click)="getReport()"
          >
            {{ "GetAssignmentReport" | translate }}
          </button>
        </div>
      }
    </div>
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "ErrorDescription" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Assessment" | translate }}
          </div>
          <div class="content-value">
            <simple-assessment
              [task]="model().errorResult.task"
              [choice]="model().errorResult.task.choice"
              [comment]="model().errorResult.comment"
              [media]="model().errorResult.media"
              [displayInfoDirectly]="true"
              [infoText]="
                model().errorAssignment.assignmentTemplate.templateType
                  .isManualMeasure
                  ? ''
                  : ('From' | translate) +
                    ' ' +
                    model().errorAssignment.assignmentTemplate.templateType.name
              "
              [url]="
                model().errorAssignment.assignmentTemplate.templateType
                  .isManualMeasure
                  ? ''
                  : model().errorAssignment.url
              "
              [linkText]="
                model().errorAssignment.assignmentTemplate.templateType.name
              "
            ></simple-assessment>
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "CreatedBy" | translate }}
          </div>
          <div class="content-value">
            <simple-view-connections
              [users]="[
                model().createdByUser
                  ? model().createdByUser
                  : model().errorAssignment.completedByUser,
              ]"
            >
            </simple-view-connections>
          </div>
        </div>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "AddedGroups" | translate }}
        </div>
        <div class="content">
          @if (model().assigneeGroups.length) {
            <div class="content-value">
              <simple-view-connections
                [userGroupsWithButtons]="model().assigneeGroups"
              >
              </simple-view-connections>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "AddedAssignees" | translate }}
        </div>
        <div class="content">
          @if (model().assignees.length) {
            <div class="content-value">
              <simple-view-connections [users]="model().assignees">
              </simple-view-connections>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</simple-view-tab>
