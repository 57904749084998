import { Assignment } from "app/components/assignment/assignment";
import { KeyValuePair } from "app/filter";
import { AssignmentState, TimeSpan } from "./enums";

export class LabelObject {
  labelFirst: string;
  labelSecond: string;
  value: string;

  constructor(labelFirst: string, labelSecond: string, value: string) {
    this.labelFirst = labelFirst;
    this.labelSecond = labelSecond;
    this.value = value;
  }
}

export class AssignmentsByLabel {
  label: string = "";
  assignments: Assignment[] = [];

  constructor(label: string, assignments: Assignment[]) {
    this.label = label;
    this.assignments = assignments;
  }
}

export class FilterObject {
  title: string = "";
  items: FilterItem[] = [];
  facet: string = "";
  opened: boolean = false;
  searchValue: string = "";

  get hasSelectedItems() {
    return this.items.some((item) => item.selected);
  }

  get selectedItemIds() {
    return this.items
      .filter((item) => item.selected)
      .map((item) => item.id)
      .join(", ");
  }

  get selectedItemNames() {
    return this.items
      .filter((item) => item.selected)
      .map((item) => item.name)
      .join(", ");
  }

  get filteredItems() {
    return this.items.filter((item) =>
      item.name.toLowerCase().includes(this.searchValue.toLowerCase()),
    );
  }

  constructor(title: string, items: FilterItem[], facet: string) {
    this.title = title;
    this.items = items;
    this.facet = facet;
  }
}

export class FilterItem {
  id: string = "";
  name: string = "";
  selected: boolean = false;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class CountObject {
  icon: string = "";
  count: number = 0;
  link: string = "";
  label: string = "";
  secondaryIcon: string = "";

  constructor(
    icon: string,
    count: number,
    link: string,
    label: string,
    secondaryIcon?: string,
  ) {
    this.icon = icon;
    this.count = count;
    this.link = link;
    this.label = label;
    this.secondaryIcon = secondaryIcon;
  }
}

export class StoredFilter {
  keyValuePairs: KeyValuePair[] = [];

  constructor(storedFilter: Partial<StoredFilter>) {
    Object.assign(this, storedFilter);
  }
}

export class DashboardStorage {
  dashboardConfigs: DashboardConfig[] = [];

  constructor(dashboardStorage: Partial<DashboardStorage>) {
    Object.assign(this, dashboardStorage);

    this.dashboardConfigs.map((config) => new DashboardConfig(config));
  }
}

export class DashboardConfig {
  userId: string;
  organizationId: string;
  dashboardFilter: StoredFilter;
  dashboardTimespan: TimeSpan;
  dashboardAssignmentState: AssignmentState;

  constructor(storedConfig: Partial<DashboardConfig>) {
    Object.assign(this, storedConfig);

    if (this.dashboardFilter) {
      this.dashboardFilter = new StoredFilter(this.dashboardFilter);
    }
  }
}
