import { PropertyNameGetter } from "app/tools/property-name-getter";
import { RoutesUtils } from "app/tools/routes-utils";

export class CrudItem<T> {
  id: string = "";
  created: string = "";
  updated: string = "";
  actionsOpened: boolean = false;
  hovered: boolean = false;
  errorMessages: string[] = [];
  url: string = "";

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf({} as new (args?: any) => T);
  }

  setUrl(domainRoute: string) {
    this.url = `${domainRoute}?${RoutesUtils.itemId}=${this.id}`;
  }
}
