<div class="extensive-image-wrapper" [ngClass]="{ loadmask: isLoading() }">
  <div class="extensive-image-div" #mainDivEl></div>

  <div class="extensive-image-div-secondary" #secondaryDivEl></div>

  @if (displayLoader() && isLoading()) {
    <dots-loader></dots-loader>
  }

  <img
    class="extensive-image-img"
    #imgEl
    (load)="setImage()"
    (error)="setErrorImage()"
    hidden
  />
</div>
