import { Component } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { RegexUtils } from "app/tools/regex-utils";
import { SimpleFormInfoComponent } from "../../../../components/simple-components/crud/modal/tabs/change/form/form-info/simple-form-info.component";
import { SimpleFormInputComponent } from "../../../../components/simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";
import { SimpleChangeTabComponent } from "../../../../components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { DatexPipe } from "../../../../pipes/datex.pipe";
import { User } from "../../user";

@Component({
  selector: "resend-email-tab",
  templateUrl: "./resend-email-tab.component.html",
  styleUrls: ["./resend-email-tab.component.less"],
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormInputComponent,
    SimpleFormInfoComponent,
    TranslateModule,
    DatexPipe,
  ],
})
export class ResendEmailTabComponent extends ChangeDetailsBaseDirective<User> {
  ngOnInit() {
    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.email]: [
          this.modifiedModel().email,
          [Validators.required, this.emailValidator],
        ],
      }),
    );
    super.ngOnInit();
  }

  private emailValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
      return { email: true };
    } else {
      return null;
    }
  }
}
