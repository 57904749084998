<div class="landing-page apx-scrollbar scroll-shadow">
  <div class="landing-page-header">
    <ng-content select="landing-page-header-slot"></ng-content>
  </div>

  <div
    class="landing-page-content"
    [ngClass]="{
      loadmask: isLoading(),
    }"
  >
    <ng-content select="landing-page-content-slot"></ng-content>
    @if (isLoading()) {
      <dots-loader></dots-loader>
    }
  </div>
</div>
