<simple-view-tab>
  <div class="view-section-layout-wrapper">
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "General" | translate }}
        </div>
        <div class="content">
          <div class="content-value">
            <div class="banner-image">
              <extensive-image
                [imageUrl]="model().thumbnailUrl"
                [displayLoader]="true"
              >
              </extensive-image>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Name" | translate }}
          </div>
          <div class="content-value">
            {{ model().name }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Description" | translate }}
          </div>
          @if (model().description) {
            <div class="content-value">
              {{ model().description }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Adress" | translate }}
          </div>
          @if (model().streetName || model().streetNumber) {
            <div class="content-value-wrapper">
              <div class="content-value">
                {{ model().streetName }}
              </div>
              <div class="content-value">
                {{ model().streetNumber }}
              </div>
            </div>
          }
          @if (model().zipCode || model().city) {
            <div class="content-value-wrapper">
              <div class="content-value">
                {{ model().zipCode }}
              </div>
              <div class="content-value">
                {{ model().city }}
              </div>
            </div>
          }
          @if (
            !model().streetName &&
            !model().streetNumber &&
            !model().zipCode &&
            !model().city
          ) {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "InfoLink" | translate }}
          </div>
          @if (model().infoLink) {
            <div class="content-value">
              <a
                class="link"
                [title]="model().infoLink"
                [href]="model().infoLink"
                target="_blank"
              >
                {{ model().infoLink }}
              </a>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Accessibility" | translate }}
          </div>
          <div class="content-value">
            {{ (model().accessible ? "Yes" : "No") | translate }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Status" | translate }}
          </div>
          <div class="content-value">
            <simple-status-label
              [text]="model().status ? 'Active' : 'Inactive'"
              [title]="model().status ? 'Active' : 'Inactive'"
            >
            </simple-status-label>
          </div>
        </div>
      </div>
    </div>
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "LocationData" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Latitude" | translate }}
          </div>
          @if (model().latitude) {
            <div class="content-value">
              {{ model().latitude }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Longitude" | translate }}
          </div>
          @if (model().longitude) {
            <div class="content-value">
              {{ model().longitude }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Radius" | translate }}
          </div>
          @if (model().radius) {
            <div class="content-value">
              {{ model().radius }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <google-maps-component
          [latitude]="model().latitude"
          [longitude]="model().longitude"
          [radius]="model().radius"
          [name]="model().name"
          [isStatic]="true"
          [height]="100"
        >
        </google-maps-component>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "Other" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "ExtraInfo" | translate }}
          </div>
          @if (model().extraInfo) {
            <div
              class="content-value with-frame"
              [innerHTML]="model().extraInfo | parseMD"
            ></div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        @if (
          globalState.selectedOrganization().hasDfIntegration ||
          globalState.selectedOrganization().hasInfraControlIntegration
        ) {
          <div class="content">
            <div class="content-header">
              {{ "ExternalId" | translate }}
            </div>
            @if (model().externalId) {
              <div class="content-value">
                {{ model().externalId }}
              </div>
            } @else {
              <div class="dummy-text">
                {{ "NotSpecified" | translate }}
              </div>
            }
          </div>
        }
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "AddedEntities" | translate }}
        </div>
        <div class="content">
          @if (model().entities.length) {
            <div class="content-value">
              <simple-view-connections [entities]="model().entities">
              </simple-view-connections>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "AddedMedia" | translate }}
        </div>
        <div class="content">
          @if (model().media.length) {
            <div class="content-value flex">
              @for (item of model().media; track $index) {
                <simple-image [url]="item.url" [size]="52"></simple-image>
              }
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
      <div class="view-section">
        <div class="view-section-header">
          {{ "AppearsIn" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "AssignmentTemplates" | translate }}
          </div>
          @if (model().assignmentTemplates.length) {
            <div class="content-value">
              <simple-view-connections
                [assignmentTemplatesAsLinks]="model().assignmentTemplates"
              >
              </simple-view-connections>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Schedules" | translate }}
          </div>
          @if (model().schedules.length) {
            <div class="content-value">
              <simple-view-connections [schedules]="model().schedules">
              </simple-view-connections>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</simple-view-tab>
