import { NgClass } from "@angular/common";
import { Component, computed, inject, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { LandingPageBaseDirective } from "app/components/simple-components/directives/landing-page-base.directive";
import { TemplateType } from "app/components/template-type/template-type";
import { OrganizationService } from "app/services/organization.service";
import { StringUtils } from "app/tools/string-utils";
import { Popover } from "primeng/popover";
import { ExtensiveImageComponent } from "../../../media/extensive-image/extensive-image.component";
import { SimpleLandingPageComponent } from "../../simple-components/landing-page/simple-landing-page.component";
import { CreateEditOrganizationComponent } from "../create-edit-organization/create-edit-organization.component";
import { ViewDetailsTabComponent } from "../create-edit-organization/tabs/view-details/view-details-tab.component";
import { Client, Organization } from "../organization";
import {
  ExternalIntegration,
  Integration,
  IntegrationTypes,
  SSENReportIntegration,
  SSENReportIntegrationTypes,
} from "./integrations/integration";
import { LogotypeImageComponent } from "./logotype-image/logotype-image.component";

@Component({
  templateUrl: "./my-organization.component.html",
  styleUrls: ["./my-organization.component.less"],
  standalone: true,
  imports: [
    ExtensiveImageComponent,
    TranslateModule,
    ViewDetailsTabComponent,
    SimpleLandingPageComponent,
    LogotypeImageComponent,
    NgClass,
    Popover,
    CreateEditOrganizationComponent,
  ],
})
export class MyOrganizationComponent extends LandingPageBaseDirective<Organization> {
  selectedOrganization = computed(() =>
    this.globalState.selectedOrganization(),
  );
  clients = signal<Client[]>([]);
  templateTypes = signal<TemplateType[]>([]);
  integrations = signal<Integration[]>([]);
  actionsOpened = signal(false);

  ssenIntegration: SSENReportIntegration;
  ssenReportIntegrationTypes = SSENReportIntegrationTypes;
  externalIntegrations: ExternalIntegration[] = [];

  hasSSEN: boolean = false;
  stringUtils = StringUtils;

  visibleCreateEdit = signal(false);
  visibleChangeLogotypeImage = signal(false);

  private organizationService = inject(OrganizationService);

  async ngOnInit() {
    try {
      this.assignCultureSettings();
      if (
        this.globalState.loggedInUser().isSuperAdmin() &&
        this.selectedOrganization().parentId
      ) {
        this.pending.set(true);
        this.selectedOrganization().parentOrganization =
          await this.organizationService.get(
            this.globalState.selectedOrganization().parentId,
          );
        this.pending.set(false);
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }

  private assignCultureSettings() {
    this.selectedOrganization().selectableCountryCodes =
      this.appInitializer.cultureSettings.countryCodes;
    this.selectedOrganization().selectableCountries =
      this.appInitializer.cultureSettings.countries;
    this.selectedOrganization().selectableCultures =
      this.appInitializer.cultureSettings.cultures;
  }

  handleCloseCreateEdit() {
    this.visibleCreateEdit.set(false);
  }

  handleCloseChangeLogotypeImage() {
    this.visibleChangeLogotypeImage.set(false);
  }

  handleRequestDoneCreateEdit(data: Organization[]) {
    this.handleCloseCreateEdit();
    Object.assign(this.globalState.selectedOrganization(), data[0]);
  }

  handleRequestDoneChangeLogotypeImage(data: Organization[]) {
    this.handleCloseChangeLogotypeImage();
    this.globalState.selectedOrganization().logotypeUrl = data[0].logotypeUrl;
  }

  openSSENReportModal(type: SSENReportIntegrationTypes) {
    // if (
    //   this.loggedInUser().canEdit(this.selectedOrganization().friendlyUrl) &&
    //   this.hasSSEN
    // ) {
    //   this.bsModalRef = this.modalService.show(EditSSENReportComponent, {
    //     initialState: {
    //       editModelId: this.selectedOrganization().id,
    //       disableTabIndexUrl: true,
    //       type: type,
    //     },
    //     ...ConfigUtils.MODAL_CONFIG_M_LARGE,
    //     // ...this.closeInterceptorConfig()
    //   });
    //   this.subscribeToCrudModalContent("integrations");
    // }
  }

  openClientModal(client?: Client) {
    // if (this.loggedInUser().canEdit(this.selectedOrganization().friendlyUrl)) {
    //   this.bsModalRef = this.modalService.show(CreateEditClientComponent, {
    //     initialState: {
    //       editModelId: client ? client.id : "",
    //       disableTabIndexUrl: true,
    //     },
    //     ...ConfigUtils.MODAL_CONFIG_LARGE,
    //     // ...this.closeInterceptorConfig()
    //   });
    //   this.subscribeToCrudModalContent("clients");
    // }
  }

  openTemplateTypeModal(templateType: TemplateType) {
    // if (this.loggedInUser().canEdit(this.selectedOrganization().friendlyUrl)) {
    //   this.bsModalRef = this.modalService.show(
    //     CreateEditTemplateTypeDescriptionComponent,
    //     {
    //       initialState: {
    //         editModelId: templateType ? templateType.id : "",
    //         name: templateType.name,
    //         disableTabIndexUrl: true,
    //       },
    //       ...ConfigUtils.MODAL_CONFIG_M_LARGE,
    //       // ...this.closeInterceptorConfig()
    //     },
    //   );
    //   this.subscribeToCrudModalContent("template-types");
    // }
  }

  openIntegrationModal(type: string) {
    // if (this.loggedInUser().isSuperAdmin()) {
    //   if (type === IntegrationTypes.DfRespons) {
    //     const dfRespons = this.externalIntegrations.find(
    //       (integration) => integration.type === IntegrationTypes.DfRespons,
    //     );
    //     this.bsModalRef = this.modalService.show(CreateEditDfResponsComponent, {
    //       initialState: {
    //         editModelId: dfRespons.id,
    //         initialModel: dfRespons,
    //         disableTabIndexUrl: true,
    //       },
    //       ...ConfigUtils.MODAL_CONFIG_LARGE,
    //     });
    //   } else if (type === IntegrationTypes.InfraControl) {
    //     const infraControl = this.externalIntegrations.find(
    //       (integration) => integration.type === IntegrationTypes.InfraControl,
    //     );
    //     this.bsModalRef = this.modalService.show(
    //       CreateEditInfraControlComponent,
    //       {
    //         initialState: {
    //           editModelId: infraControl.id,
    //           initialModel: infraControl,
    //           disableTabIndexUrl: true,
    //         },
    //         ...ConfigUtils.MODAL_CONFIG_LARGE,
    //       },
    //     );
    //   }
    //   this.subscribeToCrudModalContent("integrations");
    // }
  }

  deleteClient(client: Client) {
    // if (this.loggedInUser().canEdit(this.selectedOrganization().friendlyUrl)) {
    //   Swal.fire(
    //     new SwalConfig(this.translationService).getDelete({
    //       confirmButtonText: this.translationService.instant("DeleteClient"),
    //     }),
    //   ).then(async (result) => {
    //     if (result.value) {
    //       try {
    //         this.fetchingClients = true;
    //         const response =
    //           await this.organizationService.deleteClientsWithAffectedData(
    //             client.id,
    //             client.organizationId,
    //           );
    //         this.displaySuccessMessage(response.successMessage);
    //         this.refreshClients();
    //       } catch (errorResponse) {
    //         this.fetchingClients = false;
    //         this.handleErrorResponse(errorResponse);
    //       }
    //     }
    //   });
    // }
  }

  deleteIntegration(type: string) {
    // if (this.loggedInUser().isSuperAdmin()) {
    //   Swal.fire(
    //     new SwalConfig(this.translationService).getDelete({
    //       confirmButtonText:
    //         this.translationService.instant("DeleteIntegration"),
    //     }),
    //   ).then(async (result) => {
    //     if (result.value) {
    //       try {
    //         let integration: ExternalIntegration;
    //         if (type === IntegrationTypes.DfRespons) {
    //           integration = this.externalIntegrations.find(
    //             (i) => i.type === IntegrationTypes.DfRespons,
    //           );
    //         } else if (type === IntegrationTypes.InfraControl) {
    //           integration = this.externalIntegrations.find(
    //             (i) => i.type === IntegrationTypes.InfraControl,
    //           );
    //         }
    //         this.fetchingIntegrations = true;
    //         const response = await this.integrationService.delete(
    //           integration.id,
    //         );
    //         this.displaySuccessMessage(response.successMessage);
    //         this.refreshIntegrations();
    //       } catch (errorResponse) {
    //         this.fetchingIntegrations = false;
    //         this.handleErrorResponse(errorResponse);
    //       }
    //     }
    //   });
    // }
  }

  private async refreshClients() {
    // this.fetchingClients = true;
    // this.clients = await this.organizationService.getClientsInOrganization();
    // this.clients.sortByProperty(new Client({}).propertyStrings.name);
    // this.fetchingClients = false;
  }

  private async refreshTemplateTypes() {
    // this.fetchingTemplateTypes = true;
    // this.templateTypes = await this.assignmentService.getTemplateTypes(
    //   this.selectedOrganization().id,
    // );
    // this.templateTypes = this.templateTypes.filter(
    //   (templateType) => !templateType.isManualMeasure,
    // );
    // this.templateTypes.sortByProperty(
    //   new TemplateType({}).propertyStrings.name,
    // );
    // this.fetchingTemplateTypes = false;
  }

  private async refreshIntegrations() {
    // this.fetchingIntegrations = true;
    // this.integrations = await this.integrationService.getIntegrations(
    //   this.selectedOrganization().id,
    // );
    // this.integrations.sortByProperty(new Integration({}).propertyStrings.name);
    // const ssenIntegration = this.integrations.find(
    //   (integration) => integration.type === IntegrationTypes.SSENReport,
    // );
    // const templateTypes = await this.organizationService.getTemplateTypes(
    //   this.selectedOrganization().id,
    // );
    // const firstSSEN = templateTypes.find(
    //   (tt) => tt.templateBaseType.name === "SS-EN",
    // );
    // this.ssenIntegration = new SSENReportIntegration(ssenIntegration as any);
    // if (firstSSEN) {
    //   this.ssenIntegration.scope = firstSSEN.scope;
    //   this.ssenIntegration.templateBaseTypeId = firstSSEN.templateBaseType.id;
    // }
    // this.hasSSEN = !!firstSSEN;
    // this.externalIntegrations = this.integrations.filter(
    //   (integration) =>
    //     integration.type === IntegrationTypes.DfRespons ||
    //     integration.type === IntegrationTypes.InfraControl,
    // );
  }

  hasDfRespons() {
    const dfResponse = this.externalIntegrations.find(
      (integration) => integration.type === IntegrationTypes.DfRespons,
    );
    if (dfResponse) {
      return !!dfResponse.id;
    } else {
      return false;
    }
  }

  dfResponsIsActive() {
    const dfResponse = this.externalIntegrations.find(
      (integration) => integration.type === IntegrationTypes.DfRespons,
    );
    if (dfResponse) {
      return !!dfResponse.active;
    } else {
      return false;
    }
  }

  hasInfraControl() {
    const infraControl = this.externalIntegrations.find(
      (integration) => integration.type === IntegrationTypes.InfraControl,
    );
    if (infraControl) {
      return !!infraControl.id;
    } else {
      return false;
    }
  }

  infraControlIsActive() {
    const infraControl = this.externalIntegrations.find(
      (integration) => integration.type === IntegrationTypes.InfraControl,
    );
    if (infraControl) {
      return !!infraControl.active;
    } else {
      return false;
    }
  }

  //   async deleteOrganization() {
  //     if (this.loggedInUser().isSuperAdmin()) {
  //       const fetchedData = await this.organizationService.getAffectedData(
  //         this.selectedOrganization().id,
  //       );
  //       const swal = await Swal.fire(
  //         new SwalConfig(this.translationService).getDelete({
  //           html: this.getDeleteText(fetchedData),
  //           input: "checkbox",
  //           inputValue: "1",
  //           inputPlaceholder: this.translationService.instant(
  //             StringUtils.YES_KEEP_USERS,
  //           ),
  //           cancelButtonText: this.translationService.instant("Cancel"),
  //           confirmButtonText: `${this.translationService.instant("DeleteParam", {
  //             param: this.selectedOrganization().orgName,
  //           })}`,
  //           customClass: {
  //             popup: "wider-swal",
  //           },
  //         }),
  //       );

  //       if (swal && swal.isConfirmed) {
  //         const lastChanceSwal = await Swal.fire(
  //           new SwalConfig(this.translationService).getAreYouSure({}),
  //         );

  //         console.log("lastChanceSwal:", lastChanceSwal);

  //         if (lastChanceSwal.isConfirmed) {
  //           try {
  //             const response = await this.organizationService.deleteWithParam(
  //               this.selectedOrganization().id,
  //               swal.value === 1 ? true : false,
  //             );
  //             this.displaySuccessMessage(response.successMessage);

  //             if (this.loggedInUser().organization) {
  //               // this.authService.setSelectedOrganization( // TODO
  //               //   this.loggedInUser().organization
  //               // );
  //               this.router.navigateByUrl(
  //                 `/${this.loggedInUser().organization.friendlyUrl}/${
  //                   RoutesUtils.organization
  //                 }`,
  //                 { replaceUrl: true },
  //               );
  //             } else {
  //               //this.authService.startLogout();
  //             }
  //           } catch (errorResponse) {
  //             this.handleErrorResponse(errorResponse);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   getDeleteText(fetchedData: any) {
  //     let text = this.translationService.instant("DeleteOrganizationStatement");

  //     const orgsToBeRemoved = fetchedData.data.subOrganizationsToBeRemoved;
  //     const allUsers = fetchedData.data.allUsers;
  //     const usersToBeRemoved = fetchedData.data.usersToBeRemoved;
  //     const usersToBeMoved = fetchedData.data.usersToBeMoved;
  //     const membership = new OrganizationMembership(
  //       this.loggedInUser().organizationMemberships.find(
  //         (o) => o.status === StringUtils.ACTIVE,
  //       ),
  //     );

  //     if (orgsToBeRemoved !== 0) {
  //       text +=
  //         "<br><br><i>" +
  //         this.translationService
  //           .instant(StringUtils.SUB_ORGS_WILL_BE_REMOVED)
  //           .formatBold(orgsToBeRemoved) +
  //         "</i>";
  //     }

  //     text +=
  //       "<br><br><i>" +
  //       this.translationService
  //         .instant(StringUtils.MAIN_ORG_WILL_CHANGE_OTHER)
  //         .formatBold(allUsers) +
  //       "</i>";

  //     if (this.currentUserIncluded()) {
  //       text += this.translationService.instant(StringUtils.INCLUDING_YOURSELF);
  //     }
  //     if (usersToBeMoved > 0) {
  //       text +=
  //         "<br><i>" +
  //         this.translationService
  //           .instant(StringUtils.USERS_IN_OTHER_ORGS_REMAIN)
  //           .formatBold(usersToBeMoved) +
  //         "</i>";
  //     }
  //     if (usersToBeRemoved > 0) {
  //       text +=
  //         "<br><i>" +
  //         this.translationService
  //           .instant(StringUtils.USERS_TO_BE_REMOVED)
  //           .formatBold(usersToBeRemoved) +
  //         "</i>";
  //     }

  //     if (!this.shouldMoveCurrentUser()) {
  //       text +=
  //         "<br><br>" +
  //         this.translationService
  //           .instant(StringUtils.NOT_YOUR_MAIN_ORG)
  //           .formatBold(
  //             new Organization(this.loggedInUser().organization).orgName,
  //           );
  //     } else if (this.canMoveCurrentUser() && membership) {
  //       const membershipRole = (
  //         membership.roles.find((o) =>
  //           this.loggedInUser().roles.some((p) => p === o),
  //         ) ?? membership.roles[0]
  //       ).toLowerCase();

  //       text +=
  //         "<br><br>" +
  //         this.translationService
  //           .instant(StringUtils.MAIN_ORG_WILL_CHANGE)
  //           .formatBold(
  //             membershipRole,
  //             new Organization(membership?.organization).name,
  //           );
  //     } else {
  //       text +=
  //         '<br><br><strong class="strong danger">' +
  //         this.translationService.instant(StringUtils.NO_OTHER_MAIN_ORG) +
  //         "</strong>";
  //     }

  //     return text;
  //   }

  //   private currentUserIncluded(): boolean {
  //     return (
  //       this.loggedInUser().organization.id === this.selectedOrganization().id ||
  //       this.loggedInUser().organizationMemberships.some(
  //         (om) => om.organization.id === this.selectedOrganization().id,
  //       )
  //     );
  //   }

  //   private shouldMoveCurrentUser(): boolean {
  //     return (
  //       this.loggedInUser().organization.id === this.selectedOrganization().id
  //     );
  //   }

  //   private canMoveCurrentUser(): boolean {
  //     return (
  //       this.loggedInUser().organization.id === this.selectedOrganization().id &&
  //       this.loggedInUser().organizationMemberships.some(
  //         (om) =>
  //           om.organization.id !== this.selectedOrganization().id &&
  //           om.status === StringUtils.ACTIVE,
  //       )
  //     );
  //   }
}
