<simple-view-tab>
  <div class="view-section-layout-wrapper">
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "General" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "LegalName" | translate }}
          </div>
          <div class="content-value">
            {{ model().name }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "PublicName" | translate }}
          </div>
          @if (model().publicName) {
            <div class="content-value">
              {{ model().publicName }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "OrganizationNumber" | translate }}
          </div>
          <div class="content-value">
            {{ model().organizationNumber }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "EmailAddress" | translate }}
          </div>
          <div class="content-value">
            {{ model().emailAddress }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "PhoneNumber" | translate }}
          </div>
          @if (model().countryCode && model().phoneNumber) {
            <div class="content-value">
              (+{{ model().countryCode }})
              {{ model().phoneNumber }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Adress" | translate }}
          </div>
          @if (model().streetAddress) {
            <div class="content-value">
              {{ model().streetAddress }}
            </div>
          }
          @if (model().zipCode || model().city) {
            <div class="content-value-wrapper">
              <div class="content-value">
                {{ model().zipCode }}
              </div>
              <div class="content-value">
                {{ model().city }}
              </div>
            </div>
          }
          @if (model().country) {
            <div class="content-value">
              {{ appInitializer.getCountryName(model().country) }}
            </div>
          }
          @if (
            !model().streetAddress &&
            !model().zipCode &&
            !model().city &&
            !model().country
          ) {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "OperationsManager" | translate }}
          </div>
          @if (model().operationsManager) {
            <div class="content-value">
              {{ model().operationsManager }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "LanguageOnReports" | translate }}
          </div>
          <div class="content-value">
            {{ appInitializer.getCultureName(model().culture) }}
          </div>
        </div>
      </div>
      @if (globalState.loggedInUser().isSuperAdmin()) {
        <div class="view-section">
          <div class="view-section-header">
            {{ "ForSuperAdmins" | translate }}
          </div>
          <div class="content">
            <div class="content-header">
              {{ "ParentOrganization" | translate }}
            </div>
            @if (model().parentOrganization) {
              <div class="content-value">
                {{ model().parentOrganization.name }}
              </div>
            } @else {
              <div class="dummy-text">
                {{ "NotSpecified" | translate }}
              </div>
            }
          </div>
          <div class="content">
            <div class="content-header">
              {{ "TestOrganization" | translate }}
            </div>
            <div class="content-value">
              {{ (model().isTest ? "Yes" : "No") | translate }}
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</simple-view-tab>

<!-- <div class="wrapper" *ngIf="selectedOrganization"></div>
  <div class="main apx-scrollbar">
    
    <div class="column">
      <div class="section with-margin-bottom">
        <div class="section-header">
          <input
            type="search"
            class="search"
            [ngClass]="{
              disabled: !clients.length,
            }"
            [(ngModel)]="clientSearchValue"
            [title]="'SearchClients' | translate"
            placeholder="{{ 'SearchClients' | translate }}..."
          />
          <button
            type="button"
            *ngIf="loggedInUser() | canEdit: selectedOrganization().friendlyUrl"
            class="btn btn-transparent bi bi-plus-lg"
            [title]="'Create' | translate"
            (click)="openClientModal()"
          ></button>
        </div>
        <div class="section-body">
          <div
            class="clients"
            [ngClass]="{
              loadmask: fetchingClients,
            }"
          >
            <div class="info" *ngIf="clients.length">
              <div class="header-text">
                {{ "Clients" | translate }}
              </div>
            </div>
            <div class="client" *ngFor="let client of getClients()">
              <div class="info">
                <div class="header-text">
                  {{ "General" | translate }}
                </div>
                <div class="text bi bi-globe">
                  {{ client.name }}
                </div>
                <div class="text bi bi-envelope">
                  {{ client.email }}
                </div>
                <div
                  class="phone-wrapper bi bi-telephone"
                  *ngIf="client.number"
                >
                  <div class="country-code" *ngIf="client.countryCode">
                    (+{{ client.countryCode }})
                  </div>
                  <div class="number" *ngIf="client.number">
                    {{ client.number }}
                  </div>
                </div>
                <div class="text bi bi-person" *ngIf="client.operationsManager">
                  {{ client.operationsManager }}
                </div>
              </div>
              <div
                class="actions"
                *ngIf="
                  loggedInUser() | canEdit: selectedOrganization().friendlyUrl
                "
              >
                <button
                  class="btn btn-transparent edit-button bi bi-pencil"
                  [title]="'Open' | translate"
                  (click)="openClientModal(client)"
                ></button>
                <button
                  class="btn btn-transparent delete-button bi bi-trash"
                  [title]="'Delete' | translate"
                  (click)="deleteClient(client)"
                ></button>
              </div>
            </div>
            <div class="dummy-text" *ngIf="!clients.length && !fetchingClients">
              {{ "NoClientsAdded" | translate }}
            </div>
            <dots-loader *ngIf="fetchingClients"></dots-loader>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isSmallScreenSize()">
        <ng-container *ngTemplateOutlet="reportConfigTemplate"> </ng-container>
      </ng-container>
      <div
        class="section with-margin-bottom"
        *ngIf="loggedInUser() | canEdit: selectedOrganization().friendlyUrl"
      >
        <div class="section-body">
          <div
            class="action-buttons"
            [ngClass]="{
              loadmask: fetchingTemplateTypes,
            }"
          >
            <div class="info" *ngIf="templateTypes.length">
              <div class="header-text">
                {{ "TemplateTypes" | translate }}
              </div>
            </div>
            <button
              type="button"
              *ngFor="let templateType of templateTypes"
              class="btn btn-transparent"
              [title]="'Open' | translate"
              (click)="openTemplateTypeModal(templateType)"
            >
              {{ templateType.name }}
              <div class="icon-wrapper">
                <div
                  *ngIf="templateType.assignmentDescription"
                  class="icon bi bi-card-text"
                ></div>
                <div class="icon bi bi-pencil"></div>
              </div>
            </button>
            <div
              class="dummy-text"
              *ngIf="!templateTypes.length && !fetchingTemplateTypes"
            >
              {{ "NoTemplateTypesAdded" | translate }}
            </div>
            <dots-loader *ngIf="fetchingTemplateTypes"></dots-loader>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-body">
          <div
            class="action-buttons"
            [ngClass]="{
              disabled: !(loggedInUser() | isSuperAdmin),
              loadmask: fetchingIntegrations,
            }"
          >
            <div class="info">
              <div class="header-text">
                {{ "Integrations" | translate }}
              </div>
            </div>
            <button
              type="button"
              class="btn btn-transparent"
              [title]="'Open' | translate"
              (click)="openIntegrationModal('DfRespons')"
            >
              {{ "DFRespons" | translate }}
              <div class="icon-wrapper">
                <div
                  class="icon bi"
                  [ngClass]="{
                    'bi-toggle-on': hasDfRespons(),
                    'bi-toggle-off': !hasDfRespons(),
                    active: hasDfRespons() && dfResponsIsActive(),
                    inactive: hasDfRespons() && !dfResponsIsActive(),
                  }"
                ></div>
                <div class="icon bi bi-pencil"></div>
              </div>
            </button>
            <!-- TODO fundera på designstruktur, inte nödvändigt tills vidare -->
<!-- <button type="button" *ngIf="(loggedInUser | isSuperAdmin) && hasDfRespons()"
								class="btn btn-transparent bi-trash"
								[title]="'Delete' | translate"
								(click)="deleteIntegration('DfRespons')">
							</button> -->
<!-- <button
              type="button"
              class="btn btn-transparent"
              [title]="'Open' | translate"
              (click)="openIntegrationModal('InfraControl')"
            >
              {{ "InfraControl" | translate }}
              <div class="icon-wrapper">
                <div
                  class="icon bi"
                  [ngClass]="{
                    'bi-toggle-on': hasInfraControl(),
                    'bi-toggle-off': !hasInfraControl(),
                    active: hasInfraControl() && infraControlIsActive(),
                    inactive: hasInfraControl() && !infraControlIsActive(),
                  }"
                ></div>
                <div class="icon bi bi-pencil"></div>
              </div>
            </button> -->
<!-- TODO fundera på designstruktur, inte nödvändigt tills vidare -->
<!-- <button type="button" *ngIf="(loggedInUser | isSuperAdmin) && hasInfraControl()"
								class="btn btn-transparent bi-trash"
								[title]="'Delete' | translate"
								(click)="deleteIntegration('InfraControl')">
							</button> -->
<!-- <dots-loader *ngIf="fetchingIntegrations"></dots-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <ng-template #reportConfigTemplate>
  <div class="section with-margin-bottom">
    <div class="section-body">
      <div
        class="action-buttons"
        [ngClass]="{
          disabled: !(
            loggedInUser() | canEdit: selectedOrganization().friendlyUrl
          ),
          loadmask: fetchingIntegrations,
        }"
      >
        <div class="info">
          <ng-container *ngIf="hasSSEN">
            <div class="header-text">
              {{ "AnnualSafetyInspection" | translate }} &
              {{ "InstallationInspection" | translate }}
            </div>

            <button
              type="button"
              class="btn btn-transparent"
              [title]="'Open' | translate"
              (click)="
                openSSENReportModal(ssenReportIntegrationTypes.InAccordanceWith)
              "
            >
              {{ "InAccordanceWith" | translate }}
              <div class="icon-wrapper">
                <div
                  *ngIf="ssenIntegration?.inAccordanceWith"
                  class="icon bi bi-card-text"
                ></div>
                <div class="icon bi bi-pencil"></div>
              </div>
            </button>

            <button
              type="button"
              class="btn btn-transparent"
              [title]="'Open' | translate"
              (click)="openSSENReportModal(ssenReportIntegrationTypes.Scope)"
            >
              {{ "Scope" | translate }}
              <div class="icon-wrapper">
                <div
                  *ngIf="ssenIntegration?.scope"
                  class="icon bi bi-card-text"
                ></div>
                <div class="icon bi bi-pencil"></div>
              </div>
            </button>

            <button
              type="button"
              class="btn btn-transparent"
              [title]="'Open' | translate"
              (click)="
                openSSENReportModal(ssenReportIntegrationTypes.RiskAnalysis)
              "
            >
              {{ "RiskAnalysis" | translate }}
              <div class="icon-wrapper">
                <div
                  *ngIf="ssenIntegration?.riskAnalysis"
                  class="icon bi bi-card-text"
                ></div>
                <div class="icon bi bi-pencil"></div>
              </div>
            </button>

            <button
              type="button"
              class="btn btn-transparent"
              [title]="'Open' | translate"
              (click)="
                openSSENReportModal(
                  ssenReportIntegrationTypes.ErrorCodeExplanation
                )
              "
            >
              {{ "ErrorCodeExplanation" | translate }}
              <div class="icon-wrapper">
                <div
                  *ngIf="ssenIntegration?.errorCodeExplanation"
                  class="icon bi bi-card-text"
                ></div>
                <div class="icon bi bi-pencil"></div>
              </div>
            </button>

            <button
              type="button"
              class="btn btn-transparent"
              [title]="'Open' | translate"
              (click)="
                openSSENReportModal(ssenReportIntegrationTypes.SerenoReference)
              "
            >
              {{ "RiseReference" | translate }}
              <div class="icon-wrapper">
                <div
                  *ngIf="ssenIntegration?.serenoReference"
                  class="icon bi bi-card-text"
                ></div>
                <div class="icon bi bi-pencil"></div>
              </div>
            </button>
          </ng-container>
          <div class="dummy-text" *ngIf="!hasSSEN && !fetchingIntegrations">
            {{ "SsenIntegrationInfoText" | translate }}
          </div>
        </div>
        <dots-loader *ngIf="fetchingIntegrations"></dots-loader>
      </div>
    </div>
  </div>
</ng-template> -->
