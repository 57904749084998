import { Component, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Facility } from "app/components/facility/facility";
import { ListFacilityComponent } from "app/components/facility/list-facility/list-facility.component";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-facilities-tab",
  templateUrl: "./handle-facilities-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListFacilityComponent,
    TranslateModule,
  ],
})
export class HandleFacilitiesTabComponent extends HandleItemsBaseDirective<Facility> {
  visibleListFacility = signal<boolean>(false);

  displayOnlyFacilitiesWithPositionDataInList = input<boolean>();
  useSingleSelectionInList = input<boolean>();
  readonly = input<boolean>();

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "AddFacilities",
        "AddFacilities",
        StringUtils.icons.new,
        () => this.visibleListFacility.set(true),
        () => this.readonly(),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableFacilityColumn(
        ["Name"],
        (row) => new FacilityContent(row),
      ),
      new SimpleTableTextColumn(
        ["Description"],
        (row) => new TextContent(row.description),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.status ? StringUtils.ACTIVE : StringUtils.INACTIVE,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
        () => this.readonly(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction(
        "Remove",
        StringUtils.icons.remove,
        (row) => {
          this.itemIds.set([row.id]);
          this.remove();
        },
        () => this.readonly(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Facilities", StringUtils.icons.facility),
    );
  }

  handleCloseListFacility() {
    this.visibleListFacility.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListFacility(items: Facility[]) {
    this.handleCloseListFacility();
    this.items.set(items.map((item) => new Facility(item)));
    this.onItemsChange.emit(this.items());
  }
}
