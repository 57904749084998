import { HttpErrorResponse, HttpResponseBase } from "@angular/common/http";
import { Directive, inject } from "@angular/core";
import { AuthenticationService } from "app/authentication/authentication.service";
import { TokenStorageService } from "app/authentication/token-storage.service";
import { Filter, KeyValuePair } from "app/filter";
import { GlobalStateService } from "app/global-state/global-state.service";
import { ToastrService } from "ngx-toastr";
import { HostedHttpClientService } from "../services/hosted-httpclient.service";
import { TranslationService } from "./translation.service";

@Directive()
export class BaseService<T> {
  protected prefix = "";
  protected useCoreApi = false;

  protected hostedHttpClient = inject<HostedHttpClientService>(
    HostedHttpClientService,
  );
  protected translationService = inject(TranslationService);
  private tokenStorage = inject(TokenStorageService);
  private authService = inject(AuthenticationService);
  private toastrService = inject(ToastrService);
  protected globalState = inject(GlobalStateService);

  constructor(private instance: new (data: any) => T) {}

  async getFilteredIds(filter: Filter): Promise<string[]> {
    try {
      const response = await this.hostedHttpClient.getWithParams(
        `${this.prefix}/ids`,
        filter.toPayloadObject(),
        this.useCoreApi,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getFiltered(filter: Filter): Promise<T[]> {
    try {
      const response = await this.hostedHttpClient.getWithParams(
        `${this.prefix}/filtered`,
        filter.toPayloadObject(),
        this.useCoreApi,
      );
      return this.extractData(response).map(
        (item: T) => new this.instance(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  protected extractData(response: HttpResponseBase) {
    const responseBody = response["body"] as BackendReponse;
    if (responseBody.data) {
      const data = responseBody.data;
      if (data["backgroundJobStarted"]) {
        this.toastrService.info(
          this.translationService.instant("BackgroundJobStartedMessage"),
        );
        if (data["created"]) {
          if (Array.isArray(data["created"])) {
            return data["created"];
          } else {
            return [data["created"]]; // TODO is this correct with an array?
          }
        } else {
          return null;
        }
      } else {
        if (data["file"]) {
          return data["file"];
        } else {
          return data;
        }
      }
    } else {
      return null;
    }
  }

  protected extractError(response: HttpErrorResponse) {
    const responseBody = response["error"] as BackendErrorReponse;
    if (response.status === 401) {
      if (this.tokenStorage.getAccessData()) {
        this.refreshLogin();
        return new ErrorObject(
          this.translationService.instant(
            "TODO Meddelande att kommer loggas in på nytt!",
          ),
          [],
        );
      } else {
        window.location.reload();
        return new ErrorObject(
          this.translationService.instant(
            "TODO Meddelande att behöver logga in på nytt!",
          ),
          [],
        );
      }
    } else {
      if (responseBody.errors) {
        const errors = Object.entries(responseBody.errors).map(
          (entry) => new KeyValuePair(entry[0], entry[1]),
        );
        const errorMessage = errors.map((error) => error.value).join("\n");
        return new ErrorObject(errorMessage, errors);
      } else {
        return new ErrorObject(
          this.translationService.instant("TODO Meddelande okänt fel"),
          [],
        );
      }
    }
  }

  private async refreshLogin() {
    await this.authService.refreshLogin();
    window.location.reload();
  }
}

// Success from backend.
export class BackendReponse {
  data: unknown;
  successMessage: string;
}

// No success from backend.
export class BackendErrorReponse {
  errors: unknown;
}

// Error object sent back to the calling component.
export class ErrorObject {
  message: string;
  errors: KeyValuePair[] = [];

  constructor(message: string, errors: KeyValuePair[]) {
    this.message = message;
    this.errors = errors;
  }
}
