import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { KeyValuePair } from "app/filter";

@Component({
    selector: "simple-form-error",
    templateUrl: "./simple-form-error.component.html",
    styleUrls: ["./simple-form-error.component.less"],
    standalone: true,
    imports: [NgIf, NgFor, TranslateModule],
})
export class SimpleFormErrorComponent {
    @Input() name: string;
    @Input() form: FormGroup;
    @Input() serverErrors: KeyValuePair[] = [];

    shouldDisplayErrors() {
        const formControl = this.getFormControl();
        return (
            formControl.invalid && (formControl.dirty || formControl.touched)
        );
    }

    isSpecificError(errorType: string) {
        const formControl = this.getFormControl();
        return formControl.errors?.[errorType];
    }

    getFormControl() {
        return this.form.get(this.name);
    }

    getServerErrorMessages() {
        return this.serverErrors.filter((error) => error.key === this.name);
    }
}
