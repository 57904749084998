import { Component, ElementRef, input, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SanitizeHTMLPipe } from "../../../../../pipes/sanitize-html.pipe";
import {
  NotificationMessage,
  NotificationMessageType,
} from "../../notification-message";

@Component({
  selector: "notification-message-details",
  templateUrl: "./notification-message-details.component.html",
  styleUrls: ["./notification-message-details.component.less"],
  standalone: true,
  imports: [TranslateModule, SanitizeHTMLPipe],
})
export class NotificationMessageDetailsComponent {
  private xAxis: number;
  private yAxis: number;
  private mouseMoveAreaEl: HTMLElement;

  notificationMessage = input<NotificationMessage>();

  @ViewChild("iconWrapperRef") iconWrapperRef: ElementRef;

  get iconWrapperEl(): HTMLElement {
    return this.iconWrapperRef.nativeElement;
  }

  initiateFlyingRocket() {
    const elemBoundingRect = this.iconWrapperEl.getBoundingClientRect();
    this.xAxis = elemBoundingRect.left + elemBoundingRect.width / 2;
    this.yAxis = elemBoundingRect.top + elemBoundingRect.height / 2;

    this.mouseMoveAreaEl = document.body.querySelector(".p-dialog-mask");
    if (this.mouseMoveAreaEl) {
      this.mouseMoveAreaEl.addEventListener(
        "mousemove",
        (event: MouseEvent) => {
          this.changeRotation(event);
        },
      );
    }
  }

  private changeRotation(event: MouseEvent) {
    const angle =
      Math.atan2(event.pageX - this.xAxis, -(event.pageY - this.yAxis)) *
      (180 / Math.PI);
    this.iconWrapperEl.style.transform = `rotate(${angle}deg)`;
  }

  isReleaseType() {
    return this.notificationMessage().type === NotificationMessageType.Release;
  }

  navigateToPath(path: string) {
    window.open(path, "_blank");
  }

  ngOnDestroy() {
    this.mouseMoveAreaEl?.removeEventListener("mousemove", this.changeRotation);
  }
}
