import { Component, input, model } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Drawer } from "primeng/drawer";
import { ParseMDPipe } from "../../../../pipes/parse-md-pipe";

@Component({
  selector: "simple-view-content",
  templateUrl: "./simple-view-content.component.html",
  styleUrls: ["./simple-view-content.component.less"],
  standalone: true,
  imports: [TranslateModule, Drawer, ParseMDPipe],
})
export class SimpleViewContentComponent {
  visible = model<boolean>(false);

  headerText = input<string>();
  content = input<string>();
}
