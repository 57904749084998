import { StringUtils } from "app/tools/string-utils";
import { Report } from "../report";

export enum GeneratedReportTypes {
  Summary = "Summary",
  Error = "Error",
}

export class GeneratedReport extends Report {
  header: string;
  reportFileFormat: string = StringUtils.PDF;
  reportType: GeneratedReportTypes;
  sort: {};
  status: string;

  constructor(report: Partial<GeneratedReport>) {
    super(report);
    Object.assign(this, report);
  }
}
