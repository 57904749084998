import { Component, input } from "@angular/core";

@Component({
  selector: "simple-tab-heading",
  templateUrl: "./simple-tab-heading.component.html",
  standalone: true,
})
export class SimpleTabHeadingComponent {
  icon = input<string>();
  title = input<string>();
  helpText = input<string>();
  displayCount = input<number>();
}
