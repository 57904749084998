<simple-change-tab [form]="form()">
  <div class="change-section-layout-wrapper">
    <div class="change-section-layout">
      <div class="change-section">
        <div class="change-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Name' | translate"
          [name]="propertyStrings.value"
          [form]="form()"
          [maxlength]="3"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!modifiedModel().id"
        >
        </simple-form-input>

        <simple-form-color
          [label]="'Color' | translate"
          [name]="propertyStrings.color"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-color>
      </div>

      <div class="change-section">
        <div class="change-section-header">
          {{ "Rules" | translate }}
        </div>
        <simple-form-checkbox
          [label]="'IsError' | translate"
          [name]="propertyStrings.isError"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-checkbox>

        <simple-form-checkbox
          [label]="'IsDefault' | translate"
          [name]="propertyStrings.isDefault"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-checkbox>

        <simple-form-checkbox
          [label]="'IsUnspecified' | translate"
          [name]="propertyStrings.isUnspecified"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-checkbox>

        <simple-form-checkbox
          [label]="'AllowsPriority' | translate"
          [name]="propertyStrings.allowPriority"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-checkbox>
      </div>
    </div>
  </div>
</simple-change-tab>
