import { LowerCasePipe, NgClass } from "@angular/common";
import { Component, computed, input } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DatePicker } from "primeng/datepicker";
import { SimpleFormErrorComponent } from "../form-error/simple-form-error.component";
import { SimpleFormControlDirective } from "../simple-form-control.directive";

@Component({
  selector: "simple-form-calendar",
  templateUrl: "./simple-form-calendar.component.html",
  styleUrls: ["./simple-form-calendar.component.less"],
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    DatePicker,
    SimpleFormErrorComponent,
    LowerCasePipe,
    ReactiveFormsModule,
  ],
})
export class SimpleFormCalendarComponent extends SimpleFormControlDirective {
  minDate = input<string>();
  maxDate = input<string>();

  minDateAsDate = computed(() => {
    return this.minDate() ? new Date(this.minDate()) : null;
  });

  maxDateAsDate = computed(() => {
    return this.maxDate() ? new Date(this.maxDate()) : null;
  });
}
