<div
  class="status-label"
  [ngClass]="{
    adjusted: text() === stringUtils.NOT_STARTED,
  }"
  title="{{ text() | translate }}"
  [style.backgroundColor]="getStatusColor()"
>
  {{ text() | translate }}
</div>
