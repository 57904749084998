<div
  class="notification-messages-actions-menu"
  [ngClass]="{
    opened: popoverOpened(),
  }"
  [title]="'Messages' | translate"
  (click)="!fetching() ? messagesTemplate.toggle($event) : null"
>
  @if (!fetching()) {
    <i
      class="bi bi-bell"
      [ngClass]="{
        animated: hasUnreadNotificationMessages(),
      }"
    ></i>
  }
  @if (fetching()) {
    <i class="bi bi-arrow-repeat"></i>
  }
  @if (hasUnreadNotificationMessages() && !fetching()) {
    <span class="dot">
      <span class="dot-text">{{ getUnreadCount() }}</span>
    </span>
  }
</div>
<p-popover
  #messagesTemplate
  (onShow)="popoverOpened.set(true)"
  (onHide)="popoverOpened.set(false)"
>
  @for (notificationMessage of notificationMessages(); track $index) {
    <div
      class="notification-messages-menu-item"
      [ngClass]="{
        opened: notificationMessage.hasBeenOpened,
      }"
      [title]="notificationMessage.title"
      (click)="
        handleMessageClick(notificationMessage); messagesTemplate.toggle($event)
      "
    >
      <div class="notification-message-wrapper">
        <div class="icon-title-wrapper">
          <div
            class="notification-message-icon {{ notificationMessage.icon }} {{
              notificationMessage.type
            }}"
          ></div>
          <div class="notification-message-title">
            {{ notificationMessage.title }}
          </div>
        </div>
        <div class="date">
          {{ notificationMessage.startDate }}
        </div>
      </div>
    </div>
  }
  @if (!notificationMessages().length) {
    <div class="notification-messages-menu-item dummy">
      <div class="notification-message-wrapper">
        <div class="icon-title-wrapper">
          <div class="notification-message-icon bi-info-circle"></div>
          <div class="notification-message-title">
            {{ "NoMessages" | translate }}
          </div>
        </div>
      </div>
    </div>
  }
</p-popover>

<notification-message-modal
  [visible]="visibleNotificationMessageModal()"
  [notificationMessage]="currentMessage()"
  (onClose)="handleCloseNotificationMessageModal()"
>
</notification-message-modal>
