import { Directive, inject, signal } from "@angular/core";
import { AppInitializer } from "app/app-initializer";
import { GlobalStateService } from "app/global-state/global-state.service";
import { LocalStorageService } from "app/services/local-storage.service";
import { TranslationService } from "app/services/translation.service";
import { StringUtils } from "app/tools/string-utils";
import { ToastrService } from "ngx-toastr";

@Directive()
export abstract class LandingPageBaseDirective<T> {
  icons = StringUtils.icons;
  pending = signal<boolean>(false);

  protected globalState = inject(GlobalStateService);
  protected translationService = inject(TranslationService);
  protected localStorageService = inject(LocalStorageService);
  protected toastrService = inject(ToastrService);
  protected appInitializer = inject(AppInitializer);
}
