import { Component, input } from "@angular/core";
import { Task } from "app/components/task/task";

@Component({
  selector: "simple-task",
  templateUrl: "./simple-task.component.html",
  styleUrls: ["./simple-task.component.less"],
  standalone: true,
})
export class SimpleTaskComponent {
  task = input<Task>();
}
