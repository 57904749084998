import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Choice } from "app/components/choice/choice";
import { Entity } from "app/components/entity/entity";
import { Facility } from "app/components/facility/facility";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentService } from "app/services/assignment.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import Swal from "sweetalert2";
import { SimpleCrudModalComponent } from "../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ManualMeasureAssignment } from "../manual-measure-assignment";

@Component({
  selector: "create-manual-measure",
  templateUrl: "./create-manual-measure.component.html",
  standalone: true,
  imports: [SimpleCrudModalComponent, TranslateModule],
})
export class CreateManualMeasureComponent extends CreateEditBaseDirective<ManualMeasureAssignment> {
  // handleFacilityTabComponent = viewChild(HandleFacilityTabComponent);
  // handleEntitiesTabMeasureComponent = viewChild(
  //   HandleEntitiesTabMeasureComponent
  // );
  // handleSubscribersTabComponent = viewChild(HandleSubscribersTabComponent);

  private assignmentService = inject(AssignmentService);

  async initiate() {
    try {
      this.pending.set(true);
      const templateTypes = await this.assignmentService.getTemplateTypes(
        this.globalState.selectedOrganization().id,
      );
      const manualMeasureType = templateTypes.find((tt) => tt.isManualMeasure);
      const facilities = await this.assignmentService.getFacilities();

      // Abort if no manualMeasure template type or facilities.
      if (!manualMeasureType || !facilities.length) {
        this.toastrService.error(
          `${this.translationService.instant(
            StringUtils.NO_MANUAL_MEASURE_TEMPLATE_TYPE_ERROR,
          )}!`,
        );
        this.handleClose();
        return;
      }

      this.pending.set(false);

      this.initialModel.set(new ManualMeasureAssignment({}));
      const choices = manualMeasureType.taskChoices[0].task.choices;
      const errorChoice = choices.find((choice) => choice.isError);
      this.initialModel().choice = errorChoice
        ? new Choice(errorChoice)
        : new Choice(choices[0]);
      this.modifiedModel.set(
        new ManualMeasureAssignment(
          Utils.getUniqueVariant(this.initialModel()),
        ),
      );
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  everythingIsValid() {
    return true;
    // return (
    //   ((this.modifiedModel() &&
    //     this.modifiedModel().entities.length === 0 &&
    //     this.handleFacilityIsValid()) ||
    //     (this.modifiedModel() &&
    //       this.modifiedModel().entities.length > 0 &&
    //       this.handleEntitiesIsValid())) &&
    //   this.handleSubscribersIsValid()
    // );
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    if (
      !this.modifiedModel().assigneeGroups.length &&
      !this.modifiedModel().assignees.length
    ) {
      return Swal.fire(
        new SwalConfig(this.translationService).getInfo({
          text: this.translationService.instant(
            "ManualMeasureIsEmptyDescription",
          ),
          confirmButtonText: this.translationService.instant("IUnderstand"),
          cancelButtonText: this.translationService.instant("Cancel"),
        }),
      ).then(async (result) => {
        if (result.value) {
          this.performCreateOrUpdate();
        }
      });
    } else {
      this.performCreateOrUpdate();
    }
  }

  async performCreateOrUpdate() {
    this.pending.set(true);
    try {
      const data = await this.assignmentService.createManualMeasure(
        this.modifiedModel().toPayloadObject(
          this.globalState.selectedOrganization().id,
        ),
      );
      this.toastrService.success(
        this.translationService.instant("TheManualMeasureWasCreated"),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      // TODO this.changeDetailsTabComponent().setServerErrors(error.errors);
    }
  }

  protected triggerAllValidation() {
    if (this.modifiedModel().entities.length) {
      //this.handleEntitiesTabMeasureComponent()?.triggerValidation();
    } else {
      this.validateFacility();
    }
    //this.handleSubscribersTabComponent()?.triggerValidation();
  }

  setFacility(facilities: Facility[]) {
    this.modifiedModel().facility = facilities.length ? facilities[0] : null;
    this.clearEntities();
  }

  assignFacility(facilities: Facility[]) {
    Object.assign(this.modifiedModel().facility, facilities[0]);

    if (this.modifiedModel().entities.length) {
      this.clearFacilityError();
    } else {
      this.validateFacility();
    }
  }

  handleOnUpdatedEntities() {
    if (this.modifiedModel().entities.length) {
      this.clearFacilityError();
    } else {
      this.validateFacility();
    }
  }

  handleEntityChange(entities: Entity[]) {
    this.modifiedModel().entities = entities;

    if (this.modifiedModel().entities.length) {
      this.clearFacilityError();
    } else {
      this.validateFacility();
    }
  }

  clearFacilityError() {
    if (this.modifiedModel().facility) {
      this.modifiedModel().facility.errorMessages = [];
    }
  }

  validateFacility() {
    this.clearFacilityError();
    if (
      this.modifiedModel().facility &&
      !this.modifiedModel().facility.issueDetails
    ) {
      this.modifiedModel().facility.errorMessages.push(
        "IssueDetailsMustBeAddedFacility",
      );
    }
    if (!this.modifiedModel().facility) {
      // this.handleFacilityTabComponent().triggerValidation();
    }
  }

  clearEntities() {
    this.modifiedModel().entities = [];
  }

  // handleFacilityIsValid() {
  //   return (
  //     this.handleFacilityTabComponent?.itemListIsValid() ||
  //     !!this.modifiedModel().entities.length
  //   );
  // }

  // handleFacilityHasErrors() {
  //   return this.handleFacilityTabComponent()?.itemListHasErrors();
  // }

  // handleEntitiesIsValid() {
  //   return this.handleEntitiesTabMeasureComponent()?.itemListIsValid();
  // }

  // handleEntitiesHasErrors() {
  //   return this.handleEntitiesTabMeasureComponent()?.itemListHasErrors();
  // }

  // handleSubscribersIsValid() {
  //   return this.handleSubscribersTabComponent()?.itemListIsValid();
  // }

  // handleSubscribersHasErrors() {
  //   return this.handleSubscribersTabComponent()?.itemListHasErrors();
  // }
}
