import { KeyValuePair } from "app/filter";
import { SimpleFilterInputItem } from "./simple-filter-input-item";

export enum SimpleFilterInputType {
  MultiSelect = "MultiSelect",
  SingleSelect = "SingleSelect",
  MultiSelectWithSearch = "MultiSelectWithSearch",
  SingleSelectWithSearch = "SingleSelectWithSearch",
  ListModal = "ListModal",
  DateSelect = "DateSelect",
  DateRangeSelect = "DateRangeSelect",
}

export class SimpleFilterInput {
  id: string;
  label: string;
  iconName: string;
  type: SimpleFilterInputType;
  transformValues: (values: string) => KeyValuePair[];
  items: SimpleFilterInputItem[] = [];
  hidden: () => boolean;

  selectedItems: SimpleFilterInputItem[] = [];
  selectedItem: SimpleFilterInputItem;

  selectedYear: string;
  selectedMonth: string;
  selectedDay: string;

  selectedYear2: string;
  selectedMonth2: string;
  selectedDay2: string;

  constructor(
    id: string,
    label: string,
    iconName: string,
    type: SimpleFilterInputType,
    transformValues: (values: string) => KeyValuePair[],
    items?: SimpleFilterInputItem[],
    hidden?: () => boolean,
  ) {
    this.id = id;
    this.label = label;
    this.iconName = iconName;
    this.type = type;
    this.transformValues = transformValues;
    this.items = items ? items : [];
    this.hidden = hidden ? hidden : () => false;
  }
}
