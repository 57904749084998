import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { SimpleChangeImageComponent } from "app/components/simple-components/various/change-image/simple-change-image.component";
import { User } from "app/components/user/user";
import { PostImage } from "app/post-image";
import { UserService } from "app/services/user.service";
import { Utils } from "app/tools/utils";
import { SimpleCrudModalComponent } from "../../../../components/simple-components/crud/modal/simple-crud-modal.component";

@Component({
  selector: "change-profile-image",
  templateUrl: "./profile-image.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleChangeImageComponent,
    TranslateModule,
  ],
})
export class ProfileImageComponent extends CreateEditBaseDirective<User> {
  changeImageComponent = viewChild(SimpleChangeImageComponent);

  private userService = inject(UserService);

  async initiate() {
    this.pending.set(false);
    this.initialModel.set(this.globalState.loggedInUser());
    this.modifiedModel.set(
      new User(Utils.getUniqueVariant(this.initialModel())),
    );
  }

  async createOrUpdate() {
    this.pending.set(true);
    try {
      let image: PostImage;
      if (
        !this.modifiedModel().profileImageUrl &&
        !this.changeImageComponent().uploadedFile()
      ) {
        image = new PostImage("", new Blob());
      } else {
        image = new PostImage(
          this.changeImageComponent().uploadedFile().name,
          this.changeImageComponent().croppedImage(),
        );
      }
      const data = await this.userService.updateProfileImage(
        this.modifiedModel().id,
        image,
      );
      this.modifiedModel().profileImageUrl = data;
      this.toastrService.success(
        this.translationService.instant("TheUserWasUpdated"),
      );
      this.onRequestDone.emit([this.modifiedModel()]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }
  // TODO Special case?!
  // hasUnsavedChanges() {
  //   return (
  //     this.changeImageComponent &&
  //     (this.changeImageComponent().imageUrl !==
  //       this.changeImageComponent().initialImageUrl ||
  //       !!this.changeImageComponent().uploadedFile)
  //   );
  // }
}
