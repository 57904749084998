import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { firstValueFrom } from "rxjs";
import { TokenStorageService } from "./token-storage.service";

export type AccessData = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  expiring_date: number;
};

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  isRefreshing = false;
  private httpClient = inject(HttpClient);
  private tokenStorage = inject(TokenStorageService);

  async loginWithPassword(email: string, password: string) {
    try {
      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded;",
      );
      const body = `grant_type=password&username=${email}&password=${password}&client_id=${environment.clientId}&client_secret=${environment.secret}`;
      const response = await firstValueFrom(
        this.httpClient.post<AccessData>(
          `${environment.idServer}/connect/token`,
          body,
          { headers: headers },
        ),
      );
      return response;
    } catch {
      throw new Error("Fel användarnamn eller lösenord");
    }
  }

  async loginWithCode(email: string, code: string) {
    try {
      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded;",
      );
      const body = `grant_type=single_time_code&username=${email}&code=${code}&client_id=${environment.clientId}&client_secret=${environment.secret}`;
      const response = await firstValueFrom(
        this.httpClient.post<AccessData>(
          `${environment.idServer}/connect/token`,
          body,
          { headers: headers },
        ),
      );
      return response;
    } catch {
      throw new Error("Fel användarnamn eller kod");
    }
  }

  async requestCode(email: string) {
    try {
      const headers = new HttpHeaders().set("Content-Type", "application/json");
      const body = `username=${email}&client_id=${environment.clientId}&client_secret=${environment.secret}`;
      const response = await firstValueFrom(
        this.httpClient.post<AccessData>(
          `${environment.idServer}/api/singletimecode/requestCode`,
          body,
          { headers: headers },
        ),
      );
      return response;
    } catch {
      throw new Error("Kunde inte hämta kod");
    }
  }

  async refreshLogin() {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      try {
        const headers = new HttpHeaders().set(
          "Content-Type",
          "application/x-www-form-urlencoded;",
        );
        const body = `grant_type=refresh_token&refresh_token=${this.tokenStorage.getAccessData().refresh_token}&client_id=${environment.clientId}&client_secret=${environment.secret}`;
        const accessData = await firstValueFrom(
          this.httpClient.post<AccessData>(
            `${environment.idServer}/connect/token`,
            body,
            { headers: headers },
          ),
        );
        this.tokenStorage.setAccessData(accessData);
      } catch {
        this.tokenStorage.clearAccessData();
        window.location.reload();
      }
    }
  }
}
