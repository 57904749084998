<div class="form-checkbox" [formGroup]="form()">
  <div
    class="checkbox-wrapper"
    [ngClass]="{
      disabled: disabled(),
    }"
  >
    <input
      [id]="name()"
      [formControlName]="name()"
      [ngClass]="{
        'has-errors': hasErrors(),
      }"
      [disabled]="disabled()"
      (focus)="focused.set(true)"
      (blur)="focused.set(false)"
      type="checkbox"
    />
    <span class="checkmark"></span>
    <label
      [for]="name()"
      [ngClass]="{
        focused: focused(),
        'has-errors': hasErrors(),
      }"
    >
      {{ label() }}
    </label>
  </div>
  <simple-form-error
    [name]="name()"
    [form]="form()"
    [serverErrors]="serverErrors()"
  >
  </simple-form-error>
</div>
