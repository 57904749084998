import { Injectable } from "@angular/core";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { BaseService } from "app/services/base.service";
import { NumberUtils } from "app/tools/number-utils";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Entity, EntityPayload } from "../components/entity/entity";
import { Facility } from "../components/facility/facility";
import { Filter, KeyValuePair, SortDirection } from "../filter";
import { Utils } from "../tools/utils";

@Injectable({
  providedIn: "root",
})
export class EntityService extends BaseService<Entity> {
  prefix = RoutesUtils.entity;

  constructor() {
    super(Entity);
  }

  async get(id: string): Promise<Entity> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}/${id}`);
      return new Entity(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: EntityPayload): Promise<Entity> {
    try {
      const response = await this.hostedHttpClient.post(`${this.prefix}`, item);
      return new Entity(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: EntityPayload): Promise<Entity> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
      );
      return new Entity(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.delete(
        `${this.prefix}/${id}`,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(itemIds: string[]): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        { itemIds: itemIds },
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getFacilities(): Promise<Facility[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<Facility>("name")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `facility/filteredac`,
        filter.toPayloadObject(),
      );
      return this.extractData(response).map(
        (item: Facility) => new Facility(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateStatus(itemIds: string[], active: boolean): Promise<Entity[]> {
    try {
      const model = {
        itemIds: itemIds,
        active: active,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/statuses`,
        model,
      );
      return this.extractData(response).map((item: Entity) => new Entity(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateAccessibility(
    itemIds: string[],
    accessible: boolean,
  ): Promise<Entity[]> {
    try {
      const model = {
        itemIds: itemIds,
        accessible: accessible,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/accessible`,
        model,
      );
      return this.extractData(response).map((item: Entity) => new Entity(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async uploadMedia(parentId: string, mediaItem: MediaWidgetItem) {
    try {
      const formData = new FormData();
      formData.append("file", mediaItem.blob);
      formData.append("isDefault", mediaItem.default ? "true" : "false");

      // const response = await this.hostedHttpClient.upload(
      //   `${this.prefix}/${parentId}/media/upload`,
      //   formData,
      // );
      return this.extractData({} as any);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async assignMedia(parentId: string, mediaList: MediaWidgetItem[]) {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${parentId}/media`,
        {
          media: mediaList,
        },
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updateAssignmentTemplates(entityObjects: object[]) {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/assignmenttemplates`,
        entityObjects,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class EntityFilter extends Filter {
  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.HAS_FACILITY_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_FACILITY_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_FACILITY_KEY] = "false";
        }
      } else if (facet.key === StringUtils.LOCATION_DATA_KEY) {
        const coordinates = facet.value;
        if (coordinates === StringUtils.YES) {
          tempFacets[StringUtils.HAS_POSITION_KEY] = "true";
        } else if (coordinates === StringUtils.NO) {
          tempFacets[StringUtils.HAS_POSITION_KEY] = "false";
        }
      } else if (facet.key === StringUtils.HAS_POSITION_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.FACILITIES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.FACILITY_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.FACILITY_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.STATUS_KEY) {
        const status = facet.value;
        if (status === StringUtils.ACTIVE) {
          tempFacets[StringUtils.STATUS_KEY] = "true";
        } else if (status === StringUtils.INACTIVE) {
          tempFacets[StringUtils.STATUS_KEY] = "false";
        }
      } else if (facet.key === StringUtils.ACCESSIBILITY_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.ACCESSIBLE_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.ACCESSIBLE_KEY] = "false";
        }
      } else if (facet.key === StringUtils.ACCESSIBLE_KEY) {
        delete this.facets[facet.key];
      }
    });

    Object.assign(this.facets, tempFacets);

    return {
      filter: JSON.stringify(this),
    };
  }
}
