import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { SimpleLinkButtonComponent } from '../../../../../../various/link-button/simple-link-button.component';

@Component({
    selector: 'simple-form-info',
    templateUrl: './simple-form-info.component.html',
    styleUrls: ['./simple-form-info.component.less'],
    standalone: true,
    imports: [NgIf, SimpleLinkButtonComponent]
})
export class SimpleFormInfoComponent {
	@Input() firstText: string;
	@Input() linkText: string;
	@Input() linkHref: string;
	@Input() secondText: string;
	@Input() name: string;
}
