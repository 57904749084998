import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StringUtils } from "../tools/string-utils";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  constructor(private translateService: TranslateService) {}

  get currentLang() {
    return this.translateService.currentLang;
  }

  instant(key: string | Array<string>, interpolateParams?: object) {
    return this.translateService.instant(key, interpolateParams);
  }

  use(lang: string) {
    return this.translateService.use(lang);
  }

  addLangs(langs: Array<string>) {
    this.translateService.addLangs(langs);
  }

  setDefaultLang(lang: string) {
    return this.translateService.setDefaultLang(lang);
  }

  translate(key: string, ending?: string): string {
    let translated = this.instant(key);
    if (ending !== undefined) {
      translated += ending;
    }
    return translated;
  }

  get(key: string) {
    return this.translateService.get(key);
  }

  getTranslatedHelpText() {
    return {
      general: {
        followingNeedsToBeFulfilled: this.translate(
          StringUtils.FOLLOWING_NEEDS_TO_BE_FULFILLED,
          ":",
        ),
      },
      email: {
        firstPartCanNotContainSpecialCharacters:
          this.translate(
            StringUtils.FIRST_PART_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS_OTHER_THAN,
            "",
          ) + ' "._%+-"',
        mustContainA: this.translate(StringUtils.MUST_CONTAIN_A, "") + ' "@"',
        domainCanNotContainSpecialCharacters:
          this.translate(
            StringUtils.DOMAIN_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS_OTHER_THAN,
            "",
          ) + ' ".-"',
        lastPartMustBeBetween: this.translate(
          StringUtils.LAST_PART_MUST_BE_BETWEEN_2_AND_4_CHARACTERS,
          "",
        ),
      },
      phone: {
        noAlphabeticalCharacters: this.translate(
          StringUtils.NO_ALPHABETICAL_CHARACTERS,
          "",
        ),
        between2And15Characters: this.translate(
          StringUtils.BETWEEN_2_AND_15_CHARACTERS,
          "",
        ),
        invalidForRegion: this.translate(StringUtils.INVALID_PHONE_FOR_REGION),
        invalidPhone: this.translate(StringUtils.INVALID_PHONE),
      },
      password: {
        aValidPasswordNeedsAtLeast: this.translate(
          StringUtils.A_VALID_PASSWORD_NEEDS_AT_LEAST,
          ":",
        ),
        sixCharacters: this.translate(StringUtils.SIX_CHARACTERS, ""),
        oneDigit: this.translate(StringUtils.ONE_DIGIT, ""),
        oneLowercase: this.translate(StringUtils.ONE_LOWERCASE, ""),
        oneUppercase: this.translate(StringUtils.ONE_UPPERCASE, ""),
        oneNonAlphanumerical:
          this.translate(StringUtils.ONE_NON_ALPHANUMERICAL, "") +
          ' "$@$!%*?&^."',
      },
      zipCode: {
        first: this.translate(StringUtils.ZIPCODE_FIRST, "") + ' "99 19 99".',
        second: this.translate(StringUtils.ZIPCODE_SECOND, "") + ' "123 45".',
        third: this.translate(StringUtils.ZIPCODE_FORMAT, "") + ': "GIR 2AA".',
        fourth:
          this.translate(StringUtils.ZIPCODE_FORMAT, "") +
          ': "A22 2AA" or "A2A 2AA".',
        fifth:
          this.translate(StringUtils.ZIPCODE_FORMAT, "") +
          ': "AA22 2AA", "AA2A 2AA", "AA2 2AA".',
      },
      organizationNumber: {
        title: this.translate(StringUtils.ORGANIZATION_NUMBER_TITLE, ""),
        limit: this.translate(StringUtils.ORGANIZATION_NUMBER_LENGTH, ""),
      },
      vat: {
        title: this.translate(StringUtils.ONE_OF_THE_FOLOWING, ""),
        se: this.translate(StringUtils.VAT_SE, ""),
        gb: this.translate(StringUtils.VAT_GB, ""),
        no: this.translate(StringUtils.VAT_NO, ""),
        co: this.translate(StringUtils.VAT_CO, ""),
      },
      customerNumber: {
        title: this.translate(StringUtils.INVALID_VALUE, ""),
        digitsOnly: this.translate(StringUtils.ONLY_DIGITS, ""),
      },
    };
  }

  setLanguageFromUrl(culture: string) {
    if (culture) {
      let language = culture.substring(0, 2);
      language = language === "nb" ? "no" : language;
      this.translateService.use(language);
    }
  }

  translateArray(array: string[]) {
    const translated: Array<string> = [];
    for (let i = 0; i < array.length; i++) {
      translated.push(this.translate(array[i]));
    }
    return translated;
  }
}

export class InputTranslation {
  clear: string;
  search: string;
  required: string;
  tooLong: string;
  invalidFormat: string;

  constructor(translationService: TranslationService) {
    this.clear = translationService.instant("Clear");
    this.search = translationService.instant("Search");
    this.required = translationService.instant("Required");
    this.tooLong = translationService.instant("Too long");
    this.invalidFormat = translationService.instant("Invalid format");
  }
}
