<simple-change-tab [form]="form()">
  <div class="change-section-layout-wrapper">
    <div class="change-section-layout">
      <div class="change-section">
        <div class="change-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-select
          [label]="'TemplateType' | translate"
          [name]="propertyStrings.templateTypeId"
          [form]="form()"
          [disabled]="isEdit"
          [items]="selectableTemplateTypeItems()"
          [searchable]="true"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>

        <simple-form-select
          [label]="'Categories' | translate"
          [name]="propertyStrings.categoryIds"
          [form]="form()"
          [items]="selectableCategoryItems()"
          [multiple]="true"
          [searchable]="true"
          [addable]="true"
          [useStrictSearch]="true"
          [serverErrors]="serverErrors()"
          (onAddNewItem)="handleAddNewItemInSelect($event)"
        >
        </simple-form-select>

        <simple-form-select
          [label]="'Client' | translate"
          [name]="propertyStrings.clientId"
          [form]="form()"
          [disabled]="clientsIsDisabled()"
          [items]="selectableClientItems()"
          [searchable]="true"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>

        @if (clientsIsDisabled() || !modifiedModel().clientId) {
          <div
            class="info-text-wrapper"
            [ngClass]="{ 'place-end': !clientsIsDisabled() }"
          >
            @if (clientsIsDisabled()) {
              <simple-form-info
                [firstText]="'Organization' | translate"
                [linkText]="globalState.selectedOrganization().orgName"
                [linkHref]="disabledClientLink()"
                [secondText]="'DisabledClientInfoText' | translate"
                [name]="globalState.selectedOrganization().orgName"
              >
              </simple-form-info>
            }
            <simple-form-info
              [firstText]="'*' + ('EmptyClientInfoText' | translate)"
            >
            </simple-form-info>
          </div>
        }

        <simple-form-select-button
          [label]="'GeoFencing' | translate"
          [name]="propertyStrings.geoControlled"
          [form]="form()"
          [items]="selectableGeoFencingItems()"
          [disabled]="geoFencingIsDisabled()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select-button>

        @if (geoFencingIsDisabled()) {
          <div class="info-text-wrapper">
            <simple-form-info
              [firstText]="'Facility' | translate"
              [linkText]="modifiedModel().facility.name"
              [linkHref]="disabledGeoFencingLink()"
              [secondText]="'DisabledGeoFencingInfoText' | translate"
              [name]="modifiedModel().facility.name"
            >
            </simple-form-info>
          </div>
        }
      </div>
    </div>
  </div>
</simple-change-tab>
