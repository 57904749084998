import { Injectable } from "@angular/core";
import { RoutesUtils } from "app/tools/routes-utils";
import { Client } from "../components/organization/organization";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ClientService extends BaseService<Client> {
  prefix = RoutesUtils.client;

  constructor() {
    super(Client);
  }

  async getAll(): Promise<Client[]> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}`, true);
      return this.extractData(response).map((item: Client) => new Client(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async get(clientId: string): Promise<Client> {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/${clientId}`,
        true,
      );
      return new Client(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(client: Client): Promise<Client> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}`,
        client,
        true,
      );
      return new Client(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(client: Client): Promise<Client> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}`,
        client,
        true,
      );
      return new Client(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  // Och finns det en vanlig delete, eller funkar denna som det men sickar med extra stuff?!??!
  async delete(clientId: string, orgId: number) {
    try {
      const model = {
        itemIds: [clientId],
        organizationId: orgId,
      };
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}/affectedData`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}
