<handle-header
  [headerTitleText]="'AddedMedia' | translate"
  [headerSubtitleText]="headerSubtitleText()"
  [listActions]="listActions()"
>
</handle-header>

<media-widget
  [collection]="items()"
  [hideAddButton]="true"
  [onlyImageUpload]="onlyImageUpload()"
  [onlyPdfUpload]="onlyDocumentUpload()"
  [canToggleDefault]="canToggleDefault()"
>
</media-widget>
