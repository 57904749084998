import { Component, computed, Signal } from "@angular/core";
import { SimpleIconsComponent } from "../../../../../various/icons/simple-icons.component";
import { IconContent } from "../../../columns/simple-table-icon-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-icon",
  templateUrl: "./column-type-icon.component.html",
  standalone: true,
  imports: [SimpleIconsComponent],
})
export class ColumnTypeIconComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<IconContent>;
}
