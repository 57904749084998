import { HttpClient, provideHttpClient } from "@angular/common/http";
import {
  ApplicationConfig,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ActivatedRoute, provideRouter } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "environments/environment";
import { provideToastr } from "ngx-toastr";
import { providePrimeNG } from "primeng/config";
import { routes } from "./app.routes";
import { UrlUtils } from "./tools/url-utils";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    UrlUtils.toDeployUrl("assets/i18n/"),
    ".json?cacheBuster=" + environment.cacheBusterHash,
  );
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient, ActivatedRoute],
        },
      }),
    ),
    provideAnimations(),
    provideToastr(),
    providePrimeNG(),
  ],
};
