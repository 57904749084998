<div
  class="st-container"
  [ngClass]="{ loadmask: pending(), 'in-wizard': inWizard() }"
>
  @if (!inWizard()) {
    <simple-filter
      [searchValue]="searchValue()"
      [sortObjects]="sortObjects()"
      [currentSortObject]="currentSortObject()"
      [filterInputs]="filterInputs()"
      [inModal]="inModal()"
      [totalCount]="totalCount()"
      [selectedCount]="selectedCount()"
      (onSearch)="search($event)"
      (onSearchClear)="clearSearch()"
      (onSortDirectionChange)="sortDirectionChange($event)"
      (onSortLabelChange)="sortLabelChange($event)"
      (onFilter)="filter($event)"
      (onFilterClear)="clearFilter()"
      (onSelectedClear)="clearSelected()"
    >
    </simple-filter>
  }

  <simple-table-head
    [columns]="columns()"
    [headerActions]="headerActions()"
    [rowActions]="rowActions()"
    [isSingleSelect]="isSingleSelect()"
    [selectedIds]="selectedIds()"
    [hasSelectableData]="hasSelectableData()"
    [someAreSelected]="someAreSelected()"
    [allAreSelected]="allAreSelected()"
    [inModal]="inModal()"
    [inWizard]="inWizard()"
    (onCheckboxClick)="handleCheckboxClick()"
  >
  </simple-table-head>

  <simple-table-body
    [columns]="columns()"
    [emptyState]="emptyState()"
    [rowActions]="rowActions()"
    [isSingleSelect]="isSingleSelect()"
    [rows]="rows()"
    [selectedIds]="selectedIds()"
    [addedIds]="addedIds()"
    [modifiedIds]="modifiedIds()"
    [unselectableIds]="unselectableIds()"
    [pending]="pending()"
    [inModal]="inModal()"
    [inWizard]="inWizard()"
    [hasActiveSearchOrFilter]="hasActiveSearchOrFilter()"
    (onRowSingleClick)="handleRowSingleClick($event)"
    (onRowDoubleClick)="handleRowDoubleClick($event)"
    (onRowCheckboxClick)="handleRowCheckboxClick($event)"
    (onLazyLoad)="handleLazyLoad($event)"
  >
  </simple-table-body>

  @if (pending()) {
    <dots-loader></dots-loader>
  }
</div>
