export class FileUtils {
  public static bytesToMb(size: number) {
    return size / 1024 / 1024;
  }

  public static mbToBytes(size: number) {
    return size * 1024 * 1024;
  }

  public static isAllowedFileSize(file: File, limit: number) {
    return file.size < limit;
  }

  public static isImage(file: File) {
    return (
      file.type && ACCEPTED_MEDIA_TYPES.IMAGE.includes(file.type.substring(6))
    );
  }

  public static isPDF(file: File) {
    return file.type && ACCEPTED_MEDIA_TYPES.PDF.includes(file.type);
  }

  public static isExcel(file: File) {
    if (file.type) {
      const fileType = file.type.substring(file.type.length - 5);
      if (fileType === "excel" || fileType === "sheet") {
        return true;
      }
    }
    return false;
  }
}

export const ACCEPTED_MEDIA_TYPES = {
  IMAGE: ".jpeg,.jpg,.png",
  PDF: "application/pdf",
  SPREADSHEET: ".xlsx,.xls",
};
