import { Component, inject, input, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "app/app-initializer";
import { ScheduleForViewing } from "app/components/schedule/schedule";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { UserService } from "../../../services/user.service";
import { User } from "../user";
import { ChangeDetailsTabComponent } from "./tabs/change-details/change-details-tab.component";
import { ViewDetailsTabComponent } from "./tabs/view-details/view-details-tab.component";

@Component({
  selector: "create-edit-user",
  templateUrl: "./create-edit-user.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ViewDetailsTabComponent,
    ChangeDetailsTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditUserComponent extends CreateEditBaseDirective<User> {
  initialDocuments = signal<MediaWidgetItem[]>([]);
  currentDocuments = signal<MediaWidgetItem[]>([]);
  schedules = signal<ScheduleForViewing[]>([]);

  isInvite = input<boolean>();

  private changeDetailsTabComponent = viewChild(ChangeDetailsTabComponent);

  private userService = inject(UserService);
  private appInitializer = inject(AppInitializer);

  async initiate() {
    this.pending.set(true);
    try {
      if (this.isEdit) {
        const data = await this.userService.get(this.itemIds()[0]);
        this.initialModel.set(data);

        if (
          this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl)
        ) {
          this.schedules.set(
            await this.userService.getSchedules(this.initialModel().id),
          );
        }

        this.assignCultureSettings();

        this.initialModel().selectedOrganizationId =
          this.globalState.selectedOrganization().id;
        this.initialModel().rolesTemp = this.initialModel().getCurrentRoles(
          this.globalState.selectedOrganization().id,
        );
        this.initialModel().statusTemp = this.initialModel().getCurrentStatus(
          this.globalState.selectedOrganization().id,
        );
        this.initialModel().membershipOrganizationId =
          this.globalState.selectedOrganization().id !==
          this.initialModel().organization.id
            ? this.globalState.selectedOrganization().id
            : null;

        if (
          this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl) &&
          this.canChangeDetails()
        ) {
          this.initialDocuments.set(
            await this.userService.getPdfs(this.initialModel().id),
          );
          this.currentDocuments.set(
            JSON.parse(JSON.stringify(this.initialDocuments())),
          );
        }

        this.pending.set(false);

        this.modifiedModel.set(
          new User(Utils.getUniqueVariant(this.initialModel())),
        );
      } else {
        if (this.isInvite()) {
          // Abort if not admin.
          if (
            !this.globalState
              .loggedInUser()
              .canEdit(this.globalState.selectedOrganization().friendlyUrl)
          ) {
            this.toastrService.error(
              `${this.translationService.instant(StringUtils.NO_PERMISSION)}!`,
            );
            this.handleClose();
            return;
          }
        } else {
          // Abort if not superadmin.
          if (!this.globalState.loggedInUser().isSuperAdmin()) {
            this.toastrService.error(
              `${this.translationService.instant(StringUtils.NO_PERMISSION)}!`,
            );
            this.handleClose();
            return;
          }
        }

        this.initialModel.set(new User({}));
        this.pending.set(false);

        this.assignCultureSettings();

        this.initialModel().organization =
          this.globalState.selectedOrganization();
        this.initialModel().organizationId =
          this.globalState.selectedOrganization().id;
        this.initialModel().selectedOrganizationId =
          this.globalState.selectedOrganization().id;
        this.initialModel().rolesTemp = [
          this.initialModel().selectableRoles.find((role) => role.id === "User")
            .id,
        ];

        this.modifiedModel.set(
          new User(Utils.getUniqueVariant(this.initialModel())),
        );
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.userService.update(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
              false,
            ),
          )
        : this.isInvite()
          ? await this.userService.invite(
              this.modifiedModel().toPayloadObject(
                this.globalState.selectedOrganization().id,
                false,
              ),
            )
          : await this.userService.create(
              this.modifiedModel().toPayloadObject(
                this.globalState.selectedOrganization().id,
                false,
              ),
            );

      if (
        this.globalState
          .loggedInUser()
          .canEdit(this.globalState.selectedOrganization().friendlyUrl) &&
        this.canChangeDetails() &&
        this.mediaHasChanged()
      ) {
        const pdfsToUpload = this.currentDocuments().filter(
          (document) => !!document.blob,
        );
        const pdfsToDelete = this.initialDocuments().filter(
          (document) =>
            !this.currentDocuments().some((cd) => cd.id === document.id),
        );

        if (pdfsToUpload.length) {
          await this.userService.uploadPdfs(
            this.modifiedModel().id,
            pdfsToUpload,
          );
        }

        if (pdfsToDelete.length) {
          await this.userService.deletePdfs(
            this.modifiedModel().id,
            this.modifiedModel().organization.id,
            pdfsToDelete.map((pdf) => pdf.id),
          );
        }
      }
      this.toastrService.success(
        this.translationService.instant("TheUserWasUpdated"),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeDetailsTabComponent().setServerErrors(error.errors);
    }
  }

  handleDetailsChangeExtra() {
    if (this.changeDetailsTabComponent()) {
      const rolesTempCtrl = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.rolesTemp);
      const statusTempCtrl = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.statusTemp);
      const primary =
        this.modifiedModel().organization.id ===
        this.globalState.selectedOrganization().id;
      const membership = this.modifiedModel().organizationMemberships.find(
        (membership) =>
          membership.organization.id ===
          this.globalState.selectedOrganization().id,
      );
      if (primary) {
        this.modifiedModel().roles = rolesTempCtrl.value;
        if (statusTempCtrl) {
          this.modifiedModel().status = statusTempCtrl.value;
        }
      } else if (membership) {
        membership.roles = rolesTempCtrl.value;
        if (statusTempCtrl) {
          membership.status = statusTempCtrl.value;
        }
      }

      if (!this.isInvite()) {
        const firstname = this.changeDetailsTabComponent()
          .form()
          .get(this.propertyStrings.firstname);
        const lastname = this.changeDetailsTabComponent()
          .form()
          .get(this.propertyStrings.lastname);
        if (!firstname?.value && !lastname?.value) {
          this.modifiedModel().fullName = "";
        } else {
          this.modifiedModel().fullName = `${firstname?.value} ${lastname?.value}`;
        }
      }
    }
  }

  private assignCultureSettings() {
    this.initialModel().selectableCountryCodes =
      this.appInitializer.cultureSettings.countryCodes;
    this.initialModel().selectableCountries =
      this.appInitializer.cultureSettings.countries;
    this.initialModel().selectableTimeZones =
      this.appInitializer.cultureSettings.timeZones;
    this.initialModel().selectableCultures =
      this.appInitializer.cultureSettings.cultures;
    this.initialModel().selectableRoles = this.appInitializer.roles;
  }

  canChangeDetails() {
    if (this.globalState.loggedInUser().isSuperAdmin()) {
      return true;
    } else {
      if (this.isUserOrgForUser()) {
        if (this.modifiedModel()?.isSuperAdmin()) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  canChangeAuthorization() {
    if (this.globalState.loggedInUser().isSuperAdmin()) {
      return true;
    } else {
      if (this.isUserOrgForUser()) {
        if (this.modifiedModel()?.isSuperAdmin()) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.modifiedModel()?.isSuperAdmin()) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  private isUserOrgForUser() {
    return (
      this.modifiedModel() &&
      this.modifiedModel().organization.id ===
        this.globalState.selectedOrganization().id
    );
  }

  private mediaHasChanged() {
    const currentMedia = this.stripActionsOpened(this.currentDocuments());
    const initialMedia = this.stripActionsOpened(this.initialDocuments());
    return !Utils.isEqual(currentMedia, initialMedia);
  }

  private stripActionsOpened(items: MediaWidgetItem[]) {
    return items.map((item) => {
      const { actionsOpened, ...theRest } = item;
      return theRest as MediaWidgetItem;
    });
  }
}
