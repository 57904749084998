import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Popover } from "primeng/popover";
import { DatexPipe } from "../../../../pipes/datex.pipe";
import { ParseMDPipe } from "../../../../pipes/parse-md-pipe";
import { IconObject } from "../../list/table/columns/simple-table-icon-column";

@Component({
  selector: "simple-icons",
  templateUrl: "./simple-icons.component.html",
  styleUrls: ["./simple-icons.component.less"],
  standalone: true,
  imports: [TranslateModule, ParseMDPipe, Popover, DatexPipe],
})
export class SimpleIconsComponent {
  icons = input<IconObject[]>();
}
