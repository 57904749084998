import { Component, computed, Signal } from "@angular/core";
import { IssueNumberContent } from "../../../columns/simple-table-issue-number-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-issue-number",
  templateUrl: "./column-type-issue-number.component.html",
  standalone: true,
})
export class ColumnTypeIssueNumberComponent<
  T,
> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<IssueNumberContent>;
}
