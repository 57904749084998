import { Directive, inject, input, output, signal } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { KeyValuePair } from "app/filter";
import { GlobalStateService } from "app/global-state/global-state.service";
import { TranslationService } from "app/services/translation.service";
import { PropertyNameGetter } from "app/tools/property-name-getter";
import { StringUtils } from "app/tools/string-utils";
import { ToastrService } from "ngx-toastr";

@Directive()
export abstract class ChangeDetailsBaseDirective<T extends { id?: string }> {
  stringUtils = StringUtils;

  form = signal<FormGroup>(new FormGroup({}));
  serverErrors = signal<KeyValuePair[]>([]);

  modifiedModel = input<T>();

  onDetailsChange = output<FormGroup>();

  get isEdit() {
    return !!this.modifiedModel().id;
  }

  protected formBuilder = inject(FormBuilder);
  protected translationService = inject(TranslationService);
  protected toastrService = inject(ToastrService);
  protected globalState = inject(GlobalStateService);

  ngOnInit() {
    this.initiateReactiveForms();
    if (this.isEdit) {
      this.triggerValidation();
    }
  }

  private triggerValidation() {
    Object.keys(this.form().controls).forEach((key) => {
      const formControl = this.form().get(key);
      formControl.markAsTouched();
    });
  }

  setServerErrors(serverErrors: KeyValuePair[]) {
    this.serverErrors.set(serverErrors);
    const unmappedErrors: KeyValuePair[] = [];
    serverErrors.forEach((error) => {
      const control = this.form().get(error.key);
      if (control) {
        control.setErrors({ serverError: true });
      } else {
        unmappedErrors.push(error);
      }
    });
    if (unmappedErrors.length) {
      const headline = `${this.translationService.instant(
        "TheFollowingErrorsCouldNotBeMapped",
      )}:<BR>`;
      const errors = unmappedErrors
        .map((error) => `&bull; ${error.value}`)
        .join("<BR>");
      this.toastrService.info(`${headline}${errors}`);
    }
  }

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf({} as new (args?: unknown) => T);
  }

  private initiateReactiveForms() {
    this.form().valueChanges.subscribe(() => {
      this.onDetailsChange.emit(this.form());
    });
  }
}
