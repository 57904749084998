import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ScheduledAssignment } from "app/components/assignment/scheduled-assignment";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentService } from "app/services/assignment.service";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { ViewDetailsTabComponent } from "./tabs/view-details/view-details-tab.component";

@Component({
  selector: "detailed-assignment-scheduled-archived",
  templateUrl: "./detailed-assignment-scheduled-archived.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ViewDetailsTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class DetailedAssignmentScheduledArchivedComponent extends CreateEditBaseDirective<ScheduledAssignment> {
  private assignmentService = inject(AssignmentService);

  async initiate() {
    this.pending.set(true);
    try {
      const data = (await this.assignmentService.get(
        this.itemIds()[0],
      )) as ScheduledAssignment;
      this.initialModel.set(data);
      this.pending.set(false);

      this.modifiedModel.set(
        new ScheduledAssignment(Utils.getUniqueVariant(this.initialModel())),
      );

      if (!this.modifiedModel().isArchivedAssignment()) {
        this.toastrService.error(
          `${this.translationService.instant(
            StringUtils.WRONG_ASSIGNMENT_LIST_ERROR,
          )}!`,
        );
        this.handleClose();
        this.router.navigate(
          [
            `${
              this.globalState.selectedOrganization().friendlyUrl
            }/${this.modifiedModel().correctRoute}`,
          ],
          { queryParamsHandling: "merge", replaceUrl: true },
        );
        return;
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }
}
