import { Component, inject, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "app/app-initializer";
import { ScheduleForViewing } from "app/components/schedule/schedule";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { UserService } from "../../../services/user.service";
import { SimpleCrudModalComponent } from "../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { ChangeDetailsTabComponent } from "../create-edit-user/tabs/change-details/change-details-tab.component";
import { User } from "../user";

@Component({
  selector: "edit-logged-in-user",
  templateUrl: "./edit-logged-in-user.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    ChangeDetailsTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class EditLoggedInUserComponent extends CreateEditBaseDirective<User> {
  initialDocuments = signal<MediaWidgetItem[]>([]);
  currentDocuments = signal<MediaWidgetItem[]>([]);
  schedules = signal<ScheduleForViewing[]>([]);

  private changeDetailsTabComponent = viewChild(ChangeDetailsTabComponent);

  private userService = inject(UserService);
  private appInitializer = inject(AppInitializer);

  async initiate() {
    this.pending.set(true);
    try {
      this.initialModel.set(this.globalState.loggedInUser());
      this.assignCultureSettings();
      this.initialModel().selectedOrganizationId =
        this.initialModel().organization.id;
      this.initialModel().rolesTemp = this.initialModel().roles;
      this.initialModel().statusTemp = this.initialModel().status;
      this.initialModel().membershipOrganizationId = null;

      this.schedules.set(
        await this.userService.getSchedules(this.initialModel().id),
      );

      this.initialDocuments.set(
        await this.userService.getPdfs(this.initialModel().id),
      );
      this.currentDocuments.set(
        JSON.parse(JSON.stringify(this.initialDocuments())),
      );

      this.pending.set(false);

      this.modifiedModel.set(
        new User(Utils.getUniqueVariant(this.initialModel())),
      );
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async update() {
    if (!this.changeDetailsIsValid()) {
      // TODO? this.changeDetailsTabComponent().triggerValidation();
      return;
    }
    this.pending.set(true);
    try {
      const data = await this.userService.update(
        this.modifiedModel().toPayloadObject(
          this.globalState.selectedOrganization().id,
          true,
        ),
      );
      if (this.mediaHasChanged()) {
        const pdfsToUpload = this.currentDocuments().filter(
          (document) => !!document.blob,
        );
        const pdfsToDelete = this.initialDocuments().filter(
          (document) =>
            !this.currentDocuments().some((cd) => cd.id === document.id),
        );

        if (pdfsToUpload.length) {
          await this.userService.uploadPdfs(
            this.modifiedModel().id,
            pdfsToUpload,
          );
        }

        if (pdfsToDelete.length) {
          await this.userService.deletePdfs(
            this.modifiedModel().id,
            this.modifiedModel().organization.id,
            pdfsToDelete.map((pdf) => pdf.id),
          );
        }
      }
      this.toastrService.success(
        this.translationService.instant("TheUserWasUpdated"),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.changeDetailsTabComponent().setServerErrors(error.errors);
    }
  }

  handleDetailsChangeExtra() {
    if (this.changeDetailsTabComponent()) {
      const rolesTempCtrl = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.rolesTemp);
      const statusTempCtrl = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.statusTemp);
      const primary =
        this.modifiedModel().organization.id ===
        this.globalState.selectedOrganization().id;
      const membership = this.modifiedModel().organizationMemberships.find(
        (membership) =>
          membership.organization.id ===
          this.globalState.selectedOrganization().id,
      );
      if (primary) {
        this.modifiedModel().roles = rolesTempCtrl.value;
        if (statusTempCtrl) {
          this.modifiedModel().status = statusTempCtrl.value;
        }
      } else if (membership) {
        membership.roles = rolesTempCtrl.value;
        if (statusTempCtrl) {
          membership.status = statusTempCtrl.value;
        }
      }

      const firstname = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.firstname);
      const lastname = this.changeDetailsTabComponent()
        .form()
        .get(this.propertyStrings.lastname);
      if (!firstname?.value && !lastname?.value) {
        this.modifiedModel().fullName = "";
      } else {
        this.modifiedModel().fullName = `${firstname?.value} ${lastname?.value}`;
      }
    }
  }

  private assignCultureSettings() {
    this.initialModel().selectableCountryCodes =
      this.appInitializer.cultureSettings.countryCodes;
    this.initialModel().selectableCountries =
      this.appInitializer.cultureSettings.countries;
    this.initialModel().selectableTimeZones =
      this.appInitializer.cultureSettings.timeZones;
    this.initialModel().selectableCultures =
      this.appInitializer.cultureSettings.cultures;
    this.initialModel().selectableRoles = this.appInitializer.roles;
  }

  private mediaHasChanged() {
    const currentMedia = this.stripActionsOpened(this.currentDocuments());
    const initialMedia = this.stripActionsOpened(this.initialDocuments());
    return !Utils.isEqual(currentMedia, initialMedia);
  }

  private stripActionsOpened(items: MediaWidgetItem[]) {
    return items.map((item) => {
      const { actionsOpened, ...theRest } = item;
      return theRest as MediaWidgetItem;
    });
  }
}
