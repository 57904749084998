import { Schedule } from "app/components/schedule/schedule";
import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { Category } from "app/components/simple-components/various/categories/simple-categories.component";
import { RuleObject } from "app/components/simple-components/various/rules/simple-rules.component";
import { Task } from "app/components/task/task";
import { TemplateType } from "app/components/template-type/template-type";
import { UserGroup } from "app/components/user-group/user-group";
import { User } from "app/components/user/user";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { StringUtils } from "app/tools/string-utils";
import { DateTime } from "luxon";
import { AssignmentTemplate } from "../assignment-template/assignment-template";
import { Choice } from "../choice/choice";
import { Entity } from "../entity/entity";
import { Facility } from "../facility/facility";
import { Client } from "../organization/organization";

export class Assignment extends CrudItem<Assignment> {
  name: string = "";
  facility: Facility;
  entities: Entity[] = [];
  assigneeGroups: AssigneeGroup[] = [];
  assignees: Assignee[] = [];
  comment: string = "";
  solutionComment: string = "";

  templateType: TemplateType;
  assignmentTemplate: AssignmentTemplate;
  errorAssignment: Assignment;
  errorAssignmentId: string;

  users: User[] = [];
  errorReportId: string;
  dueDate: string = DateTime.now().plus({ minutes: 5 }).toSQLDate();
  dueTime: string;
  updated: string;
  lastUpdated: string;

  completed: string;

  notes: string;

  issueNumber: string;
  errorResult: Result;
  entityName: string = "";
  categories: Category[] = [];
  isExpired: boolean;
  task: Task;
  choices: Choice[] = [];
  adminComment: string = "";
  priority: boolean = false;
  allowPriority: boolean;
  schedule: Schedule;
  entity: Entity;
  userGroups: UserGroup[] = [];

  integrations: string[] = [];
  status: string = StringUtils.ACTIVE;
  archived: string;

  completedByUser: CompletedByUser;
  createdByUser: CreatedByUser;
  reportUrl: string;

  choice: Choice;

  firstUpdated: string;
  performed: string;

  facilityName: string = "";
  scheduleName: string = "";

  client: Client;

  geoControlled: boolean = false;

  get displayName() {
    return this.isMeasureAssignment()
      ? this.facility?.name
      : this.templateType?.name;
  }

  constructor(assignment: Partial<Assignment>) {
    super();
    Object.assign(this, assignment);
    this.mapData();
  }

  private mapData() {
    this.mapTemplateType();
    this.mapFacility();
    this.mapEntity();
    this.mapSchedule();
    this.mapEntities();
    this.mapDueTime();
    this.mapAssigneeGroups();
    this.mapAssignees();
    this.mapErrorResult();
    this.mapChoices();
    this.mapCreatedByUser();
    this.mapCompletedByUser();
    this.mapClient();
  }

  private mapTemplateType() {
    if (this.templateType) {
      this.templateType = new TemplateType(this.templateType);
    }
  }

  private mapFacility() {
    if (this.facility) {
      this.facility = new Facility(this.facility);
    }
  }

  private mapEntity() {
    if (this.entity) {
      this.entity = new Entity(this.entity);
    }
  }

  private mapSchedule() {
    if (this.schedule) {
      this.schedule = new Schedule(this.schedule);
    }
  }

  private mapEntities() {
    this.entities = this.entities.map((entity) => new Entity(entity));
    this.entities.sortByProperty(new Entity({}).propertyStrings.name);
  }

  private mapDueTime() {
    this.dueTime = DateTime.fromSQL(this.dueDate).toLocaleString(
      DateTime.TIME_24_SIMPLE,
    );
  }

  private mapAssigneeGroups() {
    this.assigneeGroups = this.assigneeGroups.map(
      (assigneeGroup) => new AssigneeGroup(assigneeGroup),
    );
    this.assigneeGroups.sortByProperty(this.propertyStrings.name);
  }

  private mapAssignees() {
    this.assignees = this.assignees.map((assignee) => new Assignee(assignee));
    this.assignees.sortByProperty("user fullName");
  }

  private mapErrorResult() {
    if (this.errorResult) {
      this.errorResult = new Result(this.errorResult);
    }
  }

  private mapChoices() {
    this.choices = this.choices.map((choice) => new Choice(choice));
    this.choices.sortByProperty(new Choice({}).propertyStrings.value);
  }

  private mapCreatedByUser() {
    if (this.createdByUser) {
      this.createdByUser = new CreatedByUser(this.createdByUser);
    }
  }

  private mapCompletedByUser() {
    if (this.completedByUser) {
      this.completedByUser = new CompletedByUser(this.completedByUser);
    }
  }

  private mapClient() {
    if (this.client) {
      this.client = new Client(this.client);
    }
  }

  isArchivedAssignment() {
    return !!this.completed || this.isExpired;
  }

  isSSEN() {
    return (
      this.assignmentTemplate.templateType.templateBaseType.name === "SS-EN"
    );
  }

  isMeasureAssignment() {
    return !!this.errorAssignmentId;
  }
}

export class AssignmentPayload extends Assignment {
  id: string;
  status: string;
  priority: boolean;
  adminComment: string;
  dueDate: string;
  organizationId: number;

  constructor(assignmentPayload: Partial<Assignment>) {
    super(assignmentPayload);
    Object.assign(this, assignmentPayload);
  }
}

export class AssigneeGroup extends UserGroup {
  canPerformScheduled: boolean = true;
  canPerformMeasure: boolean = true;
  canAssign: boolean = false;
  userGroup: UserGroup = null;
  userGroupId: string = "";
  assignmentId: string = "";
  notifyOnAdd: boolean = false;

  get rulesReadonlyInScheduledAssignment() {
    const rules: RuleObject[] = [];
    if (this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment green",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill blue",
          title: "CanAssign",
        }),
      );
    }
    return rules;
  }

  get rulesEditableInScheduledAssignment() {
    const rules: RuleObject[] = [];
    if (this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment green",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (!this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (!this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill blue",
          title: "CanAssign",
        }),
      );
    }
    if (!this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill",
          title: "CanAssign",
        }),
      );
    }
    return rules;
  }

  get rulesReadonlyInMeasureAssignment() {
    const rules: RuleObject[] = [];
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    return rules;
  }

  get rulesEditableInMeasureAssignment() {
    const rules: RuleObject[] = [];
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (!this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure",
          title: "CanPerformMeasure",
        }),
      );
    }
    return rules;
  }

  get notifyOnAddRule() {
    const rules: RuleObject[] = [];
    if (this.notifyOnAdd) {
      rules.push(
        new RuleObject({
          icon: "bi bi-bell-fill green",
          title: "NotifyOnAdd",
        }),
      );
    }
    if (!this.notifyOnAdd) {
      rules.push(
        new RuleObject({
          icon: "bi bi-bell-fill",
          title: "NotifyOnAdd",
        }),
      );
    }
    return rules;
  }

  constructor(assigneeGroup: Partial<AssigneeGroup>) {
    super(assigneeGroup);
    Object.assign(this, assigneeGroup);
    this.mapUserGroup();
  }

  private mapUserGroup() {
    if (this.userGroup) {
      this.userGroup = new UserGroup(this.userGroup);
      this.userGroupId = this.userGroup.id;
      this.name = this.userGroup.name;
    }
  }
}

export class Assignee extends User {
  canPerformScheduled: boolean = true;
  canPerformMeasure: boolean = true;
  canAssign: boolean = false;
  user: User = null;
  userId: string = "";
  assignmentId: string = "";
  notifyOnAdd: boolean = false;

  get rulesReadonlyInScheduledAssignment() {
    const rules: RuleObject[] = [];
    if (this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment green",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill blue",
          title: "CanAssign",
        }),
      );
    }
    return rules;
  }

  get rulesEditableInScheduledAssignment() {
    const rules: RuleObject[] = [];
    if (this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment green",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (!this.canPerformScheduled) {
      rules.push(
        new RuleObject({
          icon: "ii-assignment",
          title: "CanPerformScheduled",
        }),
      );
    }
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (!this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill blue",
          title: "CanAssign",
        }),
      );
    }
    if (!this.canAssign) {
      rules.push(
        new RuleObject({
          icon: "bi bi-people-fill",
          title: "CanAssign",
        }),
      );
    }
    return rules;
  }

  get rulesReadonlyInMeasureAssignment() {
    const rules: RuleObject[] = [];
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    return rules;
  }

  get rulesEditableInMeasureAssignment() {
    const rules: RuleObject[] = [];
    if (this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure red",
          title: "CanPerformMeasure",
        }),
      );
    }
    if (!this.canPerformMeasure) {
      rules.push(
        new RuleObject({
          icon: "ii-measure",
          title: "CanPerformMeasure",
        }),
      );
    }
    return rules;
  }

  get notifyOnAddRule() {
    const rules: RuleObject[] = [];
    if (this.notifyOnAdd) {
      rules.push(
        new RuleObject({
          icon: "bi bi-bell-fill green",
          title: "NotifyOnAdd",
        }),
      );
    }
    if (!this.notifyOnAdd) {
      rules.push(
        new RuleObject({
          icon: "bi bi-bell-fill",
          title: "NotifyOnAdd",
        }),
      );
    }
    return rules;
  }

  constructor(assignee: Partial<Assignee>) {
    super(assignee);
    Object.assign(this, assignee);
    this.mapUser();
  }

  private mapUser() {
    if (this.user) {
      this.user = new User(this.user);
      this.userId = this.user.id;
      this.email = this.user.email;
    }
  }
}

export class CompletedByUser extends User {
  constructor(completedByUser: Partial<CompletedByUser>) {
    super(completedByUser);
  }
}

export class CreatedByUser extends User {
  constructor(createdByUser: Partial<CreatedByUser>) {
    super(createdByUser);
  }
}

export class Result extends CrudItem<Result> {
  task: Task;
  comment: string = "";
  media: MediaWidgetItem[] = [];
  priority: boolean = false;
  color: string = "";
  solved: string;
  measureAssignmentId: string = "";

  constructor(result: Partial<Result>) {
    super();
    Object.assign(this, result);
    this.media = this.media.map((item) => new MediaWidgetItem(item));
    this.task = new Task(this.task);
  }

  get images() {
    return this.media.filter((item) => item.isImage);
  }
}
