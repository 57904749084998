import { inject, Injectable } from "@angular/core";
import { GlobalStateService } from "app/global-state/global-state.service";
import { OrganizationTreeItem } from "../components/organization/organization";
import { HostedHttpClientService } from "./hosted-httpclient.service";

const ENDPOINTS = {
  GET_ORG_TREE_ADMIN_ONLY: (selectedOrganizationId) =>
    `organization/${selectedOrganizationId}/userTree?adminOnly=true`,
  GET_WHOLE_ORG_TREE: (selectedOrganizationId) =>
    `organization/${selectedOrganizationId}/userTree`,
  GET_BRANCH: (organizationId) => `organization/${organizationId}/children`,
};

@Injectable({
  providedIn: "root",
})
export class GlobalOrganizationService {
  private treeData: OrganizationTreeItem[];

  private hostedHttpClient = inject(HostedHttpClientService);
  private globalState = inject(GlobalStateService);

  async getTreeData() {
    return new Promise((resolve) => {
      // No cached data: request
      if (!this.treeData) {
        this.getWholeOrgTree(this.globalState.selectedOrganization().id).then(
          (organizations: OrganizationTreeItem[]) => {
            this.treeData = organizations;
            resolve(null);
          },
        );
      } else {
        resolve(null);
      }
    }).then((_) => {
      // Create a clone so changes don't affect the original array.
      return Object.assign([], this.treeData);
    });
  }

  private async getWholeOrgTree(selectedOrganizationId: number) {
    return this.hostedHttpClient
      .get(ENDPOINTS.GET_WHOLE_ORG_TREE(selectedOrganizationId), true)
      .then((res) => res["body"].data)
      .then((rawOrganizations) => {
        return rawOrganizations.map((organization) => {
          return new OrganizationTreeItem(organization);
        });
      });
  }

  async getTreeBranch(rootId: number) {
    return this.hostedHttpClient
      .get(ENDPOINTS.GET_BRANCH(rootId), true)
      .then((res) => res["body"].data)
      .then((rawOrganizations) => {
        return rawOrganizations.map((organization) => {
          return new OrganizationTreeItem(organization);
        });
      });
  }
}
