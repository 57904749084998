export abstract class SimpleTableColumn<T> {
  readonly labels: string[];
  readonly hidden: () => boolean;

  type: SimpleTableColumnType;
  maxwidth: number;
  customOrder: number;
  customHidden: boolean;

  constructor(
    labels: string[],
    hidden?: () => boolean,
    customHidden?: boolean,
  ) {
    this.labels = labels;
    this.hidden = hidden ? hidden : () => false;
    this.customHidden = customHidden ? customHidden : false;
  }

  abstract getValue(item: T): unknown;
}

export enum SimpleTableColumnType {
  TEXT = "text",
  DATE = "date",
  ICON = "icon",
  COUNT = "count",
  STATUS = "status",
  CHOICE = "choice",
  CATEGORY = "category",
  ASSESSMENT = "assessment",
  RULE = "rule",
  PUBLISH_DATE = "publish_date",
  DUE_DATE = "due_date",
  ISSUE_NUMBER = "issue_number",
  IMAGE = "image",
  TRANSLATED_TEXT = "translated_text",
  USER = "user",
  TASK = "task",
  FACILITY = "facility",
  ENTITY = "entity",
}
