import { Injectable } from "@angular/core";
import { TemplateType } from "app/components/template-type/template-type";
import { RoutesUtils } from "app/tools/routes-utils";
import { SSENReportIntegration } from "../components/organization/my-organization/integrations/integration";
import {
  Address,
  Email,
  Organization,
  OrganizationPayload,
  Phone,
} from "../components/organization/organization";
import { PostImage } from "../post-image";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class OrganizationService extends BaseService<Organization> {
  prefix = RoutesUtils.organization;

  constructor() {
    super(Organization);
  }

  async getByFriendlyUrl(friendlyUrl: string): Promise<Organization> {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/byname/${friendlyUrl}`,
        true,
      );
      return new Organization(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async get(id: number): Promise<Organization> {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/${id}`,
        true,
      );
      return new Organization(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: OrganizationPayload): Promise<Organization> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}`,
        item,
        true,
      );
      return new Organization(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: OrganizationPayload): Promise<Organization> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
        true,
      );
      return new Organization(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: number, moveUsers: boolean): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}/${id}`,
        {
          moveAll: JSON.stringify(moveUsers),
        },
        true,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateLogotype(orgId: number, img: PostImage): Promise<Organization> {
    try {
      const formData = new FormData();
      formData.append("file", img.blob, img.name);
      const response = await this.hostedHttpClient.putWithoutContentType(
        `${this.prefix}/${orgId}/logotype`,
        formData,
        true,
      );
      return new Organization(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async getAffectedData(orgId: number) {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/${orgId}/dataAffected`,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getTemplateTypes(orgId: number): Promise<TemplateType[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `templateType/organization/${orgId}`,
      );
      return this.extractData(response).map(
        (item: TemplateType) => new TemplateType(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateTemplateTypes(
    orgId: number,
    integration: SSENReportIntegration,
  ): Promise<TemplateType[]> {
    try {
      const model = {
        organizationId: orgId,
        scope: integration.scope,
      };
      const response = await this.hostedHttpClient.put(
        `templateBaseType/${integration.templateBaseTypeId}/scope`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updateEmail(item: Email) {
    try {
      const response = await this.hostedHttpClient.put(
        `email/${item.id}`,
        item,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async createPhone(item: Phone) {
    try {
      const response = await this.hostedHttpClient.post(`phone`, item, true);
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updatePhone(item: Phone) {
    try {
      const response = await this.hostedHttpClient.put(
        `phone/${item.id}`,
        item,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async deletePhone(id: number) {
    try {
      const response = await this.hostedHttpClient.delete(`phone/${id}`, true);
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async createAddress(item: Address) {
    try {
      const response = await this.hostedHttpClient.post(`address`, item, true);
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updateAddress(item: Address) {
    try {
      const response = await this.hostedHttpClient.put(
        `address/${item.id}`,
        item,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async deleteAddress(id: number) {
    try {
      const response = await this.hostedHttpClient.delete(
        `address/${id}`,
        true,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}
