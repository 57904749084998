import { Entity } from "app/components/entity/entity";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableEntityColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => EntityContent;

  constructor(
    labels: string[],
    getValue: (row: T) => EntityContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.ENTITY;
  }
}

export class EntityContent {
  entity: Entity;

  constructor(entity: Entity) {
    this.entity = entity;
  }
}
