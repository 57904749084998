<simple-crud-modal
  [visible]="visible()"
  [headerText]="'ChangeProfileImage' | translate"
  [closeText]="'Close' | translate"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <simple-change-image
      [imageUrl]="modifiedModel().profileImageUrl"
      [rounded]="true"
      (onClear)="modifiedModel().profileImageUrl = null"
    >
    </simple-change-image>
  }
</simple-crud-modal>
