import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableStatusColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (item: T) => StatusContent;

  constructor(
    labels: string[],
    getValue: (row: T) => StatusContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.STATUS;
    this.maxwidth = 84;
  }
}

export class StatusContent {
  status: string;

  constructor(status: string) {
    this.status = status;
  }
}
