import { Pipe, PipeTransform } from "@angular/core";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";

@Pipe({
  name: "getVisibleRowActions",
  standalone: true,
})
export class GetVisibleRowActions<T> implements PipeTransform {
  transform(
    rowActions: SimpleTableRowAction<T>[],
    row: T,
  ): SimpleTableRowAction<T>[] {
    return rowActions.filter((action) => !action.hidden(row));
  }
}
