@if (displayMode() === "pending") {
  <div
    class="list-skeleton-wrapper"
    [ngClass]="{
      'in-modal': inModal(),
      'in-wizard': inWizard(),
    }"
  >
    <div class="skeletons">
      <div class="fake-row-top"></div>
      @for (item of fakeRowCount; track $index) {
        <div class="skeleton-row">
          @for (column of columns(); track $index) {
            <div
              class="skeleton-column"
              [ngStyle]="{ 'max-width': column.maxwidth + 'px' }"
            >
              <p-skeleton />
            </div>
          }
          <div class="skeleton-column action">
            <p-skeleton />
          </div>
          <div class="skeleton-column action">
            <p-skeleton />
          </div>
        </div>
      }
      <div class="fake-row-bottom"></div>
    </div>
  </div>
} @else if (displayMode() === "empty") {
  <div
    class="empty-state"
    [ngClass]="{
      'in-modal': inModal(),
      'in-wizard': inWizard(),
    }"
  >
    <div class="header-section apx-scrollbar">
      <div
        class="icon {{
          hasActiveSearchOrFilter() ? 'no-results' : emptyState().icon
        }}"
      ></div>
      <div class="header-text">
        {{
          (hasActiveSearchOrFilter() ? "NoResultHeader" : "NoDataHeader")
            | translate
              : {
                  identifier: emptyState().identifier | translate | lowercase,
                }
        }}!
      </div>
      <div class="info-text">
        {{
          (hasActiveSearchOrFilter()
            ? "NoResultsMatchingSearchOrFilter"
            : "OpenUserSupportOrContactNearestSupport"
          ) | translate
        }}
      </div>
    </div>
  </div>
}
