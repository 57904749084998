import { SimpleAction } from "../../simple-action";

export class SimpleTableRowAction<T> extends SimpleAction {
  readonly event: (row: T) => void;
  readonly hidden: (row: T) => boolean;
  isEditAction: boolean;
  isWiewAction: boolean;
  isDeleteAction: boolean;

  constructor(
    name: string,
    iconName: string,
    event: (row: T) => void,
    hidden?: (row: T) => boolean,
  ) {
    super(name, iconName);
    this.event = event;
    this.hidden = hidden ? hidden : () => false;
  }
}

export class SimpleTableRowActionEdit<T> extends SimpleTableRowAction<T> {
  isEditAction = true;
}

export class SimpleTableRowActionView<T> extends SimpleTableRowAction<T> {
  isViewAction = true;
}

export class SimpleTableRowActionDelete<T> extends SimpleTableRowAction<T> {
  isDeleteAction = true;
}
