<simple-view-tab>
  <div class="view-section-layout-wrapper">
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "General" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "TemplateType" | translate }}
          </div>

          <div class="content-value">
            {{ model().assignmentTemplate.templateType.name }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Facility" | translate }}
          </div>
          <div class="content-value">
            <simple-view-connections [facility]="model().facility">
            </simple-view-connections>
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Categories" | translate }}
          </div>
          @if (model().assignmentTemplate.categories.length) {
            <div class="content-value">
              <simple-categories
                [categories]="model().assignmentTemplate.categories"
              >
              </simple-categories>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        @if (model().scheduleName) {
          <div class="content">
            <div class="content-header">
              {{ "Schedule" | translate }}
            </div>

            <div class="content-value">
              {{ model().scheduleName }}
            </div>
          </div>
        }
        <div class="content">
          <div class="content-header">
            {{ "Archived" | translate }}
          </div>

          <div
            class="content-value"
            [title]="model().dueDate | datex: 'with-time'"
          >
            {{
              (model().completed ? model().completed : model().dueDate) | datex
            }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Client" | translate }}
          </div>
          @if (model().assignmentTemplate.client) {
            <div class="content-value">
              {{ model().assignmentTemplate.client.name }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Geofencing" | translate }}
          </div>
          {{
            model().assignmentTemplate.geoControlled
              ? ("Yes" | translate)
              : ("No" | translate)
          }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Status" | translate }}
          </div>
          <div class="content-value">
            <simple-status-label
              [text]="
                model().status === stringUtils.ACTIVE
                  ? stringUtils.NOT_STARTED
                  : model().status
              "
              [title]="
                model().status === stringUtils.ACTIVE
                  ? stringUtils.NOT_STARTED
                  : model().status
              "
            >
            </simple-status-label>
          </div>
        </div>
      </div>

      @if (model().isSSEN()) {
        <div class="view-section">
          <div class="view-section-header">
            {{ "Other" | translate }}
          </div>
          @if (model().assignmentTemplate.templateType.scope) {
            <div class="content">
              <div class="content-header">
                {{ "Scope" | translate }}
              </div>
              <div
                class="content-value with-frame"
                [innerHTML]="
                  model().assignmentTemplate.templateType.scope | parseMD
                "
              ></div>
            </div>
          }
          <div class="content">
            <div class="content-header">
              {{
                (model().assignmentTemplate.templateType.scope
                  ? "Deviations"
                  : "ScopeFreeText"
                ) | translate
              }}
            </div>

            @if (model().notes) {
              <div class="content-value">
                {{ model().notes }}
              </div>
            } @else {
              <div class="dummy-text">
                {{ "NotSpecified" | translate }}
              </div>
            }
          </div>
        </div>
      }
      @if (model().reportUrl) {
        <div class="view-section">
          <button
            class="btn-success"
            [title]="'GetAssignmentReport' | translate"
            (click)="getReport()"
          >
            {{ "GetAssignmentReport" | translate }}
          </button>
        </div>
      }
    </div>
    <div class="view-section-layout">
      <div class="view-section">
        <div class="view-section-header">
          {{ "InspectionResults" | translate }}
        </div>
        @if (model().facility.results.length) {
          <div class="content">
            <div class="content-header">
              {{ "Assessments" | translate }}
            </div>
            <div class="content-value flex-column">
              @for (result of model().facility.results; track $index) {
                <simple-assessment
                  [task]="result.task"
                  [choice]="result.task.choice"
                  [comment]="result.comment"
                  [media]="result.media"
                  [displayInfoOnClick]="true"
                  [infoText]="
                    result.task.choice.isError
                      ? ('MeasureAssignment' | translate) +
                        ' ' +
                        ((result.solved ? 'Handled' : 'NotHandled')
                          | translate
                          | lowercase)
                      : ''
                  "
                  [url]="
                    result.measureAssignmentId
                      ? getUrl(result.measureAssignmentId, !!result.solved)
                      : ''
                  "
                  [linkText]="'MeasureAssignment' | translate"
                ></simple-assessment>
              }
            </div>
          </div>
        }
        @if (model().entities.length) {
          <div class="content">
            <div class="content-value flex-column">
              @for (entity of model().entities; track $index) {
                <div
                  class="entity"
                  [ngClass]="{
                    opened: entity.actionsOpened,
                  }"
                  (click)="entity.actionsOpened = !entity.actionsOpened"
                >
                  <simple-entity [entity]="entity"></simple-entity>
                  <div
                    class="value"
                    [ngClass]="{
                      'has-errors': entity.numberOfErrors > 0,
                    }"
                    [title]="'AmountOfErrors' | translate"
                  >
                    {{
                      entity.numberOfErrors > 0
                        ? entity.numberOfErrors +
                          " " +
                          ("Issues" | translate | lowercase)
                        : "OK"
                    }}
                  </div>
                  <i
                    class="icon"
                    [ngClass]="{
                      'bi-chevron-up': entity.actionsOpened,
                      'bi-chevron-down': !entity.actionsOpened,
                    }"
                  >
                  </i>
                  <simple-link-button
                    [url]="entity.url"
                    [name]="entity.name"
                    [shouldStopPropagation]="true"
                  >
                  </simple-link-button>
                </div>
                @if (entity.actionsOpened) {
                  <div
                    class="entity-content"
                    [ngClass]="{
                      last: $index === model().entities.length - 1,
                    }"
                  >
                    <div class="content">
                      <div class="content-header">
                        {{ "Assessments" | translate }}
                      </div>
                      <div class="content-value flex-column">
                        @for (result of entity.results; track $index) {
                          <simple-assessment
                            [task]="result.task"
                            [choice]="result.task.choice"
                            [comment]="result.comment"
                            [media]="result.media"
                            [displayInfoOnClick]="true"
                            [infoText]="
                              result.task.choice.isError
                                ? ('MeasureAssignment' | translate) +
                                  ' ' +
                                  ((result.solved ? 'Handled' : 'NotHandled')
                                    | translate
                                    | lowercase)
                                : ''
                            "
                            [url]="
                              result.measureAssignmentId
                                ? getUrl(
                                    result.measureAssignmentId,
                                    !!result.solved
                                  )
                                : ''
                            "
                            [linkText]="'MeasureAssignment' | translate"
                          ></simple-assessment>
                        }
                      </div>
                    </div>
                  </div>
                }
              }
            </div>
          </div>
        }
        <div class="content">
          <div class="content-header">
            {{ "CompletedBy" | translate }}
          </div>
          @if (model().completedByUser) {
            <div class="content-value">
              <simple-view-connections [users]="[model().completedByUser]">
              </simple-view-connections>
            </div>
            @if (model().isSSEN() && model().performed) {
              <div class="performed-date">
                {{ "PerformedDate" | translate }}:
                {{ model().performed | datex }}
              </div>
            }
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</simple-view-tab>
