import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "translateEach",
})
export class TranslateCommaSeparatedPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string) {
    if (!value) return "";

    const values = value.includes(",") ? value.split(",") : [value];
    return values
      .map((val) => val?.trim())
      .filter((val) => val)
      .map((val) => this.translateService.instant(val))
      .join(", ");
  }
}
