<div
  class="feedback-action"
  [ngClass]="{
    opened: popoverOpened(),
    'in-modal': isInModal(),
    disabled: wasSent() || wasNotSent() || sending() || loading(),
  }"
  [title]="'ContactSupport' | translate"
  (click)="feedbackTemplate.toggle($event)"
>
  @if (!sending() && !wasSent() && !wasNotSent() && !screenshot()) {
    <i class="bi bi-chat-heart"></i>
  }
  @if (screenshot()) {
    <i class="bi bi-envelope-paper"></i>
  }
  @if (sending()) {
    <i class="bi bi-send"></i>
  }
  @if (wasSent()) {
    <i class="bi bi-envelope-check"></i>
  }
  @if (wasNotSent()) {
    <i class="bi bi-envelope-slash"></i>
  }
</div>
<p-popover
  #feedbackTemplate
  (onShow)="popoverOpened.set(true); focusTextarea()"
  (onHide)="popoverOpened.set(false)"
>
  <div
    class="feedback-wrapper"
    [ngClass]="{
      loadmask: loading(),
    }"
  >
    <div class="feedback">
      <textarea
        #textarea
        id="textarea"
        class="feedback-textarea apx-scrollbar"
        [placeholder]="'DescribeCase' | translate"
        [rows]="10"
        [maxLength]="maxLength()"
        [(ngModel)]="feedback().description"
      >
      </textarea>
      <span class="counter">
        {{ feedback().description.length }} / {{ maxLength() }}
      </span>
    </div>
    <button
      class="btn btn-success"
      [disabled]="
        !feedback().description.length ||
        feedback().description.length > maxLength()
      "
      (click)="submit(feedbackTemplate)"
    >
      {{ "Send" | translate }}
    </button>
    @if (loading()) {
      <dots-loader></dots-loader>
    }
  </div>
</p-popover>
