import { Component, computed, Signal } from "@angular/core";
import { SimpleCountsComponent } from "../../../../../various/counts/simple-counts.component";
import { CountContent } from "../../../columns/simple-table-count-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-count",
  templateUrl: "./column-type-count.component.html",
  standalone: true,
  imports: [SimpleCountsComponent],
})
export class ColumnTypeCountComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<CountContent>;
}
