export const RoutesUtils = {
  list: "list",
  grid: "grid",
  idParam: ":id",
  orgParam: ":org",
  viewQueryParam: "?view=",
  assignments: "assignments",
  scheduledOngoing: "scheduled-ongoing",
  scheduledArchived: "scheduled-archived",
  measureOngoing: "measure-ongoing",
  measureArchived: "measure-archived",
  assignmentTemplates: "assignment-templates",
  schedules: "schedules",
  facilities: "facilities",
  entities: "entities",
  users: "users",
  userGroups: "user-groups",
  groups: "groups",
  statistics: "statistics",
  library: "library",
  organization: "organization",
  myOrganization: "my-organization",
  administration: "administration",
  notification: "notification",
  templateTypes: "template-types",
  tasks: "tasks",
  choices: "choices",
  profile: "profile",
  empty: "",
  base: "/",
  notSpecified: "**",
  webhook: "webhook",
  client: "client",
  dashboard: "dashboard",

  // Invitation related routes.
  invitation: "invitation",

  // Singulars
  assignment: "assignment",
  assignmentTemplate: "assignmentTemplate",
  templateType: "templateType",
  user: "user",
  userGroup: "userGroup",
  current: "current",
  schedule: "schedule",
  facility: "facility",
  entity: "entity",
  task: "task",
  choice: "choice",
  admin: "admin",

  itemId: "itemId",
  new: "new",

  searchParamString: "search",

  sortParamString: "sort",

  wikiCrudParams: {
    new: "new",
    edit: "edit",
    view: "view",
  },

  clickUpActions: {
    postFeedback: "post-feedback",
  },

  // Environment related objects.
  devEnv: {
    idServer: "https://login-dev.inquinova.com",
    coreApi: "https://core-development.azurewebsites.net/api/web/",
    inspectionApi: "https://inspection-development.azurewebsites.net/api/web/",
    coreUrl: "https://admin-dev.inquinova.com",
    storageUrl:
      "https://europe-west1-inspection-development-38d73.cloudfunctions.net",
  },

  localhostEnv: {
    // This will be changed when the IdentityServer.Api has been separated
    // (client and backend), to run locally as the other projects below,
    // but for now it will run against the Azure dev service.
    idServer: "http://localhost:5001",
    localIdServer: "http://localhost:5001",
    coreApi: "http://localhost:5002/api/web/",
    inspectionApi: "http://localhost:5003/api/web/",
    inspectionNightlyApi:
      "https://inspection-nightly.azurewebsites.net/api/web/",
    coreUrl: "http://localhost:4200",
    storageUrl:
      "https://europe-west1-inspection-development-38d73.cloudfunctions.net",
  },

  testEnv: {
    idServer: "test",
    coreApi: "test",
    inspectionApi: "test",
    coreUrl: "http://localhost:4200",
    storageUrl: "test",
    blobstorageUrl: "test",
  },

  stageEnv: {
    idServer: "https://login-stage.inquinova.com",
    coreApi: "https://core-stage.azurewebsites.net/api/web/",
    inspectionApi: "https://inspection-stage.azurewebsites.net/api/web/",
    coreUrl: "https://admin-stage.inquinova.com",
    storageUrl:
      "https://europe-west1-inspection-development-38d73.cloudfunctions.net",
  },

  prodEnv: {
    idServer: "https://id.inquinova.com",
    coreApi: "https://core-production.azurewebsites.net/api/web/",
    inspectionApi: "https://inspection-production.azurewebsites.net/api/web/",
    coreUrl: "https://admin.inquinova.com",
    storageUrl: "https://europe-west1-inspection-production.cloudfunctions.net",
  },
};
