import { Directive, ElementRef, input, signal, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { KeyValuePair } from "app/filter";

@Directive()
export abstract class SimpleFormControlDirective {
  focused = signal<boolean>(false);
  isRequired = signal<boolean>(false);

  label = input<string>();
  name = input<string>();
  form = input<FormGroup>();
  disabled = input<boolean>();
  maxlength = input<number>();
  serverErrors = input<KeyValuePair[]>([]);
  focusOnInit = input<boolean>(false);

  @ViewChild("input", { read: ElementRef }) input: ElementRef;

  ngOnInit() {
    if (this.disabled()) {
      this.getFormControl().disable();
    }
    this.isRequired.set(
      this.getFormControl().hasValidator(Validators.required),
    );
  }

  hasErrors() {
    const formControl = this.getFormControl();
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  getFormControl() {
    return this.form().get(this.name());
  }

  focusElement() {
    if (this.input) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 200);
    }
  }
}
