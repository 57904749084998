import { Component } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { SimpleFormCheckboxComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-checkbox/simple-form-checkbox.component";
import { SimpleFormInputComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";
import { SimpleChangeTabComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { SimpleFormColorComponent } from "../../../simple-components/crud/modal/tabs/change/form/form-color/simple-form-color.component";
import { Choice } from "../../choice";

@Component({
  selector: "change-details-tab",
  templateUrl: "./change-details-tab.component.html",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormInputComponent,
    SimpleFormColorComponent,
    SimpleFormCheckboxComponent,
    TranslateModule,
  ],
})
export class ChangeDetailsTabComponent extends ChangeDetailsBaseDirective<Choice> {
  ngOnInit() {
    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.value]: [
          this.modifiedModel().value,
          [Validators.required, Validators.maxLength(3)],
        ],
        [this.propertyStrings.color]: [
          this.modifiedModel().color,
          Validators.required,
        ],
        [this.propertyStrings.isError]: [
          this.modifiedModel().isError,
          this.rulesHelper,
        ],
        [this.propertyStrings.isDefault]: [
          this.modifiedModel().isDefault,
          this.rulesHelper,
        ],
        [this.propertyStrings.isUnspecified]: [
          this.modifiedModel().isUnspecified,
          this.rulesHelper,
        ],
        [this.propertyStrings.allowPriority]: [
          this.modifiedModel().allowPriority,
          this.rulesHelper,
        ],
      }),
    );
    super.ngOnInit();
  }

  private rulesHelper(control: AbstractControl) {
    if (control.parent) {
      const form = control.parent;
      const controlName = Object.keys(form.controls).find(
        (key) => form.controls[key] === control,
      );
      const isError = form.get("isError");
      const isDefault = form.get("isDefault");
      const isUnspecified = form.get("isUnspecified");
      const allowPriority = form.get("allowPriority");

      if (controlName === "isError" && !control.value) {
        allowPriority.setValue(control.value);
      } else if (controlName === "isDefault" && !control.value) {
        isUnspecified.setValue(control.value);
      } else if (controlName === "isUnspecified" && control.value) {
        isDefault.setValue(control.value);
        isError.setValue(!control.value);
      } else if (controlName === "allowPriority" && control.value) {
        isError.setValue(control.value);
      } else if (controlName === "isError" && control.value) {
        isUnspecified.setValue(!control.value);
      }
    }
    return null;
  }
}
