import { Component, input, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { SimpleCrudModalComponent } from "../../../../components/simple-components/crud/modal/simple-crud-modal.component";
import { NotificationMessage } from "../notification-message";
import { NotificationMessageDetailsComponent } from "./details/notification-message-details.component";

@Component({
  selector: "notification-message-modal",
  templateUrl: "./notification-message-modal.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    NotificationMessageDetailsComponent,
    TranslateModule,
  ],
})
export class NotificationMessageModalComponent extends CreateEditBaseDirective<NotificationMessage> {
  notificationMessage = input<NotificationMessage>();

  notificationMessageDetailsComponent = viewChild(
    NotificationMessageDetailsComponent,
  );

  initiate() {
    setTimeout(() => {
      if (this.notificationMessageDetailsComponent().isReleaseType()) {
        this.notificationMessageDetailsComponent().initiateFlyingRocket();
      }
    });
  }
}
