import { Component, computed, Signal } from "@angular/core";
import { DatexPipe } from "app/pipes/datex.pipe";
import { DateContent } from "../../../columns/simple-table-date-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-date",
  templateUrl: "./column-type-date.component.html",
  styleUrl: "./column-type-date.component.less",
  standalone: true,
  imports: [DatexPipe],
})
export class ColumnTypeDateComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<DateContent>;
}
