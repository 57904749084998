<simple-change-tab [form]="form()">
  <div class="change-section-layout-wrapper">
    <div class="change-section-layout">
      <div class="change-section">
        <div class="change-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Name' | translate"
          [name]="propertyStrings.name"
          [form]="form()"
          [maxlength]="100"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!modifiedModel().id"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'Abbreviation' | translate"
          [name]="propertyStrings.abbreviation"
          [form]="form()"
          [maxlength]="10"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-select-button
          [label]="'BaseType' | translate"
          [name]="propertyStrings.templateBaseTypeId"
          [form]="form()"
          [disabled]="!!modifiedModel().id"
          [items]="selectableTemplateBaseTypeItems()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select-button>
      </div>
      <div class="change-section">
        <div class="change-section-header">
          {{ "UrgentChoice" | translate }}
        </div>
        <!-- <choices TODO
          #component
          [items]="
            modifiedModel().notificationChoice
              ? [modifiedModel().notificationChoice]
              : []
          "
          [actionTitle]="'Add' | translate"
          [useButtonAction]="true"
          [hideAddButton]="!!modifiedModel().notificationChoice"
          [useSingleSelectionInList]="true"
          (onModelsChange)="handleChoicesChange($event)"
        >
        </choices> -->
        @if (notificationChoiceErrorMessages().length) {
          <div class="error-messages">
            @for (message of notificationChoiceErrorMessages(); track $index) {
              <div class="error-message">&bull; {{ message | translate }}</div>
            }
          </div>
        }
      </div>
    </div>
  </div>
</simple-change-tab>
