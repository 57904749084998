import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { UserSettingsItem } from "app/components/user/user";
import { RoutesUtils } from "app/tools/routes-utils";

export class Organization extends CrudItem<Organization> {
  name: string = "";
  publicName: string = "";
  organizationNumber: string = "";
  logotypeUrl: string = "";
  operationsManager: string = "";
  friendlyUrl: string = "";
  hasDfIntegration: boolean = false;
  hasInfraControlIntegration: boolean = false;
  emails: Email[] = [];
  phones: Phone[] = [];
  addresses: Address[] = [];
  emailObject: Email;
  phoneObject: Phone;
  addressObject: Address;
  emailAddress: string = "";
  countryCode: string = null; // Needs to be instantiated with null instead of empty as it is used for the ng-select lib, and is optional.
  phoneNumber: string = "";
  streetAddress: string = "";
  zipCode: string = "";
  city: string = "";
  country: string = null; // Needs to be instantiated with null instead of empty as it is used for the ng-select lib, and is optional.
  culture: string = "";
  isTest: boolean = false;
  parentId: number;
  parentOrganization: Organization;
  selectableCountryCodes: UserSettingsItem[] = [];
  selectableCountries: UserSettingsItem[] = [];
  selectableCultures: UserSettingsItem[] = [];
  id: any; // måste eftersom är enda gången id är number............

  get orgName() {
    return this.publicName ? this.publicName : this.name;
  }

  constructor(organization: Partial<Organization>) {
    super();
    Object.assign(this, organization);
    this.mapData();
    this.setUrl(RoutesUtils.myOrganization);
  }

  private mapData() {
    this.emailObject = this.emails.length
      ? new Email(
          this.emails.find((email) => email.isDefault) ?? this.emails[0],
        )
      : null;
    if (this.addresses.length) {
      const address = this.addresses.find(
        (address) => address.addressType === "MailingAddress",
      );
      if (address) {
        this.addressObject = new Address(address);
      } else {
        this.addressObject = new Address(this.addresses[0]);
      }
    }
    if (this.phones.length) {
      const phone = this.phones.find((phone) => phone.phoneType === "Work");
      if (phone) {
        this.phoneObject = new Phone(phone);
      } else {
        this.phoneObject = new Phone(this.phones[0]);
      }
    }
    if (this.emailObject) {
      this.emailAddress = this.emailObject.emailAddress;
    }
    if (this.phoneObject) {
      this.countryCode =
        this.phoneObject.countryCode === "0"
          ? null
          : this.phoneObject.countryCode;
      this.phoneNumber = this.phoneObject.number;
    }
    if (this.addressObject) {
      this.streetAddress = this.addressObject.streetAddress;
      this.zipCode = this.addressObject.zipCode;
      this.city = this.addressObject.city;
      this.country = this.addressObject.country;
    }
  }

  toPayloadObject() {
    return new OrganizationPayload({
      id: this.id,
      name: this.name,
      publicName: this.publicName,
      organizationNumber: this.organizationNumber,
      email: this.emailAddress,
      operationsManager: this.operationsManager ? this.operationsManager : null,
      culture: this.culture,
      parentId: this.parentId,
      isTest: this.isTest,
      logotypeUrl: this.logotypeUrl,
    });
  }
}

export class OrganizationPayload extends Organization {
  email: string;

  constructor(organizationPayload: Partial<OrganizationPayload>) {
    super(organizationPayload);
    Object.assign(this, organizationPayload);
  }
}

export class Email {
  id: number;
  emailAddress: string = "";
  organizationId: number;
  emailType: string = "Default";
  isDefault: boolean = true;

  constructor(email: Partial<Email>) {
    Object.assign(this, email);
  }
}

export class Phone {
  id: number;
  countryCode: string = "";
  number: string = "";
  organizationId: number;
  phoneType: string = "Work";

  constructor(phone: Partial<Phone>) {
    Object.assign(this, phone);
  }
}

export class Address {
  id: number;
  streetAddress: string = "";
  streetAddress2: string = "";
  zipCode: string = "";
  city: string = "";
  country: string = "";
  organizationId: number;
  addressType: string = "MailingAddress";
  county: string = "someCounty";

  constructor(address: Partial<Address>) {
    Object.assign(this, address);
  }
}

export class Client extends Organization {
  organizationId: number;
  email: string = "";
  number: string = "";

  constructor(client: Partial<Client>) {
    super(client);
    Object.assign(this, client);
    this.emailAddress = this.email ? this.email : this.emailAddress;
    this.phoneNumber = this.number;
  }

  toPayloadObject() {
    return new ClientPayload({
      id: this.id,
      name: this.name,
      organizationNumber: this.organizationNumber,
      email: this.emailAddress,
      countryCode: this.countryCode,
      number: this.phoneNumber,
      operationsManager: this.operationsManager ? this.operationsManager : null,
      streetAddress: this.streetAddress,
      zipCode: this.zipCode,
      city: this.city,
      country: this.country,
      organizationId: this.organizationId,
    });
  }
}

export class ClientPayload extends Client {
  constructor(clientPayload: Partial<ClientPayload>) {
    super(clientPayload);
    Object.assign(this, clientPayload);
  }
}

export class OrganizationTreeNode extends Organization {
  public organizations: OrganizationTreeNode[] = [];

  constructor(o: any) {
    super(o);

    if (o.organizations) {
      o.organizations.forEach((subOrg: any) => {
        this.organizations.push(new OrganizationTreeNode(subOrg));
      });
    }
  }
}

// Organization tree (Inspire Tree-compatible interfaces)
interface ITreeState {
  collapsed: boolean;
  selected: boolean;
  isActive: boolean;
}

interface ITree {
  state: ITreeState;
  li: {};
}

// Organization tree node (represents a single node in the tree)
export class OrganizationTreeItem {
  id: number;
  realId: number;
  text: string;
  name: string;
  publicName: string;
  url: string;
  childrenCount: number;
  isTest: boolean;
  children: OrganizationTreeItem[] | boolean;
  itree: ITree;

  constructor(treeItem: Partial<OrganizationTreeItem>) {
    Object.assign(this, treeItem);

    this.text = this.publicName ? this.publicName : this.name;

    // Display the number of sub organizations
    if (this.childrenCount) {
      this.text = this.text + " - " + this.childrenCount;
    }

    // Are there suborganizations? Do initialize recursively
    if (
      this.children &&
      typeof this.children === "object" &&
      this.children.length
    ) {
      this.children = this.children
        .slice()
        .map((subOrg) => new OrganizationTreeItem(subOrg));
    } else {
      // Suborganizations will be lazy loaded
      if (this.childrenCount) {
        this.children = true;
      } else {
        delete this.children;
      }
    }

    if (this.itree) {
      // Leaves should be collapsed
      if (!this.childrenCount) {
        this.itree.state.collapsed = true;
      }
    }
  }
}
