import { inject, Injectable, signal } from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";
import { Settings } from "luxon";
import { PrimeNG } from "primeng/config";
import { MapConfig } from "./components/google-maps/google-maps.component";
import {
  CultureSettings,
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
  User,
  UserSettingsItem,
} from "./components/user/user";
import { BaseService } from "./services/base.service";
import { UserSupportLocalStorageService } from "./services/user-support-local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AppInitializer extends BaseService<User> {
  cultureSettings: CultureSettings;
  roles: UserSettingsItem[] = [];

  initiated = signal(false);

  private userSupportLocalStorageService = inject(
    UserSupportLocalStorageService,
  );

  constructor(private primengConfig: PrimeNG) {
    super(User);
  }

  async initiate() {
    this.initiateLanguages();

    // User support config.
    this.userSupportLocalStorageService.setInitialUserConfig(
      this.globalState.loggedInUser().userConfig,
    );

    // Get map api
    this.getMap();

    await this.initiateCultureSettings();
    await this.initiateRoles();

    this.initiated.set(true);
  }

  private initiateLanguages() {
    let language = this.globalState.loggedInUser().language.toLowerCase();
    language = SUPPORTED_LANGUAGES.some((o) => o.indexOf(language) >= 0)
      ? language
      : DEFAULT_LANGUAGE;

    this.translationService.addLangs(SUPPORTED_LANGUAGES);
    this.translationService.setDefaultLang(DEFAULT_LANGUAGE);
    this.translationService.use(language);
    this.translationService
      .get("primeng")
      .subscribe((res) => this.primengConfig.setTranslation(res));

    Settings.defaultLocale = language.replace("no", "nb");
    Settings.defaultZone = this.globalState.loggedInUser().timeZoneIANA;
  }

  private getMap() {
    const loader = new Loader({
      apiKey: MapConfig.apiKey,
      version: "weekly",
      libraries: ["marker"],
    });
    loader.importLibrary("maps");
  }

  private async initiateCultureSettings() {
    try {
      const response = await this.hostedHttpClient.get(
        `user/culturesettings`,
        true,
      );
      this.cultureSettings = new CultureSettings(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  private async initiateRoles() {
    try {
      const response = await this.hostedHttpClient.get("role", true);
      this.roles = this.extractData(response).map(
        (role: UserSettingsItem) => new UserSettingsItem(role),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  getCountryName(value: string) {
    const country = this.cultureSettings.countries.find((c) => c.id === value);
    if (country) {
      return country.text;
    } else {
      return "";
    }
  }

  getTimeZoneName(value: string) {
    const timeZone = this.cultureSettings.timeZones.find(
      (tz) => tz.id === value,
    );
    if (timeZone) {
      return timeZone.text;
    } else {
      return "";
    }
  }

  getCultureName(value: string) {
    const culture = this.cultureSettings.cultures.find((c) => c.id === value);
    if (culture) {
      return culture.text;
    } else {
      return "";
    }
  }
}
