import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { Entity } from "app/components/entity/entity";
import { Facility } from "app/components/facility/facility";
import { Schedule, ScheduleForViewing } from "app/components/schedule/schedule";
import { UserGroup } from "app/components/user-group/user-group";
import { User } from "app/components/user/user";
import { SimpleEntityComponent } from "../entity/simple-entity.component";
import { SimpleFacilityComponent } from "../facility/simple-facility.component";
import { SimpleLinkButtonComponent } from "../link-button/simple-link-button.component";
import { SimpleUserComponent } from "../user/simple-user.component";

@Component({
  selector: "simple-view-connections",
  templateUrl: "./simple-view-connections.component.html",
  styleUrls: ["./simple-view-connections.component.less"],
  standalone: true,
  imports: [
    SimpleLinkButtonComponent,
    TranslateModule,
    SimpleEntityComponent,
    SimpleFacilityComponent,
    SimpleUserComponent,
  ],
})
export class SimpleViewConnectionsComponent {
  userGroupsAsLinks = input<UserGroup[]>([]);
  userGroupsWithButtons = input<UserGroup[]>([]);
  assignmentTemplatesAsLinks = input<AssignmentTemplate[]>([]);
  assignmentTemplatesWithButtons = input<AssignmentTemplate[]>([]);
  schedules = input<Schedule[]>([]);
  schedulesForUsers = input<ScheduleForViewing[]>([]);
  users = input<User[]>([]);
  entities = input<Entity[]>([]);
  facility = input<Facility>();
  disabled = input<boolean>();
}
