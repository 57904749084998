import { Component } from '@angular/core';
import { SimpleFormControlDirective } from '../simple-form-control.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { SimpleFormErrorComponent } from '../form-error/simple-form-error.component';

@Component({
    selector: 'simple-form-checkbox',
    templateUrl: './simple-form-checkbox.component.html',
    styleUrls: ['./simple-form-checkbox.component.less'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass, SimpleFormErrorComponent]
})
export class SimpleFormCheckboxComponent extends SimpleFormControlDirective { }
