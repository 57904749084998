import { Injectable } from "@angular/core";
import { JsonHelper } from "app/tools/json-helper";

// Local storage keys that can be used.
export enum LocalStorageKey {
  LoggedInUser = "LoggedInUser",
  Statistics = "Statistics",
  UserSupport = "UserSupport",
  CollapsedMenu = "CollapsedMenu",
  DashboardStorage = "DashboardStorage",
  AccessData = "AccessData",
}

// These keys will be kept when the storage is cleared.
const PRESERVED_KEYS = [
  LocalStorageKey.UserSupport,
  LocalStorageKey.CollapsedMenu,
  LocalStorageKey.DashboardStorage,
  LocalStorageKey.AccessData,
];

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  getItem(key: LocalStorageKey) {
    return JsonHelper.tryToParse(localStorage.getItem(key));
  }

  setItem(key: LocalStorageKey, value: unknown) {
    localStorage.setItem(key, JsonHelper.tryToStringify(value));
  }

  removeItem(key: LocalStorageKey) {
    localStorage.removeItem(key);
  }

  // Clears storage but keeps specified items.
  clearStorage() {
    const preservedItems = [];
    PRESERVED_KEYS.forEach((key) => {
      if (this.getItem(key)) {
        preservedItems.push({
          key: key,
          value: this.getItem(key),
        });
      }
    });
    localStorage.clear();
    if (preservedItems.length) {
      preservedItems.forEach((item) => {
        this.setItem(item.key, item.value);
      });
    }
  }
}
