export class RegexUtils {
  public static EMAIL =
    /^[a-zåäöA-ZÅÄÖ0-9._%+-]+@[a-zåäöA-ZÅÄÖ0-9.-]+\.[a-zåäöA-ZÅÄÖ]{2,}$/i;

  public static PASSWORD_DIGIT = /[\d]/;
  public static PASSWORD_LOWER = /[a-zåäö]/;
  public static PASSWORD_UPPER = /[A-ZÅÄÖ]/;
  public static PASSWORD_SPECIAL = /[$@$!%*?&^.]/;
  public static PASSWORD_LENGTH = /.{6,}/;

  public static ORGANIZATION_NUMBER = /^(\d{1})(\d{5})[-]?(\d{4})$/;

  public static LATITUDE = /^[-+]?([1-8]?\d((\.|,)\d+)?|90((\.|,)0+)?)$/;
  public static LONGITUDE =
    /^[-+]?(180((\.|,)0+)?|((1[0-7]\d)|([1-9]?\d))((\.|,)\d+)?)$/;

  public static NUMBER = /^[0-9]*$/;
  public static RADIUS = /^(10[0-9]|1[1-9]\d|[2-9]\d\d|1000)$/;

  public static URL =
    /^https?:\/\/(www\.)?[-a-zåäöA-ZÅÄÖ0-9@:%._\+~#=]{2,256}\.[a-zåäö]{2,6}\b([-a-zåäöA-Z0-9@:%_\+.~#?&//=]*)/;

  public static DATE =
    /^(19|20)\d\d-(0[13578]|1[02])-(0[1-9]|[12]\d|3[01])|(19|20)\d\d-(0[469]|11)-(0[1-9]|[12]\d|30)|(19|20)\d\d-02-(0[1-9]|1\d|2[0-8])$/;

  public static TIME = /^([01]\d|2[0-3]):([0-5]\d)$/;
}
