import { Component, computed, Signal } from "@angular/core";
import { SimpleFacilityComponent } from "../../../../../various/facility/simple-facility.component";
import { FacilityContent } from "../../../columns/simple-table-facility-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-facility",
  templateUrl: "./column-type-facility.component.html",
  standalone: true,
  imports: [SimpleFacilityComponent],
})
export class ColumnTypeFacilityComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<FacilityContent>;
}
