import { NgClass } from "@angular/common";
import { Component, computed, Signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { DatexPipe } from "app/pipes/datex.pipe";
import { Utils } from "app/tools/utils";
import { PublishDateContent } from "../../../columns/simple-table-publish-date-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-publish-date",
  templateUrl: "./column-type-publish-date.component.html",
  styleUrl: "./column-type-publish-date.component.less",
  standalone: true,
  imports: [DatexPipe, NgClass, TranslateModule],
})
export class ColumnTypePublishDateComponent<
  T,
> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<PublishDateContent>;
  utils = Utils;
}
