import { Component, computed, Signal } from "@angular/core";
import { SimpleUserComponent } from "app/components/simple-components/various/user/simple-user.component";
import { UserContent } from "../../../columns/simple-table-user-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-user",
  templateUrl: "./column-type-user.component.html",
  standalone: true,
  imports: [SimpleUserComponent],
})
export class ColumnTypeUserComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<UserContent>;
}
