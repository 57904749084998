import { TemplateBaseType } from "app/components/template-type/template-type";
import { LoggedInUser } from "app/components/user/user";
import { environment } from "environments/environment";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { NumberUtils } from "./number-utils";

export enum LogLevel {
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export enum InspectionColor {
  DarkRed = "#cb1214",
  DarkOrange = "#ee7203",
  Orange = "#ff9800",
  Grey = "#9d9d9c",
  SoftBlue = "#2a94b5",
  Blue = "#1e88e5",
  SoftGreen = "#81cb12",
  Green = "#61b232",
}

export const coordinateSystems = {
  "+proj=tmerc +lat_0=0 +lon_0=18.05779 +k=0.99999425 +x_0=100178.1808 +y_0=-6500614.7836 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
    "ST74",
  SWEREF99: {
    "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 TM",
    "+proj=tmerc +lat_0=0 +lon_0=22.5563333333333 +k=1.0000049 +x_0=1500121.846 +y_0=-672.557 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 / RT90 5 gon O emulation",
    "+proj=tmerc +lat_0=0 +lon_0=23.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 23 15",
    "+proj=tmerc +lat_0=0 +lon_0=11.30625 +k=1.000006 +x_0=1500025.141 +y_0=-667.282 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 / RT90 7.5 gon V emulation",
    "+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 12 00",
    "+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 13 30",
    "+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 15 00",
    "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 16 30",
    "+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 18 00",
    "+proj=tmerc +lat_0=0 +lon_0=18.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 18 45",
    "+proj=tmerc +lat_0=0 +lon_0=14.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 14 15",
    "+proj=tmerc +lat_0=0 +lon_0=20.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 20 15",
    "+proj=tmerc +lat_0=0 +lon_0=18.0563 +k=1.0000054 +x_0=1500083.521 +y_0=-668.844 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 / RT90 0 gon emulation",
    "+proj=tmerc +lat_0=0 +lon_0=13.5562666666667 +k=1.0000058 +x_0=1500044.695 +y_0=-667.13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 / RT90 5 gon V emulation",
  },
  RT90: {
    "+proj=longlat +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +no_defs +type=crs":
      "RT90",
    "+proj=tmerc +lat_0=0 +lon_0=22.5582777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs":
      "RT90 5 gon O",
    "+proj=tmerc +lat_0=0 +lon_0=22.5563333333333 +k=1.0000049 +x_0=1500121.846 +y_0=-672.557 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 / RT90 5 gon O emulation",
    "+proj=tmerc +lat_0=0 +lon_0=11.3082777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs":
      "RT90 7.5 gon V",
    "+proj=tmerc +lat_0=0 +lon_0=11.30625 +k=1.000006 +x_0=1500025.141 +y_0=-667.282 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 / RT90 7.5 gon V emulation",
    "+proj=tmerc +lat_0=0 +lon_0=13.5582777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs":
      "RT90 5 gon V",
    "+proj=tmerc +lat_0=0 +lon_0=15.8082777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs":
      "RT90 2.5 gon V",
    "+proj=tmerc +lat_0=0 +lon_0=18.0582777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs":
      "RT90 0 gon",
    "+proj=tmerc +lat_0=0 +lon_0=20.3082777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs":
      "RT90 2.5 gon O",
    "+proj=tmerc +lat_0=0 +lon_0=18.0563 +k=1.0000054 +x_0=1500083.521 +y_0=-668.844 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 / RT90 0 gon emulation",
    "+proj=tmerc +lat_0=0 +lon_0=13.5562666666667 +k=1.0000058 +x_0=1500044.695 +y_0=-667.13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs":
      "SWEREF99 / RT90 5 gon V emulation",
  },
};

/**
 * Reusable helpers to parse different kinds of data.
 */
export class Utils {
  public static logMessage(
    message: string,
    user: LoggedInUser = null,
    level: LogLevel = LogLevel.Info,
  ) {
    if (!environment.production || (user !== null && user.isSuperAdmin())) {
      switch (level) {
        case LogLevel.Info:
          console.log(message);
          break;

        case LogLevel.Warning:
          console.warn(message);
          break;

        case LogLevel.Error:
          console.error(message);
          break;

        default:
          console.log(message);
          break;
      }
    }
  }

  public static uppercaseEachFirst(str) {
    if (typeof str === "undefined") {
      return str;
    }

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  public static nameof<T>(key: keyof T, instance?: T): keyof T {
    return key;
  }

  public static isEqual(first: any, second: any) {
    return isEqual(first, second);
  }

  public static isFutureDate(date: string): boolean {
    return (
      DateTime.fromSQL(date).toUnixInteger() > DateTime.now().toUnixInteger()
    );
  }

  public static convertToHumanDate(date: string): string {
    return DateTime.fromSQL(date).toRelativeCalendar();
  }

  public static isTodayDate(date: string): boolean {
    return DateTime.fromSQL(date).toISODate() === DateTime.now().toISODate();
  }

  public static capitalizeFirstLetter(value: string) {
    if (typeof value === "string") {
      return value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      return "";
    }
  }

  public static uncapitalizeFirstLetter(value: string) {
    if (typeof value === "string") {
      return value.charAt(0).toLowerCase() + value.slice(1);
    } else {
      return "";
    }
  }

  public static getRandomNumberString() {
    return Math.random().toString();
  }

  public static isMediumScreenSize() {
    return window.innerWidth < NumberUtils.BOOTSTRAP_LG_MIN;
  }

  public static isLargeScreenSize() {
    return window.innerWidth < NumberUtils.BOOTSTRAP_XL_MIN;
  }

  public static isStandard(templateBaseType: TemplateBaseType) {
    return templateBaseType.name === "Standard";
  }

  public static getUniqueEntriesOnly(...array: string[]) {
    return [...new Set(array)];
  }

  public static getDay(dateString: string) {
    return DateTime.fromSQL(dateString).toFormat("d");
  }

  public static getMonthName(dateString: string) {
    return DateTime.fromSQL(dateString).toFormat("LLL").replace(".", "");
  }

  public static getDayName(dateString: string) {
    return Utils.capitalizeFirstLetter(
      DateTime.fromSQL(dateString).toFormat("cccc"),
    );
  }

  public static getYear(dateString: string) {
    return DateTime.fromSQL(dateString).toFormat("y");
  }

  public static debounce(func: Function, time: number) {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, time);
    };
  }

  public static getUniqueVariant(data: unknown) {
    // TODO Byt till: const copy = items.map((item) => new Entity({ ...item }));
    return JSON.parse(JSON.stringify(data));
  }
}
