import { NgClass } from "@angular/common";
import { Component, signal, viewChild } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Facility } from "app/components/facility/facility";
import {
  GoogleMapsComponent,
  LocationObject,
} from "app/components/google-maps/google-maps.component";
import { SimpleEditorComponent } from "app/components/simple-components/crud/modal/tabs/change/form/form-editor/simple-form-editor.component";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { RegexUtils } from "app/tools/regex-utils";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormInfoComponent } from "../../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-info/simple-form-info.component";
import { SimpleFormInputComponent } from "../../../../../../app/components/simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";
import { SimpleChangeTabComponent } from "../../../../../../app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { SimpleFormSelectButtonComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-select-button/simple-form-select-button.component";

@Component({
  selector: "change-details-tab",
  templateUrl: "./change-details-tab.component.html",
  styleUrl: "./change-details-tab.component.less",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormInputComponent,
    GoogleMapsComponent,
    SimpleFormInfoComponent,
    TranslateModule,
    SimpleEditorComponent,
    NgClass,
    SimpleFormSelectButtonComponent,
  ],
})
export class ChangeDetailsTabComponent extends ChangeDetailsBaseDirective<Facility> {
  selectableStatusItems = signal<FormSelectItem[]>([]);
  selectableAccessibilityItems = signal<FormSelectItem[]>([]);

  displayGeoInfoText = signal<boolean>(false);
  address = signal<string>("");

  googleMapsComponent = viewChild(GoogleMapsComponent);

  ngOnInit() {
    this.setSelectableStatusItems();
    this.setSelectableAccessibilityItems();

    this.displayGeoInfoText.set(this.modifiedModel().hasGeoControlledTemplates);

    this.form.set(
      this.formBuilder.group(
        {
          [this.propertyStrings.name]: [
            this.modifiedModel().name,
            [Validators.required, Validators.maxLength(150)],
          ],
          [this.propertyStrings.description]: [
            this.modifiedModel().description,
            Validators.maxLength(500),
          ],
          [this.propertyStrings.status]: [
            this.modifiedModel().status,
            Validators.required,
          ],
          [this.propertyStrings.streetName]: [
            this.modifiedModel().streetName,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.streetNumber]: [
            this.modifiedModel().streetNumber,
            Validators.maxLength(15),
          ],
          [this.propertyStrings.zipCode]: [
            this.modifiedModel().zipCode,
            Validators.maxLength(15),
          ],
          [this.propertyStrings.city]: [
            this.modifiedModel().city,
            Validators.maxLength(25),
          ],
          [this.propertyStrings.latitude]: [
            this.modifiedModel().latitude,
            this.latitudePattern,
          ],
          [this.propertyStrings.longitude]: [
            this.modifiedModel().longitude,
            this.longitudePattern,
          ],
          [this.propertyStrings.radius]: [
            this.modifiedModel().radius,
            this.radiusPattern,
          ],
          [this.propertyStrings.accessible]: [
            this.modifiedModel().accessible,
            Validators.required,
          ],
          [this.propertyStrings.infoLink]: [
            this.modifiedModel().infoLink,
            [Validators.maxLength(250), this.urlPattern],
          ],
          [this.propertyStrings.externalId]: [
            this.modifiedModel().externalId,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.extraInfo]: [this.modifiedModel().extraInfo],
        },
        { validators: this.positionValidator },
      ),
    );

    if (this.modifiedModel().hasGeoControlledTemplates) {
      this.form().setValidators(this.positionValidatorAlwaysRequired);
    }

    if (
      this.modifiedModel().streetName &&
      (this.modifiedModel().city || this.modifiedModel().zipCode)
    ) {
      this.buildAddress(
        this.modifiedModel().streetName,
        this.modifiedModel().streetNumber,
        this.modifiedModel().zipCode,
        this.modifiedModel().city,
      );
    }
    super.ngOnInit();
  }

  handleMarkerMove(locationObject: LocationObject) {
    this.form()
      .get(this.modifiedModel().propertyStrings.latitude)
      .setValue(locationObject.latitude.toString());
    this.form()
      .get(this.modifiedModel().propertyStrings.longitude)
      .setValue(locationObject.longitude.toString());
    this.form()
      .get(this.modifiedModel().propertyStrings.radius)
      .setValue(locationObject.radius.toString());
  }

  protected async getAddressFromLocation() {
    if (this.validLocationData()) {
      const addressObject =
        await this.googleMapsComponent().getAddressFromLocation();
      if (addressObject) {
        this.form()
          .get(this.modifiedModel().propertyStrings.streetName)
          .setValue(addressObject.streetName ? addressObject.streetName : "");
        this.form()
          .get(this.modifiedModel().propertyStrings.streetNumber)
          .setValue(
            addressObject.streetNumber ? addressObject.streetNumber : "",
          );
        this.form()
          .get(this.modifiedModel().propertyStrings.zipCode)
          .setValue(addressObject.zipCode ? addressObject.zipCode : "");
        this.form()
          .get(this.modifiedModel().propertyStrings.city)
          .setValue(addressObject.city ? addressObject.city : "");
      }
    }
  }

  protected async getLocationFromAddress() {
    if (this.validAddressData()) {
      const locationObject =
        await this.googleMapsComponent().getLocationFromAddress();
      if (locationObject) {
        const location = new google.maps.LatLng(
          parseFloat(locationObject.latitude),
          parseFloat(locationObject.longitude),
        );
        this.googleMapsComponent().emitLocation(location);
      }
    }
  }

  protected async getCurrentLocation() {
    const locationObject =
      await this.googleMapsComponent().getCurrentLocation();
    if (locationObject) {
      const location = new google.maps.LatLng(
        parseFloat(locationObject.latitude),
        parseFloat(locationObject.longitude),
      );
      this.googleMapsComponent().emitLocation(location);
    }
  }

  protected validLocationData() {
    const latitude = this.form().get(
      this.modifiedModel().propertyStrings.latitude,
    );
    const longitude = this.form().get(
      this.modifiedModel().propertyStrings.longitude,
    );
    const radius = this.form().get(this.modifiedModel().propertyStrings.radius);
    return (
      !!latitude.value &&
      !!latitude.valid &&
      !!longitude.value &&
      !!longitude.valid &&
      !!radius.value &&
      !!radius.valid
    );
  }

  protected validAddressData() {
    const streetName = this.form().get(
      this.modifiedModel().propertyStrings.streetName,
    );
    const streetNumber = this.form().get(
      this.modifiedModel().propertyStrings.streetNumber,
    );
    const zipCode = this.form().get(
      this.modifiedModel().propertyStrings.zipCode,
    );
    const city = this.form().get(this.modifiedModel().propertyStrings.city);
    return (
      !!streetName.value &&
      !!streetName.valid &&
      !!streetNumber.value &&
      !!streetNumber.valid &&
      !!zipCode.value &&
      !!zipCode.valid &&
      !!city.value &&
      city.valid
    );
  }

  buildAddress(
    streetName: string,
    streetNumber: string,
    zipCode: string,
    city: string,
  ) {
    let address = "";
    if (streetName) {
      address += streetName;
    }
    if (streetNumber) {
      address += " " + streetNumber;
    }
    if (zipCode) {
      address += ", " + zipCode;
    }
    if (city) {
      address += " " + city;
    }
    this.address.set(address);
  }

  private setSelectableStatusItems() {
    this.selectableStatusItems.set([
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.ACTIVE),
      ),
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.INACTIVE),
      ),
    ]);
  }

  private setSelectableAccessibilityItems() {
    this.selectableAccessibilityItems.set([
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
    ]);
  }

  private latitudePattern(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.LATITUDE).test(control.value)) {
      return { latitude: true };
    } else {
      return null;
    }
  }

  private longitudePattern(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.LONGITUDE).test(control.value)
    ) {
      return { longitude: true };
    } else {
      return null;
    }
  }

  private radiusPattern(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.RADIUS).test(control.value)) {
      return { radius: true };
    } else {
      return null;
    }
  }

  private urlPattern(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.URL).test(control.value)) {
      return { url: true };
    } else {
      return null;
    }
  }

  private positionValidator(form: FormGroup): ValidationErrors {
    const latitude = form.get("latitude");
    const longitude = form.get("longitude");
    const radius = form.get("radius");

    if (latitude.value && (!longitude.value || !radius.value)) {
      if (!longitude.value) {
        longitude.setErrors({ required: true });
        longitude.markAsTouched();
      }
      if (!radius.value) {
        radius.setErrors({ required: true });
        radius.markAsTouched();
      }
    } else if (longitude.value && (!latitude.value || !radius.value)) {
      if (!latitude.value) {
        latitude.setErrors({ required: true });
        latitude.markAsTouched();
      }
      if (!radius.value) {
        radius.setErrors({ required: true });
        radius.markAsTouched();
      }
    } else if (radius.value && (!latitude.value || !longitude.value)) {
      if (!latitude.value) {
        latitude.setErrors({ required: true });
        latitude.markAsTouched();
      }
      if (!longitude.value) {
        longitude.setErrors({ required: true });
        longitude.markAsTouched();
      }
    } else if (!latitude.value && !longitude.value && !radius.value) {
      latitude.setErrors(null);
      longitude.setErrors(null);
      radius.setErrors(null);
    }
    return null;
  }

  private positionValidatorAlwaysRequired(form: FormGroup): ValidationErrors {
    const latitude = form.get("latitude");
    const longitude = form.get("longitude");
    const radius = form.get("radius");

    if (!latitude.value) {
      latitude.setErrors({ required: true });
    }
    if (!longitude.value) {
      longitude.setErrors({ required: true });
    }
    if (!radius.value) {
      radius.setErrors({ required: true });
    }
    return null;
  }
}
