import { Component, inject, signal } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Organization } from "app/components/organization/organization";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { PhoneValidationHelper } from "app/helpers/phone-validation/phone-validation-helper";
import { RegexUtils } from "app/tools/regex-utils";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormInputComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";
import { SimpleFormSelectButtonComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-select-button/simple-form-select-button.component";
import { SimpleFormSelectComponent } from "../../../../simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { SimpleChangeTabComponent } from "../../../../simple-components/crud/modal/tabs/change/simple-change-tab.component";

@Component({
  selector: "change-details-tab",
  templateUrl: "./change-details-tab.component.html",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormInputComponent,
    SimpleFormSelectComponent,
    TranslateModule,
    SimpleFormSelectButtonComponent,
  ],
})
export class ChangeDetailsTabComponent extends ChangeDetailsBaseDirective<Organization> {
  selectableCountryCodeItems = signal<FormSelectItem[]>([]);
  selectableCountryItems = signal<FormSelectItem[]>([]);
  selectableCultureItems = signal<FormSelectItem[]>([]);
  selectableIsTestItems = signal<FormSelectItem[]>([]);
  selectableOrganizationItems = signal<FormSelectItem[]>([]);

  private phoneValidationHelper = inject(PhoneValidationHelper);

  ngOnInit() {
    this.setSelectableCountryCodeItems();
    this.setSelectableCountryItems();
    this.setSelectableCultureItems();
    this.setSelectableIsTestItems();
    this.setSelectableOrganizationItems();

    if (this.isEdit) {
      this.form.set(
        this.formBuilder.group(
          {
            [this.propertyStrings.name]: [
              this.modifiedModel().name,
              [Validators.required, Validators.maxLength(50)],
            ],
            [this.propertyStrings.publicName]: [
              this.modifiedModel().publicName,
              Validators.maxLength(50),
            ],
            [this.propertyStrings.organizationNumber]: [
              this.modifiedModel().organizationNumber,
              [Validators.required, this.organizationNumberValidator],
            ],
            [this.propertyStrings.emailAddress]: [
              this.modifiedModel().emailAddress,
              [Validators.required, this.emailValidator],
            ],
            [this.propertyStrings.countryCode]: [
              this.modifiedModel().countryCode,
              this.countryCodeTriggerPhoneValidator,
            ],
            [this.propertyStrings.phoneNumber]: [
              this.modifiedModel().phoneNumber,
              this.phoneValidationHelper.phoneNumberValidator(
                this.propertyStrings.countryCode,
              ),
            ],
            [this.propertyStrings.operationsManager]: [
              this.modifiedModel().operationsManager,
              Validators.maxLength(50),
            ],
            [this.propertyStrings.culture]: [
              this.modifiedModel().culture,
              Validators.required,
            ],
            [this.propertyStrings.streetAddress]: [
              this.modifiedModel().streetAddress,
              Validators.maxLength(50),
            ],
            [this.propertyStrings.zipCode]: [
              this.modifiedModel().zipCode,
              Validators.maxLength(15),
            ],
            [this.propertyStrings.city]: [
              this.modifiedModel().city,
              Validators.maxLength(50),
            ],
            [this.propertyStrings.country]: [this.modifiedModel().country],
            [this.propertyStrings.parentId]: [
              this.modifiedModel().parentId,
              Validators.required,
            ],
            [this.propertyStrings.isTest]: [
              this.modifiedModel().isTest,
              Validators.required,
            ],
          },
          {
            validators: [this.countryCodePhoneValidator, this.addressValidator],
          },
        ),
      );
    } else {
      this.form.set(
        this.formBuilder.group({
          [this.propertyStrings.name]: [
            this.modifiedModel().name,
            [Validators.required, Validators.maxLength(50)],
          ],
          [this.propertyStrings.publicName]: [
            this.modifiedModel().publicName,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.organizationNumber]: [
            this.modifiedModel().organizationNumber,
            [Validators.required, this.organizationNumberValidator],
          ],
          [this.propertyStrings.emailAddress]: [
            this.modifiedModel().emailAddress,
            [Validators.required, this.emailValidator],
          ],
          [this.propertyStrings.operationsManager]: [
            this.modifiedModel().operationsManager,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.culture]: [
            this.modifiedModel().culture,
            Validators.required,
          ],
          [this.propertyStrings.parentId]: [
            this.modifiedModel().parentId,
            Validators.required,
          ],
          [this.propertyStrings.isTest]: [
            this.modifiedModel().isTest,
            Validators.required,
          ],
        }),
      );
    }
    super.ngOnInit();
  }

  private organizationNumberValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.ORGANIZATION_NUMBER).test(control.value)
    ) {
      return { organizationNumber: true };
    } else {
      return null;
    }
  }

  private emailValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
      return { email: true };
    } else {
      return null;
    }
  }

  private countryCodePhoneValidator(form: FormGroup): ValidationErrors {
    const countryCode = form.get("countryCode");
    const phoneNumber = form.get("phoneNumber");

    if (countryCode.value && !phoneNumber.value) {
      phoneNumber.setErrors({ required: true });
      phoneNumber.markAsTouched();
    } else if (phoneNumber.value && !countryCode.value) {
      countryCode.setErrors({ required: true });
      countryCode.markAsTouched();
    } else if (!countryCode.value && !phoneNumber.value) {
      countryCode.setErrors(null);
      phoneNumber.setErrors(null);
    }
    return null;
  }

  private countryCodeTriggerPhoneValidator(control: AbstractControl) {
    const form = control.parent;
    if (form && control.value) {
      const phone = form.get("phoneNumber");
      phone.updateValueAndValidity();
    }
  }

  private addressValidator(form: FormGroup): ValidationErrors {
    const streetAddress = form.get("streetAddress");
    const zipCode = form.get("zipCode");
    const city = form.get("city");
    const country = form.get("country");

    if (
      streetAddress.value &&
      (!zipCode.value || !city.value || !country.value)
    ) {
      if (!zipCode.value) {
        zipCode.setErrors({ required: true });
        zipCode.markAsTouched();
      }
      if (!city.value) {
        city.setErrors({ required: true });
        city.markAsTouched();
      }
      if (!country.value) {
        country.setErrors({ required: true });
        country.markAsTouched();
      }
    } else if (
      zipCode.value &&
      (!streetAddress.value || !city.value || !country.value)
    ) {
      if (!streetAddress.value) {
        streetAddress.setErrors({ required: true });
        streetAddress.markAsTouched();
      }
      if (!city.value) {
        city.setErrors({ required: true });
        city.markAsTouched();
      }
      if (!country.value) {
        country.setErrors({ required: true });
        country.markAsTouched();
      }
    } else if (
      city.value &&
      (!streetAddress.value || !zipCode.value || !country.value)
    ) {
      if (!streetAddress.value) {
        streetAddress.setErrors({ required: true });
        streetAddress.markAsTouched();
      }
      if (!zipCode.value) {
        zipCode.setErrors({ required: true });
        zipCode.markAsTouched();
      }
      if (!country.value) {
        country.setErrors({ required: true });
        country.markAsTouched();
      }
    } else if (
      country.value &&
      (!streetAddress.value || !zipCode.value || !city.value)
    ) {
      if (!streetAddress.value) {
        streetAddress.setErrors({ required: true });
        streetAddress.markAsTouched();
      }
      if (!zipCode.value) {
        zipCode.setErrors({ required: true });
        zipCode.markAsTouched();
      }
      if (!city.value) {
        city.setErrors({ required: true });
        city.markAsTouched();
      }
    } else if (
      !streetAddress.value &&
      !zipCode.value &&
      !city.value &&
      !country.value
    ) {
      streetAddress.setErrors(null);
      zipCode.setErrors(null);
      city.setErrors(null);
      country.setErrors(null);
    }
    return null;
  }

  private setSelectableCountryCodeItems() {
    this.selectableCountryCodeItems.set(
      this.modifiedModel().selectableCountryCodes.map(
        (countryCode) =>
          new FormSelectItem(countryCode.id, `+${countryCode.id}`),
      ),
    );
  }

  private setSelectableCountryItems() {
    this.selectableCountryItems.set(
      this.modifiedModel().selectableCountries.map(
        (country) => new FormSelectItem(country.id, country.text),
      ),
    );
  }

  private setSelectableCultureItems() {
    this.selectableCultureItems.set(
      this.modifiedModel()
        .selectableCultures.filter((culture) => culture.id !== "nb-NO")
        .map((culture) => new FormSelectItem(culture.id, culture.text)),
    );
  }

  private setSelectableIsTestItems() {
    this.selectableIsTestItems.set([
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
    ]);
  }

  private setSelectableOrganizationItems() {
    if (this.modifiedModel().parentOrganization) {
      this.selectableOrganizationItems.set([
        new FormSelectItem(
          this.modifiedModel().parentId,
          new Organization(this.modifiedModel().parentOrganization).orgName,
        ),
      ]);
    }
  }
}
