<div
  class="image"
  [ngStyle]="{
    width: size() ? size() + 'px' : '32px',
    height: size() ? size() + 'px' : '32px',
  }"
  (mouseenter)="url() ? imageTemplate.show($event) : null"
  (mouseleave)="url() ? imageTemplate.hide() : null"
>
  <extensive-image
    [imageUrl]="url()"
    [imageStorageSize]="size() ? size() : 32"
    [rounded]="rounded()"
    [placeholderImageType]="placeholderImageType()"
  >
  </extensive-image>
</div>

<p-popover #imageTemplate>
  <div class="image-large">
    <extensive-image
      [imageUrl]="url()"
      [imageStorageSize]="160"
      [rounded]="rounded()"
    >
    </extensive-image>
  </div>
</p-popover>
