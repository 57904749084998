@if (choice() && task()) {
  <div
    class="assessment"
    [ngClass]="{
      clickable: displayInfoOnClick(),
      'with-padding': displayInfoDirectly(),
    }"
    (click)="displayInfoOnClick() ? visible.set(true) : null"
  >
    <simple-choices [choices]="[choice()]" [hideRules]="true"></simple-choices>
    <simple-task [task]="task()"></simple-task>
    <div class="text">
      {{ infoText() }}
    </div>
    @if (url()) {
      <simple-link-button
        [url]="url()"
        [name]="linkText()"
        [shouldStopPropagation]="true"
      ></simple-link-button>
    }
  </div>
}

@if (displayInfoDirectly()) {
  <div class="info-wrapper">
    <ng-container *ngTemplateOutlet="infoContent"></ng-container>
  </div>
}

<p-drawer
  [(visible)]="visible"
  [position]="'left'"
  [showCloseIcon]="false"
  [appendTo]="'body'"
>
  <ng-template #header>
    <simple-assessment [task]="task()" [choice]="choice()"></simple-assessment>
    <div class="actions">
      <div
        class="action"
        [title]="'Close' | translate"
        (click)="visible.set(false)"
      >
        <i class="icon bi-x-lg"></i>
      </div>
    </div>
  </ng-template>
  <div class="info-wrapper with-padding">
    <ng-container *ngTemplateOutlet="infoContent"></ng-container>
  </div>
</p-drawer>

<ng-template #infoContent>
  <div class="info">
    <div class="info-header">
      {{ "Comment" | translate }}
    </div>
    @if (comment()) {
      <div class="info-value">
        {{ comment() }}
      </div>
    } @else {
      <div class="dummy-text">
        {{ "NotSpecified" | translate }}
      </div>
    }
  </div>
  <div class="info">
    <div class="info-header">
      {{ "Images" | translate }}
    </div>
    @if (media()?.length) {
      <div class="info-value">
        @for (item of media(); track $index) {
          <simple-image [url]="item.url" [size]="52"></simple-image>
        }
      </div>
    } @else {
      <div class="dummy-text">
        {{ "NotSpecified" | translate }}
      </div>
    }
  </div>
</ng-template>
