import { Component, computed, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { ScheduleGroup, ScheduleUser } from "app/components/schedule/schedule";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableUserColumn,
  UserContent,
} from "app/components/simple-components/list/table/columns/simple-table-user-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { ListUserComponent } from "app/components/user/list-user/list-user.component";
import { User } from "app/components/user/user";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-schedule-users-tab",
  templateUrl: "./handle-schedule-users-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListUserComponent,
    TranslateModule,
  ],
})
export class HandleScheduleUsersTabComponent extends HandleItemsBaseDirective<ScheduleUser> {
  globalDummyUser = new ScheduleUser({});

  visibleListUser = signal<boolean>(false);

  selectedScheduleGroups = input<ScheduleGroup[]>([]);

  itemAsIds = computed(() => this.items().map((item) => item.user.id));

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "AddAssignees",
        "AddAssignees",
        StringUtils.icons.new,
        () => this.visibleListUser.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableUserColumn(
        ["Name", "EmailAddress"],
        (row) => new UserContent(row),
      ),
      new SimpleTableRuleColumn(
        ["Rules"],
        (row) => new RuleContent(row.rulesEditableInSchedule),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.user.getCurrentStatus(
              this.globalState.selectedOrganization().id,
            ),
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Assignees", StringUtils.icons.user),
    );
  }

  handleCloseListUser() {
    this.visibleListUser.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListUser(items: User[]) {
    this.handleCloseListUser();
    const scheduleUsers = items.map((user) => {
      const scheduleUser = new ScheduleUser(user);
      scheduleUser.user = new User(user);
      return scheduleUser;
    });
    this.items.set(scheduleUsers);
    this.onItemsChange.emit(this.items());
  }

  // initiateGlobalRules() { TODO
  //   const selectedItems = this.handleItems.filter(
  //     (model) => model.selected === true
  //   );
  //   this.globalDummyUser.canPerformScheduled = selectedItems.every(
  //     (item) => item.canPerformScheduled
  //   );
  //   this.globalDummyUser.canPerformMeasure = selectedItems.every(
  //     (item) => item.canPerformMeasure
  //   );
  //   this.globalDummyUser.canAssign = selectedItems.every(
  //     (item) => item.canAssign
  //   );
  // }

  // handleRuleClick(item: ScheduleUser, rule: RuleObject) {
  //   const propertyName = Utils.uncapitalizeFirstLetter(rule.title);
  //   item[propertyName] = !item[propertyName];

  //   if (propertyName === this.propertyStrings.canAssign && item.canAssign) {
  //     item.canPerformScheduled = true;
  //   } else if (
  //     propertyName === this.propertyStrings.canPerformScheduled &&
  //     !item.canPerformScheduled
  //   ) {
  //     item.canAssign = false;
  //   }
  // }

  // handleRuleClickGlobal(rule: RuleObject) {
  //   const propertyName = Utils.uncapitalizeFirstLetter(rule.title);
  //   this.globalDummyUser[propertyName] = !this.globalDummyUser[propertyName];
  //   const selectedItems = this.handleItems.filter(
  //     (model) => model.selected === true
  //   );
  //   selectedItems.forEach(
  //     (item) => (item[propertyName] = this.globalDummyUser[propertyName])
  //   );

  //   if (
  //     propertyName === this.propertyStrings.canAssign &&
  //     this.globalDummyUser.canAssign
  //   ) {
  //     this.globalDummyUser.canPerformScheduled = true;
  //     selectedItems.forEach((item) => (item.canPerformScheduled = true));
  //   } else if (
  //     propertyName === this.propertyStrings.canPerformScheduled &&
  //     !this.globalDummyUser.canPerformScheduled
  //   ) {
  //     this.globalDummyUser.canAssign = false;
  //     selectedItems.forEach((item) => (item.canAssign = false));
  //   }
  // }

  // override handleConfirmed(confirmedItems: User[]) {
  //   const mappedConfirmedItems = confirmedItems.map((confirmedItem) => {
  //     const existingItem = this.handleItems.find(
  //       (item) => item.user.id === confirmedItem.id
  //     );
  //     if (existingItem) {
  //       return existingItem;
  //     } else {
  //       return new ScheduleUser({
  //         user: confirmedItem,
  //         userId: confirmedItem.id,
  //       });
  //     }
  //   });
  //   this.handleItems = this.constructItemList(
  //     this.handleItems,
  //     mappedConfirmedItems
  //   );
  // }

  // override constructItemList(
  //   currentItemList: ScheduleUser[],
  //   confirmedItemList: ScheduleUser[]
  // ) {
  //   // Reduce to only confirmed items.
  //   currentItemList = currentItemList.filter((currentItem) =>
  //     confirmedItemList.find(
  //       (confirmedItem) => confirmedItem.user.id === currentItem.user.id
  //     )
  //   );

  //   // Apply only new items.
  //   currentItemList = currentItemList.concat(
  //     confirmedItemList.filter(
  //       (confirmedItem) =>
  //         !currentItemList.find(
  //           (currentItem) => currentItem.user.id === confirmedItem.user.id
  //         )
  //     )
  //   );

  //   return currentItemList;
  // }

  // override remove(itemToRemove: ScheduleUser) {
  //   this.handleItems = this.handleItems.filter(
  //     (model) => model.user.id !== itemToRemove.user.id
  //   );
  //   this.setAllAreSelected();
  //   this.setSomeAreSelected();
  //   this.emitModelsChange();
  // }

  userExistsInAnySelectedScheduleGroup(scheduleUser: ScheduleUser) {
    return this.selectedScheduleGroups().some((scheduleGroup) =>
      scheduleGroup.userGroup.users.some(
        (user) => user.id === scheduleUser.user.id,
      ),
    );
  }
}
