import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableDueDateColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => DueDateContent;

  constructor(
    labels: string[],
    getValue: (row: T) => DueDateContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.DUE_DATE;
    this.maxwidth = 110;
  }
}

export class DueDateContent {
  date: string;

  constructor(date: string) {
    this.date = date;
  }
}
