<p-drawer
  [(visible)]="visible"
  [position]="'left'"
  [showCloseIcon]="false"
  [appendTo]="'body'"
>
  <ng-template #header>
    <div class="label">
      {{ headerText() }}
    </div>
    <div class="actions">
      <div
        class="action"
        [title]="'Close' | translate"
        (click)="visible.set(false)"
      >
        <i class="icon bi-x-lg"></i>
      </div>
    </div>
  </ng-template>
  <div class="view-timeline">
    <timeline
      [items]="timeline().publicationDates()"
      [previewCount]="timeline().previewCount"
      [useSmallStyles]="true"
    >
    </timeline>
  </div>
</p-drawer>
