<p-drawer
  [(visible)]="visible"
  [position]="'left'"
  [showCloseIcon]="false"
  [appendTo]="'body'"
>
  <ng-template #header>
    <div class="label">
      {{ headerText() }}
    </div>
    <div class="actions">
      <div
        class="action"
        [title]="'Close' | translate"
        (click)="visible.set(false)"
      >
        <i class="icon bi-x-lg"></i>
      </div>
    </div>
  </ng-template>
  <div class="view-client">
    <div class="view-client-section">
      <div class="view-client-section-header">
        {{ "LegalName" | translate }}
      </div>
      <div class="view-client-section-value">
        {{ client().name }}
      </div>
    </div>
    <div class="view-client-section">
      <div class="view-client-section-header">
        {{ "OrganizationNumber" | translate }}
      </div>
      <div class="view-client-section-value">
        {{ client().organizationNumber }}
      </div>
    </div>
    <div class="view-client-section">
      <div class="view-client-section-header">
        {{ "EmailAddress" | translate }}
      </div>
      @if (client().emailAddress) {
        <div class="view-client-section-value">
          {{ client().emailAddress }}
        </div>
      } @else {
        <div class="dummy-text">
          {{ "NotSpecified" | translate }}
        </div>
      }
    </div>
    <div class="view-client-section">
      <div class="view-client-section-header">
        {{ "PhoneNumber" | translate }}
      </div>
      @if (client().countryCode && client().phoneNumber) {
        <div class="view-client-section-value">
          (+{{ client().countryCode }})
          {{ client().phoneNumber }}
        </div>
      } @else {
        <div class="dummy-text">
          {{ "NotSpecified" | translate }}
        </div>
      }
    </div>
    <div class="view-client-section">
      <div class="view-client-section-header">
        {{ "Adress" | translate }}
      </div>
      @if (client().streetAddress) {
        <div class="view-client-section-value">
          {{ client().streetAddress }}
        </div>
      }
      @if (client().zipCode || client().city) {
        <div class="view-client-section-value-wrapper">
          <div class="view-client-section-value">
            {{ client().zipCode }}
          </div>
          <div class="view-client-section-value">
            {{ client().city }}
          </div>
        </div>
      }
      @if (client().country) {
        <div class="view-client-section-value">
          {{ appInitializer.getCountryName(client().country) }}
        </div>
      }
      @if (
        !client().streetAddress &&
        !client().zipCode &&
        !client().city &&
        !client().country
      ) {
        <div class="dummy-text">
          {{ "NotSpecified" | translate }}
        </div>
      }
    </div>
    <div class="view-client-section">
      <div class="view-client-section-header">
        {{ "OperationsManager" | translate }}
      </div>
      @if (client().operationsManager) {
        <div class="view-client-section-value">
          {{ client().operationsManager }}
        </div>
      } @else {
        <div class="dummy-text">
          {{ "NotSpecified" | translate }}
        </div>
      }
    </div>
  </div>
</p-drawer>
