import { NgClass } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Choice } from "app/components/choice/choice";

@Component({
  selector: "simple-choices",
  templateUrl: "./simple-choices.component.html",
  styleUrls: ["./simple-choices.component.less"],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class SimpleChoicesComponent {
  choices = input<Choice[]>();
  editable = input<boolean>();
  disableAction = input<boolean>();
  hideRules = input<boolean>();

  onChoiceClick = output<Choice>();

  handleChoiceClick(choice: Choice) {
    this.onChoiceClick.emit(choice);
  }

  handleAddClick() {
    // TODO
  }
}
