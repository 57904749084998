import { LowerCasePipe, NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ScheduledAssignment } from "app/components/assignment/scheduled-assignment";
import { SimpleViewTabComponent } from "app/components/simple-components/crud/modal/tabs/view/simple-view-tab.component";
import { ViewDetailsBaseDirective } from "app/components/simple-components/directives/view-details-base.directive";
import { SimpleCategoriesComponent } from "app/components/simple-components/various/categories/simple-categories.component";
import { SimpleStatusLabelComponent } from "app/components/simple-components/various/status-label/simple-status-label.component";
import { DatexPipe } from "app/pipes/datex.pipe";
import { RoutesUtils } from "app/tools/routes-utils";
import { ParseMDPipe } from "../../../../../../../pipes/parse-md-pipe";
import { SimpleAssessmentComponent } from "../../../../../../simple-components/various/assessments/simple-assessment.component";
import { SimpleEntityComponent } from "../../../../../../simple-components/various/entity/simple-entity.component";
import { SimpleLinkButtonComponent } from "../../../../../../simple-components/various/link-button/simple-link-button.component";
import { SimpleViewConnectionsComponent } from "../../../../../../simple-components/various/view-connections/simple-view-connections.component";

@Component({
  selector: "view-details-tab",
  templateUrl: "./view-details-tab.component.html",
  styleUrl: "./view-details-tab.component.less",
  standalone: true,
  imports: [
    SimpleViewTabComponent,
    SimpleCategoriesComponent,
    SimpleStatusLabelComponent,
    TranslateModule,
    DatexPipe,
    NgClass,
    SimpleAssessmentComponent,
    SimpleEntityComponent,
    LowerCasePipe,
    ParseMDPipe,
    SimpleViewConnectionsComponent,
    SimpleLinkButtonComponent,
  ],
})
export class ViewDetailsTabComponent extends ViewDetailsBaseDirective<ScheduledAssignment> {
  ngOnInit() {
    this.model()
      .entities.filter((e) => e.results)
      .forEach((entity) => {
        entity.numberOfErrors = 0;
        entity.results
          .filter((r) => r.task.choice.isError)
          .forEach(() => (entity.numberOfErrors += 1));
      });
    this.model().facility.numberOfErrors = 0;
    this.model()
      .facility.results.filter((r) => r.task.choice.isError)
      .forEach(() => (this.model().facility.numberOfErrors += 1));
  }

  getUrl(id: string, isArchived: boolean) {
    return `${RoutesUtils.assignments}/${isArchived ? RoutesUtils.measureArchived : RoutesUtils.measureOngoing}?${RoutesUtils.itemId}=${id}`;
  }

  getReport() {
    //TODO
    // this.bsModalRef = this.modalService.show(StaticReportComponent, {
    //   initialState: {
    //     editModelIds: [this.viewModel.id],
    //     disableTabIndexUrl: true,
    //     useAffectableIds: true,
    //     hideText: true,
    //     isMeasure: this.viewModel.isMeasureAssignment(),
    //   },
    //   ...ConfigUtils.MODAL_CONFIG_LARGE,
    // });
    // this.subscribeToCrudModalContent();
  }
}
