import { Injectable } from "@angular/core";
import { Organization } from "app/components/organization/organization";
import { LoggedInUser } from "app/components/user/user";
import { RoutesUtils } from "app/tools/routes-utils";
import { UrlUtils } from "app/tools/url-utils";
import packageInfo from "../../../package.json";
import { FeedbackObject } from "../components/feedback/feedback.component";

@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  async createClickUpSpec(
    feedback: FeedbackObject,
    user: LoggedInUser,
    selectedOrganization: Organization,
  ) {
    const response = await fetch(
      `${UrlUtils.CLICKUP_INTERACTION}/${RoutesUtils.clickUpActions.postFeedback}`,
      {
        method: "POST",
        body: JSON.stringify({
          url: window.location.href,
          selectedOrganizationId: selectedOrganization.id,
          selectedOrganizationName: selectedOrganization.name,
          organizationId: user.organizationId,
          organizationName: user.organization.name,
          userId: user.id,
          email: user.email,
          fullName: user.fullName,
          version: packageInfo.version,
          userAgent: navigator.userAgent,
          description: feedback.description,
          screenshot: feedback.screenshot,
        }),
      },
    );
    return response;
  }
}
