import { Component, input, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { SimpleFormInfoComponent } from "app/components/simple-components/crud/modal/tabs/change/form/form-info/simple-form-info.component";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { SimpleChangeTabComponent } from "app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormSelectButtonComponent } from "../../../../../../simple-components/crud/modal/tabs/change/form/form-select-button/simple-form-select-button.component";

@Component({
  selector: "change-priority-tab",
  templateUrl: "./change-priority-tab.component.html",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    TranslateModule,
    SimpleFormInfoComponent,
    SimpleFormSelectButtonComponent,
  ],
})
export class ChangePriorityTabComponent extends ChangeDetailsBaseDirective<MeasureAssignment> {
  selectablePriorityItems = signal<FormSelectItem[]>([]);

  affectableIds = input<string[]>();

  ngOnInit() {
    this.setSelectablePriorityItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.priority]: [
          this.modifiedModel().priority,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectablePriorityItems() {
    this.selectablePriorityItems.set([
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
    ]);
  }
}
