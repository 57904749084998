import { Injectable, signal } from "@angular/core";
import { Organization } from "app/components/organization/organization";
import { LoggedInUser } from "app/components/user/user";

@Injectable({
  providedIn: "root",
})
export class GlobalStateService {
  private readonly _loggedInUser = signal<LoggedInUser | null>(null);
  private readonly _selectedOrganization = signal<Organization | null>(null);

  readonly loggedInUser = this._loggedInUser.asReadonly();
  readonly selectedOrganization = this._selectedOrganization.asReadonly();

  setLoggedInUser(user: LoggedInUser | null) {
    this._loggedInUser.set(user);
  }

  setSelectedOrganization(organization: Organization | null) {
    this._selectedOrganization.set(organization);
  }
}
