import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CountObject } from "../../list/table/columns/simple-table-count-column";

@Component({
  selector: "simple-counts",
  templateUrl: "./simple-counts.component.html",
  styleUrls: ["./simple-counts.component.less"],
  standalone: true,
  imports: [TranslateModule],
})
export class SimpleCountsComponent {
  counts = input<CountObject[]>();
}
