import { inject, Injectable } from "@angular/core";
import { FileData } from "app/tools/file-helper";
import { HostedHttpClientService } from "./hosted-httpclient.service";
import { ImportExportModelKey } from "./import-helper";
import { ORG_QUERY_PARAM } from "./import.service";

@Injectable({
  providedIn: "root",
})
export class ExportService {
  private hostedHttpClient = inject(HostedHttpClientService);

  async export(
    selectedIds: string[],
    modelName: ImportExportModelKey,
    orgId: number,
  ): Promise<FileData> {
    try {
      const endpoint = `${modelName}/export${ORG_QUERY_PARAM}${orgId.toString()}`;
      const propertyName = `${modelName}Ids`;
      const data = { [propertyName]: selectedIds };
      const response = await this.hostedHttpClient.post(endpoint, data);
      return new FileData(response["body"].data["file"]);
    } catch {
      throw new Error("Exportering av data misslyckades");
    }
  }
}
