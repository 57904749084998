<simple-crud-modal
  [visible]="visible()"
  [headerText]="'ChangeLogotype' | translate"
  [closeText]="'Close' | translate"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <simple-change-image
      [imageUrl]="modifiedModel().logotypeUrl"
      (onClear)="modifiedModel().logotypeUrl = null"
    >
    </simple-change-image>
  }
</simple-crud-modal>
