import { inject, Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { marked } from "marked";

// Workaround for the default DatePipe.
// Taken from https://stackoverflow.com/a/40160501/186787
@Pipe({
  name: "parseMD",
  standalone: true,
})
export class ParseMDPipe implements PipeTransform {
  sanitizer = inject(DomSanitizer);

  transform(value: string): SafeHtml {
    return this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.sanitizer.bypassSecurityTrustHtml(marked(value)),
    );
  }
}
