<handle-header
  [headerTitleText]="'AddedGroups' | translate"
  [headerSubtitleText]="headerSubtitleText()"
  [listActions]="listActions()"
></handle-header>

<simple-table
  [columns]="columns()"
  [headerActions]="headerActions()"
  [rowActions]="rowActions()"
  [emptyState]="emptyState()"
  [rows]="items()"
  [selectedIds]="selectedIds()"
  [addedIds]="addedIds()"
  [modifiedIds]="modifiedIds()"
  [unselectableIds]="unselectableIds()"
  [inWizard]="true"
  [totalCount]="totalCount"
  [selectedCount]="selectedCount"
  [hasSelectableData]="hasSelectableData"
  [someAreSelected]="someAreSelected"
  [allAreSelected]="allAreSelected"
  (onCheckboxClick)="handleCheckboxClick($event)"
  (onRowSingleClick)="handleRowSingleClick($event)"
  (onRowDoubleClick)="handleRowDoubleClick($event)"
  (onRowCheckboxClick)="handleRowCheckboxClick($event)"
>
</simple-table>

<list-user-group
  [visible]="visibleListUserGroup()"
  [isInModal]="true"
  [dataToSetAsSelected]="itemAsIds()"
  (onClose)="handleCloseListUserGroup()"
  (onConfirm)="handleConfirmListUserGroup($event)"
></list-user-group>

<!-- <ng-container slot="headerActions"> TODO
    <button
      *ngIf="!isMediumScreenSize()"
      class="btn btn-success"
      #setRulesPop="bs-popover"
      placement="bottom"
      containerClass="gr-menu-popover"
      [popover]="setRulesTemplate"
      [outsideClick]="true"
      [ngClass]="{
        disabled: !someAreSelected
      }"
      [title]="'SetRulesOnSelected' | translate"
      (click)="initiateGlobalRules()"
    >
      {{ "SetRulesOnSelected" | translate }}
    </button>
    <button
      *ngIf="isMediumScreenSize()"
      class="btn btn-success bi-ui-checks-grid"
      #setRulesPop="bs-popover"
      placement="bottom"
      containerClass="gr-menu-popover"
      [popover]="setRulesTemplate"
      [outsideClick]="true"
      [ngClass]="{
        disabled: !someAreSelected
      }"
      [title]="'SetRulesOnSelected' | translate"
      (click)="initiateGlobalRules()"
    ></button>
    <ng-template #setRulesTemplate>
      <div class="gr-menu-item">
        <simple-rules
          [rules]="globalDummyGroup.rulesEditableInSchedule"
          [editable]="true"
          [shouldStopPropagationOnClick]="true"
          (onRuleClick)="handleRuleClickGlobal($event)"
        >
        </simple-rules>
      </div>
    </ng-template>
  </ng-container> -->

<!-- <ng-container slot="content">
          <div class="details">
            <div
              class="name"
              title="{{ item.userGroup.name }}"
              *ngIf="item.userGroup.name"
            >
              {{ item.userGroup.name }}
            </div>
            <div
              class="description"
              title="{{ item.userGroup.users.length }} {{
                'Assignees' | translate | lowercase
              }}"
            >
              {{ item.userGroup.users.length }}
              {{ "Assignees" | translate | lowercase }}
              <view-members [members]="item.userGroup.users"> </view-members>
            </div>
          </div>
          <div class="rules">
            <simple-rules
              [rules]="item.rulesEditableInSchedule"
              [editable]="true"
              (onRuleClick)="handleRuleClick(item, $event)"
            >
            </simple-rules>
          </div>
        </ng-container>
        <ng-container slot="actions">
          <div
            class="can-be-assigned"
            [title]="'CanBeAssigned' | translate"
            (click)="toggleCanBeAssigned(item)"
          >
            <div
              class="bi icon"
              [ngClass]="{
                'bi-file-plus': !item.canBeAssigned,
                'bi-file-plus-fill': item.canBeAssigned
              }"
            ></div>
          </div>
        </ng-container> -->
