import { Component, effect, inject, input, model } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { PublishingType, Schedule } from "app/components/schedule/schedule";
import { GlobalStateService } from "app/global-state/global-state.service";
import { ScheduleService } from "app/services/schedule.service";
import { TranslationService } from "app/services/translation.service";
import { Utils } from "app/tools/utils";
import { DateTime } from "luxon";
import { Drawer } from "primeng/drawer";
import {
  DateItem,
  TimelineComponent,
} from "../../../schedule/timeline/timeline.component";

@Component({
  selector: "simple-view-timeline",
  templateUrl: "./simple-view-timeline.component.html",
  styleUrls: ["./simple-view-timeline.component.less"],
  standalone: true,
  imports: [TranslateModule, Drawer, TimelineComponent],
})
export class SimpleViewTimelineComponent {
  visible = model<boolean>(false);

  headerText = input<string>();
  timeline = model<Schedule>();

  private scheduleService = inject(ScheduleService);
  private globalState = inject(GlobalStateService);
  private translationService = inject(TranslationService);

  constructor() {
    effect(() => {
      if (this.visible()) {
        this.refreshPublicationDates();
      }
    });
  }

  private async refreshPublicationDates() {
    const publicationDates = await this.scheduleService.getPublicationDates(
      this.timeline().toPayloadObject(
        this.globalState.selectedOrganization().id,
      ),
    );

    const copy = new Schedule({ ...this.timeline() });
    let publicationDatesSecondBatch = [];
    if (publicationDates.length) {
      copy.startDate = DateTime.fromSQL(
        publicationDates[publicationDates.length - 1],
      )
        .plus({ days: 1 })
        .toSQL();
      publicationDatesSecondBatch =
        await this.scheduleService.getPublicationDates(
          copy.toPayloadObject(this.globalState.selectedOrganization().id),
        );
    }

    let allDates = [...publicationDates, ...publicationDatesSecondBatch];

    if (
      !allDates.length &&
      this.timeline().publishingType === PublishingType.Once
    ) {
      allDates = [
        `${
          this.timeline().toPayloadObject(
            this.globalState.selectedOrganization().id,
          ).startDate
        } ${this.timeline().publishingTime}`,
      ];
    }

    this.timeline().publicationDates.set([]);
    this.timeline().publicationDates.set(
      allDates.map(
        (item) =>
          new DateItem({
            yearString: Utils.getYear(item),
            dayAndMonthString: `${Utils.getDay(item)} ${Utils.getMonthName(
              item,
            )}`,
            dayNameString: Utils.getDayName(item),
            infoText: this.getInfoText(item),
          }),
      ),
    );

    setTimeout(() => {
      this.timeline()
        .publicationDates()
        .forEach((item) => item.display.set(true));
    }, 0);
  }

  private getInfoText(dateString: string) {
    const first = DateTime.fromSQL(dateString)
      .toLocaleString(DateTime.DATETIME_MED)
      .replace(".", "");
    const second = `${this.translationService.instant("Expires")}:\n${DateTime.fromSQL(
      dateString,
    )
      .plus({ days: this.timeline().daysDelay })
      .toLocaleString(DateTime.DATETIME_MED)
      .replace(".", "")}`;
    return `${first}\n\n${second}`;
  }
}
