<div
  class="list-actions"
  [ngClass]="{
    'in-modal': inModal(),
    'in-wizard': inWizard(),
  }"
>
  @for (action of visibleActions(); track $index) {
    <div
      class="list-action"
      [title]="action.titleText | translate"
      (click)="action.event()"
    >
      <div class="icon {{ action.iconName }}"></div>
      <div class="text">
        {{ action.name | translate }}
      </div>
    </div>
  }
</div>
