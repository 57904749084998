import { LowerCasePipe, NgClass } from "@angular/common";
import { Component, input, output, viewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NgLabelTemplateDirective,
  NgOption,
  NgSelectComponent,
  NgSelectConfig,
} from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { TranslationService } from "app/services/translation.service";
import { SimpleFormErrorComponent } from "../form-error/simple-form-error.component";
import { SimpleFormControlDirective } from "../simple-form-control.directive";

@Component({
  selector: "simple-form-select",
  templateUrl: "./simple-form-select.component.html",
  styleUrls: ["./simple-form-select.component.less"],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgSelectComponent,
    NgLabelTemplateDirective,
    SimpleFormErrorComponent,
    LowerCasePipe,
    TranslateModule,
  ],
})
export class SimpleFormSelectComponent extends SimpleFormControlDirective {
  items = input<FormSelectItem[]>();
  multiple = input<boolean>();
  searchable = input<boolean>();
  addable = input<boolean>();
  redBackground = input<boolean>();
  greenBackground = input<boolean>();
  sortOnAdd = input<boolean>();
  useStrictSearch = input<boolean>();

  onAddNewItem = output<FormSelectItem>();

  ngSelectComponent = viewChild(NgSelectComponent);

  constructor(config: NgSelectConfig, translationService: TranslationService) {
    super();
    config.clearAllText = translationService.instant("Clear");
    config.notFoundText = translationService.instant("NoDataWasFound");
    config.addTagText = translationService.instant("Add");
  }

  addTagFn(name: string) {
    const newItem = new FormSelectItem(name, name);
    newItem.newlyAdded = true;
    return newItem;
  }

  handleAdd(item: FormSelectItem) {
    if (item.newlyAdded) {
      this.onAddNewItem.emit(item);
    }
    if (this.sortOnAdd()) {
      function compare(a: NgOption, b: NgOption) {
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
        return 0;
      }
      this.ngSelectComponent().selectedItems.sort(compare);
    }
  }

  searchFnStrict(term: string, item: { name: string }) {
    return item.name.includes(term);
  }

  searchFnLoose(term: string, item: { name: string }) {
    return item.name.toLowerCase().includes(term.toLowerCase());
  }
}

export class FormSelectItem {
  id: any;
  name: string;
  newlyAdded: boolean;

  constructor(id: any, name: string) {
    this.id = id;
    this.name = name;
  }
}
