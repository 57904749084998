import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTablePublishDateColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => PublishDateContent;

  constructor(
    labels: string[],
    getValue: (row: T) => PublishDateContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.PUBLISH_DATE;
    this.maxwidth = 110;
  }
}

export class PublishDateContent {
  date: string;
  active: boolean;

  constructor(date: string, active: boolean) {
    this.date = date;
    this.active = active;
  }
}
