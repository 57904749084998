import { Component, model } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Drawer } from "primeng/drawer";

@Component({
  selector: "simple-view-items",
  templateUrl: "./simple-view-items.component.html",
  styleUrls: ["./simple-view-items.component.less"],
  standalone: true,
  imports: [TranslateModule, Drawer],
})
export class SimpleViewItemsComponent {
  visible = model<boolean>(false);
}
