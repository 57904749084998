import { Injectable } from "@angular/core";
import { GeneratedReport } from "app/components/assignment/report/generated/generated-report";
import { Report } from "app/components/assignment/report/report";
import { StaticReport } from "app/components/assignment/report/static/static-report";
import { Subscriber } from "app/components/user/user";
import { BaseService } from "app/services/base.service";
import { RoutesUtils } from "app/tools/routes-utils";

@Injectable({
  providedIn: "root",
})
export class ReportService extends BaseService<Report> {
  prefix = RoutesUtils.assignment;

  constructor() {
    super(Report);
  }

  // TODO vad ger denna tillbaka? Typa!
  async getGeneratedReport(report: GeneratedReport) {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/report`,
        report,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async getStaticReports(report: StaticReport) {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/staticreports`,
        report,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getReportData(itemIds: string[], orgId: number): Promise<string[]> {
    try {
      const model = {
        itemIds: itemIds,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/reportscount?organization=${orgId}`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getSubscribers(
    itemIds: string[],
    orgId: number,
  ): Promise<Subscriber[]> {
    try {
      const model = {
        itemIds: itemIds,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/subscribers?organization=${orgId}`,
        model,
      );
      return this.extractData(response).map(
        (item: Subscriber) => new Subscriber(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}
