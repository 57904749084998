import { Component, computed, Signal } from "@angular/core";
import { SimpleRulesComponent } from "../../../../../various/rules/simple-rules.component";
import { RuleContent } from "../../../columns/simple-table-rule-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-rule",
  templateUrl: "./column-type-rule.component.html",
  standalone: true,
  imports: [SimpleRulesComponent],
})
export class ColumnTypeRuleComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<RuleContent>;
}
