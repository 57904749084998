@if (!isInModal()) {
  <ng-container *ngTemplateOutlet="tableContent"></ng-container>
}
@if (isInModal()) {
  <simple-crud-modal
    [visible]="visible()"
    [headerText]="'AddAssignees' | translate"
    [closeText]="'Close' | translate"
    [submitText]="'ConfirmSelected' | translate"
    [large]="true"
    (onClose)="handleClose()"
    (onSubmit)="handleConfirm()"
  >
    <ng-container *ngTemplateOutlet="tableContent"></ng-container>
  </simple-crud-modal>
}

<ng-template #tableContent>
  <list-header
    [emptyState]="emptyState()"
    [listActions]="listActions()"
    [columns]="columns()"
    [inModal]="isInModal()"
    (onCustomColumnConfigChange)="handleCustomColumnConfigChange($event)"
  ></list-header>
  <simple-table
    [initialFacets]="initialFacets()"
    [searchValue]="searchValue()"
    [filterInputs]="filterInputs()"
    [sortObjects]="sortObjects()"
    [currentSortObject]="currentSortObject()"
    [columns]="columns()"
    [headerActions]="headerActions()"
    [rowActions]="rowActions()"
    [emptyState]="emptyState()"
    [filteredIds]="filteredIds()"
    [rows]="items()"
    [selectedIds]="selectedIds()"
    [addedIds]="addedIds()"
    [modifiedIds]="modifiedIds()"
    [unselectableIds]="unselectableIds()"
    [pending]="pending()"
    [inModal]="isInModal()"
    [totalCount]="totalCount"
    [selectedCount]="selectedCount"
    [hasSelectableData]="hasSelectableData"
    [someAreSelected]="someAreSelected"
    [allAreSelected]="allAreSelected"
    [hasActiveSearchOrFilter]="hasActiveSearchOrFilter"
    (onSearch)="handleSearch($event)"
    (onSearchClear)="handleSearchClear()"
    (onSortDirectionChange)="handleSortDirectionChange($event)"
    (onSortLabelChange)="handleSortLabelChange($event)"
    (onFilter)="handleFilter($event)"
    (onFilterClear)="handleFilterClear()"
    (onSelectedClear)="handleSelectedClear()"
    (onCheckboxClick)="handleCheckboxClick($event)"
    (onRowSingleClick)="handleRowSingleClick($event)"
    (onRowDoubleClick)="handleRowDoubleClick($event)"
    (onRowCheckboxClick)="handleRowCheckboxClick($event)"
    (onLazyLoad)="handleLazyLoad($event)"
  >
  </simple-table>

  <create-edit-user
    [visible]="visibleCreateEdit()"
    [itemIds]="itemIds()"
    [useUnsavedChangesLogic]="!isInModal()"
    [displayDeleteButton]="false"
    [isInvite]="isInvite()"
    (onClose)="handleCloseCreateEdit()"
    (onDelete)="handleDelete()"
    (onRequestDone)="handleRequestDoneCreateEdit($event)"
  ></create-edit-user>

  <resend-email
    [visible]="visibleResendEmail()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseResendEmail()"
    (onRequestDone)="handleRequestDoneResendEmail($event)"
  ></resend-email>

  <change-password
    [visible]="visibleChangePassword()"
    [otherUserId]="itemIds()[0]"
    (onClose)="handleCloseChangePassword()"
    (onRequestDone)="handleRequestDoneChangePassword($event)"
  ></change-password>

  <change-status-user
    [visible]="visibleChangeStatus()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseSetStatus()"
    (onRequestDone)="handleRequestDoneSetStatus($event)"
  >
  </change-status-user>
</ng-template>
