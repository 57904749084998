import { KeyValuePair } from "app/filter";
import { TranslationService } from "app/services/translation.service";
import { ColorUtils } from "app/tools/color-utils";
import { StringUtils } from "app/tools/string-utils";
import { ChartConfiguration, ScriptableScaleContext } from "chart.js";
import { DateTime } from "luxon";

export class DashboardUtils {
  public static filterObjectScheduledExpiringFacets = [
    new KeyValuePair(StringUtils.IS_ACTIVE_KEY, "true"),
    new KeyValuePair(StringUtils.IS_STARTED_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MEASURE_KEY, "false"),
    new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "false"),
    new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
  ];

  public static filterObjectScheduledCompletedFacets = [
    new KeyValuePair(StringUtils.IS_COMPLETED_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MEASURE_KEY, "false"),
    new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "false"),
    new KeyValuePair(StringUtils.STATUS_KEY, StringUtils.COMPLETED),
    new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
  ];

  public static filterObjectScheduledExpiredFacets = [
    new KeyValuePair(StringUtils.IS_EXPIRED_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MEASURE_KEY, "false"),
    new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "false"),
    new KeyValuePair(StringUtils.STATUS_KEY, StringUtils.EXPIRED),
    new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
  ];

  public static filterObjectMeasureCreatedFacets = [
    new KeyValuePair(StringUtils.IS_ACTIVE_KEY, "true"),
    new KeyValuePair(StringUtils.IS_STARTED_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MEASURE_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "true"),
    new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
  ];

  public static filterObjectMeasureResolvedFacets = [
    new KeyValuePair(StringUtils.IS_COMPLETED_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MEASURE_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "true"),
    new KeyValuePair(StringUtils.STATUS_KEY, StringUtils.COMPLETED),
    new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
  ];

  public static filterObjectMeasureClosedFacets = [
    new KeyValuePair(StringUtils.IS_CLOSED_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MEASURE_KEY, "true"),
    new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "true"),
    new KeyValuePair(StringUtils.STATUS_KEY, StringUtils.COMPLETED),
    new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
  ];

  public static getGreeting(translationService: TranslationService) {
    const hour = DateTime.now().hour;

    if (hour >= 21) {
      return translationService.instant("GoodNight");
    }
    if (hour >= 18) {
      return translationService.instant("GoodEvening");
    }
    if (hour >= 15) {
      return translationService.instant("GoodAfternoon");
    }
    if (hour >= 12) {
      return translationService.instant("GoodDay");
    }
    if (translationService.currentLang === "sv" && hour >= 9) {
      return "God förmiddag";
    }
    return translationService.instant("GoodMorning");
  }

  public static isCurrent(
    context: ScriptableScaleContext,
    currentTodayValue: string,
  ) {
    let label = context.tick.label;
    if (typeof label === "string") {
      label = label;
    } else {
      label = label[0];
    }
    return label === currentTodayValue;
  }

  public static chartOptions: ChartConfiguration["options"] = {
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
            family: "Source Sans Pro",
            weight: 600,
          },
          autoSkip: false,
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
            family: "Source Sans Pro",
            weight: 600,
          },
          autoSkip: false,
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        radius: 4,
      },
    },
    animation: {
      easing: "easeOutQuad",
      duration: 1000,
      onComplete: (event) => {
        if (event.chart.data.datasets.length) {
          event.chart.data.datasets[0].datalabels.display = true;
          event.chart.update();
        }
      },
    },
    plugins: {
      legend: {
        onClick: null,
        position: "bottom",
        labels: {
          borderRadius: 4,
        },
        display: false,
      },
      datalabels: {
        color: ColorUtils.WHITE_COLOR,
        font: {
          weight: "bold",
          size: 12,
          family: "Roboto",
        },
        anchor: "end",
      },
      tooltip: {
        displayColors: false,
        animation: false,
        enabled: false,
      },
    },
    maintainAspectRatio: false,
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
  };
}
