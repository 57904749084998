import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { PropertyNameGetter } from "app/tools/property-name-getter";
import { Utils } from "app/tools/utils";

export class Integration extends CrudItem<Integration> {
  organizationId: number;
  name: string = "";
  active: boolean = false;
  type: IntegrationTypes;
  variables: VariablesObject[] = [];

  constructor(integration: Partial<Integration>) {
    super();
    Object.assign(this, integration);
  }
}

export class ExternalIntegration extends Integration {
  constructor(externalIntegration: Partial<ExternalIntegration>) {
    super(externalIntegration);
    Object.assign(this, externalIntegration);
  }
}

export class ReportIntegration extends Integration {
  constructor(reportIntegration: Partial<ReportIntegration>) {
    super(reportIntegration);
    Object.assign(this, reportIntegration);
  }
}

export class SSENReportIntegration extends ReportIntegration {
  inAccordanceWith: string = "";
  scope: string = "";
  riskAnalysis: string = "";
  errorCodeExplanation: string = "";
  serenoReference: string = "";
  templateBaseTypeId: string = "";

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf(
      {} as new (args?: any) => SSENReportIntegration
    );
  }

  constructor(integration: Partial<SSENReportIntegration>) {
    super(integration);
    Object.assign(this, integration);
    this.inAccordanceWith = this.variables.find(
      (variable) => variable.key === this.propertyStrings.inAccordanceWith
    ).value;
    this.riskAnalysis = this.variables.find(
      (variable) => variable.key === this.propertyStrings.riskAnalysis
    ).value;
    this.errorCodeExplanation = this.variables.find(
      (variable) => variable.key === this.propertyStrings.errorCodeExplanation
    ).value;
    this.serenoReference = this.variables.find(
      (variable) => variable.key === this.propertyStrings.serenoReference
    ).value;
  }

  toPayloadObject() {
    this.variables = [];
    return new SSENReportIntegrationPayload({
      id: this.id,
      organizationId: this.organizationId,
      active: this.active,
      type: this.type,
      variables: this.getVariables().filter((variable) => variable != null),
      scope: this.scope,
      templateBaseTypeId: this.templateBaseTypeId,
    });
  }

  private getVariables() {
    this.variables.push(
      new VariablesObject({
        key: this.propertyStrings.inAccordanceWith,
        value: this.inAccordanceWith,
      }),
      new VariablesObject({
        key: this.propertyStrings.riskAnalysis,
        value: this.riskAnalysis,
      }),
      new VariablesObject({
        key: this.propertyStrings.errorCodeExplanation,
        value: this.errorCodeExplanation,
      }),
      new VariablesObject({
        key: this.propertyStrings.serenoReference,
        value: this.serenoReference,
      })
    );
    return this.variables;
  }
}

export class SSENReportIntegrationPayload extends SSENReportIntegration {
  constructor(integration: Partial<SSENReportIntegration>) {
    super(integration);
    Object.assign(this, integration);
  }
}

export class DfResponsIntegration extends ExternalIntegration {
  email: string;

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf(
      {} as new (args?: any) => DfResponsIntegration
    );
  }

  constructor(integration: Partial<DfResponsIntegration>) {
    super(integration);
    Object.assign(this, integration);
    this.email = this.variables.find(
      (variable) => variable.key === this.propertyStrings.email
    )?.value;
  }

  toPayloadObject() {
    this.variables = [];
    return new DfResponsIntegration({
      id: this.id,
      organizationId: this.organizationId,
      active: this.active,
      type: this.type,
      variables: this.getVariables().filter((variable) => variable != null),
    });
  }

  private getVariables() {
    this.variables.push(
      new VariablesObject({
        key: this.propertyStrings.email,
        value: this.email,
      })
    );
    return this.variables;
  }
}

export class InfraControlIntegration extends ExternalIntegration {
  system: string;
  username: string;
  serviceAccountEmail: string;
  newPassword: string;
  confirmPassword: string;
  oldPassword: string;
  emails: string[] = [];
  password: string;
  oldValue: string;

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf(
      {} as new (args?: any) => InfraControlIntegration
    );
  }

  constructor(integration: Partial<InfraControlIntegration>) {
    super(integration);
    Object.assign(this, integration);
    this.system = this.variables.find(
      (variable) => variable.key === this.propertyStrings.system
    )?.value;
    this.username = this.variables.find(
      (variable) => variable.key === this.propertyStrings.username
    )?.value;
    this.serviceAccountEmail = this.variables.find(
      (variable) => variable.key === this.propertyStrings.serviceAccountEmail
    )?.value;
    const poop = this.variables.find(
      (variable) => variable.key === this.propertyStrings.emails
    );
    this.emails = poop ? [poop.value] : [];
  }

  toPayloadObject() {
    this.variables = [];
    return new InfraControlIntegration({
      id: this.id,
      organizationId: this.organizationId,
      active: this.active,
      type: this.type,
      variables: this.getVariables().filter((variable) => variable != null),
    });
  }

  getVariables() {
    this.variables.push(
      new VariablesObject({
        key: Utils.nameof<InfraControlIntegration>("system"),
        value: this.system,
      }),
      new VariablesObject({
        key: Utils.nameof<InfraControlIntegration>("username"),
        value: this.username,
      }),
      new VariablesObject({
        key: Utils.nameof<InfraControlIntegration>("serviceAccountEmail"),
        value: this.serviceAccountEmail,
      }),
      this.newPassword
        ? new VariablesObject({
            key: Utils.nameof<InfraControlIntegration>("password"),
            value: this.newPassword,
            oldValue: this.id ? this.oldPassword : null,
          })
        : null,
      new VariablesObject({
        key: Utils.nameof<InfraControlIntegration>("emails"),
        value: this.emails.join(),
      })
    );
    return this.variables;
  }
}

export class VariablesObject {
  key: string;
  value: string;
  oldValue: string;

  constructor(variablesObject: Partial<VariablesObject>) {
    Object.assign(this, variablesObject);
  }
}

export enum IntegrationTypes {
  InfraControl = "InfraControl",
  DfRespons = "DfRespons",
  StandardReport = "StandardReport",
  SSENReport = "SSENReport",
  None = "None",
}

export enum SSENReportIntegrationTypes {
  InAccordanceWith = "InAccordanceWith",
  Scope = "Scope",
  RiskAnalysis = "RiskAnalysis",
  ErrorCodeExplanation = "ErrorCodeExplanation",
  SerenoReference = "SerenoReference",
  TemplateBaseTypeId = "TemplateBaseTypeId",
}
