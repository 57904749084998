import { Component, inject, input, model } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "app/app-initializer";
import { Client } from "app/components/organization/organization";
import { Drawer } from "primeng/drawer";

@Component({
  selector: "simple-view-client",
  templateUrl: "./simple-view-client.component.html",
  styleUrls: ["./simple-view-client.component.less"],
  standalone: true,
  imports: [TranslateModule, Drawer],
})
export class SimpleViewClientComponent {
  visible = model<boolean>(false);

  headerText = input<string>();
  client = input<Client>();

  appInitializer = inject(AppInitializer);
}
