import { NgTemplateOutlet } from "@angular/common";
import {
  Component,
  ElementRef,
  inject,
  input,
  signal,
  viewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
  SimpleTableRowActionView,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  EntityContent,
  SimpleTableEntityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-entity-column";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  IconContent,
  IconObject,
  SimpleTableIconColumn,
} from "app/components/simple-components/list/table/columns/simple-table-icon-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import {
  SimpleTableHeaderAction,
  SimpleTableHeaderActionDelete,
} from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { SimpleViewContentComponent } from "app/components/simple-components/various/view-content/simple-view-content.component";
import { SimpleViewLocationDataComponent } from "app/components/simple-components/various/view-location-data/simple-view-location-data.component";
import { KeyValuePair } from "app/filter";
import { ExportService } from "app/services/export.service";
import {
  ImportActionKey,
  ImportExportModelKey,
  ImportHelper,
} from "app/services/import-helper";
import { ImportService } from "app/services/import.service";
import { ParseXLSXService } from "app/services/parse-xlsx.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { FileHelper } from "app/tools/file-helper";
import { ACCEPTED_MEDIA_TYPES, FileUtils } from "app/tools/file-utils";
import { StringUtils } from "app/tools/string-utils";
import { coordinateSystems } from "app/tools/utils";
import Swal from "sweetalert2";
import { EntityFilter, EntityService } from "../../../services/entity.service";
import { ListHeaderComponent } from "../../header/list-header/list-header.component";
import { CreateEditEntityComponent } from "../create-edit-entity/create-edit-entity.component";
import { Entity } from "../entity";
import { ChangeAccessibilityEntityComponent } from "./change-accessibility/change-accessibility-entity.component";
import { ChangeStatusEntityComponent } from "./change-status/change-status-entity.component";

@Component({
  selector: "list-entity",
  templateUrl: "./list-entity.component.html",
  standalone: true,
  imports: [
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    ChangeStatusEntityComponent,
    ChangeAccessibilityEntityComponent,
    NgTemplateOutlet,
    ListHeaderComponent,
    SimpleViewContentComponent,
    CreateEditEntityComponent,
    SimpleViewLocationDataComponent,
  ],
})
export class ListEntityComponent extends ListBaseDirective<Entity> {
  readonly filterObject = new EntityFilter();
  acceptedMediaTypesForImport = ACCEPTED_MEDIA_TYPES.SPREADSHEET;

  visibleChangeStatus = signal<boolean>(false);
  visibleChangeAccessibility = signal<boolean>(false);
  visibleListFacility = signal<boolean>(false);
  locationData = signal<string[]>([]);
  extraInfo = signal<string>("");
  visibleViewLocationData = signal<boolean>(false);
  visibleViewContent = signal<boolean>(false);

  facilityIds = input<string[]>([]);
  onlyActiveEntities = input<boolean>();
  onlyEntitiesWithFacility = input<boolean>();

  importInput = viewChild<ElementRef>("importInput");

  private importService = inject(ImportService);
  private exportService = inject(ExportService);
  private importHelper = inject(ImportHelper);
  private parseXLSXService = inject(ParseXLSXService);
  private fileHelper = inject(FileHelper);

  constructor(private entityService: EntityService) {
    super(entityService);
  }

  protected async configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "Create",
        "NewEntity",
        StringUtils.icons.new,
        () => this.visibleCreateEdit.set(true),
        () => this.isInModal(),
      ),
      new SimpleListAction(
        "Import",
        "Import",
        StringUtils.icons.import,
        () => this.importInput().nativeElement.click(),
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
      new SimpleListAction(
        "Export",
        "Export",
        StringUtils.icons.export,
        () => this.export(),
        () =>
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl) ||
          this.isInModal(),
      ),
    ]);

    if (this.globalState.loggedInUser().isSuperAdmin()) {
      // Check if has any imports to start monitoring.
      try {
        const inProgress = await this.importService.hasImportInProgress(
          ImportExportModelKey.Entity,
          ImportActionKey.ImportEntities,
        );
        if (inProgress) {
          this.importService.monitorProgress(
            ImportExportModelKey.Entity,
            ImportActionKey.ImportEntities,
          );
        }
      } catch (error) {
        this.toastrService.error(error.message);
      }
    }
  }

  protected async configureTableFilter() {
    if (
      this.facilityIds().length ||
      this.onlyActiveEntities() ||
      this.onlyEntitiesWithFacility()
    ) {
      const initialFacets = [];
      if (this.facilityIds().length) {
        initialFacets.push(
          new KeyValuePair(
            StringUtils.FACILITIES_KEY,
            this.facilityIds().join(", "),
          ),
        );
      }
      if (this.onlyActiveEntities()) {
        initialFacets.push(new KeyValuePair(StringUtils.STATUS_KEY, "true"));
      }
      if (this.onlyEntitiesWithFacility()) {
        initialFacets.push(
          new KeyValuePair(StringUtils.HAS_FACILITY_KEY, "true"),
        );
      }
      this.initialFacets.set(initialFacets);
    }

    const facilitiesKey = StringUtils.FACILITIES_KEY;
    const locationDataKey = StringUtils.LOCATION_DATA_KEY;
    const accessibleKey = StringUtils.ACCESSIBILITY_KEY;
    const createdKey = StringUtils.CREATED_KEY;
    const updatedKey = StringUtils.UPDATED_KEY;
    const statusKey = StringUtils.STATUS_KEY;
    const hasFacilityKey = StringUtils.HAS_FACILITY_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [facilitiesKey]: null,
        [locationDataKey]: null,
        [accessibleKey]: null,
        [createdKey]: null,
        [updatedKey]: null,
        [statusKey]: null,
        [hasFacilityKey]: null,
      });
    }

    try {
      const facilities = await this.entityService.getFacilities();
      const convertedFacilities = facilities.map(
        (facility) => new SimpleFilterInputItem(facility.id, facility.name),
      );

      this.filterInputs.set([
        new SimpleFilterInput(
          facilitiesKey,
          facilitiesKey,
          StringUtils.icons.facility,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(facilitiesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(facilitiesKey, ""));
            }
            return keyValuePairs;
          },
          convertedFacilities,
        ),
        new SimpleFilterInput(
          hasFacilityKey,
          hasFacilityKey,
          StringUtils.icons.facility,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(hasFacilityKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(hasFacilityKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
          () => !!this.facilityIds().length,
        ),
        new SimpleFilterInput(
          accessibleKey,
          accessibleKey,
          StringUtils.icons.accessibility,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(accessibleKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(accessibleKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          locationDataKey,
          locationDataKey,
          StringUtils.icons.locationData,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(locationDataKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(locationDataKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          statusKey,
          statusKey,
          StringUtils.icons.setStatus,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(statusKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(statusKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.ACTIVE, StringUtils.ACTIVE),
            new SimpleFilterInputItem(
              StringUtils.INACTIVE,
              StringUtils.INACTIVE,
            ),
          ],
          () => this.onlyActiveEntities(),
        ),
        new SimpleFilterInput(
          createdKey,
          createdKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(createdKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(createdKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          updatedKey,
          updatedKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(updatedKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(updatedKey, ""));
            }
            return keyValuePairs;
          },
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  configureTableSort() {
    this.sortObjects.set([
      new SortObject(this.propertyStrings.name, "Name"),
      new SortObject(this.propertyStrings.description, "Description"),
      new SortObject(this.propertyStrings.manufacturer, "Manufacturer"),
      new SortObject("Facility.Name" as keyof Entity, "Facility", true),
      new SortObject(this.propertyStrings.created, "Created"),
      new SortObject(this.propertyStrings.updated, "Updated"),
      new SortObject(this.propertyStrings.accessible, "Accessibility"),
      new SortObject(this.propertyStrings.hasPosition, "LocationData"),
      new SortObject(this.propertyStrings.status, "Status"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableEntityColumn(["Name"], (row) => new EntityContent(row)),
      new SimpleTableTextColumn(
        ["Description"],
        (row) => new TextContent(row.description),
      ),
      new SimpleTableFacilityColumn(
        ["Facility"],
        (row) => new FacilityContent(row.facility),
      ),
      new SimpleTableIconColumn(
        [],
        (row) =>
          new IconContent([
            new IconObject(
              row.accessible ? StringUtils.icons.accessibility : "",
              "Accessibility",
              "AccessibilityTextEntity",
              true,
            ),
            new IconObject(
              !!row.latitude && !!row.longitude
                ? StringUtils.icons.locationData
                : "",
              "LocationData",
              `Lat: ${row.latitude}<BR>Lon: ${row.longitude}`,
            ),
            new IconObject(
              row.extraInfo ? StringUtils.icons.extraInfo : "",
              "ExtraInfo",
              row.extraInfo,
            ),
          ]),
      ),
      new SimpleTableDateColumn(
        ["Created", "Updated"],
        (row) => new DateContent(row.created, row.updated),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.status ? StringUtils.ACTIVE : StringUtils.INACTIVE,
          ),
        () => this.onlyActiveEntities(),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "ChangeAccessibility",
        StringUtils.icons.setAccessibility,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeAccessibility.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderAction(
        "ChangeStatus",
        StringUtils.icons.setStatus,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeStatus.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () => this.isInModal(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.handle,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowActionView(
        "ViewLocationData",
        StringUtils.icons.locationData,
        (row) => {
          this.locationData.set([row.latitude, row.longitude]);
          this.visibleViewLocationData.set(true);
        },
        (row) => !row.latitude || !row.longitude,
      ),
      new SimpleTableRowActionView(
        "ViewExtraInfo",
        StringUtils.icons.extraInfo,
        (row) => {
          this.extraInfo.set(row.extraInfo);
          this.visibleViewContent.set(true);
        },
        (row) => !row.extraInfo,
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        () => this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Entities", StringUtils.icons.entity),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(new SwalConfig(this.translationService).getDelete()).then(
      async (result) => {
        if (result.value) {
          this.handleCloseCreateEdit();
          this.itemIds.set(ids);
          this.pending.set(true);
          try {
            const data = await this.entityService.deleteRange(this.itemIds());
            this.pending.set(false);
            this.toastrService.success(
              this.onlyOneSelected
                ? this.translationService.instant("TheEntityWasDeleted")
                : this.translationService.instant("TheEntitiesWereDeleted"),
            );
            this.selectedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.unselectableIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.addedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.modifiedIds.update((ids) =>
              ids.filter((id) => !this.itemIds().includes(id)),
            );
            this.itemIds.set([]);
            this.getTableData();
          } catch (error) {
            this.pending.set(false);
            this.toastrService.error(error.message);
          }
        }
      },
    );
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleCloseSetStatus() {
    this.visibleChangeStatus.set(false);
    this.itemIds.set([]);
  }

  handleCloseSetAccessibility() {
    this.visibleChangeAccessibility.set(false);
    this.itemIds.set([]);
  }

  handleCloseListFacility() {
    this.visibleListFacility.set(false);
  }

  async handleRequestDoneSetStatus(data: Entity[]) {
    this.handleCloseSetStatus();
    await this.getTableDataAndScrollToItem(data[0].id);
    if (!this.isInModal()) {
      this.clearSelectedRows();
    }
  }

  async handleRequestDoneSetAccessibility(data: Entity[]) {
    this.handleCloseSetAccessibility();
    await this.getTableDataAndScrollToItem(data[0].id);
    if (!this.isInModal()) {
      this.clearSelectedRows();
    }
  }

  handleImportInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    if (file) {
      this.import(file);
    }
    input.value = "";
  }

  protected async import(file: File) {
    if (!FileUtils.isExcel(file)) {
      this.toastrService.error(
        this.translationService.instant(StringUtils.BAD_FILE_FORMAT),
      );
    }
    try {
      const inProgress = await this.importService.hasImportInProgress(
        ImportExportModelKey.Entity,
        ImportActionKey.ImportEntities,
      );
      if (inProgress) {
        this.toastrService.error(
          `${this.translationService.instant(StringUtils.IMPORT_IN_PROGRESS)}!`,
        );
      } else {
        let { value: coordinatSystem } = await Swal.fire(
          new SwalConfig(this.translationService).getInfo({
            title: this.translationService.instant("ChooseCoordinateSystem"),
            text: this.translationService.instant(
              "ChooseCoordinateSystemStatement",
            ),
            confirmButtonText: this.translationService.instant("Import"),
            cancelButtonText: this.translationService.instant("Cancel"),
            input: "select",
            inputOptions: {
              doNotConvert: "WGS84",
              ...coordinateSystems,
            },
          }),
        );
        if (coordinatSystem) {
          if (coordinatSystem === "doNotConvert") {
            coordinatSystem = "";
          }
          const parsedData = await this.parseXLSXService.parseFile(file);
          const data = this.importHelper.generateData(
            parsedData,
            ImportExportModelKey.Entity,
            coordinatSystem as string,
          );
          if (!data.length) {
            this.toastrService.error(
              this.translationService.instant(StringUtils.EMPTY_FILE_ERROR),
            );
          }
          const items = await this.importService.import(
            data,
            ImportExportModelKey.Entity,
            ImportActionKey.ImportEntities,
            this.globalState.selectedOrganization().id,
          );
          if (items.length) {
            this.toastrService.success(
              this.translationService.instant(StringUtils.IMPORT_SUCCEEDED),
            );
            this.handleRequestDoneCreateEdit(items);
          }
        }
      }
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  private async export() {
    this.pending.set(true);
    try {
      const fileData = await this.exportService.export(
        this.selectedIds(),
        ImportExportModelKey.Entity,
        this.globalState.selectedOrganization().id,
      );
      this.pending.set(false);
      this.fileHelper.handleFile(fileData);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }
}
