import { Result } from "app/components/assignment/assignment";
import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { RoutesUtils } from "app/tools/routes-utils";
import { Facility } from "../facility/facility";
import { TaskChoice } from "../template-type/template-type";

export class Entity extends CrudItem<Entity> {
  name: string = "";
  description: string = "";
  manufacturer: string = "";
  assembler: string = "";
  assemblyYear: string = null; // Needs to be instantiated with null instead of empty as it is used for the ng-select lib, and is optional.
  typeDesignation: string = "";
  infoLink: string = "";
  externalId: string = "";
  status: boolean = true;
  facilityId: string = null; // Needs to be instantiated with null instead of empty as it is used for the ng-select lib, and is optional.
  facility: Facility = null;
  media: MediaWidgetItem[] = [];
  thumbnailUrl: string = null;
  accessible: boolean = false;
  latitude: string = "";
  longitude: string = "";
  articleId: string = "";
  hasPosition: boolean = false;
  extraInfo: string = "";

  selectableFacilities: Facility[] = [];
  results: Result[] = [];
  issueExpanded: boolean = false;
  issueDetails: string = "";
  issueMedia: MediaWidgetItem[] = [];
  manualMeasureError: string = "";
  priority: boolean = false;
  taskChoices: TaskChoice[] = [];
  alreadyExists: boolean = false;
  numberOfErrors: number;

  get displayName() {
    return this.name;
  }

  constructor(entity: Partial<Entity>) {
    super();
    Object.assign(this, entity);
    this.mapData();
    this.fixOptionalTextFieldInconsistencies();
    this.setUrl(RoutesUtils.entities);
  }

  private mapData() {
    this.mapFacility();
    this.mapMedia();
    this.mapResults();
  }

  private mapFacility() {
    if (this.facility) {
      this.facility = new Facility(this.facility);
      this.facilityId = this.facility.id;
    }
  }

  private mapMedia() {
    this.media = this.media.map((media) => new MediaWidgetItem(media));
  }

  private mapResults() {
    const results = this.results.map((result) => new Result(result));
    if (results.length && results.every((result) => result.task)) {
      results.sortByProperty("task.text");
    }
    this.results = results;
  }

  private fixOptionalTextFieldInconsistencies() {
    if (this.description === null) {
      this.description = "";
    }
    if (this.manufacturer === null) {
      this.manufacturer = "";
    }
    if (this.assembler === null) {
      this.assembler = "";
    }
    if (this.assemblyYear !== null) {
      this.assemblyYear = this.assemblyYear.toString();
    }
    if (this.typeDesignation === null) {
      this.typeDesignation = "";
    }
    if (this.latitude === null) {
      this.latitude = "";
    }
    if (this.longitude === null) {
      this.longitude = "";
    }
    if (this.infoLink === null) {
      this.infoLink = "";
    }
    if (this.externalId === null) {
      this.externalId = "";
    }
    if (this.articleId === null) {
      this.articleId = "";
    }
    if (this.extraInfo === null) {
      this.extraInfo = "";
    }
    this.latitude = this.latitude.toString();
    this.longitude = this.longitude.toString();
  }

  toPayloadObject(orgId: number) {
    return new EntityPayload({
      id: this.id,
      name: this.name,
      description: this.description,
      status: this.status,
      facilityId: this.facilityId,
      manufacturer: this.manufacturer,
      assembler: this.assembler,
      assemblyYear: this.assemblyYear,
      typeDesignation: this.typeDesignation,
      latitude: this.latitude,
      longitude: this.longitude,
      accessible: this.accessible,
      infoLink: this.infoLink,
      externalId: this.externalId,
      thumbnailUrl: this.thumbnailUrl,
      organizationId: orgId,
      articleId: this.articleId,
      extraInfo: this.extraInfo,
    });
  }
}

export class EntityPayload extends Entity {
  organizationId: number;

  constructor(entityPayload: Partial<EntityPayload>) {
    super(entityPayload);
    Object.assign(this, entityPayload);
  }
}
