import { NgStyle } from "@angular/common";
import { Component, input } from "@angular/core";
import { Popover } from "primeng/popover";
import { ExtensiveImageComponent } from "../../../../media/extensive-image/extensive-image.component";

@Component({
  selector: "simple-image",
  templateUrl: "./simple-image.component.html",
  styleUrls: ["./simple-image.component.less"],
  standalone: true,
  imports: [ExtensiveImageComponent, Popover, NgStyle],
})
export class SimpleImageComponent {
  url = input<string>();
  rounded = input<boolean>();
  placeholderImageType = input<string>();
  size = input<number>();
}
