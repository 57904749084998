import { HttpClient, HttpResponseBase } from "@angular/common/http";
import { inject, Injectable, signal } from "@angular/core";
import { AuthenticationService } from "app/authentication/authentication.service";
import { TokenStorageService } from "app/authentication/token-storage.service";
import { GlobalStateService } from "app/global-state/global-state.service";
import { UrlUtils } from "app/tools/url-utils";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HostedHttpClientService {
  private isReloading = signal<boolean>(false);
  private refreshPromise = signal<Promise<void | null>>(null);
  private http = inject(HttpClient);
  private tokenStorage = inject(TokenStorageService);
  private authService = inject(AuthenticationService);
  private globalState = inject(GlobalStateService);

  get defaultOptions() {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      observe: "response",
      params: {},
    };
  }

  get hasToken() {
    return this.tokenStorage.getAccessData();
  }

  get tokenIsExpired() {
    return Date.now() >= this.tokenStorage.getAccessData().expiring_date;
  }

  async get(url: string, useCoreApi?: boolean) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const options = this.mergeOptions({});
    const response = await firstValueFrom(
      this.http.get<HttpResponseBase>(
        this.buildFullEndpoint(url, useCoreApi),
        options,
      ),
    );
    if (!response.ok) {
      throw response;
    }
    return response;
  }

  async getWithParams(url: string, params: any, useCoreApi?: boolean) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const options = this.mergeOptions({ params: params });
    const response = await firstValueFrom(
      this.http.get<HttpResponseBase>(
        this.buildFullEndpoint(url, useCoreApi),
        options,
      ),
    );
    if (!response.ok) {
      throw response;
    }
    return response;
  }

  async post(url: string, body: any, useCoreApi?: boolean) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const options = this.mergeOptions({});
    const response = await firstValueFrom(
      this.http.post<HttpResponseBase>(
        this.buildFullEndpoint(url, useCoreApi),
        body,
        options,
      ),
    );
    if (!response.ok) {
      throw response;
    }
    return response;
  }

  async postWithHeaders(
    url: string,
    body: any,
    headers: any,
    useCoreApi?: boolean,
  ) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const options = this.mergeOptions({ headers: headers });
    const response = await firstValueFrom(
      this.http.post<HttpResponseBase>(
        this.buildFullEndpoint(url, useCoreApi),
        body,
        options,
      ),
    );
    if (!response.ok) {
      throw response;
    }
    return response;
  }

  async put(url: string, body: any, useCoreApi?: boolean) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const options = this.mergeOptions({});
    const response = await firstValueFrom(
      this.http.put<HttpResponseBase>(
        this.buildFullEndpoint(url, useCoreApi),
        body,
        options,
      ),
    );
    if (!response.ok) {
      throw response;
    }
    return response;
  }

  async putWithoutContentType(url: string, body: any, useCoreApi?: boolean) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const options = this.mergeOptions({});
    delete options["headers"]["Content-Type"];
    const response = await firstValueFrom(
      this.http.put<HttpResponseBase>(
        this.buildFullEndpoint(url, useCoreApi),
        body,
        options,
      ),
    );
    if (!response.ok) {
      throw response;
    }
    return response;
  }

  async delete(url: string, useCoreApi?: boolean) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const options = this.mergeOptions({});
    const response = await firstValueFrom(
      this.http.delete<HttpResponseBase>(
        this.buildFullEndpoint(url, useCoreApi),
        options,
      ),
    );
    if (!response.ok) {
      throw response;
    }
    return response;
  }

  // async deleteWithParams(url: string, params: any, useCoreApi?: boolean) {
  //   if (!(await this.checkToken())) {
  //     return;
  //   }
  //   const options = this.mergeOptions({ params: params });
  //   const response = await firstValueFrom(
  //     this.http.delete<HttpResponseBase>(
  //       this.buildFullEndpoint(url, useCoreApi),
  //       options,
  //     ),
  //   );
  //   if (!response.ok) {
  //     throw response;
  //   }
  //   return response;
  // }

  async deleteWithBody(url: string, body: any, useCoreApi?: boolean) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const options = this.mergeOptions({});
    options["body"] = body;
    const response = await firstValueFrom(
      this.http.delete<HttpResponseBase>(
        this.buildFullEndpoint(url, useCoreApi),
        options,
      ),
    );
    if (!response.ok) {
      throw response;
    }
    return response;
  }

  // TODO httresponsebase också osv, response.ok osv?!?
  async fetch(url: string) {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Error! Status: ${response.status}`);
    }

    return await response.json();
  }

  // TODO fixa denna!!
  async upload() {
    if (!this.checkToken()) {
      return;
    }
    if (this.refreshPromise()) {
      await this.refreshPromise();
    }
  }

  private mergeOptions(options: object = {}): object {
    const headers = {
      ...this.defaultOptions.headers,
      ...options["headers"],
      Authorization: `${this.tokenStorage.getAccessData().token_type} ${this.tokenStorage.getAccessData().access_token}`,
    };

    const params = {
      ...this.defaultOptions.params,
      ...options["params"],
      organization: this.globalState.selectedOrganization()?.id,
    };

    return Object.assign({}, this.defaultOptions, options, {
      headers,
      params,
    });
  }

  private buildFullEndpoint(url: string, useCoreApi: boolean) {
    const server = useCoreApi
      ? UrlUtils.API_CORE_URL
      : UrlUtils.API_INSPECTION_URL;
    return `${server}${url}`;
  }

  private async checkToken() {
    if (this.isReloading()) {
      return false;
    } else if (!this.hasToken) {
      window.location.reload();
      this.isReloading.set(true);
      return false;
    } else if (this.tokenIsExpired && !this.refreshPromise()) {
      const promise = this.authService.refreshLogin().finally(() => {
        this.refreshPromise.set(null);
      });
      this.refreshPromise.set(promise);
    }
    return true;
  }
}
