import { Component, signal } from "@angular/core";
import { FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { FormSelectItem } from "app/components/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component";
import { SimpleChangeTabComponent } from "app/components/simple-components/crud/modal/tabs/change/simple-change-tab.component";
import { ChangeDetailsBaseDirective } from "app/components/simple-components/directives/change-details-base.directive";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormInputComponent } from "../../../../../../simple-components/crud/modal/tabs/change/form/form-input/simple-form-input.component";
import { SimpleFormSelectButtonComponent } from "../../../../../../simple-components/crud/modal/tabs/change/form/form-select-button/simple-form-select-button.component";

@Component({
  selector: "change-status-tab",
  templateUrl: "./change-status-tab.component.html",
  standalone: true,
  imports: [
    SimpleChangeTabComponent,
    SimpleFormInputComponent,
    TranslateModule,
    SimpleFormSelectButtonComponent,
  ],
})
export class ChangeStatusTabComponent extends ChangeDetailsBaseDirective<MeasureAssignment> {
  selectableStatusItems = signal<FormSelectItem[]>([]);

  ngOnInit() {
    this.setSelectableStatusItems();

    this.form.set(
      this.formBuilder.group(
        {
          [this.propertyStrings.status]: [
            this.modifiedModel().status === StringUtils.ACTIVE
              ? StringUtils.NOT_STARTED
              : this.modifiedModel().status,
            Validators.required,
          ],
          [this.propertyStrings.notes]: [
            this.modifiedModel().notes,
            Validators.required,
          ],
        },
        { validators: this.measureValidator },
      ),
    );
    super.ngOnInit();
  }

  private measureValidator(form: FormGroup): ValidationErrors {
    const status = form.get("status");
    const notes = form.get("notes");

    if (
      status.value !== StringUtils.NOT_STARTED &&
      status.value !== StringUtils.STARTED &&
      !notes.value
    ) {
      notes.setErrors({ required: true });
    } else {
      notes.setErrors(null);
    }
    return null;
  }

  private setSelectableStatusItems() {
    const status =
      this.modifiedModel().status === StringUtils.STARTED
        ? StringUtils.STARTED
        : StringUtils.NOT_STARTED;
    this.selectableStatusItems.set([
      new FormSelectItem(status, this.translationService.instant(status)),
      new FormSelectItem(
        StringUtils.COMPLETED,
        this.translationService.instant(
          !this.modifiedModel().isManualMeasureAssignment
            ? StringUtils.RESOLVED
            : StringUtils.COMPLETED,
        ),
      ),
      new FormSelectItem(
        StringUtils.CLOSED,
        this.translationService.instant(StringUtils.CLOSED),
      ),
    ]);
  }
}
