import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AppInitializer } from "app/app-initializer";
import { GlobalStateService } from "app/global-state/global-state.service";
import { OrganizationService } from "app/services/organization.service";
import { UserService } from "app/services/user.service";
import { RoutesUtils } from "app/tools/routes-utils";
import { ToastrService } from "ngx-toastr";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  private url: string;
  private orgParam: string;

  private router = inject(Router);
  private globalState = inject(GlobalStateService);
  private organizationService = inject(OrganizationService);
  private appInitializer = inject(AppInitializer);
  private tokenStorage = inject(TokenStorageService);
  private toastrService = inject(ToastrService);
  private userService = inject(UserService);

  async canActivate(route: ActivatedRouteSnapshot) {
    this.url = route.url.join("/");
    this.orgParam = route.params.org;

    // If at login page, only allow if not logged in.
    if (this.url === "login-with-password" || this.url === "login-with-code") {
      if (this.tokenStorage.getAccessData()) {
        this.router.navigate([`/`]);
        return false;
      }
      return true;
    }

    // If not logged in, redirect to login page.
    if (!this.tokenStorage.getAccessData()) {
      this.globalState.setLoggedInUser(null);
      this.globalState.setSelectedOrganization(null);
      this.router.navigate(["/login-with-password"], {
        queryParams: {
          returnUrl: `/${this.url}`,
        },
      });
      return false;
    }

    // Set logged in user.
    if (!this.globalState.loggedInUser()) {
      try {
        const data = await this.userService.getLoggedInUser();
        this.globalState.setLoggedInUser(data);
      } catch (error) {
        this.toastrService.error(error.message);
        return false;
      }
    }

    // Initiate languages and other stuff.
    if (!this.appInitializer.initiated()) {
      await this.appInitializer.initiate();
    }

    // If no org param, redirect to user org.
    if (!this.orgParam) {
      this.router.navigate([
        `/${this.globalState.loggedInUser().organization.friendlyUrl}`,
      ]);
      return false;
    }

    // Validate org param.
    if (!this.globalState.selectedOrganization()) {
      const isUserOrg =
        this.globalState.loggedInUser().organization.friendlyUrl ===
        this.orgParam;
      const isMembershipOrg = this.globalState
        .loggedInUser()
        .organizationMemberships.some(
          (o) => o.organization.friendlyUrl === this.orgParam,
        );
      if (
        isUserOrg ||
        isMembershipOrg ||
        this.globalState.loggedInUser().isSuperAdmin()
      ) {
        try {
          const organizationByFriendlyUrl =
            await this.organizationService.getByFriendlyUrl(this.orgParam);
          const organizationById = await this.organizationService.get(
            organizationByFriendlyUrl.id,
          );
          this.globalState.setSelectedOrganization(organizationById);
        } catch (error) {
          this.toastrService.error(error.message);
          this.router.navigate([`/`]);
          return false;
        }
      } else {
        this.router.navigate([`/`]);
        return false;
      }
    }

    // If changing to another org, reload page.
    const isOrgChange =
      this.globalState.selectedOrganization() &&
      this.orgParam !== this.globalState.selectedOrganization().friendlyUrl;
    if (isOrgChange) {
      setTimeout(() => {
        window.location.reload();
      });
      return true;
    }

    // Validate route access.
    if (this.isRestrictedRoute()) {
      if (this.userIsAllowed()) {
        return true;
      } else {
        this.router.navigate([
          `/${this.globalState.selectedOrganization().friendlyUrl}/profile`,
        ]);
        return false;
      }
    } else {
      return true;
    }
  }

  private isRestrictedRoute() {
    return this.isAdminRoute() || this.isSuperAdminRoute();
  }

  private isAdminRoute() {
    return ADMIN_ROUTES.some((route) => this.url.includes(route));
  }

  private isSuperAdminRoute() {
    return SUPERADMIN_ROUTES.some((route) => this.url.includes(route));
  }

  private userIsAllowed() {
    if (
      this.isAdminRoute() &&
      (this.globalState.loggedInUser().isOrgAdmin(this.orgParam) ||
        this.globalState.loggedInUser().isSuperAdmin())
    ) {
      return true;
    } else if (
      this.isSuperAdminRoute() &&
      this.globalState.loggedInUser().isSuperAdmin()
    ) {
      return true;
    } else {
      return false;
    }
  }
}

const ADMIN_ROUTES = [
  RoutesUtils.dashboard,
  RoutesUtils.schedules,
  RoutesUtils.assignmentTemplates,
  RoutesUtils.facilities,
  RoutesUtils.entities,
  RoutesUtils.groups,
];

const SUPERADMIN_ROUTES = [
  RoutesUtils.templateTypes,
  RoutesUtils.tasks,
  RoutesUtils.choices,
];
