import { Category } from "app/components/simple-components/various/categories/simple-categories.component";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableCategoryColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (item: T) => CategoryContent;

  constructor(
    labels: string[],
    getValue: (row: T) => CategoryContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.CATEGORY;
  }
}

export class CategoryContent {
  categories: Category[];

  constructor(categories: Category[]) {
    this.categories = categories;
  }
}
