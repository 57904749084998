<handle-header
  [headerTitleText]="'AddedAssignees' | translate"
  [headerSubtitleText]="headerSubtitleText()"
  [listActions]="listActions()"
></handle-header>

<simple-table
  [columns]="columns()"
  [headerActions]="headerActions()"
  [rowActions]="rowActions()"
  [emptyState]="emptyState()"
  [rows]="items()"
  [selectedIds]="selectedIds()"
  [addedIds]="addedIds()"
  [modifiedIds]="modifiedIds()"
  [unselectableIds]="unselectableIds()"
  [inWizard]="true"
  [totalCount]="totalCount"
  [selectedCount]="selectedCount"
  [hasSelectableData]="hasSelectableData"
  [someAreSelected]="someAreSelected"
  [allAreSelected]="allAreSelected"
  (onCheckboxClick)="handleCheckboxClick($event)"
  (onRowSingleClick)="handleRowSingleClick($event)"
  (onRowDoubleClick)="handleRowDoubleClick($event)"
  (onRowCheckboxClick)="handleRowCheckboxClick($event)"
>
</simple-table>

<list-user
  [visible]="visibleListUser()"
  [isInModal]="true"
  [dataToSetAsSelected]="itemAsIds()"
  (onClose)="handleCloseListUser()"
  (onConfirm)="handleConfirmListUser($event)"
></list-user>

<!-- <ng-container slot="headerActions" *ngIf="!hideHeaderSelectAll">
    <button
      *ngIf="!isMediumScreenSize()"
      class="btn btn-success"
      #setRulesPop="bs-popover"
      placement="bottom"
      containerClass="gr-menu-popover"
      [popover]="setRulesTemplate"
      [outsideClick]="true"
      [ngClass]="{
        disabled: !someAreSelected
      }"
      [title]="'SetRulesOnSelected' | translate"
      (click)="initiateGlobalRules()"
    >
      {{ "SetRulesOnSelected" | translate }}
    </button>
    <button
      *ngIf="isMediumScreenSize()"
      class="btn btn-success bi-ui-checks-grid"
      #setRulesPop="bs-popover"
      placement="bottom"
      containerClass="gr-menu-popover"
      [popover]="setRulesTemplate"
      [outsideClick]="true"
      [ngClass]="{
        disabled: !someAreSelected
      }"
      [title]="'SetRulesOnSelected' | translate"
      (click)="initiateGlobalRules()"
    ></button>
    <ng-template #setRulesTemplate>
      <div class="gr-menu-item">
        <simple-rules
          [rules]="
            isMeasure
              ? readonlyItems
                ? globalDummyUser.rulesReadonlyInMeasureAssignment
                : globalDummyUser.rulesEditableInMeasureAssignment
              : readonlyItems
              ? globalDummyUser.rulesReadonlyInScheduledAssignment
              : globalDummyUser.rulesEditableInScheduledAssignment
          "
          [editable]="!readonlyItems"
          [shouldStopPropagationOnClick]="true"
          (onRuleClick)="handleRuleClickGlobal($event)"
        >
        </simple-rules>
      </div>
    </ng-template>
  </ng-container>

  <ng-container slot="content">
    <div class="image-details-wrapper">
      <notice-icon
        [shouldDisplay]="userExistsInAnySelectedAssigneeGroup(item)"
        [iconClass]="'bi bi-exclamation-circle-fill'"
        [titleText]="'UserExistsInGroup' | translate"
        [noticeText]="'UserExistsInGroup' | translate"
      >
      </notice-icon>
      <div class="item-image" #parentDiv>
        <extensive-image
          [imageUrl]="item.user.profileImageUrl"
          [imageStorageSize]="parentDiv | getImageStorageSize"
          [rounded]="true"
          [placeholderImageType]="'user'"
        >
        </extensive-image>
      </div>
      <div class="details">
        <div
          class="name"
          title="{{ item.user.fullName }}"
          *ngIf="item.user.fullName"
        >
          {{ item.user.fullName }}
        </div>
        <div
          class="description"
          *ngIf="item.user.email"
          [ngClass]="{
            only: !item.user.fullName
          }"
          title="{{ item.user.email }}"
        >
          {{ item.user.email }}
        </div>
      </div>
    </div>
    <div class="rules">
      <simple-rules
        [rules]="
          isMeasure
            ? readonlyItems
              ? item.rulesReadonlyInMeasureAssignment
              : item.rulesEditableInMeasureAssignment
            : readonlyItems
            ? item.rulesReadonlyInScheduledAssignment
            : item.rulesEditableInScheduledAssignment
        "
        [editable]="!readonlyItems"
        (onRuleClick)="handleRuleClick(item, $event)"
      >
      </simple-rules>
    </div>
    <div class="rules" *ngIf="!hideNotificationButton">
      <simple-rules
        [rules]="item.notifyOnAddRule"
        [editable]="!readonlyItems"
        (onRuleClick)="handleRuleClick(item, $event)"
      >
      </simple-rules>
    </div>
    <div class="status">
      <simple-status-label
        [text]="item.user.getCurrentStatus(selectedOrganization().id)"
      >
      </simple-status-label>
    </div>
  </ng-container> -->
