<handle-header
  [headerTitleText]="'AddedAssignmentTemplates' | translate"
  [headerSubtitleText]="headerSubtitleText()"
  [listActions]="listActions()"
></handle-header>

<simple-table
  [columns]="columns()"
  [headerActions]="headerActions()"
  [rowActions]="rowActions()"
  [emptyState]="emptyState()"
  [rows]="items()"
  [selectedIds]="selectedIds()"
  [addedIds]="addedIds()"
  [modifiedIds]="modifiedIds()"
  [unselectableIds]="unselectableIds()"
  [inWizard]="true"
  [totalCount]="totalCount"
  [selectedCount]="selectedCount"
  [hasSelectableData]="hasSelectableData"
  [someAreSelected]="someAreSelected"
  [allAreSelected]="allAreSelected"
  (onCheckboxClick)="handleCheckboxClick($event)"
  (onRowSingleClick)="handleRowSingleClick($event)"
  (onRowDoubleClick)="handleRowDoubleClick($event)"
  (onRowCheckboxClick)="handleRowCheckboxClick($event)"
>
</simple-table>

<list-assignment-template
  [visible]="visibleListAssignmentTemplate()"
  [isInModal]="true"
  [dataToSetAsSelected]="itemAsIds()"
  (onClose)="handleCloseListAssignmentTemplate()"
  (onConfirm)="handleConfirmListAssignmentTemplate($event)"
></list-assignment-template>

<!-- TODO -->
<!-- <ng-container slot="actions">
  <div class="quantity-counter">
    <button
      class="btn btn-transparent decrease-btn"
      [title]="'DecreaseQuantity' | translate"
      (click)="decreaseQuantity(item)"
    >
      -
    </button>
    <input
      type="number"
      class="value"
      [ngModel]="item.quantity"
      [title]="'ChangeQuantity' | translate"
      (ngModelChange)="handleModelChange(item, $event)"
      (focus)="handleFocus(item)"
      (blur)="handleBlur(item)"
      (wheel)="handleWheel(item, $event)"
      min="1"
    />
    <button
      class="btn btn-transparent increase-btn"
      [title]="'IncreaseQuantity' | translate"
      (click)="increaseQuantity(item)"
    >
      +
    </button>
  </div>
  <div
    class="publish-directly"
    *ngIf="loggedInUser() | isSuperAdmin"
    [title]="'PublishDirectly' | translate"
    (click)="togglePublishDirectly(item)"
  >
    <div
      class="bi icon"
      [ngClass]="{
        'bi-file-earmark-arrow-up': !item.publishDirectly,
        'bi-file-earmark-arrow-up-fill': item.publishDirectly
      }"
    ></div>
  </div>
</ng-container> -->
